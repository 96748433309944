import React, { useEffect, useState } from "react";
import axios from "axios";
import { apiUri } from "../../constants";
import BlogSubmittedPopover from "./BlogSubmittedPopover";
import Alert from "shared/Alert/Alert";

const NewsletterSubmission = () => {
  const [content, setContent] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [imageLinks, setImageLinks] = useState([""]);

  const [category, setCategory] = useState("");
  const [description, setDescription] = useState("");
  const [descriptionWordCount, setDescriptionWordCount] = useState(0);
  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const [designation, setDesignation] = useState<string>("");

  const [blogTitle, setBlogTitle] = useState<string>("");

  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [alertMsg, setAlertMsg] = useState<string>(
    "Unable to submit, please try again later"
  );

  const [successfullySubmitted, setSuccessfullySubmitted] =
    useState<boolean>(false);

  const handleDescriptionChange = (
    e: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setDescription(e.target.value);
    setDescriptionWordCount(
      e.target.value.split(/\s+/).filter((word: any) => word).length
    );
  };

  const toggleTag = (tag: string): void => {
    setSelectedTags((prevTags: string[]) =>
      prevTags.includes(tag)
        ? prevTags.filter((t: string) => t !== tag)
        : [...prevTags, tag]
    );
  };

  const handleImageLinkChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const newLinks = [...imageLinks];
    newLinks[index] = e.target.value;
    setImageLinks(newLinks);
  };

  const addImageLink = () => {
    setImageLinks([...imageLinks, ""]);
  };

  const removeImageLink = (index: number) => {
    const newLinks = [...imageLinks];
    newLinks.splice(index, 1);
    setImageLinks(newLinks);
  };

  useEffect(() => {
    if (showAlert) {
      const timer = setTimeout(() => setShowAlert(false), 5000);
      return () => clearTimeout(timer);
    }
  }, [showAlert]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);

    if (!name.trim() || !email.trim()) {
      setAlertMsg("Name and email are required!");
      setShowAlert(true);
      setIsSubmitting(false);
      return;
    }

    if (!category) {
      setAlertMsg("Please select a category!");
      setShowAlert(true);
      setIsSubmitting(false);
      return;
    }

    if (descriptionWordCount > 100) {
      setAlertMsg("Description must be within 100 words!");
      setShowAlert(true);
      setIsSubmitting(false);
      return;
    }

    if (imageLinks.some((link) => !link.trim())) {
      setAlertMsg("All image links must be filled or removed!");
      setShowAlert(true);
      setIsSubmitting(false);
      return;
    }

    if (selectedTags.length === 0) {
      setAlertMsg("Please select at least one tag!");
      setShowAlert(true);
      setIsSubmitting(false);
      return;
    }

    const formData = {
      index:
        blogTitle +
        (name.slice(0, 2) +
          "_" +
          email.slice(2, 4) +
          "_" +
          new Date().toISOString().slice(0, 4)),
      id:
        blogTitle +
        (name.slice(0, 2) +
          "_" +
          email.slice(2, 4) +
          "_" +
          new Date().toISOString().slice(0, 4)),
      featuredImage: imageLinks[0],
      titleImage: imageLinks[imageLinks.length - 1],
      title: blogTitle,
      desc: description,
      date: new Date().toISOString().split("T")[0],
      contentInHtml: content,
      href: "/blog-single",
      authorDesc: designation,
      authorName: name,
      category: category,
      readingTime: 4,
      tags: selectedTags,
      featuredCaptionImage: blogTitle,
      postType: "Standard",
    };

    try {
      const response = await axios.post(`${apiUri}/uploadBlog`, formData);

      if (response.status === 200 || response.status === 201) {
        setSuccessfullySubmitted(true);
        setName("");
        setEmail("");
        setContent("");
        setImageLinks([""]);
        setCategory("");
        setDescription("");
        setDescriptionWordCount(0);
        setSelectedTags([]);
      } else {
        alert("Something went wrong. Please try again.");
      }
    } catch (error: any) {
      console.error("Error submitting form:", error);
      alert(
        `Error: ${error.response?.data?.error || "Unknown error occurred"}`
      );
    } finally {
      setIsSubmitting(false);
    }
  };

  const fadeInUp = {
    initial: { opacity: 0, y: 20 },
    animate: { opacity: 1, y: 0 },
    transition: { duration: 0.6 },
  };

  const handlePopoverClose = () => {
    setSuccessfullySubmitted(false);
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-violet-500 via-purple-500 to-pink-500 animate-gradient-party p-6">
      {successfullySubmitted && (
        <BlogSubmittedPopover
          isOpen={successfullySubmitted}
          onClose={handlePopoverClose}
        />
      )}
      {showAlert && <Alert children={alertMsg} type="error" />}
      <div className="max-w-7xl mx-auto space-y-8 animate-fadeIn">
        {}
        <header className="text-center space-y-4">
          <h1 className="text-5xl font-bold text-white">✍️ Verdict Vibes</h1>
          <p className="text-2xl text-white/90">Your Stories, Your Voice!</p>
        </header>

        {}
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
          {}
          <div className="space-y-6 h-full animate-slideInLeft">
            <div className="bg-white/95 backdrop-blur p-8 rounded-2xl shadow-xl hover:shadow-2xl transition-all duration-300">
              <h2 className="text-3xl font-bold bg-gradient-to-r from-violet-600 to-pink-600 bg-clip-text text-transparent">
                Share Your Story
              </h2>

              <form onSubmit={handleSubmit} className="space-y-6 mt-6">
                <div className="group">
                  <label className="block text-lg font-medium mb-2 text-purple-700">
                    Your Name
                  </label>
                  <input
                    type="text"
                    value={name}
                    placeholder="What do you want us to call you? Pen names are allowed..."
                    onChange={(e) => setName(e.target.value)}
                    className="w-full p-3 border-2 border-purple-200 rounded-lg focus:border-purple-500 focus:ring-2 focus:ring-purple-200 transition-all"
                    required
                  />
                </div>

                <div className="group">
                  <label className="block text-lg font-medium mb-2 text-purple-700">
                    Your Designation
                  </label>
                  <input
                    type="text"
                    value={designation}
                    placeholder="What do you do? You can write law vigilante..."
                    onChange={(e) => setDesignation(e.target.value)}
                    className="w-full p-3 border-2 border-purple-200 rounded-lg focus:border-purple-500 focus:ring-2 focus:ring-purple-200 transition-all"
                    required
                  />
                </div>

                <div className="group">
                  <label className="block text-lg font-medium mb-2 text-purple-700">
                    Email
                  </label>
                  <input
                    type="email"
                    value={email}
                    placeholder="We need to communicate to you... serious stuff"
                    onChange={(e) => setEmail(e.target.value)}
                    className="w-full p-3 border-2 border-purple-200 rounded-lg focus:border-purple-500 focus:ring-2 focus:ring-purple-200 transition-all"
                    required
                  />
                </div>

                <div className="group">
                  <label className="block text-lg font-medium mb-2 text-purple-700">
                    Blog Title
                  </label>
                  <input
                    type="text"
                    value={blogTitle}
                    placeholder="What is the blog called?"
                    onChange={(e) => setBlogTitle(e.target.value)}
                    className="w-full p-3 border-2 border-purple-200 rounded-lg focus:border-purple-500 focus:ring-2 focus:ring-purple-200 transition-all"
                    required
                  />
                </div>

                <div className="group">
                  <label className="block text-lg font-medium mb-2 text-purple-700">
                    Category
                  </label>
                  <select
                    value={category}
                    onChange={(e) => setCategory(e.target.value)}
                    className="w-full p-3 border-2 border-purple-200 rounded-lg focus:border-purple-500 focus:ring-2 focus:ring-purple-200 transition-all"
                    required
                  >
                    <option value="">Select a Category</option>
                    <option value="Education">Legal Education 📚</option>
                    <option value="Opinion">Opinions 🗣️</option>
                    <option value="Commentary">Expert Commentary 💭</option>
                    <option value="Rants">Legal Rants 😂</option>
                    <option value="Cases">Case Analysis ⚖️</option>
                    <option value="Technology">Legal Tech 💻</option>
                  </select>
                </div>

                {}
                <div className="group">
                  <label className="block text-lg font-medium mb-2 text-purple-700">
                    Date
                  </label>
                  <input
                    type="text"
                    value={new Date().toISOString().split("T")[0]}
                    readOnly
                    className="w-full p-3 border-2 border-gray-300 rounded-lg bg-gray-100 text-gray-500"
                  />
                </div>

                {}
                <div className="group">
                  <label className="block text-lg font-medium mb-2 text-purple-700">
                    Description
                  </label>
                  <textarea
                    value={description}
                    onChange={handleDescriptionChange}
                    placeholder="Provide a 100-word summary of the content..."
                    className="w-full p-3 border-2 border-purple-200 rounded-lg focus:border-purple-500 focus:ring-2 focus:ring-purple-200 transition-all"
                    required
                  />
                  <p className="text-sm text-gray-500 mt-1">
                    {descriptionWordCount}/100 words
                  </p>
                </div>

                {}
                <div className="group">
                  <label className="block text-lg font-medium mb-2 text-purple-700">
                    Tags
                  </label>
                  <div className="flex flex-wrap gap-2">
                    {[
                      "Rant",
                      "Opinion",
                      "LegalTech",
                      "Law",
                      "Humor",
                      "Satire",
                      "Other",
                      "Case Analysis",
                      "Commentary",
                    ].map((tag, index) => (
                      <button
                        key={index}
                        type="button"
                        onClick={() => toggleTag(tag)}
                        className={`px-4 py-2 border-2 rounded-lg transition-all ${
                          selectedTags.includes(tag)
                            ? "bg-purple-600 text-white border-purple-600"
                            : "bg-white text-purple-700 border-purple-300 hover:bg-purple-100"
                        }`}
                      >
                        {tag}
                      </button>
                    ))}
                  </div>
                  <p className="text-sm text-gray-500 mt-1">
                    Selected Tags: {selectedTags.join(", ")}
                  </p>
                </div>

                <div className="group">
                  <label className="block text-lg font-medium mb-2 text-purple-700">
                    Image/Media Links
                  </label>
                  {imageLinks.map((link, index) => (
                    <div key={index} className="flex items-center mb-3">
                      <input
                        type="url"
                        value={link}
                        placeholder={`Image URL ${index + 1}`}
                        onChange={(e) => handleImageLinkChange(e, index)}
                        className="w-full p-3 border-2 border-purple-200 rounded-lg focus:border-purple-500 focus:ring-2 focus:ring-purple-200 transition-all"
                        required
                      />
                      <button
                        type="button"
                        onClick={() => removeImageLink(index)}
                        className="ml-2 text-red-500 hover:text-red-700"
                      >
                        ✖️
                      </button>
                    </div>
                  ))}
                  <button
                    type="button"
                    onClick={addImageLink}
                    className="mt-3 text-purple-600 hover:text-purple-800 font-medium"
                  >
                    ➕ Add Another Image
                  </button>
                </div>

                <div>
                  <label className="block text-lg font-medium mb-2 text-purple-700">
                    Your Story
                  </label>
                  <textarea
                    value={content}
                    onChange={(e) => setContent(e.target.value)}
                    className="w-full h-[600px] p-4 font-mono border-2 border-purple-200 rounded-lg focus:border-purple-500 focus:ring-2 focus:ring-purple-200 transition-all"
                    placeholder="✨ Let your creativity flow..."
                    required
                  />
                </div>

                <div className="flex gap-4">
                  <button
                    type="submit"
                    disabled={isSubmitting}
                    className="px-8 py-3 bg-gradient-to-r from-violet-600 to-pink-600 text-white rounded-lg font-bold hover:scale-105 active:scale-95 transition-all disabled:opacity-50 disabled:hover:scale-100"
                  >
                    {isSubmitting ? "🚀 Sending..." : "🚀 Submit"}
                  </button>
                  <button
                    type="button"
                    onClick={() => window.history.back()}
                    className="px-8 py-3 bg-gray-100 text-gray-700 rounded-lg font-bold hover:bg-gray-200 active:scale-95 transition-all"
                  >
                    ← Back
                  </button>
                </div>
              </form>
            </div>
          </div>

          {}
          <div className="space-y-6 animate-slideInRight">
            <div className="bg-white/95 backdrop-blur p-6 rounded-2xl shadow-xl hover:shadow-2xl transition-all duration-300">
              <h2 className="text-2xl font-bold text-purple-700 mb-3">
                📬 Important Note!
              </h2>
              <p className="text-gray-700">
                Not comfortable writing with HTML tags or styling? Or too busy
                to write and design? No worries! You can simply:
              </p>
              <ul className="list-disc pl-5 text-gray-700 mb-3">
                <li>
                  📝 Ask{" "}
                  <span className="font-bold text-blue-600">ChatGPT</span> to
                  convert your article into HTML for you.
                </li>
                <li>
                  📧 Email your document to
                  <span className="font-bold text-pink-600">
                    <a href="mailto:aditya@aimootcourt.com">
                      aditya@aimootcourt.com
                    </a>
                  </span>
                  with the article title as the email subject. We will do it for
                  you !!
                </li>
              </ul>
              <p className="text-gray-700">
                For more information, feel free to call us at 📞
                <span className="font-bold text-green-600">+91-8383990480</span>
                .
              </p>
            </div>
            <div className="bg-white/95 backdrop-blur p-6 rounded-2xl shadow-xl hover:shadow-2xl transition-all duration-300">
              <h2 className="text-2xl font-bold text-purple-700 mb-4">
                👀 Preview
              </h2>
              <div
                className="prose max-w-none min-h-[400px] p-6 border-2 border-purple-100 rounded-lg bg-white"
                dangerouslySetInnerHTML={{
                  __html: content || "Your story will appear here... ✨",
                }}
              />
            </div>

            <div className="bg-white/95 backdrop-blur p-6 rounded-2xl shadow-xl hover:shadow-2xl transition-all duration-300">
              <h2 className="text-2xl font-bold mb-4">
                🎯{" "}
                <span className=" bg-gradient-to-r from-violet-600 to-pink-600 bg-clip-text text-transparent">
                  Submission Tips
                </span>
              </h2>

              <div className="space-y-6">
                <div className="bg-gradient-to-r from-violet-50 to-pink-50 p-4 rounded-lg">
                  <h3 className="text-lg font-bold text-purple-700 mb-2">
                    ✨ Make It Pop!
                  </h3>
                  <ul className="space-y-2 text-gray-700">
                    <li className="flex items-center gap-2">
                      <span className="text-purple-500">→</span> Use HTML tags
                      for style
                    </li>
                    <li className="flex items-center gap-2">
                      <span className="text-purple-500">→</span> Add relevant
                      images
                    </li>
                    <li className="flex items-center gap-2">
                      <span className="text-purple-500">→</span> Keep it
                      engaging
                    </li>
                  </ul>
                </div>

                <div className="bg-gradient-to-r from-violet-50 to-pink-50 p-4 rounded-lg">
                  <h3 className="text-lg font-bold text-purple-700 mb-2">
                    🌟 Best Practices
                  </h3>
                  <ul className="space-y-2 text-gray-700">
                    <li className="flex items-center gap-2">
                      <span className="text-purple-500">→</span> Double-check
                      spelling
                    </li>
                    <li className="flex items-center gap-2">
                      <span className="text-purple-500">→</span> Credit your
                      sources
                    </li>
                    <li className="flex items-center gap-2">
                      <span className="text-purple-500">→</span> Be original!
                    </li>
                  </ul>
                </div>

                <div className="p-4 bg-gradient-to-r from-violet-100 to-pink-100 rounded-lg">
                  <p className="text-sm text-gray-700 leading-relaxed">
                    ℹ️ We'll review your awesome submission and get back to you
                    within 5 days. While we love reading every story, the final
                    call on publishing rests with our team.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewsletterSubmission;
