import React from "react";
import { motion } from "framer-motion";
import { Scale, ArrowLeft } from "lucide-react";

const Page404 = () => {
  return (
    <div className="min-h-screen flex items-center justify-center bg-gradient-to-b from-gray-50 to-white p-4">
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className="max-w-2xl mx-auto text-center"
      >
        {/* Icon */}
        <motion.div
          initial={{ rotate: -180, scale: 0 }}
          animate={{ rotate: 0, scale: 1 }}
          transition={{ type: "spring", duration: 1, bounce: 0.5 }}
          className="inline-block p-4 bg-purple-50 rounded-full mb-6"
        >
          <Scale className="w-12 h-12 text-purple-600" />
        </motion.div>

        {/* Main Error Message */}
        <h1 className="text-6xl font-bold mb-4 bg-gradient-to-r from-purple-600 to-blue-600 bg-clip-text text-transparent">
          404: Page Not Found
        </h1>

        {/* Legal-themed Error Description */}
        <div className="mb-8 space-y-4">
          <p className="text-xl text-gray-600">
            The requested page appears to be missing from our records.
          </p>
          <div className="p-6 bg-purple-50 rounded-lg border border-purple-100">
            <p className="text-gray-700 font-serif italic">
              "Whereas the requested URL has been duly examined and found to be
              non-existent, it is hereby declared that this page cannot be
              located within our jurisdiction."
            </p>
          </div>
        </div>

        {/* Legal Options */}
        <div className="space-y-4">
          <div className="text-gray-600">
            You may proceed with one of the following actions:
          </div>

          <div className="flex flex-col sm:flex-row gap-4 justify-center">
            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              onClick={() => window.history.back()}
              className="flex items-center justify-center px-6 py-3 rounded-lg bg-white border border-purple-200 text-purple-600 hover:bg-purple-50 transition-colors"
            >
              <ArrowLeft className="w-4 h-4 mr-2" />
              Return to Previous Page
            </motion.button>

            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              onClick={() => (window.location.href = "/")}
              className="px-6 py-3 rounded-lg bg-gradient-to-r from-purple-600 to-blue-600 text-white hover:shadow-lg transition-shadow"
            >
              Proceed to Homepage
            </motion.button>
          </div>
        </div>

        {/* Footer Note */}
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.5 }}
          className="mt-12 text-sm text-gray-500"
        >
          If you believe this to be an error, please contact our support team.
        </motion.div>
      </motion.div>
    </div>
  );
};

export default Page404;
