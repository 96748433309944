import React, { FC, useState } from "react";
import GallerySlider from "components/GallerySlider/GallerySlider";
import { CaseDataType } from "data/types";

export interface StayCardProps {
  className?: string;
  data?: CaseDataType;
  size?: "default" | "small";
  selectedCaseId: string ;
  onSelect: (id: string ) => void;
}

const CASE_DATA= 
  {
    id: "1",
    _id: "dsfsdfsd",
    type: "Case Study",
    title: "The Benefits of Adopting a Cat",
    image: [
      "https://example.com/images/cat1.jpg",
      "https://example.com/images/cat2.jpg",
    ],
    href: "https://example.com/case-studies/adopting-a-cat",
    }


const CaseSelectCard: FC<StayCardProps> = ({
  size = "default",
  className = "",
  data = CASE_DATA,
  selectedCaseId,
  onSelect,
}) => {
  const { id, type, title, image } = data || {};

  const handleClick = () => {
    // Set the selected case ID, clearing the previous selection
    onSelect(selectedCaseId === id ? "" : id);
  };
    
  const renderSliderGallery = () => {
    return (
      <div className="relative w-full">
        <GallerySlider
          uniqueID={`StayCard_${id}`}
          ratioClass="aspect-w-4 aspect-h-3"
          galleryImgs={image}
          href="#"
        />
        {selectedCaseId === id && (
          <div className="absolute top-2 left-2 bg-blue-500 text-white text-xs px-2 py-1 rounded">
            Selected
          </div>
        )}
      </div>
    );
  };

  const renderContent = () => {
    return (
      <div className={size === "default" ? "p-4 space-y-4" : "p-3 space-y-2"}>
        <div className="space-y-2">
          <span className="text-sm text-neutral-500 dark:text-neutral-400">
            {type}
          </span>
          <div className="flex items-center space-x-2">
            <h2
              className={`font-medium capitalize ${
                size === "default" ? "text-lg" : "text-base"
              }`}
            >
              <span className="line-clamp-1">{title}</span>
            </h2>
          </div>
        </div>
        <div className="border-b border-neutral-100 dark:border-neutral-800"></div>
      </div>
    );
  };

  return (
    <div>
      {/* Render the Card */}
      <div
        onClick={handleClick}
        className={`nc-StayCard group relative bg-white dark:bg-neutral-900 border ${
          selectedCaseId === id
            ? "border-blue-500"
            : "border-neutral-100 dark:border-neutral-800"
        } rounded-2xl ${
          selectedCaseId !== "" && selectedCaseId !== id
            ? "pointer-events-none opacity-50"
            : ""
        } hover:border-blue-500 overflow-hidden will-change-transform hover:shadow-xl transition hover:-translate-y-1 transition-shadow duration-300 ${className}`}
        data-nc-id="StayCard"
      >
        {renderSliderGallery()}
        <div>{renderContent()}</div>
      </div>

      {/* Render the bottom bar if a case is selected */}
      {selectedCaseId && (
        <div className="fixed bottom-0 left-0 right-0 z-50 bg-gray-800 text-white p-4 flex justify-between items-center shadow-lg">
          <span>Continue with making your lobby</span>
          <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
            Continue
          </button>
        </div>
      )}
    </div>
  );
};

// Wrapper Component to manage state
const CaseSelectCardContainer: FC<{ cases: CaseDataType[] }> = ({ cases }) => {
    const [selectedCaseId, setSelectedCaseId] = useState<string>("");
    sessionStorage.setItem("MootcourtSelectLobby", selectedCaseId )
  return (
    <div className="grid grid-cols-1 gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
      {cases.map((caseData) => (
        <CaseSelectCard
          key={caseData.id}
          data={caseData}
          size="default"
          className=""
          selectedCaseId={selectedCaseId}
          onSelect={setSelectedCaseId}
        />
      ))}
      {selectedCaseId && (
        <div className="fixed bottom-0 left-0 right-0 z-50 bg-gray-800 text-white p-4 flex justify-between items-center shadow-lg">
                  <span>Continue with making your lobby. Click close.</span>
            
        </div>
      )}
    </div>
  );
};

export default CaseSelectCardContainer;
