import { Tab } from "@headlessui/react";
import React, { FC, Fragment, useState, useEffect } from "react";
import axios from "axios";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import SocialsList from "shared/SocialsList/SocialsList";
import { Helmet } from "react-helmet";
import { apiUri } from "../../constants";
import LoadingIcon from "components/Loading/LoadingIcon";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ChangePasswordPopover from "./ResetPasswordPopover";

export interface ProfileProps {
  className?: string;
}

const Profile: FC<ProfileProps> = ({ className = "" }) => {
    let [categories] = useState(["Hosted Courts", "Registered Courts"]);
    const [loading, setLoading] = useState(true)
    const [isPopoverOpen, setIsPopoverOpen] = useState(false)
  
  const [userData, setUserData] = useState({
    email: "",
    phoneNumber: "",
    hostedCourts: [] as any[], // Adjust type as needed
    registeredCourts: [] as any[], // Adjust type as needed
  });
    
    const handleLogout = () => {
  sessionStorage.clear()
  sessionStorage.clear()

  window.location.href = "/login"

    }
    
    const handlePopoverClose = () => {
        setIsPopoverOpen(false)
    }

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const uri = `${apiUri}/fetchUserPartiCourt`;
        
        const response = await axios.post(uri, {
          email: sessionStorage.getItem('email'), 
        });

        const data = response.data;

          if (data.success) {
            console.log(data.data)
          setUserData({
            email: data.data.email,
            phoneNumber: data.data.phoneNumber,
            hostedCourts: data.data.hostedCourts || [],
            registeredCourts: data.data.registeredCourts || [],
          });
        } else {
          console.error("Failed to fetch user data:", data.data);
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
        setLoading(false)
    };

    fetchUserData();
  }, []);

  const renderSidebar = () => {
    return (
      <div className="w-full flex flex-col items-center text-center sm:rounded-2xl sm:border border-neutral-200 dark:border-neutral-700 space-y-6 sm:space-y-7 px-0 sm:p-6 xl:p-8 animate__animated animate__fadeInLeft">
        
        <div className="animate__animated animate__zoomIn">
          <img
            src="/profileAvatar.svg" 
            alt="Profile Avatar"
            className="w-28 h-28 rounded-full"
          />
        </div>
        
        <div className="space-y-4 text-left w-full">
          <div className="flex items-center space-x-4">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6 text-neutral-400"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="M16 8c0-2.21-1.79-4-4-4s-4 1.79-4 4c0 3-3 5-3 8h14c0-3-3-5-3-8z"
              />
            </svg>
            <span className="text-neutral-6000 dark:text-neutral-300">
              {userData.email || "Loading..."}
            </span>
          </div>
          <div className="flex items-center space-x-4">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6 text-neutral-400"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="M3 8l7-4 7 4M5 12h14M5 16h14M5 20h14"
              />
            </svg>
            <span className="text-neutral-6000 dark:text-neutral-300">
              {userData.phoneNumber || "Loading..."}
            </span>
          </div>
          <div className="flex flex-col justify-center mt-4">
                    <ButtonSecondary className="bg-blue-600" onClick={() => {setIsPopoverOpen(true)}}>Reset Password</ButtonSecondary>
                    
                    <ButtonPrimary onClick={handleLogout} className="bg-red-600 hover:bg-red-800 mt-3">Logout</ButtonPrimary>
          </div>
        </div>

        <div className="border-b border-neutral-200 dark:border-neutral-700 w-14"></div>

     
      </div>
    );
  };

  const renderSection1 = () => {
    return (
      <div className="listingSection__wrap animate__animated animate__fadeInRight">
        <div>
          <h2 className="text-2xl font-semibold">{userData.email}'s Courts</h2>
          <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
            Browse through the courts hosted and registered by {userData.email}.
          </span>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>

         <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>

            <Tab.Group>
              <Tab.List className="flex space-x-1 overflow-x-auto">
                {categories.map((item) => (
                  <Tab key={item} as={Fragment}>
                    {({ selected }) => (
                      <button
                        className={`flex-shrink-0 block !leading-none font-medium px-5 py-2.5 text-sm sm:text-base sm:px-6 sm:py-3 capitalize rounded-full focus:outline-none ${
                          selected
                            ? "bg-secondary-900 text-secondary-50"
                            : "text-neutral-500 dark:text-neutral-400 dark:hover:text-neutral-100 hover:text-neutral-900 hover:bg-neutral-100 dark:hover:bg-neutral-800"
                        }`}
                      >
                        {item}
                      </button>
                    )}
                  </Tab>
                ))}
              </Tab.List>
              <Tab.Panels>
                <Tab.Panel className="mt-8 grid grid-cols-1 gap-6 md:gap-7 sm:grid-cols-2">
                  {userData.hostedCourts.length > 0 ? (
                    userData.hostedCourts.map((court, index) => (
                      <div key={index} className="p-4 border border-neutral-200 dark:border-neutral-700 rounded-lg">
                        <h3 className="text-lg font-semibold">{court.caseTitle}</h3>
                            <p className="text-neutral-500 dark:text-neutral-400">{court.collegeName}</p>
                            <button
                                onClick={() => window.location.href = "/lobby"}
                        className={`bg-blue-500 hover:bg-blue-700 flex-shrink-0 block !leading-none font-medium px-5 py-2.5 text-sm sm:text-base sm:px-6 sm:py-3 capitalize rounded-full focus:outline-none 
                        }`}
                      >
                        Lobby 
                      </button>
                      </div>
                    ))
                  ) : (
                    <div>No Hosted Courts Available</div>
                  )}
                </Tab.Panel>
                <Tab.Panel className="mt-8 grid grid-cols-1 gap-6 md:gap-7 sm:grid-cols-2">
                  {userData.registeredCourts.length > 0 ? (
                    userData.registeredCourts.map((court, index) => (
                        <div key={index} className="p-4 border border-neutral-200 dark:border-neutral-700 rounded-lg">
                       
                    <div className="p-6 border border-neutral-200 dark:border-neutral-700 rounded-lg shadow-lg">
  <h3 className="text-xl font-bold text-neutral-800 dark:text-neutral-100 mb-2">{court.caseTitle}</h3>
  
  <div className="text-neutral-600 dark:text-neutral-300 space-y-1">
    <p><span className="font-semibold">College:</span> {court.courtDetails.collegeName}</p>
    <p><span className="font-semibold">Date of Start:</span> {court.courtDetails.dateOfStart}</p>
    <p><span className="font-semibold">Date of End:</span> {court.courtDetails.dateOfEnd}</p>
  </div>
  
  <div className="mt-4">
    <button
      onClick={() => window.location.href = "/lobby"}
      className="w-full bg-blue-600 text-white font-medium py-3 rounded-lg hover:bg-blue-700 transition-all duration-300 ease-in-out shadow-md"
    >
      Visit Court
    </button>
  </div>
</div>

                      </div>
                    ))
                  ) : (
                    <div>No Registered Courts Available</div>
                  )}
                </Tab.Panel>
              </Tab.Panels>
            </Tab.Group>
          </div>
    );
  };

  return (
    <div className={`nc-Profile ${className}`} data-nc-id="Profile">
    
        
          {loading ? <LoadingIcon text="Loading Profile" /> : (
                    <main className="container mt-12 mb-24 lg:mb-32 flex flex-col lg:flex-row">
                        
                  {isPopoverOpen && <ChangePasswordPopover onClose={handlePopoverClose} />}
                      <div className="block flex-grow mb-24 lg:mb-0">
                          <div className="lg:sticky lg:top-24">{renderSidebar()}</div>
                      </div>
                      <div className="w-full lg:w-3/5 xl:w-2/3 space-y-8 lg:space-y-10 lg:pl-10 flex-shrink-0">
                          {renderSection1()}
                      </div>
            
               </main>
              )}
     
    </div>
  );
};

export default Profile;
