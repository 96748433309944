import React, { FC, Fragment, useState, useEffect } from "react";
import Badge from "shared/Badge/Badge";
import { useLocation, useNavigate } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHandcuffs,
  faWineBottle,
  faHeartBroken,
  faSadCry,
  faFaceGrinTongueWink,
  faBoxesStacked,
  faUserDoctor,
  faPerson,
  faHandsAslInterpreting,
} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { apiUri } from "../../constants";
import Alert from "shared/Alert/Alert";
import PeerPopover from "./peerPopover";

import LoadingIcon from "components/Loading/LoadingIcon";
import CreateLobbyDirect from "containers/HostMootcourt/CreateLobbyForTeam";
import JoinLobbyPopover from "containers/HostMootcourt/JoinLobbyPopover";
import TeamSelectPopover from "containers/HostMootcourt/TeamSelectPopover";
import SideSelectPopover from "containers/PageCourt/SideSelectPopover";
import IdealWayToGoPopover from "./IdealWayToGoPopover";
import DescriptionRenderer from "./DescriptionRenderer";
import BookmarkedCompletedIcon from "components/CaseCard/CompletedandBookmarkButtons";
import { ArrowLeft } from "lucide-react";
import { AnimatePresence, motion } from "framer-motion";
import CaseContentComponenet from "./CaseContentComponenet";
import CourtStyleChooser from "containers/PageCourt/CourtStyleChooser";

interface caseDetailsProp {
  id: string;
  caseTitle: string;
  type: string;
  description: string;
  caseLaws: string[];
  modelFeed: string;
  attributes: string[];
  oneLineQues: string;
  aim: string;
}

const CaseDetails: React.FC<caseDetailsProp> = ({
  id,
  caseTitle,
  type,
  description,
  caseLaws,
  attributes,
  oneLineQues,
  aim,
}) => {
  const thisPathname = useLocation().pathname;
  const router = useNavigate();
  const [showAlert, setShowAlert] = useState(false);
  const [friendPopover, setFriendPopover] = useState(false);

  const [freeTrialRemaining, setIsTrialRemaining] = useState(false);
  const [makeLobbyDirect, setMakeLobbyDirect] = useState(false);
  const [isMakeLobbyOpen, setisMakeLobbyOpen] = useState(false);
  const [isJoinLobbyOpen, setisJoinLobbyOpen] = useState(false);
  const [openTeamSelect, setOpenTeamSelect] = useState(false);
  const [enteredLobby, setEnteredLobby] = useState("");

  const [loading, setLoading] = useState(true);
  const subscribed = sessionStorage.getItem("subscribed");
  const tempFree = sessionStorage.getItem("freeCases")
    ? Number(sessionStorage.getItem("freeCases"))
    : 0;

  const [freeCases, setFreeCases] = useState(tempFree);

  const [idealWayToGo, setIdealWayToGo] = useState<boolean>(
    !sessionStorage.getItem("dontshowidealway")
  );

  const [lobbyName, setLobbyName] = useState("");
  const [allCourtsOpen, setAllCourtsOpen] = useState(false);
  const [allLobbiesOpen, setAllLobbiesOpen] = useState(false);
  const [showSideSelectPopover, setShowSideSelectPopover] = useState(false);
  const [courtThemePopover, setShowCourtThemePopover] = useState(false);

  useEffect(() => {
    const freeTrial = async () => {
      const email = sessionStorage.getItem("email");
      if (email) {
        try {
          const response = await axios.post(`${apiUri}/getFreeTrial`, {
            email,
          });
          if (response.data.data.freeCases > 0) {
            setFreeCases(response.data.data.freeCases);
            sessionStorage.setItem(
              "freeCases",
              response.data.data.freeCases.toString()
            );
          } else {
            setFreeCases(0);
            sessionStorage.setItem("freeCases", "0");
          }
        } catch (error) {
          console.error("Error fetching trial remaining details:", error);
        }
      }
    };
    freeTrial();
  }, []);

  const handleJoinLobbyClose = () => {
    // //console.log("Handle join lobby close");
    setMakeLobbyDirect(false);
    setisMakeLobbyOpen(false);
    setisJoinLobbyOpen(false);
    setLobbyName("");
  };

  const handleJoinLobbyOpen = () => {
    setisJoinLobbyOpen(true);
  };

  const handleIdealWayPopoverClose = () => {
    setIdealWayToGo(false);
  };

  const handleSideSelectClose = () => {
    setShowSideSelectPopover(false);
    setShowCourtThemePopover(true);
  };

  const handleJoinLobby = (name: string) => {
    setLobbyName(name);
    handleJoinLobbyOpen();
  };

  const handleTeamSelectClose = () => {
    setOpenTeamSelect(false);
  };

  const handleCreateLobby = async () => {
    setisMakeLobbyOpen(true);
    //console.log("Creating new lobby");
  };

  const handleViewAllLobbies = () => {
    setAllLobbiesOpen(true);
  };

  const freeTrial = async () => {
    const email = sessionStorage.getItem("email");

    if (email) {
      try {
        const response = await axios.post(`${apiUri}/getFreeTrial`, {
          email: email,
        });
        if (response.data.data.freeCases > 0) {
          setIsTrialRemaining(true);
          setFreeCases(response.data.data.freeCases);
          sessionStorage.setItem("freeCases", response.data.data.freeCases);
          await updateFreeTrail();
        } else {
          sessionStorage.setItem("freeCases", "0");
        }
      } catch (error) {
        console.error("Error fetching case details:", error);
      }
    }
  };

  const updateFreeTrail = async () => {
    const email = sessionStorage.getItem("email");

    if (email) {
      try {
        const response = await axios.post(`${apiUri}/updateTrial`, {
          email: email,
        });
        //console.log(response)
      } catch (error) {
        console.error("Error fetching case details:", error);
      }
    }
  };

  const handlePopoverClose = () => {
    setFriendPopover(false);
  };

  const handleTeamFight = async () => {
    if (sessionStorage.getItem("subscribed") === "true") {
      sessionStorage.setItem("caseId", id);

      setMakeLobbyDirect(true);
    } else if (sessionStorage.getItem("subscribed") === "false") {
      await freeTrial();
      if (
        sessionStorage.getItem("loggedIn") &&
        sessionStorage.getItem("freeCases") != "0"
      ) {
        sessionStorage.setItem("caseId", id);

        setMakeLobbyDirect(true);
      } else if (sessionStorage.getItem("freeCases") == "0") {
        setShowAlert(true);
      }
    } else {
      window.location.href = "/login";
    }
  };

  const handleFightNow = async () => {
    if (sessionStorage.getItem("subscribed") === "true") {
      setShowSideSelectPopover(true);
      sessionStorage.setItem("CaseName", caseTitle);
    } else if (sessionStorage.getItem("subscribed") === "false") {
      await freeTrial();
      if (
        sessionStorage.getItem("loggedIn") &&
        sessionStorage.getItem("freeCases") != "0"
      ) {
        setShowSideSelectPopover(true);
        sessionStorage.setItem("CaseName", caseTitle);
      } else if (sessionStorage.getItem("freeCases") == "0") {
        setShowAlert(true);
      }
    } else {
      window.location.href = "/login";
    }
  };

  useEffect(() => {
    if (showAlert) {
      const timer = setTimeout(() => setShowAlert(false), 4000);
      return () => clearTimeout(timer);
    }
  }, [showAlert]);

  const handleFriendFight = async () => {
    if (sessionStorage.getItem("subscribed") === "true") {
      sessionStorage.setItem("caseId", id);

      // window.location.href = "/mooting"
    } else if (sessionStorage.getItem("subscribed") === "false") {
      await freeTrial();
      if (
        sessionStorage.getItem("loggedIn") &&
        sessionStorage.getItem("freeCases") != "0"
      ) {
        sessionStorage.setItem("caseId", id);

        // window.location.href = "/mooting"
        setFriendPopover(true);
      } else if (sessionStorage.getItem("freeCases") == "0") {
        setShowAlert(true);
      } else {
        window.location.href = "/login";
      }
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-50 to-purple-50 p-4 md:p-8">
      {/* Fun Alert */}
      {courtThemePopover && <CourtStyleChooser isOpen={true}/>}
      <AnimatePresence>
        {showAlert && (
          <Alert
            children="No free trial remaining, kindly subscribe"
            type="warning"
          />
        )}

        {idealWayToGo && (
          <IdealWayToGoPopover onClose={handleIdealWayPopoverClose} />
        )}
      </AnimatePresence>

      {/* Main Container */}
      <div className="max-w-7xl mx-auto">
        {/* Header */}
        <div className="flex justify-between items-center mb-6">
          <motion.button
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            className="inline-flex items-center gap-2 px-4 py-2 rounded-xl bg-white border-2 border-purple-200 hover:border-purple-400 hover:bg-purple-50 transition-all duration-300"
            onClick={() => (window.location.href = "/mootcourt")}
          >
            <ArrowLeft className="w-4 h-4" />
            <span>🏛️ Back to Cases</span>
          </motion.button>

          <BookmarkedCompletedIcon />

          <div className="flex items-center gap-2">
            {subscribed === "false" && (
              <span className="inline-flex items-center px-4 py-2 rounded-xl text-sm font-medium bg-white border-2 border-blue-200">
                🎁 {freeCases} Free Cases Left
              </span>
            )}
            {subscribed === "true" && (
              <span className="inline-flex items-center px-4 py-2 rounded-xl text-sm font-medium bg-gradient-to-r from-purple-600 to-blue-600 text-white">
                👑 PREMIUM STUDENT
              </span>
            )}
          </div>
        </div>
        {showSideSelectPopover && (
          <SideSelectPopover onClose={handleSideSelectClose} caseId={id} />
        )}

        {/* Case Header Card */}
        <div className="bg-white rounded-xl shadow-lg p-8 mb-8 border-2 border-purple-100">
          <div className="flex flex-wrap gap-3 mb-4">
            <span className="px-4 py-2 rounded-xl text-sm font-medium bg-blue-100 text-blue-800">
              ⚖️ {type}
            </span>
            {attributes.map((attr, idx) => (
              <span
                key={idx}
                className="px-4 py-2 rounded-xl text-sm font-medium bg-purple-100 text-purple-800"
              >
                🔍 {attr}
              </span>
            ))}
          </div>
          <h1 className="text-3xl font-bold mb-3 bg-gradient-to-r from-purple-600 to-blue-600 bg-clip-text text-transparent">
            {caseTitle}
          </h1>
          <p className="text-gray-600 text-lg">{oneLineQues}</p>

          <div className="mt-6">
            <motion.button
              whileHover={{ scale: 1.02 }}
              whileTap={{ scale: 0.98 }}
              className="w-full md:w-auto px-8 py-4 rounded-xl bg-gradient-to-r from-blue-600 to-purple-600 text-white font-bold text-lg shadow-lg hover:shadow-xl transition-all duration-300"
              onClick={handleFightNow}
            >
              🤖 Fight Against AI
            </motion.button>
          </div>
        </div>

        <CaseContentComponenet
          description={description}
          caseLaws={caseLaws}
          aim={aim}
        />
      </div>
    </div>
  );
};

function ListCaseDetails() {
  const caseId = sessionStorage.getItem("caseId");
  const [details, setDetails] = useState<caseDetailsProp | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchDetails = async () => {
      try {
        const response = await axios.post(`${apiUri}/fetchCaseDetails`, {
          id: caseId,
        });
        setDetails(response.data.data);
      } catch (error) {
        console.error("Error fetching case details:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchDetails();
  }, [caseId]);

  if (loading) {
    return <LoadingIcon text="Loading Cases" />;
  } else if (details) {
    return <CaseDetails {...details} />;
  } else {
    return <p>Error loading case details.</p>;
  }
}

export default ListCaseDetails;
