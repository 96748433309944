import React, { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import axios from "axios";
import { apiUri } from "../../constants";
import { Star, Send, SkipForward, MessageSquare, Smile, Layout, Zap, Bot } from 'lucide-react';

type RatingCategory = "Overall experience" | "UI experience" | "Ease of use" | "AI responses";

interface FeedbackState {
  ratings: {
    [key in RatingCategory]: number;
  };
  detailedFeedback: string;
}

const StarRating: React.FC<{
  rating: number;
  onRate: (rating: number) => void;
  disabled?: boolean;
}> = ({ rating, onRate, disabled }) => {
  const [hoverRating, setHoverRating] = useState<number>(0);

  return (
    <div className="flex items-center gap-1">
      {[1, 2, 3, 4, 5].map((star) => (
        <motion.div
          key={star}
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.9 }}
        >
          <motion.button
            type="button"
            className={`p-1 rounded-full transition-all ${disabled ? 'cursor-not-allowed' : 'cursor-pointer'}`}
            onClick={() => !disabled && onRate(star)}
            onMouseEnter={() => setHoverRating(star)}
            onMouseLeave={() => setHoverRating(0)}
            animate={{
              scale: star <= (hoverRating || rating) ? 1.1 : 1,
            }}
          >
            <Star
              className={`w-8 h-8 ${
                star <= (hoverRating || rating)
                  ? 'fill-yellow-400 text-yellow-400'
                  : 'text-gray-300'
              } transition-colors`}
            />
          </motion.button>
        </motion.div>
      ))}
    </div>
  );
};

const FeedbackComponent: React.FC = () => {
  const [feedback, setFeedback] = useState<FeedbackState>({
    ratings: {
      "Overall experience": 0,
      "UI experience": 0,
      "Ease of use": 0,
      "AI responses": 0,
    },
    detailedFeedback: "",
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showThankYou, setShowThankYou] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const categoryIcons = {
    "Overall experience": Smile,
    "UI experience": Layout,
    "Ease of use": Zap,
    "AI responses": Bot,
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    setIsSubmitting(true);
    setError(null);

    try {
      const response = await axios.post(`${apiUri}/addFeedback`, {
        email: sessionStorage.getItem("email"),
        overall: feedback.ratings["Overall experience"],
        easeOfUse: feedback.ratings["Ease of use"],
        ai: feedback.ratings["AI responses"],
        ui: feedback.ratings["UI experience"],
        detailedFeedback: feedback.detailedFeedback,
      });

      if (response.data.success) {
        setShowThankYou(true);
        setTimeout(() => {
          window.location.href = "/mootcourt";
        }, 2000);
      }
    } catch (error) {
      setError("Unable to submit feedback. Please try again.");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className="min-h-screen bg-gradient-to-br from-purple-50 to-blue-50 py-12 px-4"
    >
      <div className="max-w-2xl mx-auto">
        <motion.div
          initial={{ y: -20 }}
          animate={{ y: 0 }}
          className="text-center mb-8"
        >
          <h1 className="text-4xl font-bold text-gray-800 mb-2">
            How was your experience? 🎓
          </h1>
          <p className="text-gray-600">
            Your feedback helps us improve the learning experience for everyone!
          </p>
        </motion.div>

        <AnimatePresence>
          {error && (
            <motion.div
              initial={{ opacity: 0, y: -10 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0 }}
              className="bg-red-100 border-l-4 border-red-500 text-red-700 p-4 mb-6 rounded-lg"
            >
              {error}
            </motion.div>
          )}
        </AnimatePresence>

        <motion.form
          onSubmit={handleSubmit}
          className="bg-white rounded-2xl shadow-xl p-8 border-2 border-purple-100"
        >
          <div className="space-y-8">
            {(Object.keys(feedback.ratings) as RatingCategory[]).map((category, index) => (
              <motion.div
                key={category}
                initial={{ opacity: 0, x: -20 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ delay: index * 0.1 }}
                className="bg-gray-50 rounded-xl p-6"
              >
                <div className="flex items-center gap-3 mb-4">
                  {React.createElement(categoryIcons[category], {
                    className: "w-5 h-5 text-purple-500"
                  })}
                  <h3 className="text-lg font-semibold text-gray-700">{category}</h3>
                </div>
                <StarRating
                  rating={feedback.ratings[category]}
                  onRate={(rating) =>
                    setFeedback((prev) => ({
                      ...prev,
                      ratings: { ...prev.ratings, [category]: rating },
                    }))
                  }
                  disabled={isSubmitting}
                />
              </motion.div>
            ))}

            <motion.div
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ delay: 0.4 }}
              className="bg-gray-50 rounded-xl p-6"
            >
              <div className="flex items-center gap-3 mb-4">
                <MessageSquare className="w-5 h-5 text-purple-500" />
                <h3 className="text-lg font-semibold text-gray-700">
                  Additional Feedback
                </h3>
              </div>
              <textarea
                className="w-full p-4 rounded-xl border-2 border-gray-200 focus:border-purple-400 focus:ring focus:ring-purple-100 transition-all resize-none"
                rows={4}
                placeholder="Share your thoughts and suggestions..."
                value={feedback.detailedFeedback}
                onChange={(e) =>
                  setFeedback((prev) => ({
                    ...prev,
                    detailedFeedback: e.target.value,
                  }))
                }
                disabled={isSubmitting}
              />
            </motion.div>

            <div className="flex flex-col gap-4">
              <motion.button
                whileHover={{ scale: 1.02 }}
                whileTap={{ scale: 0.98 }}
                type="submit"
                disabled={isSubmitting}
                className="w-full py-4 rounded-xl bg-gradient-to-r from-purple-600 to-blue-600 text-white font-medium flex items-center justify-center gap-2 shadow-lg hover:shadow-xl transition-all disabled:opacity-50"
              >
                <Send className="w-5 h-5" />
                {isSubmitting ? "Submitting..." : "Submit Feedback"}
              </motion.button>

              <motion.button
                whileHover={{ scale: 1.02 }}
                whileTap={{ scale: 0.98 }}
                type="button"
                onClick={() => window.location.href = "/mootcourt"}
                disabled={isSubmitting}
                className="w-full py-4 rounded-xl border-2 border-gray-200 text-gray-700 font-medium flex items-center justify-center gap-2 hover:bg-gray-50 transition-all disabled:opacity-50"
              >
                <SkipForward className="w-5 h-5" />
                Skip for now
              </motion.button>
            </div>
          </div>
        </motion.form>
      </div>

      <AnimatePresence>
        {showThankYou && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="fixed inset-0 bg-black/50 backdrop-blur-sm flex items-center justify-center"
          >
            <motion.div
              initial={{ scale: 0.9 }}
              animate={{ scale: 1 }}
              exit={{ scale: 0.9 }}
              className="bg-white rounded-2xl p-8 text-center shadow-xl"
            >
              <h2 className="text-2xl font-bold mb-2">Thank You! 🎉</h2>
              <p className="text-gray-600">Your feedback helps us improve!</p>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </motion.div>
  );
};

export default FeedbackComponent;