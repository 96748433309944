import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import axios from "axios";
import { apiUri, modelUri } from "../../constants";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Alert from "shared/Alert/Alert";
import LoadingIcon from "components/Loading/LoadingIcon";
import {
  CheckCircle,
  AlertCircle,
  XCircle,
  Lightbulb,
  BookOpen,
  Target,
  Award,
  Brain,
  BarChart2,
  ArrowRight,
} from "lucide-react";
import PreventNavigation from "containers/PageCourt/PreventNavigation";
import PerformanceBreakdown from "containers/HostMootcourt/ScoreGraphAnalysis";
import SocialShare from "containers/HostMootcourt/SocialCaseShare";

interface PageAnalyticsProps {
  idealComparison: string;
  score: string;
  givenConversation: string;
  idealConversation: string;
  VocabScore: string;
  SenScore: string;
  ThemeScore: string;
  OverallCohScore: string;
  Decision: string;
}

const PageAnalytics: React.FC<PageAnalyticsProps> = ({
  idealComparison,
  score,
  VocabScore,
  SenScore,
  ThemeScore,
  OverallCohScore,
  givenConversation,
  idealConversation,
  Decision,
}) => {
  const getScoreData = (score: string) => {
    const numScore = parseInt(score);
    if (numScore >= 80) {
      return {
        color: "text-green-500",
        bgColor: "bg-green-100",
        icon: CheckCircle,
        message: "Excellent work! 🌟",
      };
    }
    if (numScore >= 40) {
      return {
        color: "text-yellow-500",
        bgColor: "bg-yellow-100",
        icon: AlertCircle,
        message: "Good effort! Keep improving! 💪",
      };
    }
    return {
      color: "text-red-500",
      bgColor: "bg-red-100",
      icon: XCircle,
      message: "Need more practice! You can do it! 📚",
    };
  };

  const scoreData = getScoreData(score);

  return (
    <div className="min-h-screen bg-gradient-to-br from-purple-50 to-blue-50 py-8 px-4">
      {/* <PreventNavigation allowedRoutes={['feedback']}/> */}

      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        className="max-w-7xl mx-auto space-y-8"
      >
        {/* Score Overview */}
        <motion.div
          initial={{ scale: 0.9 }}
          animate={{ scale: 1 }}
          className="bg-white rounded-2xl shadow-xl p-8 border-2 border-purple-100"
        >
          <div className="flex flex-col items-center gap-4">
            <div className={`${scoreData.bgColor} p-4 rounded-full`}>
              <scoreData.icon className={`w-16 h-16 ${scoreData.color}`} />
            </div>
            <h1 className={`text-6xl font-bold ${scoreData.color}`}>{score}</h1>
            <p className="text-xl text-gray-600">{scoreData.message}</p>
          </div>
        </motion.div>

        {/* Decision */}
        <motion.div
          initial={{ opacity: 0, x: -20 }}
          animate={{ opacity: 1, x: 0 }}
          className="bg-white rounded-2xl shadow-xl p-8 border-2 border-purple-100"
        >
          <div className="flex items-center gap-3 mb-4">
            <Lightbulb className="w-8 h-8 text-purple-500" />
            <h2 className="text-2xl font-bold text-gray-800">
              Judge's Decision
            </h2>
          </div>
          <p className="text-gray-700 bg-purple-50 p-4 rounded-xl leading-relaxed">
            {Decision}
          </p>
        </motion.div>

        {/* Score Breakdown */}
        <PerformanceBreakdown
          SenScore={parseInt(SenScore)}
          ThemeScore={parseInt(ThemeScore)}
          OverallCohScore={parseInt(OverallCohScore)}
          VocabScore={parseInt(VocabScore)}
        />

        {/* Argument Comparison */}
        <motion.div
          initial={{ opacity: 0, x: 20 }}
          animate={{ opacity: 1, x: 0 }}
          className="bg-white rounded-2xl shadow-xl p-8 border-2 border-purple-100"
        >
          <div className="flex items-center gap-3 mb-6">
            <BookOpen className="w-8 h-8 text-blue-500" />
            <h2 className="text-2xl font-bold text-gray-800">
              Argument Analysis
            </h2>
          </div>

          <div className="grid md:grid-cols-2 gap-6">
            <div className="space-y-4">
              <h3 className="text-lg font-semibold text-gray-700 flex items-center gap-2">
                <Target className="w-5 h-5 text-purple-500" />
                Your Argument
              </h3>
              <div className="h-64 overflow-auto bg-gray-50 p-4 rounded-xl">
                <p className="text-gray-700">{givenConversation}</p>
              </div>
            </div>

            <div className="space-y-4">
              <h3 className="text-lg font-semibold text-gray-700 flex items-center gap-2">
                <Award className="w-5 h-5 text-green-500" />
                Ideal Argument
              </h3>
              <div className="h-64 overflow-auto bg-gray-50 p-4 rounded-xl">
                <p className="text-gray-700">{idealConversation}</p>
              </div>
            </div>
          </div>

          <div className="mt-6 p-4 bg-blue-50 rounded-xl">
            <h3 className="text-lg font-semibold text-gray-700 flex items-center gap-2 mb-2">
              <Brain className="w-5 h-5 text-blue-500" />
              Comparison Analysis
            </h3>
            <p className="text-gray-700">{idealComparison}</p>
          </div>
        </motion.div>

        <SocialShare score={parseInt(score)} caseName={sessionStorage.getItem("CaseName") || ""} />

        {/* Exit Button */}
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          className="flex justify-center"
        >
          <motion.button
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            onClick={() => {
              sessionStorage.removeItem("fullUserConversation");
              sessionStorage.removeItem("messages");
              sessionStorage.removeItem("Details");
              window.location.href = "/feedback";
            }}
            className="group px-8 py-4 rounded-2xl bg-gradient-to-r from-red-500 to-pink-500 text-white font-semibold text-lg shadow-lg hover:shadow-xl transition-all duration-300 flex items-center gap-2"
          >
            Exit Session
            <ArrowRight className="w-5 h-5 group-hover:translate-x-1 transition-transform" />
          </motion.button>
        </motion.div>
      </motion.div>
    </div>
  );
};
function GetAnalytics() {
  const userConversation = sessionStorage.getItem("fullUserConversation");
  const userSide = sessionStorage.getItem("userSide");
  const [caseDetails, setcaseDetails] = useState<string>("");
  const [details, setDetails] = useState<PageAnalyticsProps | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchIdealConversation = async () => {
      const caseId = sessionStorage.getItem("caseId");
      if (!caseId) return;

      try {
        const response = await axios.post(`${apiUri}/fetchArgument`, {
          caseId,
        });
        if (response.data.success) {
          setcaseDetails(response.data.data.initialPrompt);
        }
      } catch (error) {
        //console.log(error);
      }
    };

    fetchIdealConversation();
  }, []);

  useEffect(() => {
    if (!caseDetails) return;

    const fetchDetails = async () => {
      const cachedDetails = sessionStorage.getItem("Details");
      if (cachedDetails) {
        const parsedDetails = JSON.parse(cachedDetails) as PageAnalyticsProps;
        setDetails(parsedDetails);
        setLoading(false);
        return;
      }
      try {
        //console.log('STARTING FETCHDETAILS');
        //console.log(caseDetails);
        const response = await axios.post(`${modelUri}/getAnalytics`, {
          userConversation: userConversation,
          caseDetails: caseDetails,
          side: userSide,
        });
        //console.log('FETCH DETAILS RESPONSE');
        sessionStorage.setItem("Details", JSON.stringify(response.data));
        setDetails(response.data);
        //console.log('CHECKING SETDETAILS');
      } catch (error) {
        // console.error('Error fetching case details:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchDetails();
  }, [userConversation, caseDetails]);

  if (loading) {
    return <LoadingIcon />;
  } else if (details) {
    //console.log(details);
    return <PageAnalytics {...details} />;
  }
}

export default GetAnalytics;
