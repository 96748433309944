import React, { useEffect, useRef, useState } from "react";
import { ChevronDown } from "lucide-react";
import gsap from "gsap";
import generateFAQSchema from "utils/faqSchema";
import { Schema } from "./Schema/Schema";

interface FAQItem {
  id: number;
  question: string;
  answer: string;
}

const faqData: FAQItem[] = [
  {
    id: 1,
    question: "What is AI Moot Court and how does it work?",
    answer:
      "AI Moot Court is an innovative platform where law students can practice their advocacy skills against AI judges. You'll present arguments, respond to questions, and receive detailed feedback on your legal reasoning, presentation style, and courtroom etiquette - all in a risk-free environment that's available 24/7.",
  },
  {
    id: 2,
    question: "Do I need any special equipment to participate?",
    answer:
      "All you need is a computer with a microphone and a stable internet connection. We recommend using headphones for better audio quality, but they're not required. Our platform works on all major browsers, and there's no need to download any additional software.",
  },
  {
    id: 3,
    question: "What are the cases I can practice?",
    answer:
      "We regularly add new cases every week, focusing on landmark judgments from the Indian judiciary. These cases are carefully selected to align with law school curricula and cover foundational areas that are essential for both academic study and practical legal research. This ensures that you'll practice with cases that not only enhance your legal understanding but also mirror the type of research conducted by practicing lawyers.",
  },
  {
    id: 4,
    question: "What is the most useful way to use this platform?",
    answer:
      "The best way to practice is to analyze the details of real legal cases from our database, prepare your arguments, and present them professionally using appropriate language. After presenting, review your performance to identify mistakes and areas for improvement. This iterative process will help enhance your legal skills effectively.",
  },
];

const FAQComponent: React.FC = () => {
  const [activeIndex, setActiveIndex] = useState<number | null>(null);
  const answerRefs = useRef<(HTMLDivElement | null)[]>([]);
  const questionRefs = useRef<(HTMLDivElement | null)[]>([]);

  useEffect(() => {
    // Initial animations
    gsap.from(questionRefs.current, {
      y: 30,
      opacity: 0,
      duration: 0.8,
      stagger: 0.1,
      ease: "power3.out",
    });
  }, []);

  const toggleAnswer = (index: number) => {
    const answerElement = answerRefs.current[index];
    const isOpening = activeIndex !== index;

    if (answerElement) {
      if (isOpening) {
        // Close previous if exists
        if (activeIndex !== null && answerRefs.current[activeIndex]) {
          gsap.to(answerRefs.current[activeIndex], {
            height: 0,
            opacity: 0,
            duration: 0.3,
            ease: "power2.inOut",
          });
        }
        // Open new
        gsap.to(answerElement, {
          height: "auto",
          opacity: 1,
          duration: 0.5,
          ease: "power2.out",
        });
        setActiveIndex(index);
      } else {
        // Close current
        gsap.to(answerElement, {
          height: 0,
          opacity: 0,
          duration: 0.3,
          ease: "power2.inOut",
          onComplete: () => setActiveIndex(null),
        });
      }
    }
  };

  return (
    <div className=" mx-auto p-6 mb-10  bg-gradient-to-br from-blue-600 to-blue-900 rounded-xl shadow-2xl">
      <Schema data={generateFAQSchema} />
      
      <h2 className="text-4xl font-bold text-white mb-12 text-center">
        Frequently Asked Questions
      </h2>

      <div className="space-y-4">
        {faqData.map((faq, index) => (
          <div
            key={faq.id}
            className="bg-white/10 backdrop-blur-lg rounded-lg overflow-hidden"
            ref={(el) => (questionRefs.current[index] = el)}
          >
            <button
              onClick={() => toggleAnswer(index)}
              className="w-full p-6 text-left flex items-center justify-between hover:bg-white/5 transition-all duration-300"
            >
              <h3 className="text-xl font-semibold text-white pr-8">
                {faq.question}
              </h3>
              <ChevronDown
                className={`w-6 h-6 text-purple-300 transition-transform duration-300 ${
                  activeIndex === index ? "rotate-180" : ""
                }`}
              />
            </button>

            <div
              ref={(el) => (answerRefs.current[index] = el)}
              className="overflow-hidden"
              style={{ height: 0, opacity: 0 }}
            >
              <p className="p-6 text-purple-100 leading-relaxed border-t border-white/10">
                {faq.answer}
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FAQComponent;
