import SectionSliderNewCategories from "components/SectionSliderNewCategories/SectionSliderNewCategories";
import React, { useState } from "react";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import SectionOurFeatures from "components/SectionOurFeatures/SectionOurFeatures";
import SectionGridFeaturePlaces from "./SectionGridFeaturePlaces";
import SectionHowItWork from "components/SectionHowItWork/SectionHowItWork";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import { TaxonomyType } from "data/types";

import SectionGridCategoryBox from "components/SectionGridCategoryBox/SectionGridCategoryBox";

import SectionVideos from "./SectionVideos";
import SectionClientSay from "components/SectionClientSay/SectionClientSay";
import Testimonials from "components/Testimonials/Testimonials";
import PageContact from "containers/PageContact/PageContact";
import ContactSection from "components/ContactUs/Contact";
import { Alert } from "shared/Alert/Alert";
import LandingPage from "./LandingPage";
import Loader from "./Loader";
import Header from "components/Header/Header";
import ParallaxText from "./VelocityScroller";
import SubscriptionSection from "./SubcriptionSection";
import { Link } from "react-router-dom";
import ParticleBackground from "./ParticleCanvas";
import FAQComponent from "components/FAQ";
import Demo from "components/StepWorker/Demo";
import ComparisonSection from "./ComparisonSection";
import { Schema } from "components/Schema/Schema";
import {
  generateHomePageSchema,
  generateOrganizationSchema,
} from "utils/schema";
import { modelUri } from "../../constants";
import HostWithUsSection from "./HostWithUsSection";

function PageHome() {
  const [isLoadingComplete, setIsLoadingComplete] = useState(true);

  const handleLoadingComplete = () => {
    setIsLoadingComplete(true);
  };

  return (
    <div className="nc-PageHome container bg-creamyYellow relative overflow-hidden">
      <BgGlassmorphism />

      <Schema data={generateHomePageSchema()} />
      <Schema data={generateOrganizationSchema()} />

      <div className="mx-auto z-20">
        <div id="hero-section" className="w-full">
          {/* <Loader onComplete={handleLoadingComplete} /> */}

          {/* <div className=" min-h-screen">
            <CanvasParticle options={options} />
          </div> */}

          <div
            className={`transition-opacity duration-1000 ${
              isLoadingComplete ? "opacity-100" : "opacity-0"
            }`}
          >
            {isLoadingComplete && (
              <div className="z-50">
                <LandingPage />
              </div>
            )}
          </div>
        </div>

        <div className="flex flex-col z-20 mt-10">
          {isLoadingComplete && (
            <div className="flex flex-col overflow-hidden">
              {/* {sessionStorage.getItem("email") ? (
                <div className="md:inline ">
                  <ParallaxText baseVelocity={-5}>AI MOOTCOURT</ParallaxText>
                  <ParallaxText baseVelocity={5}>LAW TRAINING</ParallaxText>
                </div>
              ) : (
                <div className=" mx-auto items-center border border-black w-1/2 bg-whiteBlue animate-gradient  text-black text-center py-4 px-6 rounded-lg shadow-lg">
                  <h3 className="text-lg font-semibold mb-1">
                    Sign up today and get 10 free trial cases!
                  </h3>
                  <p className="text-sm">
                    Start practicing your cases right away with our free trial
                    offer.
                  </p>
                  <Link
                    className="mt-3 border border-black inline-block bg-purple-600 text-white font-bold py-2 px-4 rounded-full hover:bg-purple-800 transition duration-300"
                    to="/signup"
                  >
                    Sign Up Now
                  </Link>
                </div>
              )}
 */}

              <div id="demo" className=" w-full ">
                <Demo />
              </div>

              <div id="features-section" className=" w-full ">
                {/* <SectionOurFeatures /> */}
                <ComparisonSection />
              </div>

              <div id="how-it-works" className="  w-full ">
                <SectionHowItWork />
              </div>

              <div className="w-full ">
                <Testimonials />
              </div>

              <div className="w-full ">
                <HostWithUsSection />
              </div>

              <div className="w-full ">
                <SubscriptionSection />
              </div>

              <div className="mt-10 mb-10">
                <SectionSubscribe2 />
              </div>

              <div className="py-6">
                <FAQComponent />
              </div>
            </div>
          )}
        </div>
      </div>

      {isLoadingComplete && (
        <a
          href="/mootCourt"
          className="animate-bounce z-50 fixed bottom-4 right-4 bg-purple-600 text-white rounded-full w-16 h-16 md:w-20 md:h-20 flex items-center justify-center shadow-lg hover:bg-purple-800 transition-colors hidden lg:flex"
        >
          <span className="text-sm md:text-lg font-bold">Try Now</span>
        </a>
      )}
    </div>
  );
}

export default PageHome;
