import { FC, useState } from "react";
import SimpleBar from "simplebar-react";
import "simplebar-react/dist/simplebar.min.css";
import { TeamMessage } from "./types";
import TeamChatConversationLog from "./TeamChatConversationLog";
import { Socket } from "socket.io-client";

interface TeamChatProps {
  setExpand: (value: boolean) => void;
  messages: TeamMessage[];
  setTeamMessages: React.Dispatch<React.SetStateAction<TeamMessage[]>>;
  teamMessageConversationLogRef: React.RefObject<HTMLDivElement>;
  isExpand: boolean;
  setIsExpand: React.Dispatch<React.SetStateAction<boolean>>;
  socket: Socket;
}

const TeamChat: FC<TeamChatProps> = ({
  setTeamMessages,
  socket,
  isExpand,
  setIsExpand,
  setExpand,
  messages,
  teamMessageConversationLogRef,
}) => {
  const [messageInput, setMessageInput] = useState("");
  const userId = sessionStorage.getItem("email");
  const hostedCourtName = sessionStorage.getItem("hostedCourtName");
  const teamName = sessionStorage.getItem("teamName");
  const [hoverTeamChat, setHoverTeamChat] = useState(false);

  const handleSendMessage = () => {
    if (messageInput.trim() === "") {
      return;
    }
    setTeamMessages((prevTeamMessages) => [
      ...prevTeamMessages,
      { senderId: userId ?? "", message: messageInput },
    ]);
    socket.emit("sendTeam", hostedCourtName, teamName, messageInput, userId);
    setMessageInput(""); // Clear input after sending
  };

  return (
    <>
      <button
        className="fixed bottom-4 right-4 inline-flex items-center justify-center text-sm font-medium disabled:pointer-events-none disabled:opacity-50 border rounded-full w-20 h-20 bg-black hover:bg-gray-700 m-0 cursor-pointer border-gray-200 p-0 normal-case leading-5 hover:text-gray-900 z-40"
        type="button"
        aria-haspopup="dialog"
        aria-expanded={isExpand}
        onMouseEnter={() => setIsExpand(true)}
        onMouseLeave={() => setIsExpand(false)}
        onClick={() => setIsExpand(!isExpand)}
      >

        <span className="text-white">TEAM</span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="30"
          height="40"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="text-white block border-gray-200 align-middle"
        >
          <path
            d="m3 21 1.9-5.7a8.5 8.5 0 1 1 3.8 3.8z"
            className="border-gray-200"
          />
        </svg>
      </button>

      {isExpand && (
        <div
          style={{ boxShadow: "0 0 #0000, 0 0 #0000, 0 1px 2px 0 rgb(0 0 0 / 0.05)" }}

          onMouseEnter={() => setIsExpand(true)}
          onMouseLeave={() => setIsExpand(false)}
          className="fixed 0 bottom-[calc(4rem+1.5rem)] bg-gray-500 bg-opacity-80 right-12 mr-4 bg-white p-6 rounded-lg border border-[#e5e7eb] w-1/4 h-1/2 z-40"
        >
          {/* Heading */}
          <div className="flex flex-col space-y-1.5 pb-6">
            <h2 className="font-semibold text-lg tracking-tight">Team Chat</h2>
          </div>

          {/* Chat Container */}
          <SimpleBar style={{ height: "100%", width: "100%" }} autoHide={true}>
            <div className="pr-4 h-[474px]" style={{ minWidth: "100%", display: "table" }}>
              {messages.length <= 0 ? (
                <span>No Messages Yet :(</span>
              ) : (
                <TeamChatConversationLog messages={messages} conversationRef={teamMessageConversationLogRef} />
              )}
            </div>
          </SimpleBar>

          {/* Input box */}
          <div className="flex items-center pt-0">
            <form
              className="flex items-center justify-center w-full space-x-2"
              onSubmit={(e) => {
                e.preventDefault();
                handleSendMessage();
              }}
            >
              <input
                className="flex h-10 w-full rounded-md border border-[#e5e7eb] px-3 py-2 text-sm placeholder-[#6b7280] focus:outline-none focus:ring-2 focus:ring-[#9ca3af] disabled:cursor-not-allowed disabled:opacity-50 text-[#030712] focus-visible:ring-offset-2"
                placeholder="Type your message"
                value={messageInput}
                onChange={(e) => setMessageInput(e.target.value)}
              />
              <button
                className="inline-flex items-center justify-center rounded-md text-sm font-medium text-[#f9fafb] disabled:pointer-events-none disabled:opacity-50 bg-black hover:bg-[#111827E6] h-10 px-4 py-2"
                type="button"
                onClick={handleSendMessage}
              >
                Send
              </button>
            </form>
          </div>
        </div>
      )}
    </>
  );
};

export default TeamChat;
