import React, { useState, useEffect, useRef } from "react";

interface EyeProps {
  size?: number;
}

const Eye: React.FC<EyeProps> = ({ size = 96 }) => {
  const [pupilTop, setPupilTop] = useState<string>("50%");
  const [pupilLeft, setPupilLeft] = useState<string>("50%");
  const eyeRef = useRef<HTMLDivElement>(null);

  const handleMouseMove = (event: MouseEvent) => {
    if (!eyeRef.current) return;

    const { clientX, clientY } = event;
    const { left, top, width, height } = eyeRef.current.getBoundingClientRect();

    const centerX = left + width / 2;
    const centerY = top + height / 2;

    const angle = Math.atan2(clientY - centerY, clientX - centerY);

    const pupilRadius = size / 4;
    const pupilX = Math.cos(angle) * pupilRadius * 0.8;
    const pupilY = Math.sin(angle) * pupilRadius * 0.8;

    requestAnimationFrame(() => {
      setPupilTop(`calc(50% + ${pupilY}px)`);
      setPupilLeft(`calc(50% + ${pupilX}px)`);
    });
  };

  useEffect(() => {
    window.addEventListener("mousemove", handleMouseMove);
    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
    };
  }, []);

  return (
    <div
      className="relative"
      ref={eyeRef}
      style={{ width: size, height: size * 0.75 }}
    >
      <div
        className="relative w-full h-full rounded-full bg-white border-4 border-gray-300 shadow-lg"
        style={{ borderRadius: "50% 50% 50% 50% / 60% 60% 40% 40%" }}
      >
        <div
          className="absolute w-12 h-12 rounded-full bg-gray-800"
          style={{
            top: pupilTop,
            left: pupilLeft,
            transition: "top 0.05s ease-out, left 0.05s ease-out",
            transform: "translate(-50%, -50%)",
          }}
        />
      </div>
    </div>
  );
};

export default Eye;
