import React from "react";
import { motion } from "framer-motion";
import { BarChart2, BookOpen, Target, Brain, Award } from "lucide-react";
import {
  RadarChart,
  PolarGrid,
  PolarAngleAxis,
  PolarRadiusAxis,
  Radar,
  ResponsiveContainer,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
} from "recharts";

const PerformanceBreakdown = ({
  VocabScore = 10,
  SenScore = 20,
  ThemeScore = 13,
  OverallCohScore = 15,
}) => {
  const chartData = [
    { name: "Vocabulary", score: VocabScore },
    { name: "Structure", score: SenScore },
    { name: "Theme", score: ThemeScore },
    { name: "Coherence", score: OverallCohScore },
  ];

  const radarData = chartData.map((item) => ({
    category: item.name,
    score: item.score,
    fullMark: 25,
  }));

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      className="bg-white rounded-2xl shadow-xl p-4 sm:p-6 lg:p-8 border-2 border-purple-100 w-full"
    >
      {/* Header - Responsive padding and text size */}
      <div className="flex items-center gap-2 sm:gap-3 mb-4 sm:mb-6">
        <BarChart2 className="w-6 h-6 sm:w-8 sm:h-8 text-orange-500 flex-shrink-0" />
        <h2 className="text-xl sm:text-2xl font-bold text-gray-800 truncate">
          Performance Breakdown
        </h2>
      </div>

      {/* Score Cards - Responsive grid */}
      <div className="grid grid-cols-2 lg:grid-cols-4 gap-2 sm:gap-4 mb-4 sm:mb-8">
        {[
          { label: "Vocabulary", score: VocabScore, icon: BookOpen },
          { label: "Structure", score: SenScore, icon: Target },
          { label: "Theme", score: ThemeScore, icon: Brain },
          { label: "Coherence", score: OverallCohScore, icon: Award },
        ].map((item, index) => (
          <motion.div
            key={item.label}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: index * 0.1 }}
            className="bg-gray-50 p-3 sm:p-4 lg:p-6 rounded-xl"
          >
            <div className="flex items-center gap-1.5 sm:gap-2 mb-1 sm:mb-2">
              <item.icon className="w-4 h-4 sm:w-5 sm:h-5 text-purple-500 flex-shrink-0" />
              <h3 className="font-semibold text-gray-700 text-sm sm:text-base truncate">
                {item.label}
              </h3>
            </div>
            <p className="text-xl sm:text-2xl font-bold text-purple-600">
              {item.score}
            </p>
          </motion.div>
        ))}
      </div>

      {/* Charts Section - Responsive layout and height */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 sm:gap-8">
        {/* Radar Chart */}
        <motion.div
          initial={{ opacity: 0, scale: 0.95 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ delay: 0.4 }}
          className="bg-gray-50 p-3 sm:p-4 lg:p-6 rounded-xl"
        >
          <h3 className="font-semibold text-gray-700 mb-2 sm:mb-4 text-sm sm:text-base">
            Score Distribution
          </h3>
          <div className="h-48 sm:h-56 lg:h-64 w-full">
            <ResponsiveContainer>
              <RadarChart
                data={radarData}
                margin={{ top: 5, right: 5, bottom: 5, left: 5 }}
              >
                <PolarGrid strokeDasharray="3 3" />
                <PolarAngleAxis
                  dataKey="category"
                  tick={{ fontSize: window.innerWidth < 640 ? 10 : 12 }}
                />
                <PolarRadiusAxis angle={30} domain={[0, 25]} />
                <Radar
                  name="Score"
                  dataKey="score"
                  stroke="#9333ea"
                  fill="#a855f7"
                  fillOpacity={0.4}
                />
                <Tooltip />
              </RadarChart>
            </ResponsiveContainer>
          </div>
        </motion.div>

        {/* Bar Chart */}
        <motion.div
          initial={{ opacity: 0, scale: 0.95 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ delay: 0.5 }}
          className="bg-gray-50 p-3 sm:p-4 lg:p-6 rounded-xl"
        >
          <h3 className="font-semibold text-gray-700 mb-2 sm:mb-4 text-sm sm:text-base">
            Score Comparison
          </h3>
          <div className="h-48 sm:h-56 lg:h-64 w-full">
            <ResponsiveContainer>
              <BarChart
                data={chartData}
                margin={{ top: 5, right: 5, bottom: 5, left: 5 }}
              >
                <CartesianGrid strokeDasharray="3 3" stroke="#e5e7eb" />
                <XAxis
                  dataKey="name"
                  tick={{ fontSize: window.innerWidth < 640 ? 10 : 12 }}
                  interval={0}
                />
                <YAxis
                  domain={[0, 25]}
                  tick={{ fontSize: window.innerWidth < 640 ? 10 : 12 }}
                />
                <Tooltip />
                <Bar dataKey="score" fill="#9333ea" />
              </BarChart>
            </ResponsiveContainer>
          </div>
        </motion.div>
      </div>
    </motion.div>
  );
};

export default PerformanceBreakdown;
