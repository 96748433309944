import React from 'react';
import { Popover, Transition } from '@headlessui/react';
interface CongratulationsModalProps {
    isOpen: boolean;
    onRequestClose: () => void;
    setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
    isCorrect: boolean;
}

const CongratulationsModal: React.FC<CongratulationsModalProps> = ({ isOpen, onRequestClose, setIsModalOpen, isCorrect }) => {
    if (!isOpen) {
        return null;
    }
    const handleContentClick = (e: React.MouseEvent) => {
        e.stopPropagation();
    };
    return (
        <Popover>
            <Transition
                show={true}
                enter="transition duration-100 ease-out"
                enterFrom="transform scale-95 opacity-0"
                enterTo="transform scale-100 opacity-100"
                leave="transition duration-75 ease-out"
                leaveFrom="transform scale-100 opacity-100"
                leaveTo="transform scale-95 opacity-0"
            >
                <Popover.Panel static className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
                    {isCorrect ? (<div className="top-0 left-0 right-0 bottom-0 bg-transparent flex justify-center items-center z-1000">
                        <div className="bg-white dark:bg-gray-800 rounded-lg p-4 shadow-lg max-w-md w-full" onClick={handleContentClick}>
                            <h2 className='text-4xl p-1 text-green-800 font-bold animate-pulse'>Congratulations!</h2>
                            <p className='text-2xl p-1'>You have successfully completed the task.</p>
                            <button className="bg-blue-500 mb-4 mt-2 text-white px-4 py-2 rounded-md hover:bg-blue-700 mr-2" onClick={(ev) => {
                                ev.preventDefault();
                                setIsModalOpen(false);
                                onRequestClose();
                                window.location.href = '/';
                            }}>Home</button>
                        </div>
                    </div>) : (<div className="fixed top-0 left-0 right-0 bottom-0 bg-transparent flex justify-center items-center z-1000">
                        <div className="bg-white dark:bg-gray-800 rounded-lg p-4 shadow-lg max-w-md w-full" onClick={handleContentClick}>
                            <h2 className='text-4xl p-1 text-red-800 font-bold animate-pulse'>Oops!</h2>
                            <p className='text-2xl p-1'>Incorrect answers or crossword is incomplete</p>
                            <button className="bg-blue-500 mb-4 mt-2 text-white px-4 py-2 rounded-md hover:bg-blue-700 mr-2" onClick={(ev) => {
                                ev.preventDefault();
                                setIsModalOpen(false);
                                onRequestClose();
                            }}>Retry</button>
                        </div>
                    </div>)}
                   
                    <div className="mt-4 flex justify-end">
                        <button
                            className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-700"
                            onClick={() => { }}
                        >
                            Close
                        </button>
                    </div>
                </Popover.Panel>
            </Transition>
        </Popover>
    );
};

export default CongratulationsModal;
