import React from "react";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import { CaseDataType } from "data/types";
import {
  ChevronRight,
  BookOpen,
  Scale,
  Clock,
  ArrowUpRight,
} from "lucide-react";

interface StayCardProps {
  className?: string;
  data?: CaseDataType;
  size?: "default" | "small";
}

const CaseCard: React.FC<StayCardProps> = ({
  size = "default",
  className = "",
  data = {
    id: "1",
    _id: "dsfsdfsd",
    type: "Case Study",
    title: "The Benefits of Adopting a Cat",
    image: [
      "https://example.com/images/cat1.jpg",
      "https://example.com/images/cat2.jpg",
    ],
    href: "https://example.com/case-studies/adopting-a-cat",
  },
}) => {
  const { id, type, title, image } = data;

  const handleCaseClick = () => {
    sessionStorage.setItem("caseId", id);
    window.location.href = "/caseDetails";
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      whileInView={{ opacity: 1, y: 0 }}
      viewport={{ once: true }}
      className={`group relative overflow-hidden rounded-2xl ${className}`}
    >
      {/* Card Container */}
      <div className="relative bg-gradient-to-br from-purple-900 to-blue-900 p-1">
        <div className="absolute inset-0 bg-gradient-to-r from-purple-500 to-blue-500 opacity-0 group-hover:opacity-20 transition-opacity duration-300" />

        <div className="relative bg-gray-900 rounded-xl overflow-hidden">
          {/* Image Carousel */}
          <div className="relative overflow-hidden">
            {/* Main Image */}
            <motion.div
              whileHover={{ scale: 1.05 }}
              transition={{ duration: 0.3 }}
              className="relative w-full h-full cursor-pointer"
              onClick={handleCaseClick}
            >
              <div className="relative w-full overflow-hidden">
                <img
                  src={image[0]}
                  alt={title}
                  className="w-full h-[300px] object-cover"
                />
              </div>

              {/* Image Overlay */}
              <div className="absolute inset-0 bg-gradient-to-t from-gray-900 via-gray-900/40 to-transparent opacity-60 group-hover:opacity-40 transition-opacity duration-300" />

              {/* Type Badge */}
              <div className="absolute top-4 left-4">
                <motion.div
                  whileHover={{ scale: 1.05 }}
                  className="flex items-center gap-2 bg-purple-600/90 backdrop-blur-sm px-3 py-1 rounded-full"
                >
                  <Scale className="w-4 h-4 text-white" />
                  <span className="text-white text-sm font-medium">{type}</span>
                </motion.div>
              </div>
            </motion.div>
          </div>

          <div className="p-5 min-h-[150px]">
            <motion.h3
              whileHover={{ x: 5 }}
              className="text-xl font-bold text-center line-clamp-2  text-white mb-3 group-hover:text-purple-300 transition-colors duration-300"
            >
              {title}
            </motion.h3>

            {/* <div className="flex items-center gap-4 text-gray-300 text-sm mb-4">
              <div className="flex items-center gap-1">
                <Clock className="w-4 h-4" />
                <span>30 mins read</span>
              </div>
              <div className="flex items-center gap-1">
                <BookOpen className="w-4 h-4" />
                <span>Advanced level</span>
              </div>
            </div> */}

            <button
              onClick={handleCaseClick}
              className="w-full mt-2 flex items-center justify-center gap-2 px-4 py-2.5 bg-gradient-to-r from-purple-600 to-blue-600 text-white rounded-lg
                transform transition-all duration-300
                hover:from-purple-700 hover:to-blue-700 hover:shadow-lg hover:-translate-y-0.5
                focus:ring-2 focus:ring-purple-500 focus:ring-offset-2 focus:ring-offset-gray-900"
            >
              <span>View Case Details</span>
              <ArrowUpRight className="w-4 h-4" />
            </button>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default CaseCard;
