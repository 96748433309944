import React, { useEffect, useRef, useState } from "react";
import { gsap } from "gsap";
import {
  PartyPopper,
  PenTool,
  CheckCircle,
  X,
  ThumbsUp,
  Award,
  Star,
} from "lucide-react";

interface BlogSubmittedPopoverProps {
  isOpen: boolean;
  blogTitle?: string;
  onClose: () => void;
}

const BlogSubmittedPopover: React.FC<BlogSubmittedPopoverProps> = ({
  isOpen,
  blogTitle = "Your Blog Post",
  onClose,
}) => {
  const popoverRef = useRef<HTMLDivElement>(null);
  const [confetti, setConfetti] = useState<
    Array<{ x: number; y: number; color: string }>
  >([]);

  useEffect(() => {
    if (isOpen) {
      const colors = ["#4CAF50", "#2196F3", "#FFC107", "#9C27B0", "#FF5722"];
      const newConfetti = Array.from({ length: 50 }, (_, i) => ({
        x: Math.random() * window.innerWidth,
        y: -20,
        color: colors[Math.floor(Math.random() * colors.length)],
      }));
      setConfetti(newConfetti);
    }
  }, [isOpen]);

  useEffect(() => {
    if (isOpen && popoverRef.current) {
      gsap.fromTo(
        popoverRef.current,
        {
          scale: 0.5,
          opacity: 0,
          y: 50,
        },
        {
          scale: 1,
          opacity: 1,
          y: 0,
          duration: 0.5,
          ease: "back.out(1.7)",
        }
      );

      confetti.forEach((particle, i) => {
        gsap.to(`#confetti-${i}`, {
          y: window.innerHeight + 100,
          x: particle.x + (Math.random() - 0.5) * 200,
          rotation: Math.random() * 720 - 360,
          duration: 2 + Math.random() * 3,
          ease: "power1.out",
          delay: Math.random() * 0.5,
        });
      });
    }
  }, [isOpen, confetti]);

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-black/50">
      {confetti.map((particle, i) => (
        <div
          key={i}
          id={`confetti-${i}`}
          className="absolute w-3 h-3 rounded-sm"
          style={{
            left: particle.x,
            top: particle.y,
            backgroundColor: particle.color,
            transform: "rotate(0deg)",
          }}
        />
      ))}

      <div
        ref={popoverRef}
        className="bg-white rounded-2xl shadow-2xl w-11/12 max-w-lg overflow-hidden"
      >
        <div className="bg-gradient-to-r from-green-500 to-teal-500 p-6 text-white relative">
          <button
            className="absolute right-4 top-4 p-1 hover:bg-white/20 rounded-full transition-colors"
            onClick={onClose}
          >
            <X className="h-6 w-6" />
          </button>
          <div className="flex items-center justify-center space-x-3">
            <PenTool className="h-8 w-8 animate-bounce" />
            <h2 className="text-2xl font-bold">Success!</h2>
            <ThumbsUp className="h-8 w-8 animate-bounce" />
          </div>
        </div>

        <div className="p-6 flex flex-col items-center">
          <div className="mb-6 relative">
            <Award className="h-20 w-20 text-green-400 animate-pulse" />
          </div>

          <div className="text-center space-y-4">
            <h3 className="text-xl font-semibold text-gray-800">
              Blog Post Published! 🎉
            </h3>
            <p className="text-gray-600">
              Your content is now live and ready for readers
            </p>
            <p className="text-lg font-medium text-teal-600">{blogTitle}</p>
            <div className="flex items-center justify-center space-x-2 text-green-600">
              <CheckCircle className="h-5 w-5" />
              <span>Successfully published</span>
            </div>
          </div>

          <div className="grid grid-cols-2 gap-4 mt-8 w-full max-w-sm">
            <div className="bg-green-50 p-4 rounded-lg text-center">
              <p className="text-2xl font-bold text-green-600">Public</p>
              <p className="text-sm text-green-700">Visibility</p>
            </div>
            <div className="bg-teal-50 p-4 rounded-lg text-center">
              <p className="text-2xl font-bold text-teal-600">Ready</p>
              <p className="text-sm text-teal-700">For Sharing</p>
            </div>
          </div>

          <div className="mt-6 bg-gray-50 p-4 rounded-lg w-full max-w-sm">
            <h4 className="font-semibold text-gray-700 mb-2">Next steps:</h4>
            <ul className="text-sm text-gray-600 space-y-2">
              <li className="flex items-center space-x-2">
                <CheckCircle className="h-4 w-4 text-green-500" />
                <span>Share on social media</span>
              </li>
              <li className="flex items-center space-x-2">
                <CheckCircle className="h-4 w-4 text-green-500" />
                <span>Monitor reader engagement</span>
              </li>
              <li className="flex items-center space-x-2">
                <CheckCircle className="h-4 w-4 text-green-500" />
                <span>Respond to comments</span>
              </li>
            </ul>
          </div>

          <div className="mt-8 space-y-4">
            <button
              className="px-6 py-2 bg-gradient-to-r from-green-500 to-teal-500 text-white rounded-lg hover:from-green-600 hover:to-teal-600 transition-all transform hover:scale-105 active:scale-95 w-full"
              onClick={() => (window.location.href = "/blog")}
            >
              View Published Post
            </button>
            <p className="text-sm text-gray-500 text-center">
              Your blog post is now part of our community! 📝
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogSubmittedPopover;
