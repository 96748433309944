import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { motion, AnimatePresence } from "framer-motion";
import SectionLatestPosts from "./SectionLatestPosts";
import Alert from "shared/Alert/Alert";
import {
  Sparkles,
  Bell,
  BookOpen,
  ChevronDown,
  Star,
  Coffee,
  Mail,
  Phone,
  PenSquare,
} from "lucide-react";
import axios from "axios";
import { apiUri } from "../../constants";
import NewsletterConfirmation from "./NewsletterPopoverSuccess";

const BlogPage = () => {
  const [email, setEmail] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState("Something went wrong");
  const [showPopover, setShowPopover] = useState(false);

  const scrollToSubscribe = () => {
    document
      .getElementById("subscribe")
      ?.scrollIntoView({ behavior: "smooth" });
  };

  const isValidEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handlePopoverClose = () => {
    setShowPopover(false);
  };

  useEffect(() => {
    if (showAlert) {
      const timer = setTimeout(() => setShowAlert(false), 4000);
      return () => clearTimeout(timer);
    }
  }, [showAlert]);

  const handleJoinNewsletter = async () => {
    if (isValidEmail(email)) {
      try {
        const response = await axios.post(`${apiUri}/addToNewsletter`, {
          email: email,
        });

        if (response.data.success) {
          setShowPopover(true);
        } else {
          console.log(response.data.message || "Failed to subscribe.");
        }
      } catch (error) {
        setAlertMsg(
          "Unable to subscribe to the newsletter. Kindly try again later."
        );
        setShowAlert(true);
      }
    } else {
      setShowAlert(true);
      setAlertMsg("Email not valid.");
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-pink-50 to-purple-50 dark:from-gray-900 dark:to-purple-900 overflow-hidden relative">
      <Helmet>
        <title>Verdict Vibes | Where Legal Learning Meets Fun! 🎉</title>
        <meta
          name="description"
          content="Join the coolest legal community! Discover fun takes on law, memes that'll make your day, and actually enjoyable legal learning. Verdict Vibes - where legal knowledge meets good vibes! ✨"
        />
      </Helmet>

      {showPopover && (
        <NewsletterConfirmation
          isOpen={showPopover}
          email={email}
          onClose={handlePopoverClose}
        />
      )}

      {/* Hero Section - Enhanced Responsiveness */}
      <div className="relative bg-gradient-to-r from-violet-600 via-fuchsia-500 to-pink-500 animate-gradient-party text-white py-12 md:py-24 overflow-hidden">
        <div className="absolute inset-0 opacity-10">
          <svg
            className="w-full h-full"
            viewBox="0 0 100 100"
            preserveAspectRatio="none"
          >
            <pattern
              id="grid"
              width="10"
              height="10"
              patternUnits="userSpaceOnUse"
            >
              <path
                d="M 10 0 L 0 0 0 10"
                fill="none"
                stroke="white"
                strokeWidth="0.5"
              />
            </pattern>
            <rect width="100" height="100" fill="url(#grid)" />
          </svg>
        </div>

        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="container mx-auto px-4 relative z-10"
        >
          <div className="max-w-3xl mx-auto">
            <motion.div
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ delay: 0.2 }}
              className="text-center md:text-left"
            >
              <h1 className="text-4xl md:text-6xl font-bold mb-4 md:mb-6 leading-tight bg-clip-text text-transparent bg-gradient-to-r from-white to-pink-100">
                Verdict Vibes ⚖️✨
              </h1>
              <p className="text-xl md:text-2xl text-pink-100 mb-6 md:mb-8">
                Where legal learning gets a glow-up! Join us for weekly fresh
                takes, epic memes, and actually fun legal content. No boring
                stuff allowed!
              </p>
            </motion.div>

            <div className="flex flex-wrap justify-center md:justify-start gap-4">
              <motion.button
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                onClick={scrollToSubscribe}
                className="w-full sm:w-auto px-6 py-3 bg-white text-fuchsia-600 rounded-xl font-medium flex items-center justify-center gap-2 shadow-lg"
              >
                <Star className="w-5 h-5" />
                Get the Vibes
              </motion.button>
            </div>
          </div>
        </motion.div>

        <motion.div
          animate={{ y: [0, -10, 0] }}
          transition={{ duration: 2, repeat: Infinity }}
          className="absolute bottom-4 left-1/2 transform -translate-x-1/2"
        >
          <ChevronDown className="w-6 h-6 md:w-8 md:h-8 text-white/70" />
        </motion.div>
      </div>

      {/* Main Content Section */}
      <div className="container mx-auto px-4 py-8 md:py-12">
        <div className="flex flex-col lg:flex-row gap-8">
          {/* Main Content */}
          <div className="flex-grow">
            <SectionLatestPosts />
          </div>

          {/* Sidebar */}
          <div className="w-full space-y-6">
            {/* Category Cards */}
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-1 gap-6">
              {[
                {
                  title: "Tech Updates",
                  icon: "💻",
                  desc: "Major advancements in the legal tech field, explained simply.",
                },
                {
                  title: "Public Pulse",
                  icon: "🗣️",
                  desc: "Insights into public opinion on law and current legal judgments.",
                },
                {
                  title: "Raw and Real",
                  icon: "📰",
                  desc: "A law-focused newspaper free from government crackdowns.",
                },
              ].map((category) => (
                <motion.button
                  key={category.title}
                  whileHover={{ scale: 1.03 }}
                  className="w-full p-4 md:p-6 bg-white dark:bg-gray-800 rounded-2xl shadow-lg hover:shadow-xl transition-all border border-pink-100 dark:border-purple-800"
                >
                  <div className="text-2xl md:text-3xl mb-3">
                    {category.icon}
                  </div>
                  <h3 className="text-lg md:text-xl font-bold text-fuchsia-600 dark:text-fuchsia-400 mb-2">
                    {category.title}
                  </h3>
                  <p className="text-sm md:text-base text-gray-600 dark:text-gray-300">
                    {category.desc}
                  </p>
                </motion.button>
              ))}
            </div>

            {/* Submit Article Card */}
            <div className="p-4 md:p-6 bg-gradient-to-r from-violet-500 to-fuchsia-500 rounded-2xl text-white">
              <PenSquare className="w-10 h-10 md:w-12 md:h-12 mb-4" />
              <h3 className="text-lg md:text-xl font-bold mb-3">
                Feel something is missing?
              </h3>
              <p className="text-sm md:text-base mb-4">
                Share your legal insights with our community!
              </p>
              <a
                href="/newsletterSubmission"
                className="block w-full py-3 bg-white text-fuchsia-600 rounded-xl font-medium text-center hover:bg-pink-100 transition-colors"
              >
                Submit Your Article
              </a>
            </div>

            {/* Contact Card */}
            <div className="p-4 md:p-6 bg-white dark:bg-gray-800 rounded-2xl shadow-lg">
              <h3 className="text-lg md:text-xl font-bold text-fuchsia-600 dark:text-fuchsia-400 mb-4">
                Got complaints or suggestions?
              </h3>
              <div className="space-y-3">
                <a
                  href="mailto:aditya@aimootcourt.com"
                  className="flex items-center gap-2 text-sm md:text-base text-gray-600 dark:text-gray-300 hover:text-fuchsia-600"
                >
                  <Mail className="w-4 h-4 md:w-5 md:h-5" />
                  aditya@aimootcourt.com
                </a>
                <a
                  href="tel:8383990480"
                  className="flex items-center gap-2 text-sm md:text-base text-gray-600 dark:text-gray-300 hover:text-fuchsia-600"
                >
                  <Phone className="w-4 h-4 md:w-5 md:h-5" />
                  8383990480
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Newsletter Section */}
      <motion.div
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        viewport={{ once: true }}
        className="bg-gradient-to-r from-violet-600 to-fuchsia-600 text-white py-12 md:py-20"
      >
        <div className="container mx-auto px-4" id="subscribe">
          {showAlert && <Alert type="error" children={alertMsg} />}
          <div className="max-w-2xl mx-auto text-center animate-gradient-shimmer">
            <Sparkles className="w-12 h-12 md:w-16 md:h-16 mx-auto mb-6 text-pink-300" />
            <h2 className="text-3xl md:text-4xl font-bold mb-4">
              Join the Vibe Tribe! ✨
            </h2>
            <p className="text-base md:text-lg text-pink-100 mb-8">
              Get the freshest legal content, memes that'll make your day, and
              actually useful insights. No boring newsletters, we promise!
            </p>
            <div className="flex flex-col sm:flex-row gap-4 max-w-md mx-auto">
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Drop your email here! ✉️"
                aria-label="Email address"
                required
                className="w-full flex-1 px-4 md:px-6 py-3 bg-white/10 rounded-xl border border-white/20 text-white placeholder:text-pink-200 focus:outline-none focus:border-pink-300"
              />

              <motion.button
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                onClick={handleJoinNewsletter}
                className="w-full sm:w-auto px-6 py-3 bg-white text-fuchsia-600 rounded-xl font-medium hover:bg-pink-100 transition-colors shadow-lg"
              >
                Let's Vibe!
              </motion.button>
            </div>
          </div>
        </div>
      </motion.div>
    </div>
  );
};

export default BlogPage;
