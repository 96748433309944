import React, { FC, useRef, useEffect, useState } from "react";
import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import Button from "shared/Button/Button";
import { init, animateCamera, toggleCharacterVisibility } from "./threeUtils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBook,
  faRobot,
  faMicrophone,
  faBrain,
  faNotesMedical,
  faArrowLeft,
  faGavel,
  faKeyboard,
  faVideo,
} from "@fortawesome/free-solid-svg-icons";
import NotesPopover from "components/CaseTools/NotesPopover";
import axios from "axios";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import IpcBotPopover from "components/CaseTools/IPCbot/IpcBotPopover";
import MessageBox from "./messageBox";
// import { Message } from "./types";
import CaseDetailsPopover from "components/CaseTools/CaseDetailsPopover";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { apiUri, modelUri } from "../../constants";
import { Alert } from "shared/Alert/Alert";
import TutorialPopover from "containers/Tutorial/Tutorial";
import LoadingButton from "components/Loading/LoadingButton";
import Sidebar from "components/Sidebar/Sidebar";
import CaseCompletedPopover from "./caseCompletedPopover";
import { motion, AnimatePresence } from "framer-motion";
import MessageDisplay from "./messageBox";
import ConversationPopover from "components/Sidebar/Sidebar";
import ExitConfirmation from "./ExitPopover";
import PreventNavigation from "./PreventNavigation";
import ThinkingPopover from "./LawyerThinkingPopover";
import StreamAnalyzer from "./VideoSteamAnalyzer";
import PixelatedCourtroom from "./PixelatedCourt";
import PixelMessagePopover from "./PixelMessagePopover";
import { QuestionMarkCircleIcon } from "@heroicons/react/24/solid";

interface Message {
  role: Role;
  text: string;
}
type Role = "judge" | "user" | "ai";

const PageCourtAnimated: FC<{}> = () => {
  const [messages, setMessages] = useState<Message[]>([]);
  const [input, setInput] = useState<string>("");
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [isListening, setIsListening] = useState<boolean>(false);
  const [isTutorialOpen, setIsTutorialOpen] = useState<boolean>(true);
  const [isNotesPopoverOpen, setIsNotesPopoverOpen] = useState<boolean>(false);
  const [isCaseDetailsPopoverOpen, setIsCaseDetailsPopoverOpen] =
    useState<boolean>(false);
  const [isBotPopoverOpen, setIsBotPopoverOpen] = useState<boolean>(false);
  const [witnessInStand, setWitnessInStand] = useState<boolean>(false);
  const [analysisReady, setAnalysisReady] = useState<boolean>(false);
  const [sideMenuIsExpand, setSideMenuIsExpand] = useState<boolean>(false);
  const [isExpand, setIsExpand] = useState<boolean>(false);
  const [isCaseComplete, setIsCaseComplete] = useState<boolean>(false);
  const [numCaseCompleted, setNumCaseCompleted] = useState<number>(0);
  const [messageCount, setMessageCount] = useState<number>(
    parseInt(sessionStorage.getItem("messages")?.length.toString() || "0")
  );
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [alertMsg, setAlertMsg] = useState<string>("");
  const [showExit, setShowExit] = useState<boolean>(false);
  const [fullUserConversation, setFullUserConversation] = useState<string>("");

  const [isMessagePopoverOpen, setMessagePopoverOpen] = useState(false)

  const CASEID: string = sessionStorage.getItem("caseId") || "";
  const conversationLogRef = useRef<HTMLDivElement>(null);
  const messagesEndRef = useRef<HTMLDivElement | null>(null);

  const { transcript, resetTranscript, browserSupportsSpeechRecognition } =
    useSpeechRecognition();

  useEffect(() => {
    setMessages(getStoredMessages());
  }, []);

  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [messages]);

  useEffect(() => {
    sessionStorage.setItem("messages", JSON.stringify(messages));
  }, [messages]);

  useEffect(() => {
    if (showAlert) {
      const timer = setTimeout(() => setShowAlert(false), 4000);
      return () => clearTimeout(timer);
    }
  }, [showAlert]);

  const handleInputChange = (
    e: React.ChangeEvent<HTMLTextAreaElement>
  ): void => {
    setInput(e.target.value);
    resetTranscript();
  };

  const handleSendMessage = async (): Promise<void> => {
    setIsProcessing(true);
    setMessageCount((prev) => prev + 1);

    if (input.trim() !== "") {
      const newMessage: Message = { role: "user", text: input };
      setMessages((prev) => [...prev, newMessage]);
      setFullUserConversation((prev) => `${prev} ${input}`);

      try {
        const response = await axios.post(`${modelUri}/model`, {
          message: input,
          initial_prompt: iPrompt,
          witness_prompt: iWitnessPrompt,
          userSide: sessionStorage.getItem("userSide"),
        });

        //  const role = response.data.speaker as Role;
        const role = "ai" as Role;
        const botMessage: Message = { role, text: response.data.msg };
        setMessages((prev) => [...prev, botMessage]);
        setIsOpen(true);
      } catch (error) {
        console.error("Error sending message:", error);
      }

      setInput("");
      resetTranscript();
      setIsProcessing(false);
      setMessagePopoverOpen(true);
    }
  };

  // useEffect(() => {
  //   updateTextSprite(dynamicText, sceneRef, textSpriteRef, { x: -3.55, y: 1.5, z: 2 });
  // }, [dynamicText]);

  // useEffect(() => {
  //   updateTextSprite(lawyer2Text, sceneRef, textSpriteRefLawyer2, { x: -3, y: 1.5, z: -1.5 });
  // }, [lawyer2Text]);

  const caseActive: string = sessionStorage.getItem("caseId") || "";

  let iPrompt: string = "";
  let iWitnessPrompt: string = "";

  const getPrompts = async () => {
    try {
      const response = await axios.post(`${apiUri}/getPrompts`, {
        caseId: caseActive,
      }); // Make sure to include this option to send cookies with requests
      //console.log(response.data.data);
      iPrompt = response.data.data.initialPrompt;
      iWitnessPrompt = response.data.data.witnessPrompt;
    } catch (error) {
      console.error("Error setting prompts:", error);
    }
  };

  const getStoredMessages = (): Message[] => {
    const storedMessages = sessionStorage.getItem("messages");
    return storedMessages ? JSON.parse(storedMessages) : [];
  };

  useEffect(() => {
    setMessages(getStoredMessages());
  }, []);

  useEffect(() => {
    if (transcript && !isListening) {
      setInput(transcript);
      // setFullUserConversation((prevConversation) => prevConversation + ' ' + transcript);
      //console.log(fullUserConversation);
      resetTranscript();
    }
  }, [transcript, isListening, fullUserConversation, resetTranscript]);

  useEffect(() => {
    sessionStorage.setItem("messages", JSON.stringify(messages));
  }, [messages]);

  const startVoiceRecognition = () => {
    if (browserSupportsSpeechRecognition) {
      SpeechRecognition.startListening({ continuous: true });
      setIsListening(true);
    } else {
      console.error("Browser does not support speech recognition.");
    }
  };

  const handleVoiceInput = () => {
    startVoiceRecognition();
  };

  const handleMicEnd = () => {
    SpeechRecognition.stopListening();
    setIsListening(false);
  };

  useEffect(() => {
    sessionStorage.setItem("fullUserConversation", fullUserConversation);
  }, [fullUserConversation]);

  useEffect(() => {
    if (input === "i rest my case") {
      handleRestMyCase();
    }
  }, [input]);

  const handleButtonClick = () => {
    setIsNotesPopoverOpen(true);
  };

  const handleClosePopover = () => {
    setIsNotesPopoverOpen(false);
  };

  const handleBotClosePopover = () => {
    setIsBotPopoverOpen(false);
  };

  const handleBotClick = () => {
    setIsBotPopoverOpen(true);
  };

  const handleTutorialPopover = () => {
    setIsTutorialOpen(false);
  };

  const handleDetailClick = () => {
    setIsCaseDetailsPopoverOpen(true);
  };

  const handleCLoseDetailsPopover = () => {
    setIsCaseDetailsPopoverOpen(false);
  };

  const handleQuestionWitness = () => {
    setInput("i want to question the witness");
    handleSendMessage();
  };

  const handleRestMyCase = async () => {
    await handleSendMessage();
    setAnalysisReady(true);
  };

  useEffect(() => {
    if (showAlert) {
      const timer = setTimeout(() => setShowAlert(false), 4000);
      return () => clearTimeout(timer);
    }
  }, [showAlert]);

  const handleAnalytics = async () => {
    if (messageCount < 5) {
      setAlertMsg("Conversation too short, kindly continue the argument.");
      setShowAlert(true);
      return;
    } else {
      try {
        const a = await axios.post(`${apiUri}/addToCompletedCase`, {
          caseId: sessionStorage.getItem("caseId"),
          email: sessionStorage.getItem("email"),
        });
        setIsCaseComplete(true);
        sessionStorage.removeItem("messages");
        // console.log(a.data.data);
      } catch (error) {
        // console.log(error);
      }

      setTimeout(function () {
        window.location.href = "/analysis";
      }, 5000);
    }
  };

  const handleExitCourt = () => {
    setShowExit(true);
  };

  const handleExitPopoverClose = () => {
    setShowExit(false);
  };

  return (
    <div className="relative min-h-screen bg-gradient-to-br from-purple-50 to-blue-50">
      {/* <PreventNavigation /> */}

      <ConversationPopover
        isExpand={isExpand}
        setIsExpand={setIsExpand}
        setExpand={setSideMenuIsExpand}
        messages={messages}
        conversationLogRef={conversationLogRef}
      />

      {isMessagePopoverOpen && (
        <PixelMessagePopover
          message={messages[messages.length - 1].text}
          isOpen={isMessagePopoverOpen}
          onClose={() => setMessagePopoverOpen(false)}
        />
      )}

      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        className={`flex-1 min-h-screen transition-all duration-300 ease-in-out
          `}
      >
        {/* Popup Handlers */}
        <AnimatePresence>
          {isCaseComplete && (
            <CaseCompletedPopover isOpen={true} caseTitle={CASEID} />
          )}
          {isTutorialOpen && (
            <TutorialPopover onClose={handleTutorialPopover} />
          )}
        </AnimatePresence>

        <div className="flex flex-col lg:flex-row  p-4 gap-4">
          {/* Main Court Area */}
          <div className="flex flex-col lg:w-3/4 h-full">
            {showAlert && <Alert children={alertMsg} type="error" />}

            <PixelatedCourtroom
              messages={messages}
              isProcessing={isProcessing}
            />
          </div>

          {/* Control Panel */}

          <motion.div
            initial={{ x: 20, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            className="space-y-3"
          >
            {/* Input Section */}
            <div className="bg-white rounded-xl shadow-lg p-4 border-2 border-purple-100">
              <div className="flex items-center justify-between ">
                <div className="flex flex-col space-y-2 mb-3">
                  <div className="flex items-center justify-between gap-3 bg-gray-50 p-3 rounded-lg border border-gray-100 shadow-sm">
                    <div className="flex items-center gap-3">
                      <div className="flex-shrink-0 w-2 h-8 bg-blue-500 rounded-full" />
                      <div className="flex flex-col">
                        <span className="text-sm text-gray-500 font-medium">
                          Your Role
                        </span>
                        <h3 className="text-lg font-bold text-gray-800">
                          {sessionStorage.getItem("userSide")}
                        </h3>
                      </div>
                    </div>

                    <button
                      onClick={() => setIsTutorialOpen(true)}
                      className="flex items-center gap-2 px-3 py-1.5 text-sm text-gray-600 hover:text-blue-600 bg-white rounded-md border border-gray-200 hover:border-blue-200 transition-colors shadow-sm"
                    >
                      <QuestionMarkCircleIcon className="w-4 h-4" />
                      Need Help?
                    </button>
                  </div>

                
                </div>
              </div>

              <div className="w-full aspect-video rounded-lg overflow-hidden bg-gray-100 mb-2">
                <StreamAnalyzer serverUrl={modelUri} />
              </div>

              <textarea
                className="w-full h-24 p-3 rounded-lg border-2 border-gray-200 focus:border-purple-400 focus:ring focus:ring-purple-100 transition-all text-sm"
                placeholder="Enter your arguments here..."
                value={input}
                onChange={handleInputChange}
              />

              <div className="flex gap-2 mt-2">
                <motion.button
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                  className="flex-1 flex items-center justify-center gap-2 p-2 rounded-lg bg-gradient-to-r from-purple-600 to-blue-600 text-white text-sm font-medium"
                  onClick={handleSendMessage}
                >
                  Send Message
                </motion.button>

                <motion.button
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                  className={`w-12 flex items-center justify-center rounded-lg 
          ${
            isListening ? "bg-red-500 text-white" : "bg-gray-100 text-gray-700"
          }`}
                  onClick={isListening ? handleMicEnd : handleVoiceInput}
                >
                  <FontAwesomeIcon icon={faMicrophone} />
                </motion.button>
              </div>
            </div>

            {/* Legal Tools Section */}
            <div className="bg-white rounded-xl shadow-lg p-4 border-2 border-purple-100">
              <h3 className="font-semibold text-gray-700 mb-2">Legal Tools</h3>
              <div className="grid grid-cols-2 gap-2">
                <motion.button
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                  onClick={handleDetailClick}
                  className="flex items-center gap-2 p-2 rounded-lg bg-gray-50 hover:bg-gray-100 text-gray-700 text-sm"
                >
                  <FontAwesomeIcon icon={faBook} className="text-purple-500" />
                  Case Details
                </motion.button>
                <motion.button
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                  onClick={handleBotClick}
                  className="flex items-center gap-2 p-2 rounded-lg bg-gray-50 hover:bg-gray-100 text-gray-700 text-sm"
                >
                  <FontAwesomeIcon icon={faRobot} className="text-purple-500" />
                  Avyukta Bot
                </motion.button>
                <motion.button
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                  onClick={() => setIsExpand(!isExpand)}
                  className="flex items-center gap-2 p-2 rounded-lg bg-gray-50 hover:bg-gray-100 text-gray-700 text-sm"
                >
                  <FontAwesomeIcon icon={faBrain} className="text-purple-500" />
                  View Logs
                </motion.button>
                <motion.button
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                  onClick={handleButtonClick}
                  className="flex items-center gap-2 p-2 rounded-lg bg-gray-50 hover:bg-gray-100 text-gray-700 text-sm"
                >
                  <FontAwesomeIcon
                    icon={faNotesMedical}
                    className="text-purple-500"
                  />
                  My Notes
                </motion.button>
              </div>
            </div>

            {/* Action Buttons */}
            <div className="grid grid-cols-2 gap-2">
              <motion.button
                whileHover={{ scale: 1.02 }}
                whileTap={{ scale: 0.98 }}
                onClick={handleAnalytics}
                className="p-2.5 rounded-xl bg-gradient-to-r from-red-500 to-orange-500 text-white text-sm font-medium shadow-lg"
              >
                <FontAwesomeIcon icon={faGavel} className="mr-2" />
                Request Judgment
              </motion.button>

              <motion.button
                whileHover={{ scale: 1.02 }}
                whileTap={{ scale: 0.98 }}
                onClick={handleExitCourt}
                className="p-2.5 rounded-xl bg-purple-500 text-white text-sm font-medium"
              >
                <FontAwesomeIcon icon={faArrowLeft} className="mr-2" />
                Exit Court
              </motion.button>
            </div>
          </motion.div>
        </div>
      </motion.div>

      {/* Popovers */}
      <AnimatePresence>
        {isCaseDetailsPopoverOpen && (
          <CaseDetailsPopover onClose={handleCLoseDetailsPopover} />
        )}
        {/* {isProcessing && (
          <ThinkingPopover
            isVisible={isProcessing}
            gifSrc="lawyerThinking.gif"
          />
        )} */}
        {showExit && <ExitConfirmation onClose={handleExitPopoverClose} />}
        {isBotPopoverOpen && <IpcBotPopover onClose={handleBotClosePopover} />}
        {isNotesPopoverOpen && <NotesPopover onClose={handleClosePopover} />}
      </AnimatePresence>
    </div>
  );
};

export default PageCourtAnimated;
