import React, { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { MessageCircle } from "lucide-react";
import PixelMessagePopover from "./PixelMessagePopover";
import { set } from "lodash";

type CharacterType = "judge" | "user" | "prosecutor";
type SizeType = "small" | "medium" | "large";

interface CharacterStyles {
  robe: string;
  accent: string;
  scale: string;
  headpiece: string;
  sash: string;
  skin: string;
}

interface PixelCharacterProps {
  type?: CharacterType;
  isThinking?: boolean;
  message?: string;
  className?: string;
  size?: SizeType;
}

const PixelCharacter: React.FC<PixelCharacterProps> = ({
  type = "user",
  isThinking = false,
  message = "",
  className = "",
  size = "medium",
}) => {
  const [walkFrame, setWalkFrame] = useState(0);
  const [blinkFrame, setBlinkFrame] = useState(false);

  useEffect(() => {
    const blinkInterval = setInterval(() => {
      setBlinkFrame(true);
      setTimeout(() => setBlinkFrame(false), 200);
    }, Math.random() * 3000 + 2000);
    return () => clearInterval(blinkInterval);
  }, []);

  useEffect(() => {
    if (isThinking) {
      const timer = setInterval(() => {
        setWalkFrame((prev) => (prev + 1) % 4);
      }, 250);
      return () => clearInterval(timer);
    }
  }, [isThinking]);

  const characterStyles: Record<CharacterType, CharacterStyles> = {
    judge: {
      robe: "bg-gray-800",
      accent: "bg-yellow-500",
      scale: "scale-125",
      headpiece: "bg-gray-300",
      sash: "bg-yellow-600",
      skin: "bg-[#FFE0BD]",
    },
    user: {
      robe: "bg-blue-600",
      accent: "bg-blue-300",
      scale: "scale-110",
      headpiece: "bg-blue-200",
      sash: "bg-blue-400",
      skin: "bg-[#FFD3B5]",
    },
    prosecutor: {
      robe: "bg-red-600",
      accent: "bg-red-300",
      scale: "scale-110",
      headpiece: "bg-red-200",
      sash: "bg-red-800",
      skin: "bg-[#FFE0BD]",
    },
  };

  const sizeClasses: Record<SizeType, string> = {
    small: "w-8 h-12",
    medium: "w-12 h-16",
    large: "w-16 h-20",
  };

  const styles = characterStyles[type];
  const currentSize = sizeClasses[size];

  return (
    <div className={`relative ${className}`}>
      <motion.div
        className={`relative ${styles.scale}`}
        animate={
          isThinking
            ? {
                y: [0, -2, 0, 2, 0],
                x: [0, 2, 0, -2, 0],
              }
            : {}
        }
        transition={{
          duration: 2,
          repeat: Infinity,
          ease: "easeInOut",
        }}
      >
        <div className={`relative ${currentSize}`}>
          {/* Head */}
          <motion.div
            className={`absolute w-6 h-6 ${styles.skin} rounded-full left-1/2 -translate-x-1/2 shadow-md`}
          >
            {/* Eyes */}
            <motion.div
              className="absolute top-2 left-1 w-1 h-1 bg-black rounded-full"
              animate={blinkFrame ? { scaleY: 0.2 } : { scaleY: 1 }}
            />
            <motion.div
              className="absolute top-2 right-1 w-1 h-1 bg-black rounded-full"
              animate={blinkFrame ? { scaleY: 0.2 } : { scaleY: 1 }}
            />

            {/* Mouth */}
            <motion.div
              className="absolute bottom-2 left-1/2 -translate-x-1/2 bg-black rounded-full"
              animate={
                isThinking
                  ? {
                      width: "6px",
                      height: "6px",
                      borderRadius: "50%",
                    }
                  : {
                      width: "8px",
                      height: "2px",
                    }
              }
            />

            {/* Judge-specific headpiece */}
            {type === "judge" && (
              <motion.div
                className="absolute -top-3 left-1/2 -translate-x-1/2"
                animate={
                  isThinking
                    ? {
                        rotate: [-2, 2, -2],
                        transition: { duration: 1, repeat: Infinity },
                      }
                    : {}
                }
              >
                <div
                  className={`w-8 h-4 ${styles.headpiece} rounded-t-lg shadow-inner`}
                />
                <div className={`w-8 h-2 ${styles.sash} rounded opacity-80`} />
              </motion.div>
            )}
          </motion.div>

          {/* Body */}
          <div
            className={`absolute w-8 h-8 ${styles.robe} top-5 left-1/2 -translate-x-1/2 rounded-lg shadow-lg`}
          >
            <div
              className={`absolute top-0 left-1/2 -translate-x-1/2 w-6 h-1 ${styles.accent} opacity-60 rounded-full`}
            />
            <div
              className={`absolute top-2 left-1 w-1 h-4 ${styles.accent} opacity-40`}
            />
            <div
              className={`absolute top-2 right-1 w-1 h-4 ${styles.accent} opacity-40`}
            />
          </div>

          {/* Arms */}
          <motion.div
            animate={
              isThinking
                ? {
                    rotate: [-10, 10, -10],
                    transition: { duration: 1, repeat: Infinity },
                  }
                : {}
            }
            className="absolute top-7 left-1/2 -translate-x-1/2 w-full"
          >
            <div
              className={`absolute w-3 h-2 ${styles.robe} left-0 rounded-full shadow-sm`}
            />
            <div
              className={`absolute w-3 h-2 ${styles.robe} right-0 rounded-full shadow-sm`}
            />
          </motion.div>

          {/* Legs */}
          <motion.div className="absolute top-12 left-1/2 -translate-x-1/2 w-full">
            <motion.div
              className={`absolute w-2 h-4 ${styles.robe} left-2 origin-top rounded-b-lg shadow-sm`}
              animate={
                isThinking
                  ? {
                      rotate: [-15, 15, -15],
                      transition: { duration: 1, repeat: Infinity },
                    }
                  : {}
              }
            />
            <motion.div
              className={`absolute w-2 h-4 ${styles.robe} right-2 origin-top rounded-b-lg shadow-sm`}
              animate={
                isThinking
                  ? {
                      rotate: [15, -15, 15],
                      transition: { duration: 1, repeat: Infinity },
                    }
                  : {}
              }
            />
          </motion.div>
        </div>

        {/* Thinking bubble */}
        <AnimatePresence>
          {isThinking && (
            <motion.div
              initial={{ opacity: 0, scale: 0.5, y: 10 }}
              animate={{ opacity: 1, scale: 1, y: 0 }}
              exit={{ opacity: 0, scale: 0.5, y: 10 }}
              className="absolute -top-8 -right-6"
            >
              <div className="flex items-center gap-1 px-2 py-1 bg-white rounded-full shadow-lg">
                <motion.span
                  animate={{
                    scale: [1, 1.1, 1],
                    transition: { duration: 0.5, repeat: Infinity },
                  }}
                  className="text-gray-600"
                >
                  🤔
                </motion.span>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </motion.div>
    </div>
  );
};

export default PixelCharacter;
