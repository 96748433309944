import React from "react";
import { motion } from "framer-motion";
import { X } from "lucide-react";

interface MessageDisplayProps {
  text: string;
  role: "judge" | "prosecutor";
  onClose: () => void;
}

const MessageDisplay: React.FC<MessageDisplayProps> = ({
  text,
  role,
  onClose,
}) => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="fixed inset-0 bg-black/30 backdrop-blur-sm z-50 flex items-center justify-center p-4 md:p-8"
    >
      <motion.div
        initial={{ opacity: 0, scale: 0.95 }}
        animate={{ opacity: 1, scale: 1 }}
        exit={{ opacity: 0, scale: 0.95 }}
        transition={{ duration: 0.3 }}
        className="bg-white w-full max-w-7xl rounded-2xl shadow-2xl overflow-hidden relative"
      >
        {/* Close Button */}
        <motion.button
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.9 }}
          onClick={onClose}
          className="absolute top-4 right-4 z-10 p-2 hover:bg-black/5 rounded-full transition-colors"
        >
          <X className="w-6 h-6 text-gray-500" />
        </motion.button>

        <div className="flex flex-col md:flex-row h-[80vh]">
          {/* Left Side - Image */}
          <motion.div
            initial={{ x: -50, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            transition={{ delay: 0.2, duration: 0.5 }}
            className="w-full md:w-1/2 bg-gradient-to-br from-purple-500 to-blue-500 p-8 flex items-center justify-center"
          >
            <div className="relative">
              <motion.div
                initial={{ scale: 0.8, opacity: 0 }}
                animate={{ scale: 1, opacity: 1 }}
                transition={{ delay: 0.4, duration: 0.5 }}
                className="w-64 h-64 md:w-96 md:h-96 rounded-full bg-white/10 absolute -inset-4 blur-2xl"
              />
              <motion.div
                initial={{ y: 20, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ delay: 0.6, duration: 0.5 }}
                className="relative"
              >
                <div className="text-center text-white">
                  <div className="text-4xl md:text-6xl font-bold p-6">
                    {role === "judge" ? "👨‍⚖️" : "🤖"}
                  </div>
                  <div className="w-48 h-48 md:w-64 md:h-64 rounded-full bg-white/20 mx-auto mb-6 flex items-center justify-center">
                    <img
                      src="/aiSpeaking.jpeg"
                      alt="Description of AI speaking"
                      className="w-48 h-48 md:w-64 md:h-64 rounded-full bg-white/20 mx-auto flex items-center justify-center object-cover"
                    />
                  </div>
                  <h2 className="text-2xl md:text-3xl font-bold mb-2">
                    {role === "judge" ? "Judge's Response" : "AI"}
                  </h2>
                  <p className="text-white/80 text-sm md:text-base">
                    {new Date().toLocaleTimeString()}
                  </p>
                </div>
              </motion.div>
            </div>
          </motion.div>

          {/* Right Side - Text Content */}
          <motion.div
            initial={{ x: 50, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            transition={{ delay: 0.3, duration: 0.5 }}
            className="w-full md:w-1/2 p-6 md:p-8 overflow-y-auto"
          >
            <div className="h-full">
              <div className="prose prose-lg max-w-none">
                <div className="space-y-6">
                  <motion.p
                    initial={{ y: 20, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    transition={{ delay: 0.5, duration: 0.5 }}
                    className="text-gray-700 leading-relaxed font-medium whitespace-pre-wrap"
                  >
                    {text}
                  </motion.p>
                </div>
              </div>
            </div>
          </motion.div>
        </div>
      </motion.div>
    </motion.div>
  );
};

export default MessageDisplay;
