import React, { useEffect, useRef, useState } from "react";
import { io, Socket } from "socket.io-client";
import axios from "axios";
import { time } from "console";
import { CircleOff, Brain, ActivitySquare, HandMetal, Compass } from "lucide-react";

interface AnalysisResult {
  head_position: {
    state: string;
    vertical_angle: number | null;
    horizontal_angle: number | null;
    confidence: number;
  };
  shoulder_position: {
    posture: string;
    tension_score: number;
    confidence: number;
  };
  hand_gestures: {
    detected_gestures: string[];
    gesture_confidences: string[];
    confidence: number;
  };
  body_orientation: {
    orientation: string;
    forward_angle: number;
    side_angle: number;
  };
}

interface StreamAnalyzerProps {
  serverUrl: string;
  onAnalysis?: (result: AnalysisResult) => void;
  onError?: (error: string) => void;
}

const StreamAnalyzer: React.FC<StreamAnalyzerProps> = ({
  serverUrl,
  onAnalysis,
  onError,
}) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const socketRef = useRef<Socket | null>(null);

  const email = sessionStorage.getItem("email");
  const suffix = email ? email : "";

  const streamIdRef = useRef<string>(`stream_${Date.now()}_${suffix}`);

  const [isStreaming, setIsStreaming] = useState(true);
  const [error, setError] = useState<string>("");
  const [analysisResults, setAnalysisResults] = useState<AnalysisResult | null>(
    null
  );
  const [processedFrame, setProcessedFrame] = useState<string>("");

  useEffect(() => {
      startStream();
  }, []);

  const startStream = async () => {
    try {
      // Start server-side 
      const response = await axios.post(`${serverUrl}/startStream`, {
        stream_id: streamIdRef.current,
      });

      if (response.status === 200) {
        // Initialize socket connection
        socketRef.current = io(serverUrl);
        setupSocketListeners();
        startVideoStream();
        setIsStreaming(true);
        // setTimeout(() =>,2000)
      }
    } catch (err) {
      const errorMessage =
        err instanceof Error ? err.message : "Failed to start stream";
      setError(errorMessage);
      onError?.(errorMessage);
    }
  };

  const stopStream = async () => {
    try {
      if (socketRef.current) {
        socketRef.current.disconnect();
        socketRef.current = null;
      }

      if (isStreaming) {
        // console.log("It was streaming");
        await axios.post(`${serverUrl}/stopStream`, {
          stream_id: streamIdRef.current,
        });
      }

      if (videoRef.current?.srcObject) {
        const tracks = (videoRef.current.srcObject as MediaStream).getTracks();
        tracks.forEach((track) => track.stop());
        videoRef.current.srcObject = null;
      }

      // console.log("Streaming was stopppedd")
      setIsStreaming(false);
    } catch (err) {
      const errorMessage =
        err instanceof Error ? err.message : "Failed to stop stream";
      setError(errorMessage);
      onError?.(errorMessage);
    }
  };

  const setupSocketListeners = () => {
    if (!socketRef.current) return;

    socketRef.current.on("connect", () => {
      console.log("Connected to server");
    });

    socketRef.current.on("error", (data: { message: string }) => {
      // console.log(data);
      setError(data.message);
      onError?.(data.message);
    });

    // console.log("Below is the stream id referenceeee")
    // console.log(streamIdRef.current);
    socketRef.current.on(
      `analysis_result_${streamIdRef.current}`,
      (data: { frame_analysis: AnalysisResult; processed_frame: string }) => {
        setAnalysisResults(data.frame_analysis);
        setProcessedFrame(data.processed_frame);
        onAnalysis?.(data.frame_analysis);
      }
    );
  };

  const startVideoStream = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        video: {
          width: { ideal: 1280 },
          height: { ideal: 720 },
        },
      });

      if (videoRef.current) {
        videoRef.current.srcObject = stream;
        videoRef.current.onloadedmetadata = () => {
          if (videoRef.current) videoRef.current.play();
        };
      }
      // console.log("Started streamingggggggg");
      // Start frame capture loop
      captureFrames();
    } catch (err) {
      const errorMessage =
        err instanceof Error ? err.message : "Failed to access camera";
      setError(errorMessage);
      onError?.(errorMessage);
    }
  };

  const captureFrames = () => {
    // console.log("Here in capture frames")
    let animationFrameId: number; // To store the frame ID for cleanup

    const captureFrame = () => {

      // console.log(isStreaming)
      // console.log(socketRef.current)
      // console.log(streamIdRef.current)
      if ( !socketRef.current) {
        // console.log("Cancelledddddddddddddddddddd")
        cancelAnimationFrame(animationFrameId);
        return;
      }

      const canvas = canvasRef.current;
      const video = videoRef.current;

      if (canvas && video) {
        const ctx = canvas.getContext("2d");
        if (ctx) {
          canvas.width = video.videoWidth;
          canvas.height = video.videoHeight;
          ctx.drawImage(video, 0, 0, canvas.width, canvas.height);

          const frame = canvas.toDataURL("image/jpeg", 0.8);
          // console.log("sent for processing")
          socketRef.current.emit("stream_frame", {
            stream_id: streamIdRef.current,
            frame,
          });
        }
      }

      animationFrameId = requestAnimationFrame(captureFrame);
    };

    animationFrameId = requestAnimationFrame(captureFrame);

    return () => {
      cancelAnimationFrame(animationFrameId);
    };
  };

   const AnalysisOverlay = () => {
     if (!analysisResults) {
       return (
         <div className="bg-black/60 p-4 rounded-lg text-white flex items-center gap-2">
           <CircleOff className="h-4 w-4" />
           <span className="text-sm font-medium">Analysis Unavailable</span>
         </div>
       );
     }

     const metrics = [
       {
         icon: <Brain className="h-4 w-4" />,
         label: "Head",
         value: analysisResults.head_position.state,
         // confidence: Math.round(analysisResults.head_position.confidence * 100)
       },
       {
         icon: <ActivitySquare className="h-4 w-4" />,
         label: "Posture",
         value: analysisResults.shoulder_position.posture,
         // confidence: Math.round(analysisResults.shoulder_position.confidence * 100)
       },
       {
         icon: <HandMetal className="h-4 w-4" />,
         label: "Gestures",
         value:
           analysisResults.hand_gestures.detected_gestures.join(", ") || "None",
       },
       {
         icon: <Compass className="h-4 w-4" />,
         label: "Body Orientation",
         value: `${analysisResults.body_orientation.orientation}`,
         // angle: Math.round(Math.abs(analysisResults.body_orientation.forward_angle))
       },
     ];

     return (
       <div className="w-full bg-gray-200  backdrop-blur-sm p-4 rounded-lg text-white shadow-xl border border-white/10">
         <div className="space-y-3">
           {metrics.map((metric, index) => (
             <div key={metric.label} className="flex items-center gap-3">
               <div className="text-purple-600">{metric.icon}</div>
               <div className="flex-1 min-w-0">
                 <div className="flex items-center justify-between gap-2">
                   <span className="text-sm text-black">
                     {metric.label}
                   </span>
                   <span className="text-sm text-black truncate">
                     {metric.value}
                     {/* {metric.confidence && ` (${metric.confidence}%)`}
                     {metric.angle && ` (${metric.angle}°)`} */}
                   </span>
                 </div>
               </div>
             </div>
           ))}
         </div>
       </div>
     );
   };

  return (
    <div className="relative w-full max-w-4xl mx-auto">
      {/* Error Alert */}
      {error && (
        <div className="absolute top-0 left-0 right-0 bg-red-500 text-white p-2 text-center">
          {error}
        </div>
      )}

      {/* Video Container */}
      <div className="relative aspect-video bg-gray-900 rounded-lg overflow-hidden">
        {/* Original Video (hidden) */}
        <video ref={videoRef} className="" playsInline muted />

        {/* Canvas for Frame Capture (hidden) */}
        <canvas ref={canvasRef} className="hidden" />

        {/* Processed Video Display */}
        {/* {processedFrame ? (
          <div>
            </div>
        ) : (
          <div className="absolute inset-0 flex items-center justify-center text-white">
            No video signal
          </div>
        )} */}

      </div>

      {/* Controls */}
      <div className="mt-4 flex justify-center gap-4">
        {AnalysisOverlay()}
      </div>
    </div>
  );
};

export default StreamAnalyzer;
