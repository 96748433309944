import React, { useState } from 'react';
import { Popover, Transition } from '@headlessui/react';
import axios from 'axios';
import { apiUri } from '../../constants';
import Alert from 'shared/Alert/Alert';

interface ChangePasswordPopoverProps {
  onClose: () => void;
}

const ChangePasswordPopover: React.FC<ChangePasswordPopoverProps> = ({ onClose }) => {
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState('');
    
    const [alertMsg, setAlertMsg] = useState('')

  const handleGetEmail = () => {
    return sessionStorage.getItem("email") as string;
  };

  const handleChangePassword = async () => {
    if (newPassword !== confirmPassword) {
      setError("Passwords do not match.");
      return;
    }

    const email = handleGetEmail();
    try {
      const response = await axios.post(`${apiUri}/changePassword`, {
        email: email,
        password: newPassword,
      });

      if (response.data.success) {
         
          setAlertMsg('Password change successful')
        
          setTimeout(() => {
             onClose();
          }, 5000);
          
      } else {
        setError(response.data.data || 'Error changing the password.');
      }
    } catch (err) {
      setError('New password same as old password.');
    }
  };

  return (
    <Popover>
      <Transition
        show={true}
        enter="transition duration-100 ease-out"
        enterFrom="transform scale-95 opacity-0"
        enterTo="transform scale-100 opacity-100"
        leave="transition duration-75 ease-out"
        leaveFrom="transform scale-100 opacity-100"
        leaveTo="transform scale-95 opacity-0"
          >
             
              <Popover.Panel static className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
                  
                  <div className="bg-white dark:bg-gray-800 rounded-lg p-6 shadow-lg max-w-md w-full">
                       {alertMsg != '' && <Alert children={alertMsg} type='success'/>}
            <h2 className="text-2xl font-bold mb-4 text-neutral-900 dark:text-neutral-100">Change Password</h2>

            <div className="space-y-4">
              <input
                type="password"
                placeholder="New Password"
                className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
              />
              <input
                type="password"
                placeholder="Confirm Password"
                className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
              {error && <p className="text-red-500 text-sm">{error}</p>}
            </div>

            <div className="flex justify-end mt-6">
              <button
                type="button"
                onClick={handleChangePassword}
                className="bg-blue-600 text-white px-4 py-2 rounded-md hover:bg-blue-800 mr-2 transition-all duration-300"
              >
                Change Password
              </button>
              <button
                type="button"
                onClick={onClose}
                className="bg-gray-500 text-white px-4 py-2 rounded-md hover:bg-gray-700 transition-all duration-300"
              >
                Cancel
              </button>
            </div>
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  );
};

export default ChangePasswordPopover;
