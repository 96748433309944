import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import {
  Scale,
  BookOpen,
  Users,
  MessageSquare,
  VideoIcon,
  Mic,
  Brain,
  Volume2,
  BarChart3Icon,
  ChevronLeft,
  ChevronRight,
} from "lucide-react";
import PerformanceBreakdown from "containers/HostMootcourt/ScoreGraphAnalysis";

const ComparisonSlider = () => {
  const [activeView, setActiveView] = useState("with");
  const [activeSlide, setActiveSlide] = useState(0);

  const comparisonData = [
    {
      title: "Court Practice Experience",
      withUs: {
        title: "AI-Powered Analysis",
        description:
          "Get real-time scoring and feedback on your legal arguments with our advanced AI analysis dashboard.",
        benefits: [
          "Instant performance metrics",
          "Detailed argument breakdown",
          "Theme and coherence analysis",
          "Vocabulary assessment",
          "Video analysis",
        ],
        component: <PerformanceBreakdown />,
      },
      withoutUs: {
        title: "Traditional Feedback Method",
        description:
          "Rely on manual notes and memory for tracking argument effectiveness.",
        drawbacks: [
          "No real-time feedback",
          "Subjective scoring",
          "Limited analysis depth",
          "Manual tracking required",
        ],
      },
    },
  ];

  return (
    <div className="min-h-screen py-6 sm:py-10 px-4 sm:px-6 lg:px-8 bg-gray-50">
      <div className="max-w-7xl mx-auto">
        <motion.div
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          className="text-center mb-8 sm:mb-12"
        >
          <h2 className="text-3xl sm:text-4xl font-bold text-gray-900 mb-3 sm:mb-4">
            Experience the Difference
          </h2>
          <p className="text-lg sm:text-xl text-gray-600 px-4">
            See how AI Moot Court transforms legal education
          </p>
        </motion.div>

        {/* Toggle Buttons */}
        <div className="flex justify-center mb-6 sm:mb-8">
          <div className="bg-white rounded-full p-1 shadow-lg w-full max-w-md mx-4 flex flex-col sm:flex-row">
            <button
              onClick={() => setActiveView("with")}
              className={`px-4 sm:px-6 py-2 rounded-full transition-all w-full sm:w-auto mb-2 sm:mb-0 ${
                activeView === "with"
                  ? "bg-purple-600 text-white"
                  : "text-gray-600 hover:text-purple-600"
              }`}
            >
              With AI Moot Court
            </button>
            <button
              onClick={() => setActiveView("without")}
              className={`px-4 sm:px-6 py-2 rounded-full transition-all w-full sm:w-auto ${
                activeView === "without"
                  ? "bg-purple-600 text-white"
                  : "text-gray-600 hover:text-purple-600"
              }`}
            >
              Traditional Approach
            </button>
          </div>
        </div>

        {/* Main Content */}
        <AnimatePresence mode="wait">
          <motion.div
            key={`${activeView}-${activeSlide}`}
            initial={{ opacity: 0, x: 20 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: -20 }}
            className="bg-white rounded-2xl shadow-xl overflow-hidden"
          >
            <div className="flex flex-col md:grid md:grid-cols-2 gap-6 sm:gap-8 p-4 sm:p-6 lg:p-8">
              {/* Left Side: Description */}
              <div className="flex flex-col justify-center order-2 md:order-1">
                <h3 className="text-xl sm:text-2xl font-bold text-gray-900 mb-3 sm:mb-4">
                  {
                    comparisonData[activeSlide][
                      activeView === "with" ? "withUs" : "withoutUs"
                    ].title
                  }
                </h3>
                <p className="text-gray-600 mb-4 sm:mb-6 text-sm sm:text-base">
                  {
                    comparisonData[activeSlide][
                      activeView === "with" ? "withUs" : "withoutUs"
                    ].description
                  }
                </p>
                <ul className="space-y-2 sm:space-y-3">
                  {(activeView === "with"
                    ? comparisonData[activeSlide].withUs.benefits
                    : comparisonData[activeSlide].withoutUs.drawbacks
                  ).map((item, index) => (
                    <li
                      key={index}
                      className="flex items-center gap-2 text-gray-700 text-sm sm:text-base"
                    >
                      <div
                        className={`w-2 h-2 rounded-full flex-shrink-0 ${
                          activeView === "with" ? "bg-green-500" : "bg-red-500"
                        }`}
                      />
                      {item}
                    </li>
                  ))}
                </ul>
              </div>

              {/* Right Side: Component or Traditional View */}
              <div className="h-full min-h-[300px] sm:min-h-[400px] flex items-center justify-center order-1 md:order-2">
                {activeView === "with" ? (
                  <div className="w-full h-full">
                    {comparisonData[activeSlide].withUs.component}
                  </div>
                ) : (
                  <div className="bg-gray-100 rounded-xl p-6 sm:p-8 text-center w-full h-full flex flex-col items-center justify-center">
                    <BookOpen className="w-8 h-8 sm:w-12 sm:h-12 text-gray-400 mx-auto mb-3 sm:mb-4" />
                    <p className="text-gray-500 text-sm sm:text-base">
                      Traditional Method
                    </p>
                  </div>
                )}
              </div>
            </div>
          </motion.div>
        </AnimatePresence>

        {/* Navigation Dots */}
        <div className="flex justify-center gap-2 mt-4 sm:mt-6">
          {comparisonData.map((_, index) => (
            <button
              key={index}
              onClick={() => setActiveSlide(index)}
              className={`w-2 h-2 rounded-full transition-all ${
                activeSlide === index
                  ? "bg-purple-600 w-4 sm:w-6"
                  : "bg-gray-300 hover:bg-purple-400"
              }`}
            />
          ))}
        </div>

        {/* CTA Button */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="mt-8 sm:mt-12 text-center"
        >
          <button className="px-6 sm:px-8 py-2.5 sm:py-3 rounded-full bg-gradient-to-r from-purple-600 to-blue-600 text-white font-medium hover:shadow-lg transform hover:scale-105 transition-all text-sm sm:text-base">
            Try AI Moot Court Now
          </button>
        </motion.div>
      </div>
    </div>
  );
};

export default ComparisonSlider;
