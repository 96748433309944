import React, { FC, useEffect, useState } from "react";
import { DEMO_STAY_LISTINGS } from "data/listings";
import { CaseDataType } from "data/types";
import Pagination from "shared/Pagination/Pagination";
import CaseSelectCardContainer from "./CaseSelectCard";
import axios from "axios";
import { apiUri } from "../../constants";
import LoadingIcon from "components/Loading/LoadingIcon";

export interface SelectCaseGridProps {
  className?: string;
  data?: CaseDataType[];
}

const SelectCaseGrid: FC<SelectCaseGridProps> = ({
  className = "",
}) => {

  const [cases, setCases] = useState<CaseDataType[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchCases = async () => {
      try {
        const response = await axios.get(`${apiUri}/fetchAllCases`);
        setCases(response.data.data);
      } catch (error) {
        setError('Error fetching case data');
      } finally {
        setLoading(false);
      }
    };

    fetchCases();
  }, []);

  if (loading) {
    return <LoadingIcon />;
  }

  if (error) {
    return <p>{error}</p>;
  }

  return (
    <div
      className={`nc-SelectCaseGrid ${className}`}
      data-nc-id="SelectCaseGrid"
    >
      <h2 className="text-4xl font-semibold mt-4 mb-4">Select one case</h2>

      <div className="">
        
          <CaseSelectCardContainer cases={cases} />
    
      </div>
      <div className="flex mt-16 justify-center items-center">
        {/* <Pagination /> */}
      </div>
    </div>
  );
};

export default SelectCaseGrid;
