import React from "react";
import { motion, AnimatePresence } from "framer-motion";

interface ThinkingPopoverProps {
  isVisible: boolean;
  message?: string;
  gifSrc: string;
}

const ThinkingPopover = ({
  isVisible,
  message = "Analyzing legal precedents...",
  gifSrc,
}: ThinkingPopoverProps) => {
  return (
    <AnimatePresence>
      {isVisible && (
        <div className="fixed inset-0 flex items-center justify-center bg-black/50 z-50">
          <motion.div
            initial={{ opacity: 0, y: 20, scale: 0.95 }}
            animate={{ opacity: 1, y: 0, scale: 1 }}
            exit={{ opacity: 0, y: 20, scale: 0.95 }}
            transition={{ duration: 0.3 }}
            className="bg-white rounded-lg shadow-xl p-4 max-w-sm border border-gray-200 mx-4"
          >
            <motion.div
              className="flex items-center gap-4"
              initial={{ x: 0 }}
              animate={{ x: [0, 5, -5, 0] }}
              transition={{
                duration: 2,
                repeat: Infinity,
                ease: "easeInOut",
              }}
            >
              <div className="relative w-24 h-24 rounded-lg overflow-hidden bg-gray-100">
                <img
                  src={gifSrc}
                  alt="Thinking lawyer"
                  className="w-full h-full object-cover"
                />
                <motion.div
                  className="absolute inset-0 bg-blue-500/10"
                  animate={{
                    opacity: [0, 0.1, 0],
                  }}
                  transition={{
                    duration: 1.5,
                    repeat: Infinity,
                    ease: "easeInOut",
                  }}
                />
              </div>

              <div className="flex flex-col">
                <h3 className="font-semibold text-gray-900">
                  Legal AI Assistant
                </h3>
                <div className="flex items-center gap-1">
                  <span className="text-sm text-gray-600">{message}</span>
                  <motion.span
                    animate={{ opacity: [0, 1, 0] }}
                    transition={{
                      duration: 1.5,
                      repeat: Infinity,
                      ease: "easeInOut",
                    }}
                    className="text-gray-600"
                  >
                    ...
                  </motion.span>
                </div>
              </div>
            </motion.div>

            <motion.div
              className="mt-3 w-full h-1 bg-gray-100 rounded-full overflow-hidden"
              initial={{ width: "0%" }}
              animate={{ width: "100%" }}
              transition={{
                duration: 3,
                repeat: Infinity,
                ease: "linear",
              }}
            >
              <div className="h-full bg-blue-600 rounded-full" />
            </motion.div>
          </motion.div>
        </div>
      )}
    </AnimatePresence>
  );
};

export default ThinkingPopover;
