import React from 'react';
import { Message } from './types';
// export interface Message {
//     sender: string;
//     text: string;
// };

interface ConversationLogProps {
    messages: Message[];
    conversationRef: React.RefObject<HTMLDivElement>;
}

const ConversationLog: React.FC<ConversationLogProps> = ({ messages, conversationRef }) => {

    const teamName = sessionStorage.getItem('teamName');
    return (
        <div className="flex flex-col overflow-y-auto  max-w-xl mx-auto p-4 bg-creamyYellow border border-gray-300 rounded-lg w-full" ref={conversationRef}>

            {
                messages.map((message, index) => (
                    <div key={index} className={`flex ${message.role === "User" || message.role == teamName ? "justify-end" : "justify-start"
                        } my-2`} >
                        <div className={`p-3 rounded-lg max-w-xs ${message.role === "User" || message.role == teamName
                            ? "bg-blue-500 text-white"
                            : (message.role === "judge" ? "bg-red-500 text-white" : "bg-gray-200 text-black")
                            }`}>
                            {message.text}
                        </div>
                    </div>
                ))
            }
            {/* This below div is made so that Mobile viewers can see the log. Otherwise the log is behind the buttons */}
            <div className='h-[450px]'></div>
        </div>
    );
}

export default ConversationLog;