import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { useNavigate } from "react-router-dom";

type ThemeType = "serious" | "casual";

interface ThemeStyles {
  background: string;
  title: string;
  text: string;
  accent: string;
  image: string;
}

interface StyleOptionProps {
  title: string;
  description: string;
  theme: ThemeType;
  onHover: (theme: ThemeType) => void;
  onSelect: () => void;
  image: string;
}

interface CourtStyleChooserProps {
  isOpen: boolean;
}

interface ConfirmationDialogProps {
  theme: ThemeType;
  isOpen: boolean;
  onConfirm: () => void;
  onCancel: () => void;
}

const ConfirmationDialog: React.FC<ConfirmationDialogProps> = ({
  theme,
  isOpen,
  onConfirm,
  onCancel,
}) => (
  <AnimatePresence>
    {isOpen && (
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        className="fixed inset-0 bg-black/40 flex items-center justify-center z-50 backdrop-blur-sm"
      >
        <motion.div
          initial={{ scale: 0.9, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
          exit={{ scale: 0.9, opacity: 0 }}
          className={`bg-white rounded-xl shadow-2xl p-6 max-w-md w-full mx-4 ${
            theme === "serious" ? "border-slate-200" : "border-violet-200"
          } border-2`}
        >
          <h3
            className={`text-xl font-bold mb-4 ${
              theme === "serious" ? "text-slate-800" : "text-violet-800"
            }`}
          >
            Confirm Your Choice
          </h3>
          <p className="text-gray-600 mb-6">
            {theme === "serious"
              ? "You're about to enter the Professional 3D Court. This experience offers a formal and realistic courtroom simulation."
              : "You're about to enter the Animated Interactive Court. This experience offers a casual and engaging courtroom atmosphere."}
          </p>
          <div className="flex gap-4">
            <motion.button
              whileHover={{ scale: 1.02 }}
              whileTap={{ scale: 0.98 }}
              onClick={onConfirm}
              className={`flex-1 py-2 px-4 rounded-lg text-white ${
                theme === "serious"
                  ? "bg-slate-800 hover:bg-slate-700"
                  : "bg-violet-600 hover:bg-violet-500"
              }`}
            >
              Proceed
            </motion.button>
            <motion.button
              whileHover={{ scale: 1.02 }}
              whileTap={{ scale: 0.98 }}
              onClick={onCancel}
              className="flex-1 py-2 px-4 bg-gray-100 rounded-lg hover:bg-gray-200"
            >
              Go Back
            </motion.button>
          </div>
        </motion.div>
      </motion.div>
    )}
  </AnimatePresence>
);

const StyleOption: React.FC<StyleOptionProps> = ({
  title,
  description,
  theme,
  onHover,
  onSelect,
  image,
}) => (
  <motion.button
    className="relative w-full p-4 rounded-xl shadow-lg hover:shadow-xl transition-shadow"
    initial={{ opacity: 0, y: 20 }}
    animate={{ opacity: 1, y: 0 }}
    whileHover={{ scale: 1.02 }}
    whileTap={{ scale: 0.98 }}
    onHoverStart={() => onHover(theme)}
    onClick={onSelect}
  >
    <div
      className={`aspect-video w-full rounded-lg overflow-hidden mb-4 relative ${
        theme === "serious" ? "bg-slate-900" : "bg-violet-100"
      }`}
    >
      <img
        src={image}
        alt={`${theme} court preview`}
        className="w-full h-full object-cover"
      />
      <div
        className={`absolute inset-0 ${
          theme === "serious"
            ? "bg-gradient-to-t from-slate-900/60 to-transparent"
            : "bg-gradient-to-t from-violet-500/40 to-transparent"
        }`}
      />
    </div>

    <h3
      className={`text-lg font-bold mb-2 ${
        theme === "serious" ? "text-slate-800" : "text-violet-800"
      }`}
    >
      {title}
    </h3>
    <p
      className={`text-sm ${
        theme === "serious" ? "text-slate-600" : "text-violet-600"
      }`}
    >
      {description}
    </p>
  </motion.button>
);

const CourtStyleChooser: React.FC<CourtStyleChooserProps> = ({ isOpen }) => {
  const router = useNavigate();
  const [currentTheme, setCurrentTheme] = useState<ThemeType>("serious");
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [selectedTheme, setSelectedTheme] = useState<ThemeType | null>(null);

  const themeStyles: Record<ThemeType, ThemeStyles> = {
    serious: {
      background: "bg-gradient-to-br from-slate-50 to-slate-100",
      title: "text-slate-900",
      text: "text-slate-700",
      accent: "bg-slate-800",
      image: "seriousCourt.png", // Replace with actual image path
    },
    casual: {
      background: "bg-gradient-to-br from-violet-50 to-pink-50",
      title: "text-violet-900",
      text: "text-violet-700",
      accent: "bg-violet-500",
      image: "animatedCourt.png", // Replace with actual image path
    },
  };

  const handleSelect = (theme: ThemeType) => {
    setSelectedTheme(theme);
    setShowConfirmation(true);
  };

  const handleConfirm = () => {
    if (selectedTheme) {
      const route =
        selectedTheme === "casual" ? "/animatedCourt" : "/pageCourt";
      router(route);
    }
  };

  const theme = themeStyles[currentTheme];

  return (
    <>
      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="fixed inset-0 flex items-center justify-center p-4 bg-black/20 backdrop-blur-sm"
          >
            <motion.div
              initial={{ scale: 0.9, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              exit={{ scale: 0.9, opacity: 0 }}
              className={`relative max-w-2xl w-full ${theme.background} rounded-2xl shadow-2xl p-6 transition-colors duration-300`}
            >
              <motion.div
                className="absolute -top-12 left-1/2 -translate-x-1/2"
                animate={{ y: [0, -5, 0] }}
                transition={{ repeat: Infinity, duration: 2 }}
              >
                <div
                  className={`w-24 h-24 ${theme.accent} rounded-full flex items-center justify-center`}
                >
                  <span className="text-4xl">⚖️</span>
                </div>
              </motion.div>

              <div className="text-center mb-8 mt-8">
                <h2 className={`text-2xl font-bold mb-2 ${theme.title}`}>
                  Choose Your Court Experience
                </h2>
                <p className={`${theme.text}`}>
                  Select a style that matches your preferred courtroom
                  atmosphere
                </p>
              </div>

              <div className="grid md:grid-cols-2 gap-6">
                <StyleOption
                  theme="serious"
                  title="3D Professional Court"
                  description="Experience a realistic 3D courtroom simulation with formal proceedings and professional atmosphere."
                  onHover={setCurrentTheme}
                  onSelect={() => handleSelect("serious")}
                  image={themeStyles.serious.image}
                />

                <StyleOption
                  theme="casual"
                  title="Animated Interactive Court"
                  description="Enjoy a more relaxed, animated courtroom with playful interactions and engaging visuals."
                  onHover={setCurrentTheme}
                  onSelect={() => handleSelect("casual")}
                  image={themeStyles.casual.image}
                />
              </div>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>

      <ConfirmationDialog
        theme={selectedTheme || "serious"}
        isOpen={showConfirmation}
        onConfirm={handleConfirm}
        onCancel={() => setShowConfirmation(false)}
      />
    </>
  );
};

export default CourtStyleChooser;
