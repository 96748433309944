import React, { useState } from "react";
import { Dialog, Transition, Popover } from "@headlessui/react";
import { motion, AnimatePresence } from "framer-motion";
import { ChevronRight, ChevronLeft, X } from "lucide-react";

interface TutorialStep {
  title: string;
  content: string;
  icon: string;
}

const tutorialSteps = [
  {
    title: "Welcome to Virtual Court",
    content:
      "Step into an immersive courtroom experience where you'll participate in proceedings as a lawyer. Let's get you familiar with the interface.",
    icon: "⚖️",
  },
  {
    title: "Interactive Courtroom",
    content:
      "Watch your character come to life in the main courtroom view. Your movements and expressions will reflect your participation in real-time as you present your case.",
    icon: "👨‍⚖️",
  },
  {
    title: "Voice & Text Controls",
    content:
      "Present your arguments naturally using voice commands or type them out. The microphone activates when it's your turn - just wait for the green indicator before speaking.",
    icon: "🎤",
  },
  {
    title: "Real-time Feedback",
    content:
      "Track your performance through the sidebar video feed. It provides instant feedback on your presentation style and courtroom etiquette.",
    icon: "📊",
  },
  {
    title: "Case Resources",
    content:
      "Need to review the details? Click the 'Case Details' button anytime to access the full case brief, evidence, and relevant documents.",
    icon: "📜",
  },
  {
    title: "Legal Assistant",
    content:
      "Access Avyukta, your AI legal assistant, for quick references to case laws and sections. Try asking 'What is Section 144?' or 'Find relevant precedents.'",
    icon: "🤖",
  },
  {
    title: "Ready to Begin",
    content:
      "You're all set to start your court session. Remember to speak clearly, maintain professional conduct, and utilize your resources effectively.",
    icon: "✨",
  },
];
const TutorialPopover: React.FC<{ onClose: () => void }> = ({ onClose }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const nextSlide = () => {
    if (currentIndex < tutorialSteps.length - 1) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  const previousSlide = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  const isFirstSlide = currentIndex === 0;
  const isLastSlide = currentIndex === tutorialSteps.length - 1;

  return (
    <Popover>
      <Transition
        show={true}
        enter="transition duration-100 ease-out"
        enterFrom="transform scale-95 opacity-0"
        enterTo="transform scale-100 opacity-100"
        leave="transition duration-75 ease-out"
        leaveFrom="transform scale-100 opacity-100"
        leaveTo="transform scale-95 opacity-0"
      >
        <Popover.Panel
          static
          className="fixed inset-0 flex items-center justify-center bg-black/50 backdrop-blur-sm z-50"
        >
          <motion.div
            initial={{ opacity: 0, scale: 0.95 }}
            animate={{ opacity: 1, scale: 1 }}
            className="relative bg-white rounded-xl shadow-2xl max-w-2xl w-full mx-4 overflow-hidden"
          >
            {/* Progress bar */}
            <div className="absolute top-0 left-0 right-0 h-1 bg-gray-100">
              <motion.div
                className="h-full bg-blue-500"
                initial={{ width: "0%" }}
                animate={{
                  width: `${
                    ((currentIndex + 1) / tutorialSteps.length) * 100
                  }%`,
                }}
                transition={{ duration: 0.3 }}
              />
            </div>

            <div className="p-6 pt-8">
              {/* Close button */}
              <button
                onClick={onClose}
                className="absolute top-4 right-4 text-gray-400 hover:text-gray-600 transition-colors"
              >
                <X size={20} />
              </button>

              {/* Content */}
              <AnimatePresence mode="wait">
                <motion.div
                  key={currentIndex}
                  initial={{ opacity: 0, x: 20 }}
                  animate={{ opacity: 1, x: 0 }}
                  exit={{ opacity: 0, x: -20 }}
                  className="text-center px-4"
                >
                  <motion.div
                    animate={{ scale: [1, 1.1, 1] }}
                    transition={{ duration: 0.5 }}
                    className="text-4xl mb-4"
                  >
                    {tutorialSteps[currentIndex].icon}
                  </motion.div>
                  <h2 className="text-2xl font-bold text-gray-800 mb-3">
                    {tutorialSteps[currentIndex].title}
                  </h2>
                  <p className="text-gray-600 mb-8">
                    {tutorialSteps[currentIndex].content}
                  </p>
                </motion.div>
              </AnimatePresence>

              {/* Navigation */}
              <div className="flex items-center justify-between mt-6">
                <motion.button
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  onClick={previousSlide}
                  className={`flex items-center gap-2 px-4 py-2 rounded-lg transition-colors ${
                    isFirstSlide
                      ? "text-gray-400 cursor-not-allowed"
                      : "text-gray-600 hover:bg-gray-100"
                  }`}
                  disabled={isFirstSlide}
                >
                  <ChevronLeft size={20} />
                  Previous
                </motion.button>

                <div className="flex gap-1">
                  {tutorialSteps.map((_, index) => (
                    <motion.div
                      key={index}
                      className={`w-2 h-2 rounded-full ${
                        index === currentIndex ? "bg-blue-500" : "bg-gray-300"
                      }`}
                      animate={
                        index === currentIndex ? { scale: [1, 1.2, 1] } : {}
                      }
                    />
                  ))}
                </div>

                {!isLastSlide ? (
                  <motion.button
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    onClick={nextSlide}
                    className="flex items-center gap-2 px-4 py-2 text-blue-600 hover:bg-blue-50 rounded-lg transition-colors"
                  >
                    Next
                    <ChevronRight size={20} />
                  </motion.button>
                ) : (
                  <motion.button
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    onClick={onClose}
                    className="flex items-center gap-2 px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors"
                  >
                    Get Started
                  </motion.button>
                )}
              </div>
            </div>
          </motion.div>
        </Popover.Panel>
      </Transition>
    </Popover>
  );
};

export default TutorialPopover;
