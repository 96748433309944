import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { Link, NavLink, useLocation } from "react-router-dom";
import {
  BookOpen,
  ChevronDown,
  ExternalLink,
  Grid,
  GraduationCap,
  HandHelping,
  Info,
  LayoutGrid,
  Mail,
  Scale,
  Search,
  Shield,
  User,
  Menu,
  LucideLogIn,
  LucideLogOut,
  NewspaperIcon,
  NotebookIcon,
} from "lucide-react";

// Keeping the existing interfaces
interface NavItem {
  id: string;
  name: string;
  href: string;
  icon?: React.ReactNode;
  isExternal?: boolean;
  description?: string;
}

interface NavGroup {
  id: string;
  name: string;
  type: "dropdown" | "button" | "link";
  href?: string;
  icon?: React.ReactNode;
  children?: NavItem[];
}

const NewNavigation_DATA: NavGroup[] = [
  {
    id: "products",
    name: "Products",
    type: "dropdown",
    icon: <Grid className="w-4 h-4" />,
    children: [
      {
        id: "mootcourt",
        name: "AI Mootcourt",
        href: "/mootCourt",
        icon: <Scale />,
        description: "Practice and improve your legal argumentation skills",
      },
      {
        id: "crossword",
        name: "Legal Crossword",
        href: "/crossword",
        icon: <LayoutGrid />,
        description: "Test your legal knowledge in an engaging way",
      },
    ],
  },
  {
    id: "allMootCourts",
    name: "Register for moots",
    type: "link",
    href: "/allMootCourts",
    icon: <User className="w-4 h-4" />,
  },
  {
    id: "blog",
    name: "Our Newsletter",
    type: "button",
    href: "/blog",
    icon: <NewspaperIcon className="w-4 h-4" />,
  },
  {
    id: "more",
    name: "More",
    type: "dropdown",
    icon: <Menu className="w-4 h-4" />,
    children: [
      {
        id: "vision",
        name: "Our Vision",
        href: "/vision",
        icon: <Info className="w-4 h-4" />,
        description: "Learn about our mission and goals",
      },
      {
        id: "contact",
        name: "Contact Us",
        href: "/contactUs",
        icon: <Mail className="w-4 h-4" />,
        description: "Get in touch with our team",
      },
      {
        id: "terms",
        name: "Terms of Service",
        href: "/TermsAndConditions",
        icon: <Shield className="w-4 h-4" />,
        description: "Read our terms and conditions",
      },
      {
        id: "privacy",
        name: "Privacy Policy",
        href: "/privacyPolicy",
        icon: <Shield className="w-4 h-4" />,
        description: "View our privacy policy",
      },
      {
        id: "research",
        name: "Research",
        href: "https://lawpotato.com",
        icon: <Search />,
        isExternal: true,
        description: "Legal Research Tool",
      },
    ],
  },
];

interface DropdownProps {
  items: NavItem[];
  isOpen: boolean;
  onClose: () => void;
}

const handleLogout = () => {
  sessionStorage.clear();
  window.location.href = "/login";
};

const Dropdown: React.FC<DropdownProps> = ({ items, isOpen, onClose }) => (
  <AnimatePresence>
    {isOpen && (
      <motion.div
        initial={{ opacity: 0, y: 10 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: 10 }}
        className="absolute top-full right-0 mt-2 w-64 bg-white rounded-xl shadow-lg border border-gray-100 py-2 z-50"
      >
        <div className="px-2">
          {items.map((item) => (
            <NavLink
              key={item.id}
              to={item.href}
              target={item.isExternal ? "_blank" : undefined}
              rel={item.isExternal ? "noopener noreferrer" : undefined}
              onClick={onClose}
              className={({ isActive }) => `
                flex items-center gap-3 p-3 rounded-lg 
                transition-colors
                ${
                  isActive ? "bg-purple-50 text-purple-600" : "hover:bg-gray-50"
                }
              `}
            >
              <div className="p-2 rounded-lg bg-purple-100">{item.icon}</div>
              <div className="flex-1">
                <div className="flex items-center gap-2">
                  <span className="font-medium">{item.name}</span>
                  {item.isExternal && (
                    <ExternalLink className="w-4 h-4 text-gray-400" />
                  )}
                </div>
                {item.description && (
                  <p className="text-sm text-gray-500 mt-0.5">
                    {item.description}
                  </p>
                )}
              </div>
            </NavLink>
          ))}
        </div>
      </motion.div>
    )}
  </AnimatePresence>
);

const NewNavigation: React.FC = () => {
  const [activeDropdown, setActiveDropdown] = useState<string | null>(null);
  const location = useLocation();

  return (
    <header className="sticky lg:flex hidden top-0 z-50 w-full bg-white border-b border-gray-100">
      <div className="container mx-auto px-4">
        <nav className="flex items-center h-16">
          {/* Logo - Left */}
          <div className="flex-none">
            <Link to="/" className="flex items-center gap-2">
              <img src="/logoFinal.png" alt="Logo" className="h-8 w-auto" />
              <span className="font-bold text-xl text-gray-900">
                AI MOOTCOURT
              </span>
            </Link>
          </div>

          {/* Main Navigation - Center */}
          <div className="flex-1 flex justify-center items-center gap-2">
            {NewNavigation_DATA.map((item) => {
              if (item.type === "dropdown") {
                return (
                  <div
                    key={item.id}
                    className="relative"
                    onMouseEnter={() => setActiveDropdown(item.id)}
                    onMouseLeave={() => setActiveDropdown(null)}
                  >
                    <button
                      className={`
                        flex items-center gap-1.5 px-4 py-2 rounded-lg text-sm font-medium
                        transition-colors
                        ${
                          activeDropdown === item.id
                            ? "bg-gray-200 text-gray-900"
                            : "bg-gray-100 text-gray-600 hover:text-gray-900 hover:bg-gray-50"
                        }
                      `}
                    >
                      {item.icon}
                      {item.name}
                      <ChevronDown className="w-4 h-4 text-gray-400" />
                    </button>
                    <Dropdown
                      items={item.children || []}
                      isOpen={activeDropdown === item.id}
                      onClose={() => setActiveDropdown(null)}
                    />
                  </div>
                );
              }

              if (item.type === "button") {
                return (
                  <Link
                    key={item.id}
                    to={item.href || "/"}
                    className="flex items-center gap-1.5 px-4 py-2 rounded-lg text-sm font-medium bg-purple-100 text-gray-600 hover:text-gray-900 hover:bg-purple-200 transition-colors"
                  >
                    {item.icon}
                    {item.name}
                  </Link>
                );
              }

              return (
                <NavLink
                  key={item.id}
                  to={item.href || "/"}
                  className="flex items-center gap-1.5 px-4 py-2 rounded-lg text-sm bg-blue-100 font-medium text-gray-600 hover:text-gray-900 hover:bg-blue-200 transition-colors"
                >
                  {item.icon}
                  {item.name}
                </NavLink>
              );
            })}
          </div>

          {/* Auth Buttons - Right */}
          <div className="flex-none flex items-center gap-2">
            {sessionStorage.getItem("email") === null ||
            sessionStorage.getItem("email") === "" ? (
              <>
                <Link
                  to="/login"
                  className="px-4 py-2 text-sm font-medium bg-purple-700 hover:bg-purple-800 rounded-lg text-white"
                >
                  Login
                </Link>
                <Link
                  to="/contactUs"
                  className="px-4 py-2 rounded-lg text-sm font-medium bg-blue-600 text-white hover:bg-blue-700 transition-colors flex items-center gap-2"
                >
                  Book a demo
                  <ExternalLink className="w-4 h-4" />
                </Link>
              </>
            ) : (
              <button
                className="px-4 py-2 rounded-lg text-sm font-medium bg-amber-600 hover:bg-amber-700 transition-colors flex items-center gap-2 text-white"
                onClick={handleLogout}
              >
                <LucideLogOut className="w-4 h-4" />
                Logout
              </button>
            )}
          </div>
        </nav>
      </div>
    </header>
  );
};

export default NewNavigation;
