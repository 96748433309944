import React, { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { Link } from "react-router-dom";
import axios from "axios";
import { apiUri } from "../../constants";
import {
  Mail,
  Lock,
  ArrowRight,
  AlertCircle,
  CheckCircle2,
  Sparkles,
  UserPlus,
  KeyRound,
  Shield,
} from "lucide-react";
import ChangePasswordPopover from "containers/HostMootcourt/ResetPasswordPopover";
import ChangePasswordFromLogin from "./ChangePasswordFromLoginPopover";

interface PageLoginProps {
  className?: string;
}

const PageLogin: React.FC<PageLoginProps> = ({ className = "" }) => {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    isEmailValid: false,
    showPassword: false,
    isLoading: false,
  });

  const [error, setError] = useState<string | null>(null);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [showPopover, setShowPopover] = useState<boolean>(false);

  const validateEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
      ...(name === "email" && { isEmailValid: validateEmail(value) }),
    }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setFormData((prev) => ({ ...prev, isLoading: true }));
    setError(null);

    try {
      const response = await axios.post(`${apiUri}/login`, {
        email: formData.email,
        password: formData.password,
      });

      if (response.data.success) {
        setSuccessMessage("Login successful! Redirecting...");
        sessionStorage.setItem("loggedIn", "true");
        sessionStorage.setItem("subscribed", response.data.data);
        sessionStorage.setItem("email", formData.email);

       setTimeout(() => {
         const caseId = sessionStorage.getItem("caseId");
         if (caseId) {
           window.location.href = "/caseDetails";
         } else {
           window.location.href = "/mootCourt";
         }
       }, 1000);

      }
    } catch (err) {
      setError("Invalid email or password. Please try again.");
    } finally {
      setFormData((prev) => ({ ...prev, isLoading: false }));
    }
  };

  // Clear error/success messages after delay
  useEffect(() => {
    if (error || successMessage) {
      const timer = setTimeout(() => {
        setError(null);
        setSuccessMessage(null);
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [error, successMessage]);

  const handlePopoverClose = () => {
    setShowPopover(false)
  }

  return (
    <div
      className={`min-h-screen bg-gradient-to-b from-purple-50 to-white py-20 ${className}`}
    >
      <div className="container mx-auto px-4">

        {showPopover && <ChangePasswordFromLogin onClose={handlePopoverClose} />}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="max-w-md mx-auto"
        >
          {/* Header */}
          <div className="text-center mb-8">
            <motion.div
              initial={{ scale: 0 }}
              animate={{ scale: 1 }}
              className="w-16 h-16 mx-auto mb-4 rounded-full bg-gradient-to-r from-purple-600 to-blue-600 flex items-center justify-center"
            >
              <Shield className="w-8 h-8 text-white" />
            </motion.div>
            <h1 className="text-3xl font-bold bg-gradient-to-r from-purple-600 to-blue-600 bg-clip-text text-transparent">
              Welcome Back
            </h1>
            <p className="text-gray-600 mt-2">
              Login to access your account and continue your legal journey
            </p>
          </div>

          {/* Alert Messages */}
          <AnimatePresence mode="wait">
            {(error || successMessage) && (
              <motion.div
                initial={{ opacity: 0, y: -20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -20 }}
                className={`p-4 rounded-lg mb-6 flex items-center ${
                  error
                    ? "bg-red-50 text-red-600"
                    : "bg-green-50 text-green-600"
                }`}
              >
                {error ? (
                  <AlertCircle className="w-5 h-5 mr-2" />
                ) : (
                  <CheckCircle2 className="w-5 h-5 mr-2" />
                )}
                <span>{error || successMessage}</span>
              </motion.div>
            )}
          </AnimatePresence>

          {/* Login Form */}
          <motion.form
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.2 }}
            onSubmit={handleSubmit}
            className="bg-white p-8 rounded-2xl shadow-lg"
          >
            {/* Email Field */}
            <div className="mb-6">
              <label className="block text-gray-700 text-sm font-medium mb-2">
                Email Address
              </label>
              <div className="relative">
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  className={`
                    w-full px-4 py-3 rounded-lg border bg-gray-50 
                    focus:outline-none focus:ring-2 transition-all
                    ${
                      formData.isEmailValid
                        ? "border-green-300 focus:ring-green-200"
                        : "border-gray-200 focus:ring-purple-200"
                    }
                  `}
                  placeholder="you@example.com"
                />
                <Mail
                  className={`
                  w-5 h-5 absolute right-3 top-1/2 transform -translate-y-1/2
                  ${formData.isEmailValid ? "text-green-500" : "text-gray-400"}
                `}
                />
              </div>
            </div>

            {/* Password Field */}
            <div className="mb-6">
              <label className="block text-gray-700 text-sm font-medium mb-2">
                Password
              </label>
              <div className="relative">
                <input
                  type={formData.showPassword ? "text" : "password"}
                  name="password"
                  value={formData.password}
                  onChange={handleInputChange}
                  className="w-full px-4 py-3 rounded-lg border border-gray-200 bg-gray-50 focus:outline-none focus:ring-2 focus:ring-purple-200 transition-all"
                  placeholder="Enter your password"
                />
                <Lock className="w-5 h-5 absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
              </div>
            </div>

            {/* Submit Button */}
            <button
              type="submit"
              disabled={formData.isLoading}
              className={`
                w-full py-3 px-4 rounded-lg bg-gradient-to-r from-purple-600 to-blue-600 
                text-white font-medium flex items-center justify-center
                transform transition-all duration-300
                ${
                  formData.isLoading
                    ? "opacity-75"
                    : "hover:shadow-lg hover:-translate-y-1"
                }
              `}
            >
              {formData.isLoading ? (
                <motion.div
                  animate={{ rotate: 360 }}
                  transition={{ duration: 1, repeat: Infinity, ease: "linear" }}
                  className="w-5 h-5 border-2 border-white border-t-transparent rounded-full"
                />
              ) : (
                <>
                  Sign In
                  <ArrowRight className="w-5 h-5 ml-2" />
                </>
              )}
            </button>

            {/* Forgot Password */}
            <div className="mt-6 text-center">
              <button
                type="button"
                onClick={() =>
                  setShowPopover(true)
                }
                className="text-sm text-purple-600 hover:text-purple-700 font-medium"
              >
                <KeyRound className="w-4 h-4 inline mr-1" />
                Forgot your password?
              </button>
            </div>
          </motion.form>

          {/* Sign Up Promotion */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.4 }}
            className="mt-8 p-6 bg-gradient-to-r from-purple-50 to-blue-50 rounded-2xl shadow-lg"
          >
            <div className="flex items-start space-x-4">
              <div className="p-3 bg-white rounded-lg">
                <Sparkles className="w-6 h-6 text-purple-600" />
              </div>
              <div>
                <h3 className="text-lg font-semibold text-gray-900">
                  New to our platform?
                </h3>
                <p className="text-gray-600 mt-1 mb-4">
                  Sign up today and get 10 free trial cases to jumpstart your
                  practice!
                </p>
                <Link
                  to="/signup"
                  className="inline-flex items-center px-4 py-2 rounded-lg bg-white text-purple-600 font-medium hover:bg-purple-50 transition-colors"
                >
                  <UserPlus className="w-5 h-5 mr-2" />
                  Create Account
                </Link>
              </div>
            </div>
          </motion.div>
        </motion.div>
      </div>
    </div>
  );
};

export default PageLogin;
