import React from "react"
import DescriptionRenderer from "./DescriptionRenderer";

interface caseComponentProp {

  description: string;
  caseLaws: string[];
  aim: string;
}

const CaseContentComponenet: React.FC<caseComponentProp> = (
    {
        description,
        caseLaws,
        aim
    }
) => {
  return (
    <div>
      {/* Main Content Grid */}
      <div className="grid md:grid-cols-3 gap-8">
        {/* Left Column - Main Content */}
        <div className="md:col-span-2 space-y-8">
          {/* Objective Section */}
          <div className="bg-white rounded-xl shadow-lg p-8 border-2 border-purple-100">
            <h2 className="text-2xl font-bold mb-4 flex items-center gap-3">
              🎯 Learning Objective
            </h2>
            <p className="text-gray-700 text-lg leading-relaxed">{aim}</p>
          </div>

          {/* Case Laws Section */}
          <div className="bg-white rounded-xl shadow-lg p-8 border-2 border-purple-100">
            <h2 className="text-2xl font-bold mb-4 flex items-center gap-3">
              📚 Important Case Laws
            </h2>
            <ul className="space-y-4">
              {caseLaws.map((law, idx) => (
                <li
                  key={idx}
                  className="flex items-start gap-3 p-4 bg-gray-50 rounded-xl"
                >
                  <span className="text-xl">📋</span>
                  <span className="text-gray-700">{law}</span>
                </li>
              ))}
            </ul>
          </div>

          {/* Case Description */}
          <div className="bg-white rounded-xl shadow-lg p-8 border-2 border-purple-100">
            <h2 className="text-2xl font-bold mb-4 flex items-center gap-3">
              📝 Case Brief
            </h2>
            <div className="text-gray-700 space-y-4 text-lg leading-relaxed">
              {<DescriptionRenderer description={description} />}
            </div>
          </div>
        </div>

        {/* Right Column - Study Guide */}
        <div className="space-y-6">
          {/* How to Prepare */}
          <div className="bg-white rounded-xl shadow-lg p-8 border-2 border-purple-100">
            <h2 className="text-2xl font-bold mb-4">📋 Study Guide</h2>
            <div className="space-y-4">
              <div className="p-6 bg-blue-50 rounded-xl border-2 border-blue-100">
                <h3 className="font-bold text-lg mb-2 flex items-center gap-2">
                  <span>1️⃣</span> Analyze
                </h3>
                <p className="text-gray-700">
                  Read and analyze the case thoroughly. Take notes of key
                  points! 📝
                </p>
              </div>
              <div className="p-6 bg-purple-50 rounded-xl border-2 border-purple-100">
                <h3 className="font-bold text-lg mb-2 flex items-center gap-2">
                  <span>2️⃣</span> Choose Your Role
                </h3>
                <p className="text-gray-700">
                  Pick your side: prosecutor or defense. 🤔
                </p>
              </div>
              <div className="p-6 bg-green-50 rounded-xl border-2 border-green-100">
                <h3 className="font-bold text-lg mb-2 flex items-center gap-2">
                  <span>3️⃣</span> Practice Time
                </h3>
                <p className="text-gray-700">
                  Use the AI assistant to practice your arguments! 🎯
                </p>
              </div>
            </div>
          </div>

          {/* Scoring Guide */}
          <div className="bg-white rounded-xl shadow-lg p-8 border-2 border-purple-100">
            <h2 className="text-2xl font-bold mb-4">🏆 Scoring Guide</h2>
            <ul className="space-y-4">
              <li className="flex items-center gap-3 p-4 bg-yellow-50 rounded-xl border-2 border-yellow-100">
                <span>🎯</span>
                <span className="text-gray-700">Use of relevant keywords</span>
              </li>
              <li className="flex items-center gap-3 p-4 bg-green-50 rounded-xl border-2 border-green-100">
                <span>📚</span>
                <span className="text-gray-700">Citation of case laws</span>
              </li>
              <li className="flex items-center gap-3 p-4 bg-blue-50 rounded-xl border-2 border-blue-100">
                <span>💡</span>
                <span className="text-gray-700">Quality of arguments</span>
              </li>
            </ul>
          </div>

          {/* Study Tips */}
          <div className="bg-white rounded-xl shadow-lg p-8 border-2 border-purple-100">
            <h2 className="text-2xl font-bold mb-4">💡 Pro Tips</h2>
            <ul className="space-y-3">
              <li className="flex items-center gap-2 text-gray-700">
                <span>✨</span>
                <span>Practice regularly for better results!</span>
              </li>
              <li className="flex items-center gap-2 text-gray-700">
                <span>🎯</span>
                <span>Focus on building strong arguments</span>
              </li>
              <li className="flex items-center gap-2 text-gray-700">
                <span>🌟</span>
                <span>Learn from each practice session</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};


export default CaseContentComponenet