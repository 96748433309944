import React, { useState } from 'react';
import { Popover, Transition } from '@headlessui/react';
import { X, Maximize2, Minimize2 } from 'lucide-react';

const IpcBotPopover: React.FC<{ onClose: () => void }> = ({ onClose }) => {
    const [isFullScreen, setIsFullScreen] = useState(false);

    const toggleFullScreen = () => {
        setIsFullScreen(!isFullScreen);
    };

    return (
        <Popover>
            <Transition
                show={true}
                enter="transition duration-200 ease-out"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="transition duration-150 ease-in"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
            >
                <Popover.Panel 
                    static 
                    className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50"
                >
                    <div className={`bg-white dark:bg-gray-800 rounded-lg shadow-xl overflow-hidden ${
                        isFullScreen ? 'w-full h-full' : 'w-11/12 h-5/6 max-w-4xl max-h-[90vh]'
                    }`}>
                        <div className="flex justify-between items-center p-4 bg-gray-100 dark:bg-gray-700">
                            <h2 className="text-xl font-bold text-gray-800 dark:text-gray-200">Avyukta Bot</h2>
                            <div className="flex space-x-2">
                                <button
                                    
                                    onClick={toggleFullScreen}
                                    aria-label={isFullScreen ? "Exit full screen" : "Enter full screen"}
                                >
                                    {isFullScreen ? <Minimize2 className="h-5 w-5" /> : <Maximize2 className="h-5 w-5" />}
                                </button>
                                <button
                                    
                                    onClick={onClose}
                                    aria-label="Close"
                                >
                                    <X className="h-5 w-5" />
                                </button>
                            </div>
                        </div>
                        <iframe
                            src="https://law-site-main.vercel.app/"
                            title="Avyukta Bot"
                            className="w-full h-[calc(100%-60px)]"
                            loading="lazy"
                        />
                    </div>
                </Popover.Panel>
            </Transition>
        </Popover>
    );
};

export default IpcBotPopover;