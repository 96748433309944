import Logo from "shared/Logo/Logo";
import React from "react";

export interface WidgetFooterMenu {
  id: string;
  title: string;
  menus: CustomLink[];
}

interface CustomLink {
  href: string;
  label: string;
  external?: boolean;
}

const widgetMenus: WidgetFooterMenu[] = [
  {
    id: "1",
    title: "For Students",
    menus: [
      { href: "/mootCourt", label: "Practice Moots" },
      { href: "/crossword", label: "Legal Crosswords" },
    ],
  },
  {
    id: "2",
    title: "For Institutions",
    menus: [
      { href: "/mootInfoRegistration", label: "Register Institution" },
      { href: "/subscription", label: "Institutional Plans" },
    ],
  },
  {
    id: "3",
    title: "Resources",
    menus: [
      { href: "/blog", label: "Legal Blog" },
      { href: "https://lawpotato.com/", label: "LawPotato", external: true },
      { href: "/vision", label: "Our Vision" },
    ],
  },
  {
    id: "4",
    title: "Company",
    menus: [
      { href: "/about", label: "About Us" },
      { href: "/contactus", label: "Contact" },
      { href: "/privacyPolicy", label: "Privacy Policy" },
      { href: "/TermsAndConditions", label: "Terms of Use" },
    ],
  },
  {
    id: "5",
    title: "Connect",
    menus: [
      {
        href: "https://www.linkedin.com/company/aimootcourt",
        label: "LinkedIn",
        external: true,
      },
      // {
      //   href: "https://www.instagram.com/aimootcourt",
      //   label: "Instagram",
      //   external: true,
      // },
      // {
      //   href: "https://twitter.com/shisuimadara",
      //   label: "Twitter",
      //   external: true,
      // },
    ],
  },
];

const Footer: React.FC = () => {
  const currentYear = new Date().getFullYear();

  const renderWidgetMenuItem = (menu: WidgetFooterMenu, index: number) => {
    return (
      <div key={index} className="text-sm">
        <h2 className="font-semibold text-white text-lg mb-5">{menu.title}</h2>
        <ul className="space-y-3">
          {menu.menus.map((item, index) => (
            <li key={index}>
              <a
                href={item.href}
                className="text-gray-300 hover:text-yellow-400 transition-colors duration-200"
                {...(item.external
                  ? { target: "_blank", rel: "noopener noreferrer" }
                  : {})}
              >
                {item.label}
              </a>
            </li>
          ))}
        </ul>
      </div>
    );
  };

  return (
    <footer className="bg-black">
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        {/* Main Footer Content */}
        <div className="py-12 lg:py-16">
          <div className="grid grid-cols-2 gap-8 md:grid-cols-3 lg:grid-cols-6">
            {/* Logo and Description Column */}
            <div className="col-span-2 lg:col-span-1">
              <Logo />
              <p className="mt-4 text-sm text-gray-400">
                Empowering future lawyers with AI-powered moot court training
              </p>
            </div>
            {/* Menu Columns */}
            <div className="col-span-2 lg:col-span-5">
              <div className="grid grid-cols-2 gap-8 sm:grid-cols-3 lg:grid-cols-5">
                {widgetMenus.map(renderWidgetMenuItem)}
              </div>
            </div>
          </div>
        </div>

        {/* Bottom Bar */}
        <div className="border-t border-gray-800 py-6">
          <div className="flex flex-col items-center justify-between gap-4 sm:flex-row">
            <p className="text-sm text-gray-400">
              © {currentYear} AIMootCourt. All rights reserved.
            </p>
            <div className="flex space-x-6">
              <a
                href="/privacyPolicy"
                className="text-sm text-gray-400 hover:text-yellow-400"
              >
                Privacy
              </a>
              <a
                href="/TermsAndConditions"
                className="text-sm text-gray-400 hover:text-yellow-400"
              >
                Terms
              </a>
              <a
                href="/contactus"
                className="text-sm text-gray-400 hover:text-yellow-400"
              >
                Contact
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
