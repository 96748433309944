import React, { useEffect, useRef, useState } from 'react';
import { motion } from 'framer-motion';

interface Particle {
  x: number;
  y: number;
  dx: number;
  dy: number;
  size: number;
}

interface ParticleBackgroundProps {
  className?: string;
  particleColor?: string;
  particleCount?: number;
  particleSpeed?: number;
  connectionRadius?: number;
  lineWidth?: number;
  interactive?: boolean;
}

const ParticleBackground: React.FC<ParticleBackgroundProps> = ({
  className = '',
  particleColor = '#8b5cf6', // Purple-500
  particleCount = 50,
  particleSpeed = 0.5,
  connectionRadius = 150,
  lineWidth = 0.8,
  interactive = true,
}) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const particlesRef = useRef<Particle[]>([]);
  const mouseParticleRef = useRef<Particle | null>(null);
  const animationFrameRef = useRef<number>();
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  // Create particles
  const createParticles = (width: number, height: number) => {
    return Array.from({ length: particleCount }, () => ({
      x: Math.random() * width,
      y: Math.random() * height,
      dx: (Math.random() - 0.5) * particleSpeed,
      dy: (Math.random() - 0.5) * particleSpeed,
      size: Math.random() * 1 + 1,
    }));
  };

  // Update particle positions
  const updateParticles = (width: number, height: number) => {
    particlesRef.current.forEach(particle => {
      // Bounce off walls
      if (particle.x < 0 || particle.x > width) particle.dx *= -1;
      if (particle.y < 0 || particle.y > height) particle.dy *= -1;

      // Update position
      particle.x += particle.dx;
      particle.y += particle.dy;
    });
  };

  // Draw particles and connections
  const draw = (ctx: CanvasRenderingContext2D, width: number, height: number) => {
    ctx.clearRect(0, 0, width, height);

    // Draw particles
    particlesRef.current.forEach(particle => {
      ctx.beginPath();
      ctx.arc(particle.x, particle.y, particle.size, 0, Math.PI * 2);
      ctx.fillStyle = particleColor;
      ctx.globalAlpha = 0.7;
      ctx.fill();
    });

    // Draw connections
    particlesRef.current.forEach((particle1, i) => {
      particlesRef.current.slice(i + 1).forEach(particle2 => {
        const dx = particle1.x - particle2.x;
        const dy = particle1.y - particle2.y;
        const distance = Math.sqrt(dx * dx + dy * dy);

        if (distance < connectionRadius) {
          ctx.beginPath();
          ctx.strokeStyle = particleColor;
          ctx.globalAlpha = (connectionRadius - distance) / connectionRadius * 0.5;
          ctx.lineWidth = lineWidth;
          ctx.moveTo(particle1.x, particle1.y);
          ctx.lineTo(particle2.x, particle2.y);
          ctx.stroke();
        }
      });

      // Connect to mouse particle if it exists
      if (mouseParticleRef.current) {
        const dx = particle1.x - mouseParticleRef.current.x;
        const dy = particle1.y - mouseParticleRef.current.y;
        const distance = Math.sqrt(dx * dx + dy * dy);

        if (distance < connectionRadius * 1.5) {
          ctx.beginPath();
          ctx.strokeStyle = particleColor;
          ctx.globalAlpha = (connectionRadius * 1.5 - distance) / (connectionRadius * 1.5) * 0.5;
          ctx.lineWidth = lineWidth;
          ctx.moveTo(particle1.x, particle1.y);
          ctx.lineTo(mouseParticleRef.current.x, mouseParticleRef.current.y);
          ctx.stroke();
        }
      }
    });
  };

  // Animation loop
  const animate = () => {
    if (!canvasRef.current) return;
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    if (!ctx) return;

    updateParticles(dimensions.width, dimensions.height);
    draw(ctx, dimensions.width, dimensions.height);
    animationFrameRef.current = requestAnimationFrame(animate);
  };

  // Handle mouse movement
  const handleMouseMove = (event: React.MouseEvent<HTMLCanvasElement>) => {
    if (!interactive || !canvasRef.current) return;

    const rect = canvasRef.current.getBoundingClientRect();
    const x = event.clientX - rect.left;
    const y = event.clientY - rect.top;

    if (!mouseParticleRef.current) {
      mouseParticleRef.current = {
        x,
        y,
        dx: 0,
        dy: 0,
        size: 2,
      };
    } else {
      mouseParticleRef.current.x = x;
      mouseParticleRef.current.y = y;
    }
  };

  // Handle mouse leave
  const handleMouseLeave = () => {
    mouseParticleRef.current = null;
  };

  // Handle resize
  useEffect(() => {
    const handleResize = () => {
      if (!canvasRef.current) return;
      const width = window.innerWidth;
      const height = window.innerHeight;
      
      canvasRef.current.width = width;
      canvasRef.current.height = height;
      setDimensions({ width, height });
      particlesRef.current = createParticles(width, height);
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // Start animation
  useEffect(() => {
    if (dimensions.width && dimensions.height) {
      particlesRef.current = createParticles(dimensions.width, dimensions.height);
      animate();
    }

    return () => {
      if (animationFrameRef.current) {
        cancelAnimationFrame(animationFrameRef.current);
      }
    };
  }, [dimensions]);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className={`relative inset-0 z-1 ${className}`}
    >
      <canvas
        ref={canvasRef}
        className="absolute inset-0"
        onMouseMove={handleMouseMove}
        onMouseLeave={handleMouseLeave}
      />
    </motion.div>
  );
};


export default ParticleBackground;