import React, { useCallback } from "react";
import {
  BookOpen,
  Award,
  Brain,
  Trophy,
  Users,
  Sparkles,
  ArrowRight,
  Play,
} from "lucide-react";
import ParticleBackground from "./ParticleCanvas";

const features = [
  {
    title: "Interactive Case Learning",
    description:
      "Master legal concepts through hands-on moot court simulations and real landmark cases",
    icon: <BookOpen className="w-8 h-8 text-purple-500" />,
    gradient: "from-purple-500/10 to-purple-500/30",
    ariaLabel: "Interactive learning feature",
  },
  {
    title: "Professional Development",
    description:
      "Enhance your CV with practical moot court experience and AI-powered advocacy training",
    icon: <Award className="w-8 h-8 text-blue-500" />,
    gradient: "from-blue-500/10 to-blue-500/30",
    ariaLabel: "Professional development feature",
  },
  {
    title: "Flexible Practice Platform",
    description:
      "24/7 access to AI-powered moot court practice - perfect your legal skills anytime, anywhere",
    icon: <Brain className="w-8 h-8 text-green-500" />,
    gradient: "from-green-500/10 to-green-500/30",
    ariaLabel: "Flexible practice feature",
  },
];

const stats = [
  {
    icon: <Trophy className="w-12 h-12 text-yellow-500" />,
    value: "220+",
    label: "Landmark Cases",
    description: "Practice with real Indian court cases",
  },
  {
    icon: <Users className="w-12 h-12 text-blue-500" />,
    value: "300+",
    label: "Active Students",
    description: "Join India's largest moot court community",
  },
  {
    icon: <Sparkles className="w-12 h-12 text-purple-500" />,
    value: "4.63/5",
    label: "Student Rating",
    description: "Highly rated by law students",
  },
];

const StudentLandingPage = () => {
  const scrollToSection = useCallback((id: string) => {
    document.getElementById(id)?.scrollIntoView({ behavior: "smooth" });
  }, []);

  return (
    <main className="min-h-screen" role="main">
      {/* Hero Section */}
      <section
        className="relative overflow-hidden"
        aria-label="Hero section"
      >
        <div className="absolute inset-0 bg-gradient-to-r from-purple-100 to-blue-100 opacity-50" />

        <div className="relative max-w-7xl mx-auto px-4 py-10 sm:px-6 sm:py-14 lg:px-8 ">
          <ParticleBackground
            particleColor="#8b5cf6"
            particleCount={50}
            particleSpeed={0.2}
            connectionRadius={150}
            lineWidth={0.8}
            interactive={true}
          />

          <header className="text-center space-y-6">
            <h1 className="text-5xl md:text-7xl font-bold bg-gradient-to-r from-purple-600 to-blue-600 bg-clip-text text-transparent animate-fade-in p-4">
              Master Moot Court With AI 🚀
              <span className="sr-only">
                AI-Powered Moot Court Platform for Law Students
              </span>
            </h1>

            <p className="p-4 text-xl font-semibold text-black max-w-4xl mx-auto">
              Step into the shoes of India's greatest lawyers and practice with
              landmark cases! Your AI-powered legal playground for mastering{" "}
              <span className="text-purple-600">moot court advocacy</span>. Get
              instant feedback, track your progress, and learn from the legends.
            </p>

            <div className="p-6 flex flex-wrap justify-center gap-4">
              <a
                href="/mootcourt"
                className="px-8 py-3 text-lg font-medium rounded-full bg-purple-600 text-white hover:bg-purple-700 transform hover:scale-105 transition-all shadow-lg hover:shadow-purple-500/25 flex items-center"
                aria-label="Start practicing moot court now"
              >
                Start Practicing Now
                <ArrowRight className="ml-2 w-5 h-5" />
              </a>
              <button
                className="px-8 py-3 text-lg font-medium rounded-full border-2 border-purple-600 text-purple-600 hover:bg-purple-50 transform hover:scale-105 transition-all flex items-center"
                onClick={() => scrollToSection("demo")}
                aria-label="Watch demonstration video"
              >
                Watch Demo
                <Play className="ml-2 w-5 h-5" />
              </button>
            </div>
          </header>
        </div>

        {/* Stats Section */}
        <section
          className="max-w-7xl mx-auto px-4 py-12 mt-4"
          aria-label="Platform statistics"
        >
          <div className="bg-white rounded-2xl shadow-xl p-8 transform -mt-20 relative z-10 border border-purple-100">
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
              {stats.map((stat, index) => (
                <div
                  key={index}
                  className="flex items-center justify-center space-x-4"
                >
                  {stat.icon}
                  <div>
                    <p className="text-2xl font-bold text-gray-900">
                      {stat.value}
                    </p>
                    <p className="text-gray-600">{stat.label}</p>
                    <p className="text-sm text-gray-500">{stat.description}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
      </section>

      {/* Features Section */}
      <section
        className="max-w-7xl mx-auto p-4 py-16"
        aria-label="Platform features"
      >
        <h2 className="text-3xl font-bold text-center mb-12">
          Why Law Students Choose
          <span className="text-purple-600"> Our Platform</span>
        </h2>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {features.map((feature, index) => (
            <div
              key={index}
              className={`p-6 rounded-2xl bg-gradient-to-br ${feature.gradient} backdrop-blur-sm hover:scale-105 transition-transform duration-300`}
              aria-label={feature.ariaLabel}
            >
              <div
                className="bg-white rounded-xl p-4 w-fit mb-4"
                aria-hidden="true"
              >
                {feature.icon}
              </div>
              <h3 className="text-xl font-semibold mb-2">{feature.title}</h3>
              <p className="text-gray-700">{feature.description}</p>
            </div>
          ))}
        </div>
      </section>

      {/* CTA Section */}
      <section
        className="max-w-7xl mx-auto p-4 text-center mb-16"
        aria-label="Call to action"
      >
        <div className="bg-gradient-to-r from-purple-600 to-blue-600 rounded-2xl p-12 text-white">
          <h2 className="text-3xl font-bold mb-4">
            Ready to Excel in Legal Advocacy?
          </h2>

          <p className="text-xl md:text-2xl font-semibold p-4">
            Join 300+ Law Students and Get Your First 10 Moot Court Cases Free!
            🎓
          </p>

          <div className="flex flex-col md:flex-row justify-center items-center gap-6 p-4">
            <div className="flex -space-x-4">
              {[2, 3, 4].map((num) => (
                <img
                  key={num}
                  className="w-12 h-12 rounded-full border-4 border-white"
                  src={`/avatar${num}.png`}
                  alt={`Law student ${num}`}
                  loading="lazy"
                />
              ))}
              <div className="w-12 h-12 rounded-full border-4 border-white bg-purple-600 flex items-center justify-center text-white font-medium">
                +300
              </div>
            </div>

            <a
              href="/signup"
              className="px-8 py-3 text-lg font-medium rounded-full bg-white text-purple-600 hover:bg-gray-100 transform hover:scale-105 transition-all flex items-center justify-center gap-2"
              aria-label="Sign up for free moot court practice"
            >
              Start Your Journey Today
              <ArrowRight className="w-5 h-5" />
            </a>
          </div>
        </div>
      </section>
    </main>
  );
};

export default StudentLandingPage;
