import React from "react";

interface SurpriseMeProps {
  onSurprise: () => void;
}

const SurpriseMe: React.FC<SurpriseMeProps> = ({ onSurprise }) => {
  return (
    <div
      className="relative shadow-xl flex items-center justify-center bg-white dark:bg-neutral-900 border border-neutral-100 dark:border-neutral-800 rounded-2xl overflow-hidden will-change-transform hover:shadow-xl transition hover:-translate-y-1 transition-shadow duration-300 bg-[url('/public/Cloud.png')] bg-cover bg-center cursor-pointer"
      onClick={onSurprise}
    >
      <button
        onClick={onSurprise}
        className="bg-darkBlue text-white font-bold text-xl py-4 px-8 rounded-lg shadow-lg hover:bg-blue-800 transition duration-300"
      >
        Random Pick!
      </button>
    </div>
  );
};

export default SurpriseMe;
