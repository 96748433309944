import React, { useState, useEffect } from 'react';
import { Popover, Transition } from '@headlessui/react';
import axios from 'axios';
import { apiUri } from '../../constants';
import Alert from 'shared/Alert/Alert';

interface CreateLobbyPopoverProps {
    name: string;
    onClose: () => void;
    setOpenTeamSelect: React.Dispatch<React.SetStateAction<boolean>>;
    setEnteredLobby: React.Dispatch<React.SetStateAction<string>>;
}

const JoinLobbyPopover: React.FC<CreateLobbyPopoverProps> = ({ name="", onClose, setOpenTeamSelect, setEnteredLobby }) => {
    const [lobbyName, setLobbyName] = useState(name);
    setEnteredLobby(name)
    const [password, setPassword] = useState('');
    const [isLobbyNameUnique, setIsLobbyNameUnique] = useState(true);
    const [error, setError] = useState('');
    const [isErr, setIsErr] = useState(false)


      useEffect(() => {
    if (isErr) {
      const timer = setTimeout(() => setIsErr(false), 2000);
      return () => clearTimeout(timer); 
    }
  }, [isErr]);

    const checkLobbyNameUnique = async () => {
        try {
            // const response = await axios.post(`${apiUri}/checkMootCourtName`, { hostedCourtName: lobbyName });
            // setIsLobbyNameUnique(response.data.isUnique);
            setIsLobbyNameUnique(true);
        } catch (err) {
            console.error('Error checking lobby name uniqueness:', err);
        }
    };

    const handleCreateLobby = async () => {
        if (!lobbyName) {
            setError('Lobby Name is required');
            return;
        }

        if (!isLobbyNameUnique) {
            setError('Lobby Name is already taken');
            return;
        }


        try {


            const response = await axios.post(`${apiUri}/hostMootCourt`, { hostedCourtName: lobbyName, password: password });
            // await axios.post(`${apiUri}/joinCourtInTeam`, { hostedCourtName: lobbyName, teamName:  password: password });
            if (response.status === 200) {
                setOpenTeamSelect(true);
                onClose();

            }
            else {
                //console.log("Error on creating lobby");
                //console.log(response.data.message);
            }

        } catch (err) {
            setError('Error creating lobby');
            console.error(err);
        }
    };

    const handleJoinLobby = async () => {
          if (!sessionStorage.getItem("email")) {
              window.location.href = "/login"
              return
          }
        
        if (!lobbyName) {
            setError('Lobby Name is required');
            setIsErr(true)
            return;
        }

        if (!isLobbyNameUnique) {
            setError('Lobby Name is already taken');
            setIsErr(true)
            return;
        }
        // //console.log("BEFORE SENDING TO JOIN ")
        // //console.log(lobbyName, password);
        try {
            const response = await axios.post(`${apiUri}/joinMootCourt`, { hostedCourtName: lobbyName, password: password });
            if (response.status === 200) {
                setOpenTeamSelect(true);
                onClose();
            }
            else {
                //console.log("Error on joining lobby");
                //console.log(response.data.message);
            }
        }
        catch (err) {
            setError('Error joining lobby. Please check id and password.');
            setIsErr(true)
            console.error(err);
        }


    };
    useEffect(() => {
        if (lobbyName) {
            checkLobbyNameUnique();
        }
    }, [lobbyName]);

    return (
        <Popover>
            <Transition
                show={true}
                enter="transition duration-100 ease-out"
                enterFrom="transform scale-95 opacity-0"
                enterTo="transform scale-100 opacity-100"
                leave="transition duration-75 ease-out"
                leaveFrom="transform scale-100 opacity-100"
                leaveTo="transform scale-95 opacity-0"
            >
                <Popover.Panel static className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
                    <div className="bg-white dark:bg-gray-800 rounded-lg p-6 shadow-lg max-w-md w-full">
                        <h2 className="text-xl font-bold mb-4 text-neutral-900 dark:text-neutral-100">Join Lobby</h2>

                        <div className="mb-4">
                            <label className="block text-xl font-medium text-neutral-900 dark:text-neutral-100">
                                Lobby Name
                            </label>
                            
                            <input
                                type="text"
                                className={`mt-1 p-2 w-full border rounded-md focus:ring ${isLobbyNameUnique ? 'border-gray-300' : 'border-red-500'
                                    }`}
                                value={lobbyName}
                                onChange={(e) => {
                                    setLobbyName(e.target.value);
                                    setEnteredLobby(e.target.value);
                                }}
                            />
                            {!isLobbyNameUnique && (
                                <p className="text-red-500 text-sm mt-1">Lobby Name is already taken</p>
                            )}
                        </div>

                        <div className="mb-4">
                            <label className="block text-xl font-medium text-neutral-900 dark:text-neutral-100">
                                Password
                            </label>
                            <input
                                type="password"
                                className="mt-1 p-2 w-full border rounded-md focus:ring border-gray-300"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                        </div>

                        {isErr && (
                            <Alert children={error} type='error' />
                        )}

                        <div className="flex justify-end">
                            <button
                                onClick={
                                    () => {
                                        handleJoinLobby()
                                    }}
                                className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-700 mr-2"
                            >
                                Join Lobby
                            </button>
                            <button
                                onClick={onClose}
                                className="bg-gray-300 text-gray-700 px-4 py-2 rounded-md hover:bg-gray-400"
                            >
                                Cancel
                            </button>
                        </div>
                    </div>
                </Popover.Panel>
            </Transition>
        </Popover >
    );
};

export default JoinLobbyPopover;
