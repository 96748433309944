import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import axios from 'axios';
import { apiUri, modelUri } from '../../constants';
import ButtonPrimary from 'shared/Button/ButtonPrimary';
import LoadingIcon from 'components/Loading/LoadingIcon';
import * as pdfjsLib from 'pdfjs-dist';



interface FinalMootAnalysisProps {
  givenProsConvo: string;
  AIProsConvo: string;
  idealComparisonPros: string;
  scorePros: string;
  VocabScorePros: string;
  SenScorePros: string;
  ThemeScorePros: string;
  OverallCohScorePros: string;
  givenDefConvo: string;
  AIDefConvo: string;
  idealComparisonDef: string;
  scoreDef: string;
  VocabScoreDef: string;
  SenScoreDef: string;
  ThemeScoreDef: string;
  OverallCohScoreDef: string;
}

const FinalMootAnalysis: React.FC<FinalMootAnalysisProps> = ({
  givenProsConvo,
  AIProsConvo,
  idealComparisonPros,
  scorePros,
  VocabScorePros,
  SenScorePros,
  ThemeScorePros,
  OverallCohScorePros,
  givenDefConvo,
  AIDefConvo,
  idealComparisonDef,
  scoreDef,
  VocabScoreDef,
  SenScoreDef,
  ThemeScoreDef,
  OverallCohScoreDef,
}) => {
  const getScoreColor = (score: string) => {
    if (parseInt(score) >= 80) return 'text-green-600';
    if (parseInt(score) >= 50) return 'text-yellow-600';
    return 'text-red-600';
  };

  const handleExit = () => {
    window.location.href = '/feedback';
  };

  const containerVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0 },
  };

  const itemVariants = {
    hidden: { opacity: 0, x: -50 },
    visible: { opacity: 1, x: 0 },
  };

  return (
    <div className='text-center container justify-center'>
      <motion.div
        className="max-w-6xl mx-auto p-6 bg-white shadow-lg rounded-lg border border-gray-200"
        initial="hidden"
        animate="visible"
        variants={containerVariants}
        transition={{ duration: 0.5, ease: 'easeOut' }}
      >
        {/* Prosecution Analysis */}
        <div className="mb-4">
          <h3 className="text-xl font-semibold text-gray-800">Prosecution Analysis</h3>
          <div className="flex flex-col md:flex-row justify-between gap-4">
            <motion.div
              className="mt-2 text-gray-600 w-full md:w-1/2"
              variants={itemVariants}
              transition={{ delay: 0.2 }}
            >
              <h4 className="text-lg font-semibold text-gray-800">Your Argument</h4>
              <p>{givenProsConvo}</p>
            </motion.div>
            <motion.div
              className="mt-2 text-gray-600 w-full md:w-1/2"
              variants={itemVariants}
              transition={{ delay: 0.3 }}
            >
              <h4 className="text-lg font-semibold text-gray-800">Ideal Prosecution Argument</h4>
              <p>{AIProsConvo}</p>
            </motion.div>
          </div>

          <motion.div
            className="mt-2 text-gray-600 w-full"
            variants={itemVariants}
            transition={{ delay: 0.4 }}
          >
            <p>{idealComparisonPros}</p>
          </motion.div>
        </div>

        <div className="mb-4">
          <h3 className="text-xl font-semibold text-gray-800">Prosecution Score</h3>
          <motion.div
            className={`mt-2 text-4xl font-bold ${getScoreColor(scorePros)}`}
            variants={itemVariants}
            transition={{ delay: 0.4 }}
          >
            {scorePros}
          </motion.div>
        </div>

        <div className="mb-4">
          <h3 className="text-xl font-semibold text-gray-800">Prosecution Score Breakdown</h3>
          <motion.ul
            className="mt-2 list-disc list-inside text-gray-600"
            variants={itemVariants}
            initial="hidden"
            animate="visible"
            transition={{ delay: 0.5 }}
          >
            <li>Vocabulary: {VocabScorePros}</li>
            <li>Sentence Structuring: {SenScorePros}</li>
            <li>Theme: {ThemeScorePros}</li>
            <li>Coherence: {OverallCohScorePros}</li>
          </motion.ul>
        </div>

        {/* Defense Analysis */}
        <div className="mb-4">
          <h3 className="text-xl font-semibold text-gray-800">Defense Analysis</h3>
          <div className="flex flex-col md:flex-row justify-between gap-4">
            <motion.div
              className="mt-2 text-gray-600 w-full md:w-1/2"
              variants={itemVariants}
              transition={{ delay: 0.2 }}
            >
              <h4 className="text-lg font-semibold text-gray-800">Your Argument</h4>
              <p>{givenDefConvo}</p>
            </motion.div>
            <motion.div
              className="mt-2 text-gray-600 w-full md:w-1/2"
              variants={itemVariants}
              transition={{ delay: 0.3 }}
            >
              <h4 className="text-lg font-semibold text-gray-800">Ideal Defence Argument</h4>
              <p>{AIDefConvo}</p>
            </motion.div>
          </div>

          <motion.div
            className="mt-2 text-gray-600 w-full"
            variants={itemVariants}
            transition={{ delay: 0.4 }}
          >
            <p>{idealComparisonDef}</p>
          </motion.div>
        </div>

        <div className="mb-4">
          <h3 className="text-xl font-semibold text-gray-800">Defense Score</h3>
          <motion.div
            className={`mt-2 text-4xl font-bold ${getScoreColor(scoreDef)}`}
            variants={itemVariants}
            transition={{ delay: 0.4 }}
          >
            {scoreDef}
          </motion.div>
        </div>

        <div>
          <h3 className="text-xl font-semibold text-gray-800">Defense Score Breakdown</h3>
          <motion.ul
            className="mt-2 list-disc list-inside text-gray-600"
            variants={itemVariants}
            initial="hidden"
            animate="visible"
            transition={{ delay: 0.5 }}
          >
            <li>Vocabulary: {VocabScoreDef}</li>
            <li>Sentence Structuring: {SenScoreDef}</li>
            <li>Theme: {ThemeScoreDef}</li>
            <li>Coherence: {OverallCohScoreDef}</li>
          </motion.ul>
        </div>
      </motion.div>
      <div className='p-6'>
        <ButtonPrimary className="text-center p-6 justify-center bg-red-400 hover:bg-red-600" onClick={handleExit}>Exit</ButtonPrimary>
      </div>
    </div>
  );
};


async function extractTextFromPdfBlob(pdfBlob: Blob): Promise<string> {
    const url = URL.createObjectURL(pdfBlob);
    const pdf = await pdfjsLib.getDocument(url).promise;
    let text = '';

    for (let i = 1; i <= pdf.numPages; i++) {
        const page = await pdf.getPage(i);
        const textContent = await page.getTextContent();
        text += textContent.items.map((item: any) => item.str).join(' ') + '\n';
    }

    URL.revokeObjectURL(url);
    return text;
}

function GetFinalMootAnalysis() {
  const userConversation = sessionStorage.getItem("fullUserConversation");
    const [proConvo, setProConvo] = useState("")
    const [defConvo, setDefConvo] = useState("")
  const [details, setDetails] = useState<FinalMootAnalysisProps | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
   const [caseDetails, setcaseDetails] = useState<string>("")
    
    // if (sessionStorage.getItem("teamName") === "Defendant") {
    //     setDefConvo(sessionStorage.getItem("fullUserConversation") || "")
    //     setProConvo(sessionStorage.getItem("fullFriendConversation") || "")
    // } else {
    //     setProConvo(sessionStorage.getItem("fullUserConversation") || "")
    //     setDefConvo(sessionStorage.getItem("fullFriendConversation") || "")
    // }

   useEffect(() => {
    const fetchIdealConversation = async () => {
      // const caseId = sessionStorage.getItem('caseId');
      // if (!caseId) return;

      try {
        const response = await axios.post(`${apiUri}/fetchCustomMootCase`, { mootCourtId: '66c5e0c9c28ea3d595028d30' });

        console.log(response.data)
        if (response.data) {
         
          const pdfBlob = (response.data)
        
          extractTextFromPdfBlob(pdfBlob).then(text => {
          console.log('Blob as text:', text); // Should output: 'Hello, world!'
      }).catch(error => {
          console.error('Error:', error);
      });
        
        }
      } catch (error) {
        //console.log(error);
      }
    };

    fetchIdealConversation();
   }, []);
    
//   useEffect(() => {
// //  defenceConvo = data.get("defenceConvo")
// //     prosecutionConvo = data.get("prosecutionConvo")
// //     caseRef = data.get('caseRef')
//     const fetchDetails = async () => {
//       try {
//         const response = await axios.post(`${modelUri}/getCustomCaseDecision`, {
//             defenceConvo: defConvo,
//             prosecutionConvo: proConvo,
//             caseRef: caseDetails
//         });
//         setDetails(response.data);
//       } catch (error) {
//         console.error('Error fetching case details:', error);
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchDetails();
  //   }, [userConversation]);

  return (
    <div>
      jo
    </div>
  )
  // if (loading) {
  //   return <LoadingIcon />;
  // } else if (details) {
  //   return (
  //     // <FinalMootAnalysis {...details} />
  //     <div>
  //       hi
  //     </div>
  //   );
  // }
}

export default GetFinalMootAnalysis;
