import React, { useState } from "react";
import SubscriptionSection from "containers/PageHome/SubcriptionSection";



const PageSubscription: React.FC<{ className?: string }> = ({
  className = "",
}) => {
  return (
 <SubscriptionSection />
  );
};

export default PageSubscription;
