import React from 'react';
import { Popover, Transition } from '@headlessui/react';

interface AnswerDataObject {
  clue: string;
  answer: string;
}

interface GiveUpPopoverProps {
  onClose: () => void;
  AnswerDataArray: AnswerDataObject[];
}

const GiveUpPopover: React.FC<GiveUpPopoverProps> = ({ onClose, AnswerDataArray }) => {
  return (
    <Popover>
      <Transition
        show={true}
        enter="transition ease-out duration-300"
        enterFrom="transform scale-95 opacity-0"
        enterTo="transform scale-100 opacity-100"
        leave="transition ease-in duration-200"
        leaveFrom="transform scale-100 opacity-100"
        leaveTo="transform scale-95 opacity-0"
      >
        <Popover.Panel static className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50 p-4">
          <div className="bg-white dark:bg-gray-800 rounded-lg p-6 shadow-lg max-w-md w-full sm:max-w-lg">
            <h2 className="text-2xl font-bold mb-4 text-neutral-900 dark:text-neutral-100 text-center">Revealed Answers</h2>

            <div className="space-y-4 overflow-y-auto max-h-72 sm:max-h-96">
              {AnswerDataArray.length > 0 ? (
                AnswerDataArray.map((data, index) => (
                  <div key={index} className="p-2 border-b border-gray-300">
                    <p className="text-gray-700 dark:text-gray-300"><strong>Clue:</strong> {data.clue}</p>
                    <p className="text-blue-600 dark:text-blue-400"><strong>Answer:</strong> {data.answer}</p>
                  </div>
                ))
              ) : (
                <p className="text-red-500">No answers available.</p>
              )}

            </div>

            <div className="flex justify-end mt-6">
              <button
                type="button"
                onClick={onClose}
                className="bg-gray-500 text-white px-4 py-2 rounded-md hover:bg-gray-700 transition-all duration-300"
              >
                Close
              </button>
            </div>
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  );
};

export default GiveUpPopover;
