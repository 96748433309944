import React, { useEffect, useState } from "react";
import {
  BookOpen,
  AlertCircle,
  CheckCircle,
  XCircle,
  AlertTriangle,
  X,
  Sparkles,
} from "lucide-react";

export interface AlertProps {
  containerClassName?: string;
  type?: "default" | "warning" | "info" | "success" | "error";
  children: React.ReactNode;
  duration?: number;
  showProgress?: boolean;
  persistent?: boolean;
  position?: "top" | "top-right";
}

export const Alert: React.FC<AlertProps> = ({
  children = "Alert Text",
  containerClassName = "",
  type = "default",
  duration = 7000,
  showProgress = true,
  persistent = false,
  position = "top-right",
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const [progress, setProgress] = useState(100);

  // Map alert types to styles, icons, and emojis
  const alertStyles = {
    default: {
      container:
        "border-2 border-indigo-400 bg-gradient-to-r from-indigo-50 to-purple-50 text-indigo-800 dark:from-indigo-900 dark:to-purple-900 dark:text-indigo-200",
      icon: <Sparkles className="w-6 h-6" />,
      emoji: "✨",
      button: "hover:bg-indigo-100 dark:hover:bg-indigo-800",
      progress: "bg-indigo-400 dark:bg-indigo-500",
    },
    info: {
      container:
        "border-2 border-blue-400 bg-gradient-to-r from-blue-50 to-cyan-50 text-blue-800 dark:from-blue-900 dark:to-cyan-900 dark:text-blue-200",
      icon: <AlertCircle className="w-6 h-6" />,
      emoji: "💡",
      button: "hover:bg-blue-100 dark:hover:bg-blue-800",
      progress: "bg-blue-400 dark:bg-blue-500",
    },
    success: {
      container:
        "border-2 border-green-400 bg-gradient-to-r from-green-50 to-emerald-50 text-green-800 dark:from-green-900 dark:to-emerald-900 dark:text-green-200",
      icon: <CheckCircle className="w-6 h-6" />,
      emoji: "🎉",
      button: "hover:bg-green-100 dark:hover:bg-green-800",
      progress: "bg-green-400 dark:bg-green-500",
    },
    error: {
      container:
        "border-2 border-red-400 bg-gradient-to-r from-red-50 to-rose-50 text-red-800 dark:from-red-900 dark:to-rose-900 dark:text-red-200",
      icon: <XCircle className="w-6 h-6" />,
      emoji: "⚠️",
      button: "hover:bg-red-100 dark:hover:bg-red-800",
      progress: "bg-red-400 dark:bg-red-500",
    },
    warning: {
      container:
        "border-2 border-yellow-400 bg-gradient-to-r from-yellow-50 to-amber-50 text-yellow-800 dark:from-yellow-900 dark:to-amber-900 dark:text-yellow-200",
      icon: <AlertTriangle className="w-6 h-6" />,
      emoji: "🚨",
      button: "hover:bg-yellow-100 dark:hover:bg-yellow-800",
      progress: "bg-yellow-400 dark:bg-yellow-500",
    },
  };

  const {
    container,
    icon,
    emoji,
    button,
    progress: progressColor,
  } = alertStyles[type];

  const positionStyles = {
    top: "fixed top-16 left-1/2 -translate-x-1/2",
    "top-right": "fixed top-16 right-4",
  };

  useEffect(() => {
    // Entrance animation delay
    setTimeout(() => {
      setIsVisible(true);
    }, 100);

    if (!persistent) {
      const progressInterval = setInterval(() => {
        setProgress((prev) => {
          if (prev <= 0) return 0;
          return prev - 100 / (duration / 100);
        });
      }, 100);

      const timer = setTimeout(() => {
        setIsVisible(false);
      }, duration);

      return () => {
        clearTimeout(timer);
        clearInterval(progressInterval);
      };
    }
  }, [duration, persistent]);

  if (!isVisible) return null;

  return (
    <div
      className={`
        ${positionStyles[position]}
        z-50 max-w-md w-full
        animate-slide-in
      `}
    >
      <div
        className={`
          flex items-center p-6 rounded-xl shadow-lg
          ${container} ${containerClassName}
          transform transition-all duration-300
          hover:scale-[1.02] hover:shadow-xl
          animate-float
        `}
        role="alert"
      >
        <div className="flex-shrink-0 flex items-center space-x-2">
          <span className="animate-bounce-gentle">{icon}</span>
          <span className="text-xl animate-pulse">{emoji}</span>
        </div>
        <div className="ms-4 text-base font-medium">{children}</div>
        {!persistent && (
          <button
            type="button"
            onClick={() => setIsVisible(false)}
            className={`
              ms-auto -mx-1.5 -my-1.5 rounded-lg p-1.5
              inline-flex items-center justify-center h-8 w-8
              transition-all duration-300
              ${button}
              hover:rotate-90 hover:scale-110
            `}
            aria-label="Dismiss"
          >
            <span className="sr-only">Dismiss</span>
            <X className="w-5 h-5" />
          </button>
        )}
      </div>
      {showProgress && !persistent && (
        <div className="absolute bottom-0 left-0 w-full h-1.5 bg-gray-200 dark:bg-gray-700 rounded-b-xl overflow-hidden">
          <div
            className={`h-full transition-all duration-100 ${progressColor}`}
            style={{ width: `${progress}%` }}
          />
        </div>
      )}

      {/* Add animated decorative elements */}
      <div className="absolute -top-2 -right-2 animate-spin-slow">
        <span className="text-2xl">{emoji}</span>
      </div>
      <div className="absolute -bottom-2 -left-2 animate-bounce-gentle">
        <span className="text-2xl">{emoji}</span>
      </div>
    </div>
  );
};

export default Alert;
