import React, { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { X, Maximize2, Minimize2 } from "lucide-react";
import { gsap } from "gsap";

interface Step {
  title: string;
  description: string;
  color: string;
}

interface IdealWayToGoProps {
  onClose: () => void;
}

const steps: Step[] = [
  {
    title: "📜 Review Case Facts",
    description:
      "Carefully examine the facts of the case to build a strong foundation. Understanding the full scope of details is crucial for a well-rounded legal strategy.",
    color: "bg-blue-100 dark:bg-blue-900",
  },
  {
    title: "📚 Consult Case Laws",
    description:
      "Explore applicable case laws and legal sections. Analyze how precedents can support your argument and understand key legal principles in play.",
    color: "bg-blue-200 dark:bg-blue-800",
  },
  {
    title: "📝 Formulate Your Arguments",
    description:
      "Develop compelling arguments for your side. Use your insights from case facts and legal analysis to construct a persuasive strategy, available 24/7 to accommodate your schedule.",
    color: "bg-blue-100 dark:bg-blue-900",
  },
  {
    title: "🔍 Use Precise Language",
    description:
      "Articulate your arguments with precision. Focus on logical reasoning and back your points with relevant cases to strengthen your position.",
    color: "bg-blue-200 dark:bg-blue-800",
  },
];


const IdealWayToGoPopover: React.FC<IdealWayToGoProps> = ({ onClose }) => {
  const [isFullScreen, setIsFullScreen] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState(true); // Assuming the modal is always open for this example

  // GSAP animation for card sliding
  // React.useEffect(() => {
  //   gsap.from(".step-card", {
  //     opacity: 0,
  //     y: 50,
  //     duration: 0.6,
  //     stagger: 0.3,
  //     ease: "power2.out",
  //   });
  // }, []);

  const handleDontShowAgain = () => {
       setIsOpen(false);
       sessionStorage.setItem("dontshowidealway", "true");
  }

  return (
    <Transition show={isOpen} as={Fragment}>
      <Dialog onClose={onClose} className="relative z-50">
        {/* Backdrop */}
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black/50 backdrop-blur-sm" />
        </Transition.Child>

        {/* Modal */}
        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel
                className={`w-full transform overflow-hidden rounded-xl bg-creamyYellow shadow-xl transition-all ${
                  isFullScreen ? "fixed inset-0 rounded-none" : "max-w-4xl"
                }`}
              >
                {/* Header */}
                <div className="flex items-center justify-between p-4 border-b">
                  <Dialog.Title className="text-xl font-semibold text-gray-900 dark:text-gray-100">
                    Ideal Way to Proceed
                  </Dialog.Title>
                  <div className="flex gap-2">
                    <button
                      onClick={handleDontShowAgain}
                      className=" bg-red-500 text-white py-2 px-4 rounded hover:bg-red-600 transition-colors"
                    >
                      Don't Show Me Again
                    </button>

                    <button
                      onClick={() => setIsFullScreen(!isFullScreen)}
                      className="p-2 hover:bg-gray-100 dark:hover:bg-gray-800 rounded-lg transition-colors"
                      aria-label={
                        isFullScreen ? "Exit full screen" : "Enter full screen"
                      }
                    >
                      {isFullScreen ? (
                        <Minimize2 className="h-5 w-5" />
                      ) : (
                        <Maximize2 className="h-5 w-5" />
                      )}
                    </button>
                    <button
                      onClick={onClose}
                      className="p-2 hover:bg-gray-100 dark:hover:bg-gray-800 rounded-lg transition-colors"
                      aria-label="Close dialog"
                    >
                      <X className="h-5 w-5" />
                    </button>
                  </div>
                </div>

                {/* Content */}
                <div
                  className={`overflow-auto ${
                    isFullScreen
                      ? "h-[calc(100vh-5rem)]"
                      : "max-h-[calc(90vh-5rem)]"
                  }`}
                >
                  <div className="p-6">
                    <div className="text-center mb-8">
                      <Dialog.Title className="text-3xl font-bold text-gray-900 dark:text-gray-100">
                        Prepare Your Legal Case
                      </Dialog.Title>
                      <Dialog.Description className="mt-2 text-gray-600 dark:text-gray-400">
                        Follow these steps to build and practice your legal
                        strategy
                      </Dialog.Description>
                    </div>

                    <div className="grid gap-6 md:grid-cols-2">
                      {steps.map((step, index) => (
                        <div
                          key={index}
                          className={`step-card ${step.color} rounded-xl p-6 shadow-lg transition-all duration-300 hover:shadow-xl `}
                        >
                          <div className="mb-4">
                            <h3 className="text-xl font-semibold flex items-center gap-2 text-gray-900 dark:text-gray-100">
                              <span className="flex items-center justify-center w-8 h-8 rounded-full bg-blue-600 text-white text-sm font-medium">
                                {index + 1}
                              </span>
                              {step.title}
                            </h3>
                          </div>
                          <p className="text-gray-700 dark:text-gray-300">
                            {step.description}
                          </p>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default IdealWayToGoPopover;
