import React from "react";
import { motion, AnimatePresence } from "framer-motion";
import { AlertCircle, ArrowRight, DoorOpen } from "lucide-react";
import { useNavigate } from "react-router-dom";

interface ExitConfirmationProps {
  onClose: () => void;
}

const ExitConfirmation: React.FC<ExitConfirmationProps> = ({ onClose }) => {
  const navigate = useNavigate();

  const overlayVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 },
  };

  const modalVariants = {
    hidden: {
      opacity: 0,
      scale: 0.9,
      y: 20,
    },
    visible: {
      opacity: 1,
      scale: 1,
      y: 0,
      transition: {
        type: "spring",
        damping: 20,
        stiffness: 300,
      },
    },
    exit: {
      opacity: 0,
      scale: 0.9,
      y: 20,
      transition: {
        duration: 0.2,
      },
    },
  };

  const handleExit = () => {
    navigate("/mootCourt");
  };

  return (
    <AnimatePresence>
      <motion.div
        className="fixed inset-0 bg-black/40 flex items-center justify-center z-50 px-4"
        initial="hidden"
        animate="visible"
        exit="hidden"
        variants={overlayVariants}
      >
        <motion.div
          className="bg-white dark:bg-gray-800 rounded-xl shadow-xl w-full max-w-md p-6"
          variants={modalVariants}
          initial="hidden"
          animate="visible"
          exit="exit"
        >
          {/* Header */}
          <div className="flex items-center space-x-2 text-amber-500 dark:text-amber-400">
            <AlertCircle className="w-6 h-6" />
            <h3 className="text-lg font-semibold">Hold on!</h3>
          </div>

          {/* Content */}
          <div className="mt-4 space-y-3">
            <p className="text-gray-700 dark:text-gray-300">
              Are you sure you want to exit your court session?
            </p>
            <div className="bg-amber-50 dark:bg-amber-900/20 rounded-lg p-3 text-sm text-amber-800 dark:text-amber-200">
              <p>If you leave now:</p>
              <ul className="mt-2 space-y-1 list-disc list-inside">
                <li>You won't see your performance analysis</li>
                <li>You won't get feedback on your arguments</li>
                <li>This session won't count towards your progress</li>
              </ul>
            </div>
          </div>

          {/* Buttons */}
          <div className="mt-6 flex gap-3">
            <motion.button
              className="flex-1 flex items-center justify-center gap-2 px-4 py-2.5 text-sm font-medium text-gray-700 dark:text-gray-200 bg-gray-100 dark:bg-gray-700 rounded-lg hover:bg-gray-200 dark:hover:bg-gray-600 transition-colors"
              onClick={onClose}
              whileHover={{ scale: 1.02 }}
              whileTap={{ scale: 0.98 }}
            >
              Continue Practice
              <ArrowRight className="w-4 h-4" />
            </motion.button>
            <motion.button
              className="flex-1 flex items-center justify-center gap-2 px-4 py-2.5 text-sm font-medium text-red-600 dark:text-red-400 bg-red-50 dark:bg-red-900/20 rounded-lg hover:bg-red-100 dark:hover:bg-red-900/40 transition-colors"
              onClick={handleExit}
              whileHover={{ scale: 1.02 }}
              whileTap={{ scale: 0.98 }}
            >
              Exit Session
              <DoorOpen className="w-4 h-4" />
            </motion.button>
          </div>
        </motion.div>
      </motion.div>
      )
    </AnimatePresence>
  );
};

export default ExitConfirmation;
