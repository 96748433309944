import React from "react";
import { motion, AnimatePresence } from "framer-motion";
import { MessageCircle } from "lucide-react";


interface pixelMsgProp {
    message: string,
    isOpen: boolean, 
    onClose: () => void,
}

const PixelSpeaker = () => {
  return (
    <motion.div
      initial={{ x: -50, opacity: 0 }}
      animate={{ x: 0, opacity: 1 }}
      className="relative w-8 h-8"
    >
      {/* Head */}
      <motion.div
        className="absolute w-6 h-6 bg-[#FFD3B5] rounded-full shadow-md"
        animate={{
          rotate: [-2, 2, -2],
          y: [0, -1, 0],
        }}
        transition={{
          repeat: Infinity,
          duration: 1.5,
        }}
      >
        {/* Face */}
        <div className="absolute top-2 left-1 w-1 h-1 bg-black rounded-full" />
        <div className="absolute top-2 right-1 w-1 h-1 bg-black rounded-full" />
        <motion.div
          className="absolute bottom-2 left-1/2 -translate-x-1/2 bg-black rounded-full"
          animate={{
            scaleX: [1, 1.2, 1],
            scaleY: [1, 0.8, 1],
          }}
          transition={{
            repeat: Infinity,
            duration: 0.5,
          }}
        >
          <div className="w-2 h-[2px]" />
        </motion.div>
      </motion.div>

      {/* Body */}
      <div className="absolute w-6 h-6 bg-blue-500 top-4 left-1 rounded-lg shadow-md">
        <div className="absolute top-1 left-1/2 -translate-x-1/2 w-4 h-1 bg-blue-300 opacity-50" />
      </div>
    </motion.div>
  );
};

const PixelMessagePopover: React.FC<pixelMsgProp> = ({ message, isOpen, onClose }) => {
  return (
    <AnimatePresence>
      {isOpen && (
        <div className="fixed inset-0 flex items-center justify-center z-20  p-4 bg-black/20">
          <motion.div
            initial={{ scale: 0.8, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            exit={{ scale: 0.8, opacity: 0 }}
            className="relative bg-white rounded-lg shadow-xl max-w-sm w-full p-4"
          >
            <div className="flex items-start gap-4">
              <PixelSpeaker />

              <motion.div
                initial={{ x: 20, opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                transition={{ delay: 0.2 }}
                className="flex-1 relative bg-blue-50 rounded-lg p-3"
              >
                <div className="text-sm">{message}</div>
                <div className="absolute -left-2 top-4 w-4 h-4 bg-blue-50 transform rotate-45" />
              </motion.div>
            </div>

            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              onClick={onClose}
              className="mt-4 w-full px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors"
            >
              Got it!
            </motion.button>
          </motion.div>
        </div>
      )}
    </AnimatePresence>
  );
};

export default PixelMessagePopover;
