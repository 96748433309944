import React, { FC } from "react";

import SelectCaseGrid from "./GridShow";

const SelectOurCase: FC<{}> = () => {
    return(
        <div className="p-4 container">
             <SelectCaseGrid className="pb-24 lg:pb-28" />
        </div>
        
    )
}

export default function SelectOurCasePage() {
    return (
        <SelectOurCase />
    );
  }