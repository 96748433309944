import React, { useState, useEffect } from "react";
import axios from "axios";
import { gsap } from "gsap";
import { apiUri } from "../../constants";
import Alert from "shared/Alert/Alert";
import { motion, AnimatePresence } from "framer-motion";
import { Scale, Swords, Clock, XCircle, Coffee, Users, Timer, Trophy, Search, AlertTriangle } from "lucide-react";

// Define TypeScript interfaces for data structure
interface TeamInAction {
  team1: string;
  team2: string;
}

interface TeamComingUp extends TeamInAction {
  StartTime: string;
}

interface TeamStatus {
  teamName: string;
}

interface ScheduleResponse {
  inAction: TeamInAction[];
  comingUp: TeamComingUp[];
  eliminated: TeamStatus[];
  resting: TeamStatus[];
}

const SchedulePage: React.FC = () => {
  const [teamsInAction, setTeamsInAction] = useState<TeamInAction[]>([]);
  const [teamsComingUp, setTeamsComingUp] = useState<TeamComingUp[]>([]);
  const [eliminatedTeams, setEliminatedTeams] = useState<TeamStatus[]>([]);
  const [restingTeams, setRestingTeams] = useState<TeamStatus[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetch = async () => {
      try {
        setLoading(true);
        const response = await axios.post(`${apiUri}/fullSchedule`, {
          id: sessionStorage.getItem("FetchMootCourtId"),
        });

        const { inAction, ScheduledTeams, eliminatedTeams, idealTeams } =
          response.data.data;
        setTeamsInAction(inAction);
        setTeamsComingUp(ScheduledTeams);
        setEliminatedTeams(eliminatedTeams);
        setRestingTeams(idealTeams);
      } catch (error) {
        console.error("Error fetching schedule:", error);
      } finally {
        setLoading(false);
      }
    };

    fetch();
  }, []);

  const pageVariants = {
    initial: { opacity: 0, y: 20 },
    animate: { opacity: 1, y: 0 },
    exit: { opacity: 0, y: -20 },
  };

  return (
    <motion.div
      className="min-h-screen bg-gray-50 py-12 px-4 sm:px-6 lg:px-8"
      variants={pageVariants}
      initial="initial"
      animate="animate"
      exit="exit"
    >
      <div className="max-w-7xl mx-auto">
        <motion.div
          className="text-center mb-12"
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.2 }}
        >
          <div className="flex justify-center items-center mb-6">
            <Scale className="w-12 h-12 text-blue-600 mr-4" />
            <h1 className="text-4xl font-bold text-gray-900">
              Moot Court Schedule
            </h1>
          </div>
          <p className="text-gray-600 text-lg max-w-2xl mx-auto">
            Track real-time progress of all participating teams in the
            competition
          </p>
        </motion.div>

        {loading ? (
          <div className="flex justify-center items-center h-64">
            <div className="animate-spin rounded-full h-16 w-16 border-b-2 border-blue-600"></div>
          </div>
        ) : (
          <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-8">
            <CategoryCard
              icon={<Swords className="w-6 h-6" />}
              title="Teams In Action"
              teams={teamsInAction}
              type="action"
              color="blue"
            />
            <CategoryCard
              icon={<Clock className="w-6 h-6" />}
              title="Coming Up Next"
              teams={teamsComingUp}
              type="upcoming"
              color="purple"
            />
            <CategoryCard
              icon={<XCircle className="w-6 h-6" />}
              title="Eliminated Teams"
              teams={eliminatedTeams}
              type="eliminated"
              color="red"
            />
            <CategoryCard
              icon={<Coffee className="w-6 h-6" />}
              title="Resting Teams"
              teams={restingTeams}
              type="resting"
              color="yellow"
            />
          </div>
        )}
      </div>
    </motion.div>
  );
};

interface CategoryCardProps {
  icon: React.ReactNode;
  title: string;
  teams: TeamInAction[] | TeamComingUp[] | TeamStatus[];
  type: "action" | "upcoming" | "eliminated" | "resting";
  color: "blue" | "purple" | "red" | "yellow";
}

const CategoryCard: React.FC<CategoryCardProps> = ({
  icon,
  title,
  teams,
  type,
  color,
}) => {
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [isSearchFocused, setIsSearchFocused] = useState(false);

  const colorVariants = {
    blue: {
      bg: "bg-blue-50",
      border: "border-blue-200",
      text: "text-blue-600",
      gradient: "from-blue-500 to-blue-600",
      hover: "hover:bg-blue-100",
    },
    purple: {
      bg: "bg-purple-50",
      border: "border-purple-200",
      text: "text-purple-600",
      gradient: "from-purple-500 to-purple-600",
      hover: "hover:bg-purple-100",
    },
    red: {
      bg: "bg-red-50",
      border: "border-red-200",
      text: "text-red-600",
      gradient: "from-red-500 to-red-600",
      hover: "hover:bg-red-100",
    },
    yellow: {
      bg: "bg-yellow-50",
      border: "border-yellow-200",
      text: "text-yellow-600",
      gradient: "from-yellow-500 to-yellow-600",
      hover: "hover:bg-yellow-100",
    },
  };

  const filteredTeams = teams.filter((team) => {
    if (type === "action" || type === "upcoming") {
      const t = team as TeamInAction;
      return (
        t.team1.toLowerCase().includes(searchTerm.toLowerCase()) ||
        t.team2.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }
    return (team as TeamStatus).teamName
      .toLowerCase()
      .includes(searchTerm.toLowerCase());
  });

  const TeamCard: React.FC<{ team: any; index: number }> = ({
    team,
    index,
  }) => {
    const cardVariants = {
      hidden: { opacity: 0, y: 20 },
      visible: {
        opacity: 1,
        y: 0,
        transition: { delay: index * 0.1 },
      },
    };

    if (type === "action" || type === "upcoming") {
      return (
        <motion.div
          variants={cardVariants}
          initial="hidden"
          animate="visible"
          className={`${colorVariants[color].bg} rounded-lg p-4 shadow-sm border ${colorVariants[color].border} ${colorVariants[color].hover} transition-all duration-300`}
        >
          <div className="flex items-center justify-between">
            <div className="flex-1">
              <div className="flex items-center space-x-2">
                <Users className={`w-4 h-4 ${colorVariants[color].text}`} />
                <span className="font-semibold">{team.team1}</span>
              </div>
              <div className="my-2 text-gray-400 text-center">VS</div>
              <div className="flex items-center space-x-2">
                <Users className={`w-4 h-4 ${colorVariants[color].text}`} />
                <span className="font-semibold">{team.team2}</span>
              </div>
            </div>
            {type === "upcoming" && (
              <div
                className={`text-sm ${colorVariants[color].text} flex items-center`}
              >
                <Timer className="w-4 h-4 mr-1" />
                {(team as TeamComingUp).StartTime}
              </div>
            )}
          </div>
        </motion.div>
      );
    }

    return (
      <motion.div
        variants={cardVariants}
        initial="hidden"
        animate="visible"
        className={`${colorVariants[color].bg} rounded-lg p-4 shadow-sm border ${colorVariants[color].border} ${colorVariants[color].hover} transition-all duration-300`}
      >
        <div className="flex items-center space-x-2">
          <Trophy className={`w-4 h-4 ${colorVariants[color].text}`} />
          <span className="font-semibold">{(team as TeamStatus).teamName}</span>
        </div>
      </motion.div>
    );
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      className="bg-white rounded-xl shadow-lg overflow-hidden"
    >
      <div className={`bg-gradient-to-r ${colorVariants[color].gradient} p-4`}>
        <div className="flex items-center justify-between">
          <div className="flex items-center space-x-3">
            <div className="bg-white p-2 rounded-lg">{icon}</div>
            <h2 className="text-xl font-bold text-white">{title}</h2>
          </div>
          <span className="bg-white bg-opacity-20 text-white px-3 py-1 rounded-full text-sm">
            {teams.length} Teams
          </span>
        </div>
      </div>

      <div className="p-4">
        <div className="relative mb-4">
          <input
            type="text"
            placeholder="Search teams..."
            className={`w-full pl-10 pr-4 py-2 rounded-lg border ${
              isSearchFocused
                ? "border-blue-500 ring-2 ring-blue-100"
                : "border-gray-200"
            } focus:outline-none transition-all duration-300`}
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            onFocus={() => setIsSearchFocused(true)}
            onBlur={() => setIsSearchFocused(false)}
          />
          <Search className="w-5 h-5 text-gray-400 absolute left-3 top-2.5" />
        </div>

        <div className="space-y-3 max-h-[400px] overflow-y-auto custom-scrollbar">
          <AnimatePresence>
            {filteredTeams.length > 0 ? (
              filteredTeams.map((team, index) => (
                <TeamCard key={index} team={team} index={index} />
              ))
            ) : (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                className="text-center py-8 text-gray-500"
              >
                <AlertTriangle className="w-8 h-8 mx-auto mb-2" />
                <p>No teams found</p>
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      </div>
    </motion.div>
  );
};
export default SchedulePage;
