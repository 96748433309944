import React from "react";
import { motion } from "framer-motion";
import { Bot, Scale, Gavel, ArrowRight, Brain, Sparkles } from "lucide-react";

type ColorScheme = "purple" | "blue" | "amber";

interface ColorConfig {
  light: string;
  medium: string;
  text: string;
  border: string;
  shadow: string;
}

interface HowItWorkItem {
  id: number;
  title: string;
  desc: string;
  icon: React.ReactNode;
  color: ColorScheme;
  img: string;
  imgDark?: string;
}

const colors: Record<ColorScheme, ColorConfig> = {
  purple: {
    light: "bg-purple-50",
    medium: "bg-purple-100",
    text: "text-purple-600",
    border: "border-purple-200",
    shadow: "shadow-purple-200/50",
  },
  blue: {
    light: "bg-blue-50",
    medium: "bg-blue-100",
    text: "text-blue-600",
    border: "border-blue-200",
    shadow: "shadow-blue-200/50",
  },
  amber: {
    light: "bg-amber-50",
    medium: "bg-amber-100",
    text: "text-amber-600",
    border: "border-amber-200",
    shadow: "shadow-amber-200/50",
  },
};

const data: HowItWorkItem[] = [
  {
    id: 1,
    icon: <Bot className="w-8 h-8" />,
    title: "Its all AI",
    desc: "The AI acts as the prosecutor, the judge and the witnesses.",
    img: "/howitworks1.jpeg",
    color: "purple",
  },
  {
    id: 2,
    icon: <Brain className="w-8 h-8" />,
    title: "Logic based",
    desc: "The AI acts on logic, with each of the argument analyzed on the current laws",
    img: "/howitworks2.jpeg",
    color: "blue",
  },
  {
    id: 3,
    icon: <Gavel className="w-8 h-8" />,
    title: "The decision",
    desc: "The AI judge then evaluates the arguments.",
    img: "/howitworks3.jpeg",
    color: "amber",
  },
];

interface FeatureCardProps {
  item: HowItWorkItem;
  index: number;
}

const FeatureCard: React.FC<FeatureCardProps> = ({ item, index }) => {
  const cardVariants = {
    offscreen: {
      y: 50,
      opacity: 0,
    },
    onscreen: {
      y: 0,
      opacity: 1,
      transition: {
        type: "spring",
        bounce: 0.4,
        delay: index * 0.2,
        duration: 0.8,
      },
    },
  };

  const iconVariants = {
    initial: { scale: 0, rotate: -180 },
    animate: {
      scale: 1,
      rotate: 0,
      transition: {
        type: "spring",
        bounce: 0.6,
        delay: index * 0.2 + 0.3,
        duration: 0.8,
      },
    },
  };

  const colorClasses = colors[item.color];

  return (
    <motion.div
      initial="offscreen"
      whileInView="onscreen"
      viewport={{ once: true, amount: 0.2 }}
      variants={cardVariants}
      className="relative"
    >
      {/* Connecting line */}
      {index < data.length - 1 && (
        <div className="hidden md:block absolute top-1/2 left-[calc(100%_-_2rem)] w-16 transform -translate-y-1/2">
          <motion.div
            initial={{ width: 0 }}
            whileInView={{ width: "100%" }}
            transition={{ delay: index * 0.2 + 0.5, duration: 0.5 }}
            className={`h-0.5 ${colorClasses.medium}`}
          />
          <ArrowRight
            className={`w-4 h-4 absolute right-0 top-1/2 transform -translate-y-1/2 ${colorClasses.text}`}
          />
        </div>
      )}

      <div
        className={`p-6 rounded-2xl border ${colorClasses.border} ${colorClasses.light} min-h-[480px] hover:shadow-xl transition-shadow duration-300`}
      >
        <div className="relative mb-8">
          {/* Image */}
          <div className="w-full aspect-square rounded-xl overflow-hidden mb-4">
            <img
              src={item.img}
              alt={item.title}
              className="w-full h-full object-cover transform hover:scale-110 transition-transform duration-300"
            />
          </div>

          {/* Icon */}
          <motion.div
            variants={iconVariants}
            initial="initial"
            whileInView="animate"
            viewport={{ once: true }}
            className={`absolute -top-4 -right-4 p-3 rounded-xl ${colorClasses.medium} ${colorClasses.shadow}`}
          >
            {item.icon}
          </motion.div>
        </div>

        <div className="text-center">
          <h3 className={`text-xl font-bold mb-3 ${colorClasses.text}`}>
            {item.title}
          </h3>
          <p className="text-gray-600">{item.desc}</p>
        </div>
      </div>
    </motion.div>
  );
};

interface SectionHowItWorkProps {
  className?: string;
}

const SectionHowItWork: React.FC<SectionHowItWorkProps> = ({
  className = "",
}) => {
  return (
    <section className={` ${className}`}>
      <div className="container mx-auto px-4">
        {/* Section Header */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
          viewport={{ once: true }}
          className="text-center mb-16"
        >
          <Sparkles className="w-8 h-8 mx-auto mb-4 text-purple-600" />
          <h2 className="text-4xl font-bold mb-4 bg-gradient-to-r from-purple-600 to-blue-600 bg-clip-text text-transparent">
            How AI Powers Your Learning
          </h2>
          <p className="text-lg text-gray-600 max-w-2xl mx-auto">
            Experience a revolutionary way to practice law with our AI-powered
            moot court system
          </p>
        </motion.div>

        {/* Features Grid */}
        <div className="grid md:grid-cols-3 gap-8 md:gap-12">
          {data.map((item, index) => (
            <FeatureCard key={item.id} item={item} index={index} />
          ))}
        </div>

        {/* Call to Action */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6, delay: 0.6 }}
          viewport={{ once: true }}
          className="text-center mt-16"
        >
          <button className="px-8 py-3 rounded-full bg-gradient-to-r from-purple-600 to-blue-600 text-white font-medium hover:shadow-lg transform hover:scale-105 transition-all"
          onClick={() => window.location.href = "/mootcourt"}>
            Try Your First Case Now
          </button>
        </motion.div>
      </div>
    </section>
  );
};

export default SectionHowItWork;
