import React from "react";
import { motion, useScroll, useTransform } from "framer-motion";
import {
  Quote,
  Star,
  ThumbsUp,
  Award,
  MessageCircle,
  SparklesIcon,
} from "lucide-react";

interface Testimonial {
  id: number;
  name: string;
  role: string;
  text: string;
  imageUrl: string;
  svg: JSX.Element;
}

const MaleAvatar1 = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 64 64"
    width="100%"
    height="100%"
    className="text-gray-600"
  >
    <circle cx="32" cy="20" r="16" fill="#6E8EAE" />
    <rect x="22" y="38" width="20" height="20" fill="#A7C6D9" />
    <path
      d="M22,38c-4,5-4,10,0,14c4,4,8,4,12,0c4-4,4-9,0-14H22z"
      fill="#BACEDC"
    />
  </svg>
);

const FemaleAvatar3 = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 64 64"
    width="100%"
    height="100%"
    className="text-gray-600"
  >
    <circle cx="32" cy="20" r="16" fill="#EAB8D1" />
    <rect x="22" y="38" width="20" height="20" fill="#F3D5E6" />
    <path
      d="M22,38c-4,5-4,10,0,14c4,4,8,4,12,0c4-4,4-9,0-14H22z"
      fill="#F9C3D7"
    />
  </svg>
);

const FemaleAvatar1 = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 64 64"
    width="100%"
    height="100%"
    className="text-gray-600"
  >
    <circle cx="32" cy="20" r="16" fill="#D68C8E" />
    <rect x="22" y="38" width="20" height="20" fill="#F2A2A4" />
    <path
      d="M22,38c-4,5-4,10,0,14c4,4,8,4,12,0c4-4,4-9,0-14H22z"
      fill="#F2C2C4"
    />
  </svg>
);

const FemaleAvatar2 = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 64 64"
    width="100%"
    height="100%"
    className="text-gray-600"
  >
    <circle cx="32" cy="20" r="16" fill="#C7B2D9" />
    <rect x="22" y="38" width="20" height="20" fill="#E6C3D1" />
    <path
      d="M22,38c-4,5-4,10,0,14c4,4,8,4,12,0c4-4,4-9,0-14H22z"
      fill="#D2A5B0"
    />
  </svg>
);

const testimonials: Testimonial[] = [
  {
    id: 1,
    name: "Tushar",
    role: "Law Student",
    text: "Helped in polishing my case analysation skills. Highly recommended!",
    imageUrl: "",
    svg: MaleAvatar1,
  },
  {
    id: 2,
    name: "Gulista Khan",
    role: "Law Intern",
    text: "The online mootcourt platform provided me with a realistic experience to practice my arguments and improve my courtroom skills. It's a game-changer!",
    imageUrl: "",
    svg: FemaleAvatar1,
  },
  {
    id: 3,
    name: "Aaraadhana Gaba",
    role: "Law Student",
    text: "As a law student, the AI integrated law service has been invaluable in helping me understand complex legal concepts and prepare for exams. A must-have tool!",
    imageUrl: "",
    svg: FemaleAvatar3,
  },
  {
    id: 4,
    name: "Tanya Madhu",
    role: "Legal Intern",
    text: "The online mootcourt platform provided me with practical experience in presenting legal arguments and receiving feedback. It's a great learning tool!",
    imageUrl: "",
    svg: FemaleAvatar2,
  },
];

interface TestimonialCardProps {
  name: string;
  role: string;
  text: string;
  imageUrl: string;
  svg: JSX.Element;
  index: number;
}

const TestimonialCard: React.FC<TestimonialCardProps> = ({
  name,
  role,
  text,
  svg,
  index,
}) => {
  // Random rotation for cards
  const rotation = ["-2deg", "1deg", "-1deg", "2deg"][index];

  return (
    <motion.div
      initial={{ opacity: 0, y: 50 }}
      whileInView={{ opacity: 1, y: 0 }}
      viewport={{ once: true, amount: 0.2 }}
      whileHover={{
        scale: 1.02,
        rotate: "0deg",
        transition: { type: "spring", stiffness: 300 },
      }}
      style={{ rotate: rotation }}
      className="h-full perspective-1000"
    >
      <div className="relative h-full group">
        {/* Glowing effect on hover */}
        <div className="absolute -inset-1 bg-gradient-to-r from-purple-600 to-blue-600 rounded-2xl opacity-0 group-hover:opacity-30 blur transition duration-500" />

        {/* Card Content */}
        <div className="relative h-full bg-white rounded-2xl shadow-lg overflow-hidden">
          {/* Animated gradient background */}
          <div className="absolute inset-0 bg-gradient-to-br from-purple-50/50 via-blue-50/50 to-purple-50/50 opacity-0 group-hover:opacity-100 transition-opacity duration-500" />

          {/* Top accent bar */}
          <div className="absolute top-0 left-0 right-0 h-1 bg-gradient-to-r from-purple-600 to-blue-600 transform origin-left scale-x-0 group-hover:scale-x-100 transition-transform duration-500" />

          <div className="relative p-6">
            {/* Rating Stars */}
            <div className="flex gap-1 mb-4">
              {[...Array(5)].map((_, i) => (
                <motion.div
                  initial={{ opacity: 0, scale: 0 }}
                  animate={{ opacity: 1, scale: 1 }}
                  transition={{ delay: 0.1 * i }}
                  key={i}
                >
                  <Star className="w-4 h-4 text-yellow-400 fill-current" />
                </motion.div>
              ))}
            </div>

            {/* Quote and Avatar Section */}
            <div className="flex items-start gap-4 mb-6">
              {/* Avatar with pulsing effect */}
              <div className="relative">
                <div className="w-16 h-16 rounded-xl overflow-hidden transform group-hover:scale-110 transition-transform duration-300">
                  {svg}
                </div>
                <motion.div
                  animate={{
                    scale: [1, 1.2, 1],
                    opacity: [0.3, 0.5, 0.3],
                  }}
                  transition={{
                    duration: 2,
                    repeat: Infinity,
                    ease: "easeInOut",
                  }}
                  className="absolute -inset-1 bg-gradient-to-r from-purple-600 to-blue-600 rounded-xl blur-sm -z-10"
                />
              </div>

              {/* Quote Icon with floating animation */}
              <motion.div
                animate={{
                  y: [-2, 2, -2],
                }}
                transition={{
                  duration: 3,
                  repeat: Infinity,
                  ease: "easeInOut",
                }}
                className="bg-gradient-to-br from-purple-100 to-blue-100 rounded-full p-3"
              >
                <Quote className="w-4 h-4 text-purple-600" />
              </motion.div>
            </div>

            {/* Testimonial Text */}
            <div className="relative mb-6">
              <SparklesIcon className="absolute -top-2 -left-2 w-4 h-4 text-purple-400 opacity-0 group-hover:opacity-100 transition-opacity duration-300" />
              <p className="text-gray-700 leading-relaxed">"{text}"</p>
            </div>

            {/* Author Info with hover effect */}
            <div className="pt-4 border-t border-gray-100">
              <div className="flex items-center justify-between">
                <div>
                  <h4 className="font-bold text-gray-900 group-hover:text-purple-600 transition-colors">
                    {name}
                  </h4>
                  <p className="text-sm bg-gradient-to-r from-purple-600 to-blue-600 bg-clip-text text-transparent">
                    {role}
                  </p>
                </div>
                <motion.div
                  whileHover={{ scale: 1.2, rotate: 10 }}
                  className="bg-purple-100 rounded-full p-2"
                >
                  <ThumbsUp className="w-4 h-4 text-purple-600" />
                </motion.div>
              </div>
            </div>

            {/* Achievement Badge */}
            <div className="absolute top-4 right-4">
              <motion.div
                whileHover={{ rotate: 20 }}
                className="bg-gradient-to-r from-yellow-400 to-orange-500 text-white text-xs font-bold px-2 py-1 rounded-full flex items-center gap-1"
              >
                <Award className="w-3 h-3" />
                <span>Verified</span>
              </motion.div>
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

const Testimonials: React.FC = () => {
  // Parallax scroll effect for background
  const { scrollYProgress } = useScroll();
  const y = useTransform(scrollYProgress, [0, 1], ["0%", "100%"]);

  return (
    <section className="relative py-20 overflow-hidden">
      {/* Animated Background Patterns */}
      <motion.div style={{ y }} className="absolute inset-0 opacity-10">
        <div className="absolute top-0 left-0 w-72 h-72 bg-purple-400 rounded-full mix-blend-multiply filter blur-xl animate-blob" />
        <div className="absolute top-0 right-0 w-72 h-72 bg-blue-400 rounded-full mix-blend-multiply filter blur-xl animate-blob animation-delay-2000" />
        <div className="absolute -bottom-8 left-20 w-72 h-72 bg-pink-400 rounded-full mix-blend-multiply filter blur-xl animate-blob animation-delay-4000" />
      </motion.div>

      <div className="relative container mx-auto px-4">
        {/* Section Header */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          className="text-center mb-16"
        >
          <motion.div
            initial={{ scale: 0 }}
            animate={{ scale: 1 }}
            transition={{ type: "spring", stiffness: 200 }}
            className="inline-flex items-center justify-center p-2 bg-gradient-to-r from-purple-100 to-blue-100 rounded-2xl mb-4"
          >
            <MessageCircle className="w-6 h-6 text-purple-600 mr-2" />
            <span className="font-medium bg-gradient-to-r from-purple-600 to-blue-600 bg-clip-text text-transparent">
              User Testimonials
            </span>
          </motion.div>

          <h2 className="text-4xl md:text-5xl font-bold mb-4">
            <span className="bg-gradient-to-r from-purple-600 to-blue-600 bg-clip-text text-transparent">
              What Our Users Say
            </span>
          </h2>
          <p className="text-lg text-gray-600 max-w-2xl mx-auto">
            Join our community of successful law professionals
          </p>
        </motion.div>

        {/* Testimonials Grid */}
        <div className="grid gap-8 md:grid-cols-2 lg:grid-cols-4">
          {testimonials.map((testimonial, index) => (
            <TestimonialCard
              key={testimonial.id}
              {...testimonial}
              index={index}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default Testimonials;
