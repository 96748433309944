import React, { useState } from "react";
import { Twitter, Linkedin, Facebook, Share2, Copy, Check } from "lucide-react";
import { motion } from "framer-motion";

const SocialShare = ({
  caseName = "Contract Law Case",
  score = 85,
  platform = "AI Moot Court",
  defaultMessage = "I just completed a case on AI Moot Court! 🎉 Check out my performance and try it yourself!",
}) => {
  const [copied, setCopied] = useState(false);

  const messages = {
    twitter: `Just scored ${score}/100 on ${caseName} at ${platform}! 🎓⚖️\n\nLeveling up my legal skills with AI-powered moot courts.\n\nTry it yourself at aimootcourt.com #LegalTech #LawStudent`,
    linkedin: `I'm excited to share that I've completed ${caseName} on AI Moot Court with a score of ${score}/100! 🎓\n\nThis innovative platform is helping me enhance my legal advocacy skills through AI-powered moot court simulations.\n\nInterested in improving your legal skills? Visit aimootcourt.com to learn more.\n\n#LegalEducation #LawSchool #Innovation`,
    facebook: `Just completed ${caseName} on AI Moot Court with a score of ${score}/100! 🎉\n\nExcited to be improving my legal skills through this innovative platform. Want to test your advocacy skills? Check out aimootcourt.com`,
  };

 type ShareMessages = {
   twitter: string;
   linkedin: string;
   facebook: string;
 };

 const handleShare = (
   platform: "twitter" | "linkedin" | "facebook",
 ) => {
   const urls = {
     twitter: `https://twitter.com/intent/tweet?text=${encodeURIComponent(
       messages.twitter
     )}`,
     linkedin: `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(
       "https://aimootcourt.com"
     )}&summary=${encodeURIComponent(messages.linkedin)}`,
     facebook: `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
       "https://aimootcourt.com"
     )}&quote=${encodeURIComponent(messages.facebook)}`,
   };

   // Open the share URL in a new window
   window.open(urls[platform], "_blank", "width=600,height=400");
 };

  const copyToClipboard = async (text: string) => {
    try {
      await navigator.clipboard.writeText(text);
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    } catch (err) {
      console.error("Failed to copy text: ", err);
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      className="bg-white rounded-2xl shadow-xl p-8 border-2 border-purple-100"
    >
      <div className="flex items-center gap-3 mb-6">
        <Share2 className="w-8 h-8 text-orange-500" />
        <h2 className="text-2xl font-bold text-gray-800">
          Share Your Achievement
        </h2>
      </div>

      <div className="space-y-6">
        {/* Share buttons */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          <motion.button
            whileHover={{ scale: 1.02 }}
            whileTap={{ scale: 0.98 }}
            onClick={() => handleShare("twitter")}
            className="flex items-center justify-center gap-2 p-3 rounded-lg bg-[#1DA1F2] text-white hover:bg-[#1a8cd8] transition-colors"
          >
            <Twitter className="w-5 h-5" />
            <span>Share on Twitter</span>
          </motion.button>

          <motion.button
            whileHover={{ scale: 1.02 }}
            whileTap={{ scale: 0.98 }}
            onClick={() => handleShare("linkedin")}
            className="flex items-center justify-center gap-2 p-3 rounded-lg bg-[#0A66C2] text-white hover:bg-[#084d94] transition-colors"
          >
            <Linkedin className="w-5 h-5" />
            <span>Share on LinkedIn</span>
          </motion.button>

          <motion.button
            whileHover={{ scale: 1.02 }}
            whileTap={{ scale: 0.98 }}
            onClick={() => handleShare("facebook")}
            className="flex items-center justify-center gap-2 p-3 rounded-lg bg-[#1877F2] text-white hover:bg-[#1664d9] transition-colors"
          >
            <Facebook className="w-5 h-5" />
            <span>Share on Facebook</span>
          </motion.button>
        </div>

        {/* Preview section */}
        <div className="mt-8 space-y-4">
          <div className="flex items-center justify-between">
            <h3 className="text-lg font-semibold text-gray-700">
              Preview Message
            </h3>
            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              onClick={() => copyToClipboard(messages.linkedin)}
              className="flex items-center gap-2 px-4 py-2 rounded-lg bg-purple-100 text-purple-600 hover:bg-purple-200 transition-colors"
            >
              {copied ? (
                <>
                  <Check className="w-4 h-4" />
                  <span>Copied!</span>
                </>
              ) : (
                <>
                  <Copy className="w-4 h-4" />
                  <span>Copy text</span>
                </>
              )}
            </motion.button>
          </div>

          <div className="bg-gray-50 p-4 rounded-lg">
            <p className="text-gray-600 whitespace-pre-line">
              {messages.linkedin}
            </p>
          </div>
        </div>

        {/* Custom message input */}
        <div className="mt-6">
          <h3 className="text-lg font-semibold text-gray-700 mb-2">
            Customize Your Message
          </h3>
          <textarea
            className="w-full p-3 border-2 border-gray-200 rounded-lg focus:border-purple-400 focus:ring-purple-400 transition-colors"
            rows={4}
            placeholder="Write your custom message..."
            defaultValue={defaultMessage}
          />
        </div>
      </div>
    </motion.div>
  );
};

export default SocialShare;
