import React, { FC, useEffect, useState, useRef } from "react";
import axios from "axios";
import { AnimatePresence, motion, useInView } from "framer-motion";
import { apiUri } from "../../constants";
import LoadingIcon from "components/Loading/LoadingIcon";
import BlogPostCard from "./BlogPostCard";
import { PostPosterType } from "data/types";
import { ChevronLeft, ChevronRight } from "lucide-react";

interface SectionLatestPostsProps {
  posts?: PostPosterType[];
  className?: string;
}

interface BlogRowProps {
  title: string;
  emoji: string;
  posts: PostPosterType[];
}

const BlogRow: FC<BlogRowProps> = ({ title, emoji, posts }) => {
  const rowRef = useRef<HTMLDivElement>(null);
  const [canScrollLeft, setCanScrollLeft] = useState(false);
  const [canScrollRight, setCanScrollRight] = useState(true);
  const isInView = useInView(rowRef, { once: true });

  const handleScroll = () => {
    if (!rowRef.current) return;

    const { scrollLeft, scrollWidth, clientWidth } = rowRef.current;
    setCanScrollLeft(scrollLeft > 0);
    setCanScrollRight(scrollLeft < scrollWidth - clientWidth - 10);
  };

  const scroll = (direction: "left" | "right") => {
    if (!rowRef.current) return;

    const scrollAmount = direction === "left" ? -400 : 400;
    rowRef.current.scrollBy({ left: scrollAmount, behavior: "smooth" });
  };

  if (posts.length === 0) return null;

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={isInView ? { opacity: 1, y: 0 } : {}}
      transition={{ duration: 0.5 }}
      className="mb-12"
    >
      {/* Category Header */}
      <div className="flex justify-between items-center mb-6 px-4 md:px-6">
        <motion.h2
          initial={{ x: -20, opacity: 0 }}
          animate={isInView ? { x: 0, opacity: 1 } : {}}
          transition={{ duration: 0.5, delay: 0.2 }}
          className="text-xl md:text-2xl font-bold text-gray-900 dark:text-white flex items-center gap-3"
        >
          <span className="text-2xl md:text-3xl">{emoji}</span>
          <span>{title}</span>
        </motion.h2>
      </div>

      {/* Scrollable Row */}
      <div className="relative group">
        <AnimatePresence>
          {canScrollLeft && (
            <motion.button
              initial={{ opacity: 0, x: 20 }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: 20 }}
              onClick={() => scroll("left")}
              className="absolute left-2 top-1/2 -translate-y-1/2 z-10 bg-white/90 dark:bg-gray-800/90 
                       rounded-full p-2 md:p-3 shadow-lg backdrop-blur-sm
                       hover:bg-white dark:hover:bg-gray-800 transition-all
                        block"
            >
              <ChevronLeft className="w-5 h-5 md:w-6 md:h-6 text-gray-700 dark:text-gray-200" />
            </motion.button>
          )}
        </AnimatePresence>

        <div
          ref={rowRef}
          onScroll={handleScroll}
          className="flex overflow-x-scroll gap-4 md:gap-6 px-4 md:px-6 pb-4 scroll-smooth 
                   snap-x snap-mandatory hide-scrollbar"
        >
          {posts.map((post, index) => (
            <motion.div
              key={post.id}
              initial={{ opacity: 0, x: 20 }}
              animate={isInView ? { opacity: 1, x: 0 } : {}}
              transition={{ duration: 0.5, delay: index * 0.1 }}
              className="flex-none w-[280px] md:w-[320px] snap-start"
            >
              <BlogPostCard post={post} />
            </motion.div>
          ))}
        </div>

        <AnimatePresence>
          {canScrollRight && (
            <motion.button
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: -20 }}
              onClick={() => scroll("right")}
              className="absolute right-2 top-1/2 -translate-y-1/2 z-10 bg-white/90 dark:bg-gray-800/90 
                       rounded-full p-2 md:p-3 shadow-lg backdrop-blur-sm
                       hover:bg-white dark:hover:bg-gray-800 transition-all
                        block"
            >
              <ChevronRight className="w-5 h-5 md:w-6 md:h-6 text-gray-700 dark:text-gray-200" />
            </motion.button>
          )}
        </AnimatePresence>
      </div>
    </motion.div>
  );
};

const SectionLatestPostsRenderer: FC<{
  posts: PostPosterType[];
  className?: string;
}> = ({ posts = [], className = "" }) => {
  const categories = [
    { id: "Education", name: "Legal Education", emoji: "📚" },
    { id: "Opinion", name: "Opinions", emoji: "🗣️" },
    { id: "Commentary", name: "Expert Commentary", emoji: "💭" },
    { id: "Rants", name: "Legal Rants", emoji: "😂" },
    { id: "Cases", name: "Case Analysis", emoji: "⚖️" },
    { id: "Technology", name: "Legal Tech", emoji: "💻" },
  ];

  return (
    <div className={`${className} py-8 md:py-12`}>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
        className="max-w-[950px] mx-auto"
      >
        {/* Featured Posts */}
        <BlogRow title="Trending Now" emoji="🔥" posts={posts.slice(-6).reverse()} />

        {/* Category Rows */}
        {categories.map((category, index) => (
          <BlogRow
            key={category.id}
            title={category.name}
            emoji={category.emoji}
            posts={posts.filter((post) => post.category === category.id)}
          />
        ))}
      </motion.div>
    </div>
  );
  };

  
function SectionLatestPosts() {
  const [details, setDetails] = useState<PostPosterType[] | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchDetails = async () => {
      try {
        const response = await axios.post(`${apiUri}/fetchAllBlogs`);
        setDetails(response.data.data);
      } catch (error) {
        console.error("Error fetching blogs:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchDetails();
  }, []);

  if (loading) {
    return <LoadingIcon text="Fetching all Blogs" />;
  } else if (details) {
    return <SectionLatestPostsRenderer posts={details} />;
  } else {
    return <p>Error loading blog.</p>;
  }
}

export default SectionLatestPosts;
