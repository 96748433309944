import React, { useState, useEffect } from "react";
import { Popover, Transition } from "@headlessui/react";
import { X, Save, Clock, Download, Upload, CheckCircle } from "lucide-react";

interface NotesPopoverProps {
  onClose: () => void;
}

const NotesPopover: React.FC<NotesPopoverProps> = ({ onClose }) => {
  const [notes, setNotes] = useState(() => {
    const savedNotes = sessionStorage.getItem("userNotes");
    return savedNotes ? JSON.parse(savedNotes) : "";
  });
  const [isSaved, setIsSaved] = useState(true);
  const [showSavedToast, setShowSavedToast] = useState(false);

  useEffect(() => {
    sessionStorage.setItem("userNotes", JSON.stringify(notes));
  }, [notes]);

  const handleNotesChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setNotes(event.target.value);
    setIsSaved(false);
  };

  const handleSave = () => {
    sessionStorage.setItem("userNotes", JSON.stringify(notes));
    setIsSaved(true);
    setShowSavedToast(true);
    setTimeout(() => setShowSavedToast(false), 2000);
  };

  const handleExport = () => {
    const blob = new Blob([notes], { type: "text/plain" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = `notes-${new Date().toLocaleDateString()}.txt`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };

  const handleImport = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const content = e.target?.result as string;
        setNotes(content);
        setIsSaved(false);
      };
      reader.readAsText(file);
    }
  };

  return (
    <Popover>
      <Transition
        show={true}
        enter="transition duration-200 ease-out"
        enterFrom="transform scale-95 opacity-0"
        enterTo="transform scale-100 opacity-100"
        leave="transition duration-150 ease-out"
        leaveFrom="transform scale-100 opacity-100"
        leaveTo="transform scale-95 opacity-0"
      >
        <Popover.Panel
          static
          className="fixed inset-0 flex items-center justify-center bg-black/30 backdrop-blur-sm z-50"
        >
          <div className="bg-white dark:bg-gray-800 rounded-2xl shadow-2xl max-w-2xl w-full mx-4 overflow-hidden border border-gray-200 dark:border-gray-700">
            {/* Header */}
            <div className="px-6 py-4 border-b border-gray-200 dark:border-gray-700 flex items-center justify-between">
              <div className="flex items-center gap-3">
                <div className="w-10 h-10 rounded-full bg-gradient-to-br from-blue-500 to-purple-500 flex items-center justify-center">
                  <span className="text-white text-xl">📝</span>
                </div>
                <div>
                  <h2 className="text-xl font-semibold text-gray-900 dark:text-white">
                    Case Notes
                  </h2>
                  <p className="text-sm text-gray-500 dark:text-gray-400">
                    <Clock className="w-3 h-3 inline mr-1" />
                    Last saved: {new Date().toLocaleTimeString()}
                  </p>
                </div>
              </div>
              <button
                onClick={onClose}
                className="p-2 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-full transition-colors"
              >
                <X className="w-5 h-5 text-gray-500 dark:text-gray-400" />
              </button>
            </div>

            {/* Content */}
            <div className="p-6">
              <textarea
                className="w-full h-64 p-4 border rounded-xl focus:ring-2 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:text-white dark:border-gray-600 resize-none transition-all duration-200"
                value={notes}
                onChange={handleNotesChange}
                placeholder="Start taking notes here..."
              ></textarea>

              {/* Toolbar */}
              <div className="mt-4 flex items-center justify-between">
                <div className="flex items-center gap-2">
                  <button
                    onClick={handleExport}
                    className="flex items-center gap-2 px-4 py-2 text-sm text-gray-600 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-lg transition-colors"
                  >
                    <Download className="w-4 h-4" />
                    Export
                  </button>
                  <label className="flex items-center gap-2 px-4 py-2 text-sm text-gray-600 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-lg transition-colors cursor-pointer">
                    <Upload className="w-4 h-4" />
                    Import
                    <input
                      type="file"
                      accept=".txt"
                      onChange={handleImport}
                      className="hidden"
                    />
                  </label>
                </div>
                <div className="flex items-center gap-4">
                  {!isSaved && (
                    <span className="text-sm text-amber-600 dark:text-amber-400">
                      Unsaved changes
                    </span>
                  )}
                  <button
                    onClick={handleSave}
                    className="flex items-center gap-2 px-6 py-2 bg-blue-500 hover:bg-blue-600 text-white rounded-lg transition-colors"
                  >
                    <Save className="w-4 h-4" />
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>

          {/* Save Toast */}
          <Transition
            show={showSavedToast}
            enter="transition ease-out duration-300"
            enterFrom="transform translate-y-2 opacity-0"
            enterTo="transform translate-y-0 opacity-100"
            leave="transition ease-in duration-200"
            leaveFrom="transform translate-y-0 opacity-100"
            leaveTo="transform translate-y-2 opacity-0"
            className="fixed bottom-4 right-4"
          >
            <div className="bg-green-500 text-white px-4 py-2 rounded-lg shadow-lg flex items-center gap-2">
              <CheckCircle className="w-4 h-4" />
              Notes saved successfully!
            </div>
          </Transition>
        </Popover.Panel>
      </Transition>
    </Popover>
  );
};

export default NotesPopover;
