import React from 'react';
import { TeamMessage } from './types';


interface TeamChatConversationLogProps {
    messages: TeamMessage[];
    conversationRef: React.RefObject<HTMLDivElement>;
}

const TeamChatConversationLog: React.FC<TeamChatConversationLogProps> = ({ messages, conversationRef }) => {

    const email = sessionStorage.getItem('email');

    return (
        <div className="flex flex-col overflow-y-auto  max-w-xl mx-auto p-1 bg-gray-100 border border-gray-300 rounded-lg w-full" ref={conversationRef}>

            {
               messages.map((message, index) => (
  <div
    key={index}
    className={`flex ${message.senderId === email ? "justify-end" : "justify-start"} my-2`}
  >
   
    {message.senderId !== email && (
      <p className="text-xs text-gray-500 pr-2 self-end">
        {message.senderId.slice(0, 8)}
      </p>
    )}

    <div
      className={`p-3 rounded-lg max-w-xs text-sm text-white ${
        message.senderId === email ? "bg-blue-500" : "bg-green-500 text-black"
      }`}
    >
      {message.message}
    </div>
  </div>
))

            }
        </div>
    );
}

export default TeamChatConversationLog;