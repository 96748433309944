import React from 'react';
import { motion } from 'framer-motion';

const content = `
  Education is a light that shows the mankind the right path to move forward. The purpose of education is not just making a student literate but to develop rationale thinking, enhance workable knowledge and self-sufficiency. In the words of Babasaheb Dr. Bhim Rao Ambedkar, “education will liberate all”. Each and everyone should be educated to unite and fight against the odds of the society. Legal professionals bear special onus on their shoulders to fight for the “right cause”. Right legal education prepares expert professionals who plead for all like a doctor who prescribes for all; like a priest who preaches for all and like an economist who plans for all’. A sound practical legal education is an essential condition to ensure the required legal services for proper maintenance and administration of justice in society.

  As a part of present legal education, Moot Court session is dedicated to practical application of knowledge acquired by students of law in classroom. However, in essence, it is much more valuable than the importance attached to it by the present structure of legal education. Moot court is a miniature model of an actual court for which the entire legal education has been designed and directed. It requires legal aptitude and specific skill sets including good command over legal concepts, knowledge about ever-growing jurisprudence, case laws, strong working vocabulary, art of presenting arguments with sound reasoning and good understanding of human psychology.

  Our Artificial Intelligence based Moot Court has been designed with object of providing easy access to LLB and LLM students to quality practical education so that they can grow into well-groomed, well-informed and well-trained legal professionals compatible with the requirements of the 21st Century. With advancement of technology, the focus of legal education needs to shift from simple classroom lectures to imparting practical professional skills. A law student must be able to deeply comprehend a legal matter but at the same time keep himself detached from the problem and objectively resolve it. He must have the knowledge and the ability to convey himself effectively. Legal education is expected to inculcate law students with the operative legal rules both substantive and procedural.

  Art of legal writing in law students needs to be emphasized with the different facets of legal writing like drafting pleadings, arguments, opinions and so on. Continuous practice on our AI based module will enable the students to acquire these skills automatically. Their arguments, drafting and reasoning will be evaluated and improved by the Artificial Intelligence. Skill of language and content creation will get developed hand in hand.

  Our AI technology based moot court platform will facilitate radical reconstruction of legal education. It aims to meet the need for qualitative education for upcoming legal professionals and equalizing education opportunities to transcend regional, social, gender, class and caste boundaries. Since most of the legal institutions in India do not possess requisite IT enabled infrastructural facilities for moot court training, our AI based module offers salvation to students as well as educational institutions. They need not spend huge wealth in creating their costly moot court labs. Now, they can afford AI based quality practical training without increasing burden on their already stressed finances and on the pockets of their students in the form of immensely fat fee.

  Our attention is primarily pinned to address issues of access and equity in educational opportunities amongst the backward social groups to ensure that no section of society is denied of access to its services because of poverty or social status. We cherish and strive for the ideal that equitable and inclusive education to achieve social and economic justice and seek to balance local and global human resource needs of the growing Indian legal landscape. We are committed to bridge the economic and technological handicap in the society. Therefore, as a part of moral obligation, we provide access to our premium product to the economically weaker students of society at concessional rates. Meritorious students of weaker sections can get free access to our premium product on specific recommendations of their concerned educational institute.

  Use of our Artificial Intelligence based platform will pave the path for affordable and competitive higher legal education which will ensure jobs and lay the foundation for a user friendly and competitive environment.

  Our AI technology based programme strives to capture attention of the students and making otherwise drab legal education an interesting journey toward professional competence. It attracts students as well as teachers who can keep themselves abreast of legal principles as pronounced by the Hon’ble courts in live case situations. On our platform, teachers and students have been enabled to create live court scene in their classroom itself. Further, students can get access to useful legal materials designed to increase their knowledge base and understanding of standard professional behaviour and duties towards their clients, courts and fellow lawyers as envisaged by the Bar Council of India.

  Our aims is to facilitate education which leads to liberation; liberation from ignorance which shrouds the mind; liberation from superstition which paralyses efforts; liberation from prejudice which blinds the vision of truth.
`;

const OurVision: React.FC = () => {
  const paragraphs = content.trim().split('\n').map((paragraph, index) => (
    <motion.p
      key={index}
      className="mb-4 text-justify leading-relaxed"
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ delay: index * 0.1 }}
    >
      {paragraph.trim()}
    </motion.p>
  ));

  return (
    <section className="py-16 px-8 bg-creamyYellow">
      <div className="max-w-4xl mx-auto">
        <motion.h1
          className="text-4xl font-bold mb-8 text-center"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.1 }}
        >
          Our Vision
        </motion.h1>
        {paragraphs}
      </div>
    </section>
  );
};

export default OurVision;
