import React from "react";
import { motion } from "framer-motion";
import { Film } from "lucide-react";
import Eye from "containers/PageHome/Eye";

interface DemoProps {
  className?: string;
  alt?: string;
}

const Demo: React.FC<DemoProps> = ({
  className = "",
  alt = "Demo Animation",
}) => {
  return (
    <div className={`relative ${className}`}>
      <div className="text-center">
        <div className="inline-flex items-center justify-center p-2 bg-purple-50 rounded-full mb-4">
          <Film className="w-5 h-5 text-purple-600 mr-2" />
          <span className="text-sm font-medium text-purple-600">
            Video Tutorials
          </span>
        </div>

        <h2 className="text-4xl font-bold mb-4 bg-gradient-to-r from-purple-600 to-blue-600 bg-clip-text text-transparent">
          See How It Works
        </h2>
        <p className="text-lg text-gray-600 max-w-2xl mx-auto">
          Watch our detailed tutorials to master the platform and improve your
          legal practice skills
        </p>
        <div className="flex justify-center space-x-4">
          {/* Place Eye components in a flex container */}
          <Eye />
          <Eye />
        </div>
      </div>

      <div className="relative  mx-auto">
        <img
          src="/laptopScreenResized.png"
          alt="Laptop"
          className="w-full h-full object-cover"
        />

        <div className="absolute inset-0 z-20">
          <div className="absolute top-[10.2%] left-[14.8%] right-[14.5%] bottom-[15%] rounded-lg ">
            <div className="relative w-full h-full">
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.5 }}
                className="w-full h-full"
              >
                <video
                  autoPlay
                  loop
                  muted
                  playsInline
                  className="w-full h-full object-fill"
                >
                  <source src="/demo.mp4" type="video/mp4" />
                </video>
              </motion.div>

              <div className="absolute inset-0 bg-gradient-to-br from-white/10 via-transparent to-transparent pointer-events-none" />

              <div className="absolute inset-0 bg-scan-lines opacity-[0.03] pointer-events-none" />
            </div>
          </div>
        </div>

        <div className="absolute -inset-4 bg-gradient-to-t from-purple-500/20 to-transparent blur-2xl opacity-50 -z-10" />
      </div>
    </div>
  );
};

export default Demo;
