import React, { FC } from "react";
import ButtonCircle from "shared/Button/ButtonCircle";
import rightImg from "images/SVG-subcribe2.png";
import NcImage from "shared/NcImage/NcImage";
import Badge from "shared/Badge/Badge";
import Input from "shared/Input/Input";
import { motion } from "framer-motion";
import { Book, Gift, Sparkles, Star } from "lucide-react";

export interface SectionSubscribe2Props {
  className?: string;
}

const SectionSubscribe2: FC<SectionSubscribe2Props> = ({ className = "" }) => {
  const benefits = [
    {
      icon: <Gift className="w-5 h-5 text-pink-500" />,
      title: "Exclusive Discounts",
      description: "Get special offers and early access to premium content",
    },
    {
      icon: <Book className="w-5 h-5 text-purple-500" />,
      title: "Premium Articles",
      description: "Access in-depth analysis and expert insights",
    },
    {
      icon: <Star className="w-5 h-5 text-yellow-500" />,
      title: "Community Access",
      description: "Join our vibrant community of legal enthusiasts",
    },
  ];

  return (
    <div
      className={`nc-SectionSubscribe2 relative flex flex-col lg:flex-row lg:items-center ${className}`}
      data-nc-id="SectionSubscribe2"
    >
      <div className="flex-shrink-0 mb-10 lg:mb-0 lg:mr-10 lg:w-2/5">
        <div className="flex-1 mb-12 lg:mb-0">
          <motion.div
            initial={{ opacity: 0, x: -20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ delay: 0.2 }}
            className="space-y-6"
          >
            {/* <div className="inline-flex items-center space-x-2 px-4 py-2 bg-white/10 rounded-full backdrop-blur-sm">
              <Sparkles className="w-5 h-5 text-yellow-400" />
              <span className="text-2xl font-medium text-blue-500">
                Join the legal revolution
              </span>
            </div> */}

            <h2 className="text-5xl font-bold leading-tight bg-clip-text text-transparent bg-gradient-to-r from-pink-500 to-red-600">
              Get Your Weekly Legal Vibes ⚖️✨
            </h2>

            <p className="text-xl text-pink-600">
              Stay ahead with fresh perspectives on law, justice, and everything
              in between. No boring stuff - we promise!
            </p>

            {/* Benefits Grid */}
            <div className="grid gap-6 mt-8">
              {benefits.map((benefit, index) => (
                <motion.div
                  key={index}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: 0.3 + index * 0.1 }}
                  className="flex items-center space-x-4 bg-white/5 rounded-xl p-4 backdrop-blur-sm border border-white/10"
                >
                  <div className="flex-shrink-0 p-2 bg-white/10 rounded-lg">
                    {benefit.icon}
                  </div>
                  <div>
                    <h3 className="font-semibold text-blue-500">
                      {benefit.title}
                    </h3>
                    <p className="text-pink-800 text-md">
                      {benefit.description}
                    </p>
                  </div>
                </motion.div>
              ))}
            </div>
          </motion.div>
        </div>
      </div>
      <div className="flex-grow">
        <NcImage src={rightImg} />
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6, delay: 0.6 }}
          viewport={{ once: true }}
          className="text-center mt-16"
        >
          <button
            className="px-8 py-3 rounded-full bg-gradient-to-r from-pink-600 to-red-600 text-white font-medium hover:shadow-lg transform hover:scale-105 transition-all"
            onClick={() => (window.location.href = "/blog")}
          >
            Take me there !!
          </button>
        </motion.div>
      </div>
    </div>
  );
};

export default SectionSubscribe2;
