import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import axios from "axios";
import { apiUri } from "../../../constants";
import {
  Calendar,
  Users,
  Info,
  DollarSign,
  ArrowRight,
  Award,
  Brain,
  Globe,
  Library,
  Scale,
  Star,
    Heart,
  Building,
  CheckCircle2,
  Building2,
} from "lucide-react";

interface MootCourt {
  _id: string;
  caseTitle: string;
  collegeName: string;
  time: string;
  cost: number | null;
  prizes: string;
  specialReq: string;
  dateOfStart: string;
  dateOfEnd: string;
  teamSize: number;
  description: string;
}

const MootCourtListings = () => {
  const [mootCourts, setMootCourts] = useState<MootCourt[]>([]);
    const [loading, setLoading] = useState(true);
    
  useEffect(() => {
    const fetchMootCourts = async () => {
      try {
        const response = await axios.get(`${apiUri}/fetchAllScheduled`);
          setMootCourts(response.data.data);
          
          console.log(response.data.data)
      } catch (error) {
        console.error("Error fetching moot courts:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchMootCourts();
  }, [apiUri]);

  const container = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
      },
    },
  };

  const item = {
    hidden: { opacity: 0, y: 20 },
    show: { opacity: 1, y: 0 },
  };

  const benefitsData = [
    {
      icon: <Scale className="w-8 h-8 text-purple-500" />,
      title: "Legal Expertise",
      description:
        "Develop practical legal skills and understanding of court procedures",
    },
    {
      icon: <Brain className="w-8 h-8 text-blue-500" />,
      title: "Critical Thinking",
      description: "Enhance analytical and problem-solving abilities",
    },
    {
      icon: <Globe className="w-8 h-8 text-green-500" />,
      title: "Networking",
      description: "Connect with legal professionals and fellow law students",
    },
    {
      icon: <Award className="w-8 h-8 text-yellow-500" />,
      title: "Career Growth",
      description: "Stand out in job applications and build your resume",
    },
  ];

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="w-16 h-16 border-4 border-blue-600 border-t-transparent rounded-full animate-spin"></div>
      </div>
    );
  }
    
     const handleUserRegister = (x: MootCourt) => {
       if (!sessionStorage.getItem("email")) {
         window.location.href = "/login";
       } else {
         //console.log(x)
         sessionStorage.setItem("FetchMootCourtId", x._id);
         window.location.href = "/userRegisterMoot";
       }
     };


  return (
    <div className="min-h-screen bg-gray-50">
      <div className="flex flex-col lg:flex-row">
        {/* Main Content Area */}
        <div className="lg:w-2/3 p-8">
          <motion.div
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            className="text-center mb-12"
          >
            <h1 className="text-4xl font-bold text-gray-900 mb-4">
              Upcoming Moot Courts
            </h1>
            <p className="text-lg text-gray-600">
              Discover and register for prestigious moot court competitions
              across the country
            </p>
          </motion.div>

          <motion.div
            variants={container}
            initial="hidden"
            animate="show"
            className="grid grid-cols-1 md:grid-cols-2 gap-6"
          >
            {mootCourts.length === 0 ? (
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                className="text-center py-16 px-6"
              >
                <motion.div
                  initial={{ scale: 0 }}
                  animate={{ scale: 1 }}
                  transition={{ type: "spring", bounce: 0.5 }}
                  className="w-20 h-20 mx-auto mb-6 bg-blue-50 rounded-full flex items-center justify-center"
                >
                  <Scale className="w-10 h-10 text-blue-500" />
                </motion.div>

                <h2 className="text-3xl font-bold text-gray-800 mb-4">
                  No Courts Coming Up! 🏛️
                </h2>

                <div className="max-w-md mx-auto space-y-4">
                  <p className="text-gray-600 text-lg">
                    Are you a college? Consider hosting with us! 🎓
                  </p>

                  <motion.button
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    className="inline-flex items-center space-x-2 bg-gradient-to-r from-blue-500 to-purple-500 text-white px-6 py-3 rounded-full font-medium shadow-lg hover:shadow-xl transition-shadow"
                    onClick={() =>
                      (window.location.href = "/mootInfoRegistration")
                    }
                  >
                    <Building2 className="w-5 h-5 mr-2" />
                    Host a Moot Court
                  </motion.button>

                  <p className="text-sm text-gray-500 mt-4">
                    Join leading institutions in shaping future legal minds ⚖️
                  </p>
                </div>
              </motion.div>
            ) : (
              mootCourts.map((mootCourt, index) => (
                <motion.div
                  key={index}
                  variants={item}
                  whileHover={{ scale: 1.02 }}
                  className="bg-white rounded-xl shadow-lg overflow-hidden hover:shadow-xl transition-shadow duration-300"
                >
                  <div className="p-6">
                    <div className="flex items-center justify-between mb-4">
                      <h2 className="text-xl font-semibold text-gray-900 truncate">
                        {mootCourt.caseTitle}
                      </h2>
                      {mootCourt.cost && (
                        <span className="inline-flex items-center px-3 py-1 rounded-full text-sm font-medium bg-green-100 text-green-800">
                          <DollarSign className="w-4 h-4 mr-1" />
                          Entry fees
                        </span>
                      )}
                    </div>

                    <div className="space-y-3">
                      <div className="flex items-center text-gray-600">
                        <Building className="w-5 h-5 mr-2 text-blue-600" />
                        <span>{mootCourt.collegeName}</span>
                      </div>

                      <div className="flex items-center text-gray-600">
                        <Calendar className="w-5 h-5 mr-2 text-blue-600" />
                        <span>
                          {new Date(mootCourt.dateOfStart).toLocaleDateString()}{" "}
                          - {new Date(mootCourt.dateOfEnd).toLocaleDateString()}
                        </span>
                      </div>

                      <div className="flex items-center text-gray-600">
                        <Users className="w-5 h-5 mr-2 text-blue-600" />
                        <span>{mootCourt.teamSize} members per team</span>
                      </div>
                    </div>

                    <div className="mt-6">
                      <motion.button
                        whileHover={{ scale: 1.05 }}
                        whileTap={{ scale: 0.95 }}
                        className="w-full inline-flex items-center justify-center px-4 py-2 border border-transparent text-base font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                        onClick={() => handleUserRegister(mootCourts[index])}
                      >
                        View Details
                        <ArrowRight className="ml-2 w-4 h-4" />
                      </motion.button>
                    </div>
                  </div>
                </motion.div>
              ))
            )}
          </motion.div>
        </div>

        {/* Sidebar */}
        <div className="lg:w-1/3 bg-white p-8 border-l border-gray-200">
          {/* Why Participate Section */}
          <motion.div
            initial={{ opacity: 0, x: 20 }}
            animate={{ opacity: 1, x: 0 }}
            className="mb-12"
          >
            <h2 className="text-2xl font-bold text-gray-900 mb-6 flex items-center">
              <Star className="w-6 h-6 text-yellow-500 mr-2" />
              Why Participate?
            </h2>
            <div className="space-y-6">
              {benefitsData.map((benefit, index) => (
                <motion.div
                  key={index}
                  initial={{ opacity: 0, x: 20 }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{ delay: index * 0.1 }}
                  className="flex items-start space-x-4"
                >
                  <div className="flex-shrink-0">{benefit.icon}</div>
                  <div>
                    <h3 className="font-semibold text-gray-900">
                      {benefit.title}
                    </h3>
                    <p className="text-gray-600">{benefit.description}</p>
                  </div>
                </motion.div>
              ))}
            </div>
          </motion.div>

          {/* How to Participate Section */}
          <motion.div
            initial={{ opacity: 0, x: 20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ delay: 0.2 }}
            className="mb-12"
          >
            <h2 className="text-2xl font-bold text-gray-900 mb-6 flex items-center">
              <CheckCircle2 className="w-6 h-6 text-green-500 mr-2" />
              How to Participate
            </h2>
            <div className="space-y-4">
              <div className="flex items-center space-x-3 text-gray-700">
                <div className="w-8 h-8 rounded-full bg-blue-100 flex items-center justify-center">
                  1
                </div>
                <p>Choose a competition from the listings</p>
              </div>
              <div className="flex items-center space-x-3 text-gray-700">
                <div className="w-8 h-8 rounded-full bg-blue-100 flex items-center justify-center">
                  2
                </div>
                <p>Form your team according to size requirements</p>
              </div>
              <div className="flex items-center space-x-3 text-gray-700">
                <div className="w-8 h-8 rounded-full bg-blue-100 flex items-center justify-center">
                  3
                </div>
                <p>Register through the competition portal</p>
              </div>
              <div className="flex items-center space-x-3 text-gray-700">
                <div className="w-8 h-8 rounded-full bg-blue-100 flex items-center justify-center">
                  4
                </div>
                <p>Submit required documents and pay fees (if any)</p>
              </div>
            </div>
          </motion.div>

          {/* Register Your Moot Court Section */}
          <motion.div
            initial={{ opacity: 0, x: 20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ delay: 0.3 }}
            className="bg-gradient-to-r from-blue-500 to-purple-600 rounded-xl p-6 text-white"
          >
            <h2 className="text-2xl font-bold mb-4 flex items-center">
              <Library className="w-6 h-6 mr-2" />
              Register Your Moot Court
            </h2>
            <p className="mb-6">
              Are you a college looking to host a moot court competition?
              Register your event with us!
            </p>
            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              className="w-full bg-white text-blue-600 py-2 px-4 rounded-md font-semibold hover:bg-blue-50 transition-colors"
              onClick={() => (window.location.href = "/mootInfoRegistration")}
            >
              Host a Moot Court
            </motion.button>
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default MootCourtListings;
