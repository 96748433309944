import React, { FC, useEffect, useState } from "react";
import { motion } from "framer-motion";
import axios from "axios";
import TeamRegisterPopover from "./TeamRegisterPopover";
import { apiUri } from "../../constants";
import {
  Award,
  Calendar,
  Users,
  School,
  Clock,
  FileText,
  Gift,
  AlertCircle,
  Briefcase,
  CheckCircle,
  Timer,
  Globe,
  BookOpen,
  GraduationCap,
  Lightbulb,
  ListChecks,
  ScrollText,
  LogOut,
  MoveLeftIcon,
} from "lucide-react";
import PdfViewer from "./PdfViewer";

interface UserRegisterProp {
  caseTitle: string;
  collegeName: string;
  time: string;
  cost: string;
  prizes: string[];
  specialReq: string;
  dateOfStart: string;
  dateOfEnd: string;
  teamSize: string;
  description: string;
  casePdf: Blob;
}

const MotionCard = motion.div;

const UserRegistration: FC<UserRegisterProp> = ({
  caseTitle,
  collegeName,
  time,
  prizes,
  dateOfStart,
  dateOfEnd,
  specialReq,
  teamSize,
  cost,
  description,
  casePdf,
}) => {
  const [regPopOpen, setIsRegPopOpen] = useState(false);
  const [isUserReg, setIsReg] = useState(false);
  const [isAfterStart, setIsAfterStart] = useState(false);

  useEffect(() => {
    const startDate = new Date(dateOfStart);
    const currentDate = new Date();
    if (currentDate > startDate) {
      setIsAfterStart(true);
    }
  }, [dateOfStart]);

  useEffect(() => {
    const fetchIsUserReg = async () => {
      try {
        const response = await axios.post(`${apiUri}/fetchIsUserReg`, {
          mootCourtId: sessionStorage.getItem("FetchMootCourtId"),
          member: sessionStorage.getItem("email"),
        });
        setIsReg(response.data.success);
      } catch (error) {
        console.error("Error checking registration:", error);
        setIsReg(false);
      }
    };
    fetchIsUserReg();
  }, []);

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1, transition: { staggerChildren: 0.1 } },
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 },
  };

  const renderHeader = () => (
    <motion.div
      initial={{ opacity: 0, y: -20 }}
      animate={{ opacity: 1, y: 0 }}
      className="bg-gradient-to-r from-blue-600 to-purple-600 text-white p-8 rounded-xl mb-8"
    >
      <div className="max-w-4xl mx-auto">
        <motion.h1
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          className="text-4xl font-bold mb-4"
        >
          {caseTitle}
        </motion.h1>
        <div className="flex items-center space-x-2 text-blue-100">
          <School className="w-5 h-5" />
          <span>Hosted by {collegeName}</span>
        </div>
      </div>
    </motion.div>
  );

  const renderQuickInfo = () => (
    <motion.div
      variants={containerVariants}
      initial="hidden"
      animate="visible"
      className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-8"
    >
      <MotionCard
        variants={itemVariants}
        className="bg-white p-6 rounded-xl shadow-lg hover:shadow-xl transition-shadow"
      >
        <div className="flex items-center space-x-4">
          <div className="bg-blue-100 p-3 rounded-full">
            <Calendar className="w-6 h-6 text-blue-600" />
          </div>
          <div>
            <p className="text-sm text-gray-500">Duration</p>
            <p className="font-semibold">
              {dateOfStart} - {dateOfEnd}
            </p>
          </div>
        </div>
      </MotionCard>

      <MotionCard
        variants={itemVariants}
        className="bg-white p-6 rounded-xl shadow-lg hover:shadow-xl transition-shadow"
      >
        <div className="flex items-center space-x-4">
          <div className="bg-purple-100 p-3 rounded-full">
            <Users className="w-6 h-6 text-purple-600" />
          </div>
          <div>
            <p className="text-sm text-gray-500">Team Size</p>
            <p className="font-semibold">{teamSize} Members</p>
          </div>
        </div>
      </MotionCard>

      <MotionCard
        variants={itemVariants}
        className="bg-white p-6 rounded-xl shadow-lg hover:shadow-xl transition-shadow"
      >
        <div className="flex items-center space-x-4">
          <div className="bg-green-100 p-3 rounded-full">
            <Clock className="w-6 h-6 text-green-600" />
          </div>
          <div>
            <p className="text-sm text-gray-500">Time</p>
            <p className="font-semibold">{time}</p>
          </div>
        </div>
      </MotionCard>
    </motion.div>
  );

  const renderDescription = () => (
    <motion.div
      variants={containerVariants}
      initial="hidden"
      animate="visible"
      className="bg-white rounded-xl shadow-lg p-8 mb-8"
    >
      <motion.h2
        variants={itemVariants}
        className="text-2xl font-bold mb-6 flex items-center"
      >
        <FileText className="w-6 h-6 mr-2 text-blue-600" />
        About This Moot Court
      </motion.h2>
      <motion.p
        variants={itemVariants}
        className="text-gray-600 leading-relaxed"
      >
        {description}
      </motion.p>
    </motion.div>
  );

  const renderPrizes = () => (
    <motion.div
      variants={containerVariants}
      initial="hidden"
      animate="visible"
      className="bg-white rounded-xl shadow-lg p-8 mb-8"
    >
      <motion.h2
        variants={itemVariants}
        className="text-2xl font-bold mb-6 flex items-center"
      >
        <Award className="w-6 h-6 mr-2 text-yellow-500" />
        Prizes & Recognition
      </motion.h2>
      <motion.div
        variants={itemVariants}
        className="grid grid-cols-1 md:grid-cols-2 gap-4"
      >
        {prizes.map((prize, index) => (
          <div
            key={index}
            className="flex items-center space-x-3 bg-yellow-50 p-4 rounded-lg"
          >
            <Gift className="w-5 h-5 text-yellow-600" />
            <span className="text-gray-700">{prize}</span>
          </div>
        ))}
      </motion.div>
    </motion.div>
  );

  const renderPdfViewer = () => (
    <motion.div
      variants={containerVariants}
      initial="hidden"
      animate="visible"
      className="bg-white rounded-xl shadow-lg p-8 mb-8"
    >
      <motion.h2
        variants={itemVariants}
        className="text-2xl font-bold mb-6 flex items-center"
      >
        <ScrollText className="w-6 h-6 mr-2 text-blue-600" />
        Case Materials
      </motion.h2>
      <motion.div variants={itemVariants} className="aspect-[16/9]">
        <PdfViewer pdfBlob={casePdf} />
      </motion.div>
    </motion.div>
  );

  const Guidelines = () => (
    <motion.div
      variants={containerVariants}
      initial="hidden"
      animate="visible"
      className="bg-white rounded-xl shadow-lg p-6 mb-6"
    >
      <motion.h3
        variants={itemVariants}
        className="text-xl font-semibold mb-4 flex items-center"
      >
        <ListChecks className="w-5 h-5 mr-2 text-blue-600" />
        Guidelines & Requirements
      </motion.h3>

      <motion.div variants={itemVariants} className="space-y-4">
        {/* Dress Code */}
        <div className="p-4 bg-gray-50 rounded-lg">
          <h4 className="font-medium flex items-center mb-2">
            <Briefcase className="w-4 h-4 mr-2 text-gray-600" />
            Dress Code
          </h4>
          <p className="text-sm text-gray-600">
            Professional attire required: Dark suit, formal shoes, and
            courtroom-appropriate presentation
          </p>
        </div>

        {/* Timing Requirements */}
        <div className="p-4 bg-gray-50 rounded-lg">
          <h4 className="font-medium flex items-center mb-2">
            <Timer className="w-4 h-4 mr-2 text-gray-600" />
            Timing
          </h4>
          <ul className="text-sm text-gray-600 space-y-1">
            <li>• Join 15 minutes before your slot</li>
            <li>• Late entries may face disqualification</li>
            <li>• Each round: 45 minutes per team</li>
          </ul>
        </div>

        {/* Court Etiquette */}
        <div className="p-4 bg-gray-50 rounded-lg">
          <h4 className="font-medium flex items-center mb-2">
            <GraduationCap className="w-4 h-4 mr-2 text-gray-600" />
            Court Etiquette
          </h4>
          <ul className="text-sm text-gray-600 space-y-1">
            <li>• Address judges appropriately</li>
            <li>• Maintain professional demeanor</li>
            <li>• Follow court procedures strictly</li>
          </ul>
        </div>

        {/* Special Requirements */}
        <div className="p-4 bg-gray-50 rounded-lg">
          <h4 className="font-medium flex items-center mb-2">
            <Lightbulb className="w-4 h-4 mr-2 text-gray-600" />
            Special Requirements
          </h4>
          <p className="text-sm text-gray-600">
            {specialReq || "No special requirements specified"}
          </p>
        </div>
      </motion.div>
    </motion.div>
  );

  const renderRegistration = () => (
    <motion.div
      variants={containerVariants}
      initial="hidden"
      animate="visible"
      className="space-y-6"
    >
      {/* Registration Card */}
      <motion.div
        variants={itemVariants}
        className="bg-white rounded-xl shadow-lg p-8 sticky top-4"
      >
        <motion.div variants={itemVariants} className="text-center mb-6">
          <h3 className="text-3xl font-bold mb-2">{cost}</h3>
          <p className="text-gray-500">per team</p>
        </motion.div>

        <motion.div variants={itemVariants} className="space-y-4 mb-6">
          <div className="flex justify-between items-center p-3 bg-gray-50 rounded-lg">
            <div className="flex items-center">
              <Calendar className="w-5 h-5 text-blue-600 mr-2" />
              <span>Start Date</span>
            </div>
            <span className="font-medium">{dateOfStart}</span>
          </div>
          <div className="flex justify-between items-center p-3 bg-gray-50 rounded-lg">
            <div className="flex items-center">
              <Timer className="w-5 h-5 text-blue-600 mr-2" />
              <span>End Date</span>
            </div>
            <span className="font-medium">{dateOfEnd}</span>
          </div>
        </motion.div>

        <motion.div variants={itemVariants}>
          {isUserReg ? (
            isAfterStart ? (
              <motion.button
                whileHover={{ scale: 1.02 }}
                whileTap={{ scale: 0.98 }}
                className="w-full py-4 bg-green-600 text-white rounded-xl font-semibold flex items-center justify-center space-x-2"
                onClick={() => (window.location.href = "/fullSchedule")}
              >
                <BookOpen className="w-5 h-5" />
                <span>View Schedule</span>
              </motion.button>
            ) : (
              <div className="w-full py-4 bg-purple-600 text-white rounded-xl font-semibold text-center">
                Registration Complete ✨
              </div>
            )
          ) : (
            <motion.button
              whileHover={{ scale: 1.02 }}
              whileTap={{ scale: 0.98 }}
              className="w-full py-4 bg-blue-600 text-white rounded-xl font-semibold flex items-center justify-center space-x-2"
              onClick={() => setIsRegPopOpen(true)}
            >
              <CheckCircle className="w-5 h-5" />
              <span>Register Now</span>
            </motion.button>
          )}

          <motion.button
            whileHover={{ scale: 1.02 }}
            whileTap={{ scale: 0.98 }}
            className="w-full py-4 bg-red-600 mt-3 text-white rounded-xl font-semibold flex items-center justify-center space-x-2"
            onClick={() => window.location.href=("/allMootCourts")}
          >
            <MoveLeftIcon className="w-5 h-5" />
            <span>Go back</span>
          </motion.button>
        </motion.div>
      </motion.div>

      {/* Guidelines */}
      <Guidelines />
    </motion.div>
  );

  return (
    <div className="min-h-screen bg-gray-50 pb-12 container">
      {renderHeader()}

      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
          <div className="lg:col-span-2">
            {renderQuickInfo()}
            {renderDescription()}
            {renderPdfViewer()}
            {renderPrizes()}
          </div>

          <div className="lg:col-span-1">{renderRegistration()}</div>
        </div>
      </div>

      {regPopOpen && (
        <TeamRegisterPopover
          onClose={() => setIsRegPopOpen(false)}
          teamSize={Number(teamSize)}
        />
      )}
    </div>
  );
};

export default function UserRegisterMoot() {
  const [data, setData] = useState<UserRegisterProp | null>(null);
  const [loading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchDetails = async () => {
      try {
        const response = await axios.post(`${apiUri}/fetchRegMoot`, {
          id: sessionStorage.getItem("FetchMootCourtId"),
        });

        const res = await axios.post(
          `${apiUri}/fetchMootPdf`,
          {
            id: sessionStorage.getItem("FetchMootCourtId"),
          },
          { responseType: "blob" }
        );

        response.data.data.casePdf = res.data;
        setData(response.data.data);
      } catch (error) {
        console.error("Error fetching case details:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchDetails();
  }, []);

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="w-16 h-16 border-4 border-blue-600 border-t-transparent rounded-full animate-spin"></div>
      </div>
    );
  }

  return data ? <UserRegistration {...data} /> : null;
}
