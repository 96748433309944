import React from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { CheckIcon } from "@heroicons/react/24/outline";
import { format } from 'date-fns';
import axios from "axios";



const PaySuccess: React.FC<{}> = () => {
    const pricing = ["Unlimited Mootcourt access", "Avyukta Bot - The one stop case guide", "Advanced analytics", "24/7 Customer Support"]
    const currentDate = new Date();
    const next30DaysDate = new Date();
    next30DaysDate.setDate(currentDate.getDate() + 30);

    const formattedCurrentDate = format(currentDate, 'MMM d, yyyy');
    const formattedNext30DaysDate = format(next30DaysDate, 'MMM d, yyyy');
    const renderContent = () => {
        return (
            <div className="w-full flex flex-col sm:rounded-2xl sm:border border-neutral-200 dark:border-neutral-700 space-y-8 px-0 sm:p-6 xl:p-8">
                <h2 className="text-3xl lg:text-4xl font-semibold">
                    Congratulation 🎉
                </h2>

                <div className="border-b border-neutral-200 dark:border-neutral-700"></div>

                <div className="space-y-6">
                    <h3 className="text-2xl font-semibold">Your Subscription is now active.</h3>
                    <div className="flex flex-col sm:flex-row sm:items-center">
                        <div className="">
                            <div className=" rounded-2xl overflow-hidden">
                                <div

                                    className="h-full relative px-6 py-8 rounded-3xl hover:bg-gray-100 border-2 flex flex-col overflow-hidden border-primary-500"


                                >

                                    <span className="bg-primary-500 text-white px-3 py-1 tracking-widest text-xs absolute right-3 top-3 rounded-full z-10">
                                        POPULAR
                                    </span>

                                    <div className="mb-8">
                                        <h3 className="block text-sm uppercase tracking-widest text-neutral-6000 dark:text-neutral-300 mb-2 font-medium">
                                            Individual
                                        </h3>
                                        <h2 className="text-5xl leading-none flex items-center text-neutral-900 dark:text-neutral-300">
                                            <span>INR 399</span>
                                            <span className="text-lg ml-1 font-normal text-neutral-500">
                                                /mo
                                            </span>
                                        </h2>
                                    </div>
                                    <nav className="space-y-4 mb-8">
                                        {pricing.map((item, index) => (
                                            <li className="flex items-center" key={index}>
                                                <span className="mr-4 inline-flex flex-shrink-0 text-primary-6000">
                                                    <CheckIcon className="w-5 h-5" aria-hidden="true" />
                                                </span>
                                                <span className="text-neutral-700 dark:text-neutral-300">
                                                    {item}
                                                </span>
                                            </li>
                                        ))}
                                    </nav>

                                </div>
                            </div>
                        </div>

                    </div>
                    <div className=" border border-neutral-200 dark:border-neutral-700 rounded-3xl flex flex-col sm:flex-row divide-y sm:divide-x sm:divide-y-0 divide-neutral-200 dark:divide-neutral-700">
                        <div className="flex-1 p-5 flex space-x-4">
                            <svg
                                className="w-8 h-8 text-neutral-300 dark:text-neutral-6000"
                                viewBox="0 0 28 28"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M9.33333 8.16667V3.5M18.6667 8.16667V3.5M8.16667 12.8333H19.8333M5.83333 24.5H22.1667C23.4553 24.5 24.5 23.4553 24.5 22.1667V8.16667C24.5 6.878 23.4553 5.83333 22.1667 5.83333H5.83333C4.54467 5.83333 3.5 6.878 3.5 8.16667V22.1667C3.5 23.4553 4.54467 24.5 5.83333 24.5Z"
                                    stroke="#D1D5DB"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>

                            <div className="flex flex-col">
                                <span className="text-sm text-neutral-400">Date</span>
                                <span className="mt-1.5 text-lg font-semibold">
                                    {formattedCurrentDate} - {formattedNext30DaysDate}
                                </span>
                            </div>
                            <div className="flex-1 p-5 flex space-x-4">
                                <svg
                                    className="w-8 h-8 text-neutral-300 dark:text-neutral-6000"
                                    viewBox="0 0 28 28"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M14 5.07987C14.8551 4.11105 16.1062 3.5 17.5 3.5C20.0773 3.5 22.1667 5.58934 22.1667 8.16667C22.1667 10.744 20.0773 12.8333 17.5 12.8333C16.1062 12.8333 14.8551 12.2223 14 11.2535M17.5 24.5H3.5V23.3333C3.5 19.4673 6.63401 16.3333 10.5 16.3333C14.366 16.3333 17.5 19.4673 17.5 23.3333V24.5ZM17.5 24.5H24.5V23.3333C24.5 19.4673 21.366 16.3333 17.5 16.3333C16.225 16.3333 15.0296 16.6742 14 17.2698M15.1667 8.16667C15.1667 10.744 13.0773 12.8333 10.5 12.8333C7.92267 12.8333 5.83333 10.744 5.83333 8.16667C5.83333 5.58934 7.92267 3.5 10.5 3.5C13.0773 3.5 15.1667 5.58934 15.1667 8.16667Z"
                                        stroke="#D1D5DB"
                                        strokeWidth="1.5"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                </svg>

                                <div className="flex flex-col">
                                    <span className="text-sm text-neutral-400">Usage</span>
                                    <span className="mt-1.5 text-lg font-semibold">Personal</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="space-y-6">
                        <h3 className="text-2xl font-semibold">Details</h3>
                        <div className="flex flex-col space-y-4">
                            <div className="flex text-neutral-6000 dark:text-neutral-300">
                                <span className="flex-1">Booking Receipt</span>
                                <span className="flex-1 font-medium text-neutral-900 dark:text-neutral-100">
                                    These have been mailed to the email provided in the payment gateway
                                </span>
                            </div>
                            <div className="flex text-neutral-6000 dark:text-neutral-300">
                                <span className="flex-1">Date</span>
                                <span className="flex-1 font-medium text-neutral-900 dark:text-neutral-100">
                                    {formattedCurrentDate}
                                </span>
                            </div>
                            <div className="flex text-neutral-6000 dark:text-neutral-300">
                                <span className="flex-1">Total</span>
                                <span className="flex-1 font-medium text-neutral-900 dark:text-neutral-100">
                                    INR 399
                                </span>
                            </div>
                            <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
                                <span className="flex-1">Payment method</span>
                                <span className="flex-1 font-medium text-neutral-900 dark:text-neutral-100">
                                    Razorpay
                                </span>
                            </div>
                        </div>
                    </div>
                    <div>
                        <ButtonPrimary href="/mootcourt">To MootCourt!!</ButtonPrimary>
                    </div>
                </div>
            </div>
        );
    };

    return (
        <div className={`nc-PayPage`} data-nc-id="PayPage">
            <main className="container mt-11 mb-24 lg:mb-32 ">
                <div className="max-w-4xl mx-auto">{renderContent()}</div>
            </main>
        </div>
    );
};

export default PaySuccess;
