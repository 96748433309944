import React, { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { X, Maximize2, Minimize2 } from "lucide-react";

interface Step {
  title: string;
  description: string;
  color: string;
}

interface HowItWorksProps {
  onClose: () => void;
}

const steps: Step[] = [
  {
    title: "📁 Select Case Category",
    description:
      "Browse through our comprehensive database of cases across multiple domains including Criminal, Civil, and Commercial law. Each category provides detailed case studies and relevant legal frameworks.",
    color: "bg-blue-50 dark:bg-blue-950",
  },
  {
    title: "🔍 Review Case Details",
    description:
      "Examine case specifics, precedents, and applicable legal sections. Use our structured analysis tools to build a robust legal strategy based on historical outcomes.",
    color: "bg-blue-100 dark:bg-blue-900",
  },
  {
    title: "⚖️ Select Legal Role",
    description:
      "Choose between prosecution or defense positions. Each role offers unique challenges and requires different strategic approaches to case preparation.",
    color: "bg-blue-50 dark:bg-blue-950",
  },
  {
    title: "🛠️ Begin Trial Preparation",
    description:
      "Practice your case through multiple modes: AI-powered opposition, peer-to-peer matches, or team-based competitions. Each mode provides different learning opportunities and challenges.",
    color: "bg-blue-100 dark:bg-blue-900",
  },
];

const HowItWorks: React.FC<HowItWorksProps> = ({ onClose }) => {
  const [isFullScreen, setIsFullScreen] = React.useState<boolean>(false);
  const [showDialog, setShowDialog] = React.useState<boolean>(true); // State to control dialog visibility

  // Function to handle "Don't Show Me Again" button
  const handleDontShowAgain = () => {
    setShowDialog(false);
    sessionStorage.setItem("dontshowHowitWorks", "true")
    // Additional logic can be added here to remember the user's preference (e.g., localStorage)
  };

  const isOpen = showDialog; // Use state to determine if dialog is open
  return (
    <Transition show={isOpen} as={Fragment}>
      <Dialog onClose={onClose} className="relative z-50">
        {/* Backdrop */}
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black/50 backdrop-blur-sm" />
        </Transition.Child>

        {/* Modal */}
        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel
                className={`w-full transform overflow-hidden rounded-xl bg-white dark:bg-gray-950 shadow-xl transition-all
                  ${isFullScreen ? "fixed inset-0 rounded-none" : "max-w-4xl"}`}
              >
                {/* Header */}
                <div className="flex items-center justify-between p-4 border-b dark:border-gray-800">
                  <Dialog.Title className="text-xl font-semibold text-gray-900 dark:text-gray-100">
                    How It Works
                  </Dialog.Title>
                  <div className="flex gap-2">
                 
                      <button
                        onClick={handleDontShowAgain}
                        className=" bg-red-500 text-white py-2 px-4 rounded hover:bg-red-600 transition-colors"
                      >
                        Don't Show Me Again
                      </button>
                  
                    <button
                      onClick={() => setIsFullScreen(!isFullScreen)}
                      className="p-2 hover:bg-gray-100 dark:hover:bg-gray-800 rounded-lg transition-colors"
                      aria-label={
                        isFullScreen ? "Exit full screen" : "Enter full screen"
                      }
                    >
                      {isFullScreen ? (
                        <Minimize2 className="h-5 w-5" />
                      ) : (
                        <Maximize2 className="h-5 w-5" />
                      )}
                    </button>
                    <button
                      onClick={onClose}
                      className="p-2 hover:bg-gray-100 dark:hover:bg-gray-800 rounded-lg transition-colors"
                      aria-label="Close dialog"
                    >
                      <X className="h-5 w-5" />
                    </button>
                  </div>
                </div>

                {/* Content */}
                <div
                  className={`overflow-auto ${
                    isFullScreen
                      ? "h-[calc(100vh-5rem)]"
                      : "max-h-[calc(90vh-5rem)]"
                  }`}
                >
                  <div className="p-6">
                    <div className="text-center mb-8">
                      <Dialog.Title className="text-3xl font-bold text-gray-900 dark:text-gray-100">
                        Prepare Your Legal Case
                      </Dialog.Title>
                      <Dialog.Description className="mt-2 text-gray-600 dark:text-gray-400">
                        Follow these steps to build and practice your legal
                        strategy
                      </Dialog.Description>
                    </div>

                    <div className="grid gap-6 md:grid-cols-2">
                      {steps.map((step, index) => (
                        <div
                          key={index}
                          className={`${step.color} rounded-xl p-6 shadow-lg transition-all duration-300 hover:shadow-xl`}
                        >
                          <div className="mb-4">
                            <h3 className="text-xl font-semibold flex items-center gap-2 text-gray-900 dark:text-gray-100">
                              <span className="flex items-center justify-center w-8 h-8 rounded-full bg-blue-600 text-white text-sm font-medium">
                                {index + 1}
                              </span>
                              {step.title}
                            </h3>
                          </div>
                          <p className="text-gray-700 dark:text-gray-300">
                            {step.description}
                          </p>
                        </div>
                      ))}
                    </div>
                    {/* Don't Show Me Again Button */}
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default HowItWorks;
