import React, { useEffect, useState } from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  useParams,
} from "react-router-dom";
import { Page } from "./types";
import ScrollToTop from "./ScrollToTop";
import Footer from "shared/Footer/Footer";
import PageHome from "containers/PageHome/PageHome";
import Page404 from "containers/Page404/Page404";

import PageContact from "containers/PageContact/PageContact";

import PageLogin from "containers/PageLogin/PageLogin";
import PageSubcription from "containers/PageSubcription/PageSubcription";
import BlogPage from "containers/BlogPage/BlogPage";
import BlogSingle from "containers/BlogPage/BlogSingle";

import SiteHeader from "containers/SiteHeader";
import FooterNav from "components/FooterNav";
import useWindowSize from "hooks/useWindowResize";
import MootCourtPage from "containers/mootcourt/mootCourtPage";
import PageCourt from "containers/PageCourt/PageCourt";
import ListCaseDetails from "containers/CaseDetails/CaseDetail";
import GetAnalytics from "containers/PageAnalytics/PageAnalytics";
import PaySuccess from "containers/PageSubcription/PaymentSuccess";
import FeedbackComponent from "containers/Feedback/Feedback";
import OurVision from "containers/Vision/Vision";
import PageP2P from "containers/PageCourt/PageP2P";
import Crossword from "containers/Crossword/Crossword";
import P2PAnalytics from "containers/PeerToPeerDecision/P2PAnalysation";
import { P2PAnalyticsProps } from "./types";
import Search from "containers/AdvocateModule/Search";
import Lobby from "containers/HostMootcourt/Lobby";
import PageCollegeMootcourt from "containers/HostMootcourt/CollegeMootcourtPage";
import ContactSection from "components/ContactUs/Contact";
import GetLobbyPage from "containers/HostMootcourt/CreateLobbyPage";
import SelectOurCasePage from "containers/HostMootcourt/SelectOurCases";
import UserRegisterMoot from "containers/HostMootcourt/UserRegisterMoot";
import MootRegistrationForm from "containers/HostMootcourt/RegisterYourMoot";
import RegisterSuccess from "containers/HostMootcourt/RegisterSuccess";
import VideoConference from "containers/VideoConference/VideoConference";
import Profile from "containers/HostMootcourt/Profile";
import GetFinalMootAnalysis from "containers/HostMootcourt/FinalMootAnalysis";
import SchedulePage from "containers/PageHome/Schedule";
import MootCourtAnalysationWrapper from "containers/PeerToPeerDecision/MootCourtAnalyzation";
import DiscountedPageSubscription from "containers/PageHome/DiscountedPage";
import TermsandCond from "containers/PageLogin/T&C";
import PrivacyPolicy from "containers/PageLogin/PrivacyPolicy";
import PageSignUp from "containers/PageSignUp/PageSignUp";
import NewsletterSubmission from "containers/BlogPage/NewletterInput";
import LoadingIcon from "components/Loading/LoadingIcon";
import { apiUri } from "../constants";
import MootCourtListings from "containers/HostMootcourt/MootcourtListings/allMootCourts";
import PageCourtAnimated from "containers/PageCourt/PageCourtAnimated";

export const pages: Page[] = [
  { path: "/", exact: true, component: PageHome },

  { path: "/home-1-header-2", exact: true, component: PageHome },

  //
  { path: "/blog", component: BlogPage },
  // { path: "/blog-single", component: BlogSingle },
  //

  //
  { path: "/contact", component: PageContact },

  { path: "/signup", component: PageSignUp },
  { path: "/login", component: PageLogin },
  { path: "/subscription", component: PageSubcription },
  { path: "/mootCourt", component: MootCourtPage },
  { path: "/pageCourt", component: PageCourt },
  { path: "/caseDetails", component: ListCaseDetails },

  { path: "/analysis", component: GetAnalytics },

  { path: "/paySuccess", component: PaySuccess },

  { path: "/feedback", component: FeedbackComponent },

  { path: "/vision", component: OurVision },

  { path: "/mooting", component: PageP2P },

  { path: "/crossword", component: Crossword },

  { path: "/p2pAnalytics", component: P2PAnalytics },

  { path: "/lobby", component: Lobby },

  { path: "/collegeMootcourt", component: PageCollegeMootcourt },

  { path: "/contactus", component: ContactSection },

  { path: "/selectOurCase", component: SelectOurCasePage },

  { path: "/userRegisterMoot", component: UserRegisterMoot },

  { path: "/mootInfoRegistration", component: MootRegistrationForm },

  { path: "/registerSuccess", component: RegisterSuccess },

  { path: "/MootAnalysis", component: GetFinalMootAnalysis },

  { path: "/profile", component: Profile },

  { path: "/videoConference", component: VideoConference },

  { path: "/fullSchedule", component: SchedulePage },

  { path: "/mootCourtAnalysis", component: MootCourtAnalysationWrapper },

  { path: "/couponCheckout", component: DiscountedPageSubscription },

  { path: "/TermsAndConditions", component: TermsandCond },

  { path: "/privacyPolicy", component: PrivacyPolicy },

  {path: "/allMootCourts", component: MootCourtListings},

  { path: "/newsletterSubmission", component: NewsletterSubmission },

  { path: "/about", component: OurVision },
  
  { path: "/animatedCourt", component: PageCourtAnimated},

  { path: "*", component: Page404 },
];

const MyRoutes = () => {
  let WIN_WIDTH = useWindowSize().width;
  if (typeof window !== "undefined") {
    WIN_WIDTH = WIN_WIDTH || window.innerWidth;
  }
  const [shouldRenderHeader, setShouldRenderHeader] = useState<boolean>(false);

  useEffect(() => {
    const currentPath = window.location.pathname;
    const isHeaderAllowedPath = !(
      currentPath === "/pageCourt" ||
      currentPath === "/pagecourt" ||
      currentPath === "/mooting" ||
      currentPath === "/collegeMootcourt" ||
      currentPath === "/collegemootcourt" ||
      currentPath === "/selectOurCase" ||
      currentPath === "/userRegisterMoot"
    );

    let delay = 0;
    // if (currentPath === '/') {
    //   delay = 5000;
    // } else if (isHeaderAllowedPath) {
    //   delay = 0;
    // }

    const timer = setTimeout(() => {
      if (isHeaderAllowedPath) {
        setShouldRenderHeader(true);
      }
    }, delay);

    // Cleanup the timer if the component unmounts
    return () => clearTimeout(timer);
  }, [window.location.pathname]);

  // BlogWrapper component
  const BlogWrapper = () => {
    const { id } = useParams();

    // useEffect(() => {
    //   // Fetch from API but show on main domain
    //   fetch(
    //     `${apiUri}/blog/${id}`
    //   )
    //     .then((res) => res.text())
    //     .then((html) => {
    //       document.open();
    //       document.write(html);
    //       document.close();
    //     });
    // }, [id]);

    return <LoadingIcon text="Fetching blog" />;
  };
  return (
    <BrowserRouter>
      <ScrollToTop />
      {shouldRenderHeader && <SiteHeader />}

      <Routes>
        {/* Add catch-all route for blog single pages */}
        <Route path="/blog/:id" element={<BlogWrapper />} />;
        {pages.map(({ component, path }) => {
          const Component = component;
          return <Route key={path} element={<Component />} path={path} />;
        })}
        <Route element={<Page404 />} />
      </Routes>

      {window.innerWidth < 1024 && shouldRenderHeader && <FooterNav />}
      {shouldRenderHeader && <Footer />}
    </BrowserRouter>
  );
};

export default MyRoutes;
