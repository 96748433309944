import * as THREE from "three";
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { GUI } from 'dat.gui';
import Stats from 'stats.js';
import TWEEN, { Tween } from '@tweenjs/tween.js';
export const wrapText = (text: string, maxWidth: number, context: CanvasRenderingContext2D) => {
    const words = text.split(' ');
    let lines = [];
    let currentLine = words[0];

    for (let i = 1; i < words.length; i++) {
        const word = words[i];
        const width = context.measureText(currentLine + " " + word).width;
        if (width < maxWidth) {
            currentLine += " " + word;
        } else {
            lines.push(currentLine);
            currentLine = word;
        }
    }
    lines.push(currentLine);
    return lines;
};

// Function to create text texture
export const createTextTexture = (message: string) => {
    const canvas = document.createElement("canvas");
    const maxHeight = 512;
    const maxWidth = 512;
    const padding = 10; // Padding around the text
    const fontSize = 40;
    const lineHeight = 30; // Line height for wrapping text

    //Setting inital canvas value
    canvas.width = 512;
    canvas.height = 256;

    const context = canvas.getContext("2d");
    if (context) {
        context.clearRect(0, 0, canvas.width, canvas.height);
        context.fillStyle = "rgba(0, 0, 0, 0.5)";
        context.fillRect(0, 0, canvas.width, canvas.height);
        context.font = `${fontSize}px Arial`;
        context.fillStyle = "white";
        context.textAlign = "center";
        context.textBaseline = "middle";

        // Wrap the message into multiple lines
        const lines = wrapText(message, maxWidth - 20, context);

        // canvas.height = lines.length * 300;

        // Calculate the scaled font size if the total text height exceeds maxHeight
        let scale = 1;
        if (lines.length * lineHeight > maxHeight - 20) {
            scale = (maxHeight - 20) / (lines.length * lineHeight);
            context.font = `${fontSize * scale}px Arial`;
        }

        // Recalculate line height with scaled font
        const scaledLineHeight = lineHeight * scale;

        // Draw each line of text
        lines.forEach((line, index) => {
            context.fillText(line, canvas.width / 2, (index + 1) * scaledLineHeight);
        });

        // context.fillText(message, canvas.width / 2, canvas.height / 2);
    }
    return new THREE.CanvasTexture(canvas);
};

// Function to create text sprite
export const createTextSprite = (message: string, position: { x: number, y: number, z: number }) => {
    const textTexture = createTextTexture(message);
    const spriteMaterial = new THREE.SpriteMaterial({ map: textTexture, color: 0xffffff });
    const textSprite = new THREE.Sprite(spriteMaterial);
    textSprite.position.set(position.x, position.y, position.z);
    return textSprite;
};

export const updateTextSprite = (newMessage: string, sceneRef: React.MutableRefObject<THREE.Scene | undefined>, spriteRef: React.MutableRefObject<THREE.Sprite<THREE.Object3DEventMap> | undefined>, position: { x: number, y: number, z: number }) => {
    if (sceneRef.current && spriteRef.current) {
        // Remove the old text sprite
        sceneRef.current.remove(spriteRef.current);

        // Create a new text sprite
        const newTextSprite = createTextSprite(newMessage, { x: position.x, y: position.y, z: position.z });
        spriteRef.current = newTextSprite;

        // Add the new text sprite to the scene
        sceneRef.current.add(newTextSprite);
    }
}



// Handle resize
export const handleResize = (
    camera: THREE.PerspectiveCamera,
    renderer: THREE.WebGLRenderer,
    sizes: { width: number, height: number }) => {

    const { width, height } = sizes;
    camera.aspect = width / height;
    camera.updateProjectionMatrix();
    renderer.setSize(width, height);

};

//Init function for threejs
export const init = (sceneRef: React.MutableRefObject<THREE.Scene | undefined>,
    cameraRef: React.MutableRefObject<THREE.PerspectiveCamera | undefined>,
    canvasRef: React.RefObject<HTMLCanvasElement>,
    rendererRef: React.MutableRefObject<THREE.WebGLRenderer | undefined>,
    controlsRef: React.MutableRefObject<OrbitControls | undefined>,
    textSpriteRef: React.MutableRefObject<THREE.Sprite<THREE.Object3DEventMap> | undefined>,
    textSpriteRefLawyer2: React.MutableRefObject<THREE.Sprite<THREE.Object3DEventMap> | undefined>,
    statsRef: React.MutableRefObject<Stats | undefined>,
    witnessCharacterRef: React.MutableRefObject<THREE.Object3D<THREE.Object3DEventMap> | null>
) => {
    // Initialize Scene
    const scene = new THREE.Scene();
    sceneRef.current = scene;

    // Initialize Camera
    let widthSize;
    let heightSize;
    if (window.innerWidth <= 1024) {
        widthSize = window.innerWidth - 20;
        heightSize = window.innerHeight * 0.87 / 2;
    }
    else {
        widthSize = window.innerWidth * 0.7;
        heightSize = window.innerHeight * 0.87;
    }
    const sizes = {
        width: widthSize,
        height: heightSize,
    };

    // Position of witness box
    const witnessBox = { x: 1, y: 2, z: 3 };
    // const lawyer1Position ={x = }
    const camera = new THREE.PerspectiveCamera(75, sizes.width / sizes.height, 0.01, 100);
    camera.position.set(-1, 2, 3);
    camera.lookAt(new THREE.Vector3(-55, 0, 1));
    scene.add(camera);
    cameraRef.current = camera;

    // Initialize Renderer
    const renderer = new THREE.WebGLRenderer({
        canvas: canvasRef.current!,
    });
    renderer.setSize(sizes.width, sizes.height);
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
    renderer.shadowMap.enabled = true;
    // renderer.output = THREE.sRGBEncoding;
    rendererRef.current = renderer;

    // Initialize Controls
    const controls = new OrbitControls(camera, renderer.domElement);
    controls.enableDamping = true;
    controls.target.set(-3.55, 0, 1);
    controls.minDistance = 1;
    controls.maxDistance = 3;
    // Limits for vertical rotation (up and down)
    controls.minPolarAngle = Math.PI / 6; // 45 degrees
    controls.maxPolarAngle = Math.PI / 3; // 90 degrees

    // Limits for horizontal rotation (left and right)
    controls.minAzimuthAngle = -Math.PI / 4; // -45 degrees
    controls.maxAzimuthAngle = Math.PI / 2; // 45 degrees

    controlsRef.current = controls;


    // Function to animate camera movement


    // Lighting
    const ambientLight = new THREE.AmbientLight(0xffffff, 2);
    scene.add(ambientLight);

    // GUI
    // const gui = new GUI();
    // gui.addColor({ color: '#ffffff' }, 'color')
    //     .onChange((value: string) => ambientLight.color.set(value));
    // gui.add(ambientLight, 'intensity', 0, 3, 0.01);

    // Loading Court Room
    const loader = new GLTFLoader();
    loader.load(
        '/3dmodels/court_room/court_room.glb',
        (gltf) => {
            const courtRoom = gltf.scene;
            courtRoom.scale.set(0.5, 0.5, 0.5);
            scene.add(courtRoom);
        },
        function (xhr) {
            // //console.log(xhr.total);
            // //console.log("Court room" + (xhr.loaded / xhr.total) * 100 + "% loaded");
        },
        (error) => console.error('Error loading court room model', error)
    );

    // Loading Judge
    loader.load(
        // '/3dmodels/Tessa/3d_char_Tessa.gltf',
        '/3dmodels/3d_Lawyer_Sitting_Rigged_4.glb',
        (gltf) => {
            const tessa = gltf.scene;
            tessa.scale.set(0.5, 0.5, 0.5);
            tessa.position.set(-5.1, 0.4, 0.5);
            tessa.rotation.y = Math.PI / 2;
            scene.add(tessa);

            // Add text sprite
            // const initialTextSprite = createTextSprite("Hello World", { x: -4, y: 1.5, z: 0.5 });
            // scene.add(initialTextSprite);
            // textSpriteRef.current = initialTextSprite;

            // const initialTextSpriteLawyer2 = createTextSprite("Lawyer 2", { x: -3, y: 1.5, z: -1.5 });
            // scene.add(initialTextSpriteLawyer2);
            // textSpriteRefLawyer2.current = initialTextSpriteLawyer2;
            camera.lookAt(tessa.position);
            // controls.target.set(tessa.position.x, tessa.position.y, tessa.position.z);
            // controls.update();
        },
        function (xhr) {
            // //console.log((xhr.loaded / xhr.total) * 100 + "% Char loaded");
        },
        (error) => console.error('Error loading Tessa character', error)
    );

    // Loading Lawyer 1
    loader.load('/3dmodels/Female_Lawyer_1.glb',
        (glb) => {
            const lawyer1 = glb.scene;
            lawyer1.scale.set(0.6, 0.6, 0.6);
            lawyer1.position.set(-3.55, 0.5, 1);
            lawyer1.rotation.y = -Math.PI / 2;
            scene.add(lawyer1);
        },
        undefined,
        (error) => console.error('Error loading lawyer 1', error)
    );

    //Loading Lawyer 2 
    loader.load('3dmodels/Female_Lawyer_2.glb',
        (glb) => {
            const lawyer2 = glb.scene;
            lawyer2.scale.set(0.6, 0.6, 0.6);
            lawyer2.position.set(-3.55, 0.5, -1);
            lawyer2.rotation.y = -Math.PI / 2;
            scene.add(lawyer2);
        },
        undefined,
        (error) => console.error('Error loading lawyer 2', error)
    );

    //Loading Civilian 
    loader.load('/3dmodels/Female_Civilian.glb',
        (glb) => {
            const witness = glb.scene;
            witnessCharacterRef.current = witness;
            witness.scale.set(0.6, 0.6, 0.6);
            witness.position.set(-3.55, 0.5, 3);
            witness.rotation.y = -Math.PI;
            witness.visible = false
            scene.add(witness);
        },
        () => { },
        (error) => console.error('Error loading')
    );

    window.addEventListener('resize', () => { handleResize(camera, renderer, sizes) });

    //Adding stats
    // const stats = new Stats();
    // stats.showPanel(0);
    // document.body.appendChild(stats.dom);
    // statsRef.current = stats;
    // Animation loop
    const animate = () => {
        requestAnimationFrame(animate);
        controls.update();
        TWEEN.update();
        renderer.render(scene, camera);
        // stats.begin();
        // stats.end();
    };
    animate();

    // Cleanup on component unmount
    return () => {
        window.removeEventListener('resize', () => { handleResize(camera, renderer, sizes) });
        // gui.destroy();
        controls.dispose();
        renderer.dispose();
        scene.clear();
    };
    // cameraRef.current?.position.x
}

// Function to toggle the character's visibility
export const toggleCharacterVisibility = (isVisible: boolean, character: React.MutableRefObject<THREE.Object3D<THREE.Object3DEventMap> | null>, sceneRef: React.MutableRefObject<THREE.Scene | undefined>,
    cameraRef: React.MutableRefObject<THREE.PerspectiveCamera | undefined>, rendererRef: React.MutableRefObject<THREE.WebGLRenderer | undefined>) => {
    if (character && character.current) {
        character!.current!.visible = isVisible;
        rendererRef.current?.render(sceneRef.current!, cameraRef.current!);
    }
};

export const animateCamera = (cameraRef: React.MutableRefObject<THREE.PerspectiveCamera | undefined>, controlsRef: React.MutableRefObject<OrbitControls | undefined>, targetPosition: THREE.Vector3, targetLookAt: THREE.Vector3, duration: number) => {
    let camera = cameraRef.current!;
    let controls = controlsRef.current!;
    const initialPosition = { x: camera.position.x, y: camera.position.y, z: camera.position.z };
    // camera.lookAt
    const initialLookAt = controls.target.clone();

    new TWEEN.Tween(initialPosition)
        .to(targetPosition, duration)
        .easing(TWEEN.Easing.Quadratic.InOut)
        .onUpdate(() => {
            camera.position.set(initialPosition.x, initialPosition.y, initialPosition.z);
        })
        .start();

    new TWEEN.Tween(initialLookAt)
        .to(targetLookAt, duration)
        .easing(TWEEN.Easing.Quadratic.InOut)
        .onUpdate(() => {
            controls.target.set(initialLookAt.x, initialLookAt.y, initialLookAt.z);
        })
        .onComplete(() => {
            controls.update();
        })
        .start();
}