import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import axios from 'axios';
import { apiUri } from '../../constants';
import { 
  Lock, 
  Mail, 
  Eye, 
  EyeOff, 
  CheckCircle, 
  XCircle, 
  AlertCircle,
  Shield,
  Loader2
} from 'lucide-react';

interface ChangePasswordFromLoginProps {
  onClose: () => void;
}

interface PasswordRequirement {
  regex: RegExp;
  text: string;
  icon: React.ReactNode;
}

const ChangePasswordFromLogin: React.FC<ChangePasswordFromLoginProps> = ({ onClose }) => {
  // Form states
  const [formData, setFormData] = useState({
    email: '',
    newPassword: '',
    confirmPassword: '',
  });
  const [showPassword, setShowPassword] = useState({
    new: false,
    confirm: false,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [status, setStatus] = useState<{
    type: 'success' | 'error' | null;
    message: string;
  }>({ type: null, message: '' });

  // Password requirements
  const passwordRequirements: PasswordRequirement[] = [
    {
      regex: /.{8,}/,
      text: 'At least 8 characters long',
      icon: <Lock className="w-4 h-4" />
    },
    {
      regex: /[A-Z]/,
      text: 'Contains uppercase letter',
      icon: <Shield className="w-4 h-4" />
    },
    {
      regex: /[0-9]/,
      text: 'Contains number',
      icon: <CheckCircle className="w-4 h-4" />
    },
    {
      regex: /[!@#$%^&*]/,
      text: 'Contains special character',
      icon: <AlertCircle className="w-4 h-4" />
    },
  ];

  const validatePassword = (password: string) => {
    return passwordRequirements.every(req => req.regex.test(password));
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
    setStatus({ type: null, message: '' });
  };

  const handleChangePassword = async () => {
    // Validate inputs
    if (!formData.email) {
      setStatus({ type: 'error', message: 'Please enter your email address' });
      return;
    }

    if (!validatePassword(formData.newPassword)) {
      setStatus({ type: 'error', message: 'Password does not meet requirements' });
      return;
    }

    if (formData.newPassword !== formData.confirmPassword) {
      setStatus({ type: 'error', message: 'Passwords do not match' });
      return;
    }

    setIsLoading(true);
    try {
      const response = await axios.post(`${apiUri}/changePassword`, {
        email: formData.email,
        password: formData.newPassword,
      });

      if (response.data.success) {
        setStatus({ type: 'success', message: 'Password changed successfully!' });
        setTimeout(onClose, 2000);
      }
    } catch (err) {
      setStatus({ type: 'error', message: 'New password same as old password' });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="fixed inset-0 bg-black/50 backdrop-blur-sm flex items-center justify-center z-50 p-4"
      onClick={onClose}
    >
      <motion.div
        initial={{ scale: 0.9, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        exit={{ scale: 0.9, opacity: 0 }}
        className="bg-white rounded-2xl shadow-xl max-w-md w-full p-6"
        onClick={e => e.stopPropagation()}
      >
        {/* Header */}
        <div className="text-center mb-6">
          <div className="w-12 h-12 bg-purple-100 rounded-full flex items-center justify-center mx-auto mb-4">
            <Lock className="w-6 h-6 text-purple-600" />
          </div>
          <h2 className="text-2xl font-bold text-gray-900">Change Password</h2>
          <p className="text-gray-600 mt-1">Enter your new password below</p>
        </div>

        {/* Status Message */}
        <AnimatePresence mode="wait">
          {status.type && (
            <motion.div
              initial={{ opacity: 0, y: -10 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -10 }}
              className={`p-3 rounded-lg mb-4 flex items-center ${
                status.type === 'error' ? 'bg-red-50 text-red-600' : 'bg-green-50 text-green-600'
              }`}
            >
              {status.type === 'error' ? (
                <XCircle className="w-5 h-5 mr-2" />
              ) : (
                <CheckCircle className="w-5 h-5 mr-2" />
              )}
              {status.message}
            </motion.div>
          )}
        </AnimatePresence>

        {/* Form */}
        <div className="space-y-4">
          {/* Email Input */}
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">Email Address</label>
            <div className="relative">
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                className="w-full pl-10 pr-4 py-2 border rounded-lg focus:ring-2 focus:ring-purple-500 focus:border-transparent"
                placeholder="Enter your email"
              />
              <Mail className="w-5 h-5 text-gray-400 absolute left-3 top-1/2 transform -translate-y-1/2" />
            </div>
          </div>

          {/* New Password Input */}
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">New Password</label>
            <div className="relative">
              <input
                type={showPassword.new ? 'text' : 'password'}
                name="newPassword"
                value={formData.newPassword}
                onChange={handleInputChange}
                className="w-full pl-10 pr-12 py-2 border rounded-lg focus:ring-2 focus:ring-purple-500 focus:border-transparent"
                placeholder="Enter new password"
              />
              <Lock className="w-5 h-5 text-gray-400 absolute left-3 top-1/2 transform -translate-y-1/2" />
              <button
                type="button"
                onClick={() => setShowPassword(prev => ({ ...prev, new: !prev.new }))}
                className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400 hover:text-gray-600"
              >
                {showPassword.new ? <EyeOff className="w-5 h-5" /> : <Eye className="w-5 h-5" />}
              </button>
            </div>
          </div>

          {/* Password Requirements */}
          {formData.newPassword && (
            <div className="bg-gray-50 rounded-lg p-3">
              <p className="text-sm font-medium text-gray-700 mb-2">Password Requirements:</p>
              <div className="grid grid-cols-1 gap-2">
                {passwordRequirements.map((req, index) => (
                  <div
                    key={index}
                    className={`flex items-center text-sm ${
                      req.regex.test(formData.newPassword) ? 'text-green-600' : 'text-gray-500'
                    }`}
                  >
                    {req.icon}
                    <span className="ml-2">{req.text}</span>
                  </div>
                ))}
              </div>
            </div>
          )}

          {/* Confirm Password Input */}
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">Confirm Password</label>
            <div className="relative">
              <input
                type={showPassword.confirm ? 'text' : 'password'}
                name="confirmPassword"
                value={formData.confirmPassword}
                onChange={handleInputChange}
                className="w-full pl-10 pr-12 py-2 border rounded-lg focus:ring-2 focus:ring-purple-500 focus:border-transparent"
                placeholder="Confirm new password"
              />
              <Lock className="w-5 h-5 text-gray-400 absolute left-3 top-1/2 transform -translate-y-1/2" />
              <button
                type="button"
                onClick={() => setShowPassword(prev => ({ ...prev, confirm: !prev.confirm }))}
                className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400 hover:text-gray-600"
              >
                {showPassword.confirm ? <EyeOff className="w-5 h-5" /> : <Eye className="w-5 h-5" />}
              </button>
            </div>
          </div>
        </div>

        {/* Action Buttons */}
        <div className="flex space-x-3 mt-6">
          <button
            type="button"
            onClick={onClose}
            className="flex-1 px-4 py-2 border border-gray-300 rounded-lg text-gray-700 hover:bg-gray-50 transition-colors"
          >
            Cancel
          </button>
          <button
            type="button"
            onClick={handleChangePassword}
            disabled={isLoading}
            className={`
              flex-1 px-4 py-2 rounded-lg bg-purple-600 text-white
              flex items-center justify-center
              ${isLoading ? 'opacity-75 cursor-not-allowed' : 'hover:bg-purple-700'}
              transition-colors
            `}
          >
            {isLoading ? (
              <Loader2 className="w-5 h-5 animate-spin" />
            ) : (
              'Change Password'
            )}
          </button>
        </div>
      </motion.div>
    </motion.div>
  );
};

export default ChangePasswordFromLogin;
