import React, { useState } from "react";
import { motion } from "framer-motion";
import {
  CheckIcon,
  SparklesIcon,
  BuildingIcon,
  Lock,
  Zap,
  Gift,
} from "lucide-react";
import RazorpayButton from "containers/PageSubcription/RazorpayButton";
import RazorpayButtonSem from "containers/PageSubcription/RazorPayButtonSem";
import RazorpayButtonYrly from "containers/PageSubcription/RazorPayButtonYearly";

interface PricingItem {
  isPopular: boolean;
  name: string;
  pricing: string;
  originalPrice: string;
  per: string;
  features: string[];
  desc: string;
  icon: React.ReactNode;
  gradient: string;
}

const pricings: PricingItem[] = [
  {
    isPopular: true,
    name: "Monthly",
    pricing: "₹399",
    originalPrice: "₹700",
    per: "/mo",
    features: [
      "Unlimited Mootcourt access",
      "Avyukta Bot - The one-stop case guide",
      "Advanced analytics",
      "Play with friends",
      "24/7 Customer Support",
    ],
    desc: "Perfect for law students and individuals",
    icon: <SparklesIcon className="w-6 h-6" />,
    gradient: "from-purple-500 to-blue-500",
  },
  {
    isPopular: false,
    name: "Semester",
    pricing: "₹999",
    originalPrice: "₹2100",
    per: "/3mo",
    features: [
      "All Monthly plan features",
      "Priority support",
      "Extra practice materials",
      "Detailed performance tracking",
      "Save 52% compared to monthly",
    ],
    desc: "Ideal for semester-long preparation",
    icon: <SparklesIcon className="w-6 h-6" />,
    gradient: "from-blue-500 to-purple-500",
  },
  {
    isPopular: false,
    name: "Yearly",
    pricing: "₹2999",
    originalPrice: "₹8400",
    per: "/year",
    features: [
      "All Semester plan features",
      "Premium study materials",
      "Exclusive webinars",
      "Personalized learning path",
      "Save 64% compared to monthly",
    ],
    desc: "Best value for dedicated learners",
    icon: <SparklesIcon className="w-6 h-6" />,
    gradient: "from-purple-500 to-cyan-500",
  },
  {
    isPopular: false,
    name: "Enterprise",
    pricing: "Contact Us",
    originalPrice: "",
    per: "",
    features: [
      "Group discounts available",
      "Extensive integration with institutions",
      "Dedicated account manager",
      "Advanced reporting and analytics",
      "Custom feature development",
    ],
    desc: "Ideal for law firms and institutions",
    icon: <BuildingIcon className="w-6 h-6" />,
    gradient: "from-blue-500 to-cyan-500",
  },
];

interface PricingCardProps {
  plan: PricingItem;
  onSelect: () => void;
  isSelected: boolean;
}

const PricingCard: React.FC<PricingCardProps> = ({
  plan,
  onSelect,
  isSelected,
}) => {
  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      whileInView={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      viewport={{ once: true }}
      className="relative"
    >
      <div
        className={`
          relative p-8 rounded-2xl transition-all min-h-[650px] duration-300
          ${
            isSelected ? "ring-2 ring-purple-500 shadow-2xl" : "hover:shadow-xl"
          }
          bg-white
        `}
      >
        {/* Header */}
        <div className="flex items-center justify-between mb-6">
          <div>
            <h3 className="text-2xl font-bold text-gray-900">{plan.name}</h3>
            <p className="text-gray-600 mt-1">{plan.desc}</p>
          </div>
          <div
            className={`p-3 rounded-xl bg-gradient-to-br ${plan.gradient} text-white`}
          >
            {plan.icon}
          </div>
        </div>
        {/* Pricing */}
        <div className="mb-6">
          <div className="flex items-baseline">
            <span className="text-4xl font-bold text-gray-900">
              {plan.pricing}
            </span>
            <span className="text-gray-600 ml-2">{plan.per}</span>
          </div>
          {plan.originalPrice && (
            <div className="mt-1 flex items-center gap-2">
              <span className="text-gray-500 line-through">
                {plan.originalPrice}
              </span>
              <span className="text-green-600 font-medium">
                Save{" "}
                {Math.round(
                  ((parseInt(plan.originalPrice.replace(/[^0-9]/g, "")) -
                    parseInt(plan.pricing.replace(/[^0-9]/g, ""))) /
                    parseInt(plan.originalPrice.replace(/[^0-9]/g, ""))) *
                    100
                )}
                %
              </span>
            </div>
          )}
        </div>
        {/* Features */}
        <ul className="space-y-4 mb-8">
          {plan.features.map((feature, index) => (
            <motion.li
              key={index}
              initial={{ opacity: 0, x: -20 }}
              whileInView={{ opacity: 1, x: 0 }}
              transition={{ delay: index * 0.1 }}
              className="flex items-center space-x-3"
            >
              <div
                className={`p-1 rounded-full bg-gradient-to-r ${plan.gradient}`}
              >
                <CheckIcon className="w-4 h-4 text-white" />
              </div>
              <span className="text-gray-600">{feature}</span>
            </motion.li>
          ))}
        </ul>
        {/* CTA Button */}

        <button
          onClick={() => {
            if (plan.name === "Enterprise") {
              if (sessionStorage.getItem("loggedIn")) {
                window.location.href = "/contactUs";
              } else {
                window.location.href = "/login";
              }
            }
          }}
          className={`
    w-full py-3 px-6 rounded-xl text-white font-medium
    transition-all duration-300 transform hover:scale-105
    ${
      plan.name !== "Enterprise"
        ? "bg-gradient-to-r from-purple-600 to-blue-600"
        : "bg-gradient-to-r from-blue-600 to-cyan-600"
    }
  `}
        >
          {(() => {
            if (!sessionStorage.getItem("loggedIn")) {
              return "Login First";
            }

            switch (plan.name) {
              case "Monthly":
                return <RazorpayButton />;
              case "Semester":
                return <RazorpayButtonSem />;
              case "Yearly":
                return <RazorpayButtonYrly />;
              case "Enterprise":
                return "Contact Us";
              default:
                return "Select Plan";
            }
          })()}
        </button>
      </div>
    </motion.div>
  );
};

const SubscriptionSection: React.FC<{ className?: string }> = ({
  className = "",
}) => {
  const [selectedPlan, setSelectedPlan] = useState<number | null>(null);
  const [couponCode, setCouponCode] = useState("");
  const [couponMessage, setCouponMessage] = useState("");
  const validCoupons = ["LAWTUS", "LAWGUL", "LAWTAN", "LAWGOV", "LAWANA"];

  const handleApplyCoupon = () => {
    if (validCoupons.includes(couponCode.trim().toUpperCase())) {
      sessionStorage.setItem("appliedCoupon", couponCode);
      window.location.href = "/couponCheckout";
      setCouponMessage("Coupon applied successfully!");
    } else {
      setCouponMessage("Invalid coupon code.");
    }

    setTimeout(() => setCouponMessage(""), 5000);
  };

  return (
    <section className={`mb-4 ${className}`}>
      <div className="container mx-auto px-4">
        {/* Header */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
          viewport={{ once: true }}
          className="text-center mb-16"
        >
          <div className="inline-flex items-center justify-center p-2 bg-purple-50 rounded-full mb-4">
            <Lock className="w-5 h-5 text-purple-600 mr-2" />
            <span className="text-sm font-medium text-purple-600">
              Secure Payment
            </span>
          </div>

          <h2 className="text-4xl font-bold mb-4 bg-gradient-to-r from-purple-600 to-blue-600 bg-clip-text text-transparent">
            Choose Your Plan
          </h2>
          <p className="text-lg text-gray-600 max-w-2xl mx-auto">
            Get 10 free cases on signup. Subscribe for unlimited access and
            premium features.
          </p>
        </motion.div>

        {/* Pricing Cards */}
        <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-8 max-w-7xl mx-auto">
          {pricings.map((plan, index) => (
            <PricingCard
              key={index}
              plan={plan}
              isSelected={selectedPlan === index}
              onSelect={() => setSelectedPlan(index)}
            />
          ))}
        </div>

        {/* Trust Indicators */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6, delay: 0.6 }}
          viewport={{ once: true }}
          className="p-4 text-center"
        >
          <div className="flex justify-center items-center font-semibold text-xl space-x-8">
            <div className="flex items-center">
              <Lock className="w-5 h-5 text-black mr-2" />
              <span className="text-black">Secure Payment</span>
            </div>
            <div className="flex items-center">
              <Zap className="w-5 h-5 text-black mr-2" />
              <span className="text-black">Instant Access</span>
            </div>
          </div>
        </motion.div>

        {/* Coupon Section */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6, delay: 0.3 }}
          viewport={{ once: true }}
          className="p-4 max-w-md mx-auto"
        >
          <div className="bg-white rounded-2xl p-6 shadow-lg">
            <div className="flex items-center mb-4">
              <Gift className="w-5 h-5 text-purple-600 mr-2" />
              <h3 className="text-lg font-semibold">Have a Coupon?</h3>
            </div>

            <div className="space-y-4">
              <div className="relative">
                <input
                  type="text"
                  placeholder="Enter coupon code"
                  value={couponCode}
                  onChange={(e) => setCouponCode(e.target.value)}
                  className="w-full px-4 py-3 rounded-lg border border-gray-200 focus:ring-2 focus:ring-purple-500 focus:border-transparent transition-all duration-300"
                />
                <button
                  onClick={handleApplyCoupon}
                  className="absolute right-2 top-1/2 transform -translate-y-1/2 px-4 py-1.5 bg-gradient-to-r from-purple-600 to-blue-600 text-white rounded-lg font-medium hover:shadow-lg transition-all duration-300"
                >
                  Apply
                </button>
              </div>

              {couponMessage && (
                <motion.p
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  className={`text-sm ${
                    couponMessage.includes("Invalid")
                      ? "text-red-500"
                      : "text-green-500"
                  }`}
                >
                  {couponMessage}
                </motion.p>
              )}
            </div>
          </div>
        </motion.div>
      </div>
    </section>
  );
};

export default SubscriptionSection;
