import { GridHelper } from "three";

export const EMPTYCHAR = '-'; // Define your EMPTYCHAR value
export const GRID_HEIGHT = 10; // Example grid height
const GRID_WIDTH = 10; // Example grid width
const FIT_ATTEMPTS = 10; // Example fit attempts
let wordArray = [
  { word: 'EXAMPLE', displayWord: 'Example', clue: 'This is an example' },
  {word:'TESTING',displayWord: 'TestingExample', clue: 'This is an example'},
  {word:"APPLE",displayWord: 'TestingExample', clue: 'This is an example'},
  {word:"BALL",displayWord: 'TestingExample', clue: 'This is an example'},
  {word:"SHOT",displayWord: 'TestingExample', clue: 'This is an example'},
  {word:"LAWYER",displayWord: 'TestingExample', clue: 'This is an example'},
  {word:"RAMU",displayWord: 'TestingExample', clue: 'This is an example'},
  
];

let GRID = Array(GRID_HEIGHT);
for(let i = 0; i < GRID_HEIGHT; i++){
  GRID[i] = Array(GRID_WIDTH).fill(EMPTYCHAR);
}

var finalList :Test[];

interface Coord {
  x: number;
  y: number;
  score: number;
  vertical: boolean;
}

export interface Word {
  word: string;
  displayWord: string;
  clue: string;
}

interface GridCell {
  targetChar: string;
  indexDisplay: string;
  value: string;
}

export interface Test{
  vertical:Boolean;
  row:number;
  col:number;
  answer:string;
  clue:string;
}


class Board {
  cols: number;
  rows: number;
  activeWordList: any[]; // Define the appropriate type
  acrossCount: number;
  downCount: number;
  grid: GridCell[][];
  coordList: Coord[];
  coordCount: number;
  finalList: Test[];

  constructor(cols: number, rows: number) {
    this.cols = cols;
    this.rows = rows;
    this.activeWordList = [];
    this.acrossCount = 0;
    this.downCount = 0;
    this.grid = new Array(cols);
    this.finalList = [];

    for (let i = 0; i < rows; i++) {
      this.grid[i] = new Array(rows);
    }

    for (let x = 0; x < cols; x++) {
      for (let y = 0; y < rows; y++) {
        this.grid[x][y] = {
          targetChar: EMPTYCHAR,
          indexDisplay: '',
          value: '-',
        };
      }
    }

    this.coordList = [];
    this.coordCount = 0;
  }

  suggestCoords(word: string) {
    let c = '';
    this.coordCount = 0;
    for (let i = 0; i < word.length; i++) {
      for (let x = 0; x < GRID_HEIGHT; x++) { //Iterating over Rows WISE
        for (let y = 0; y < GRID_WIDTH; y++) { 
          c = word[i];
          if (this.grid[x][y].targetChar === c ) {
            // //console.log("THis is the grid target",this.grid[x][y].targetChar)
            // //console.log(c);
            // //console.log("COndition true");
            // //console.log(word);
            //E X A M P L E - - -
            //- - - - - - - - - -
            // //console.log(x,y);
            if (x - i + 1 > 0 && x - i + word.length - 1 < GRID_HEIGHT) {
              this.coordList[this.coordCount] = { x: x - i, y, score: 0, vertical: true };
              this.coordCount++;
            }

            if (y - i + 1 > 0 && y - i + word.length - 1 < GRID_WIDTH) {
              this.coordList[this.coordCount] = { x, y: y - i, score: 0, vertical: false };
              this.coordCount++;
            }
          }
        }
      }
    }
    //console.log("Suggest coord finished");
  }

  checkFitScore(word: string, x: number, y: number, vertical: boolean): number {
    let fitScore = 1;

    if (vertical) {
      for (let i = 0; i < word.length; i++) {
        if (i === 0 && x > 0) {
          if (this.grid[x - 1][y].targetChar !== EMPTYCHAR) {
            fitScore = 0;
            break;
          }
        } else if (i === word.length && x < GRID_HEIGHT) {
          if (this.grid[x + i + 1][y].targetChar !== EMPTYCHAR) {
            fitScore = 0;
            break;
          }
        }
        if (x + i < GRID_HEIGHT) {
          if (this.grid[x + i][y].targetChar === word[i]) {
            fitScore += 1;
          } else if (this.grid[x + i][y].targetChar !== EMPTYCHAR) {
            fitScore = 0;
            break;
          } else {
            if (y < GRID_WIDTH - 1) {
              if (this.grid[x + i][y + 1].targetChar !== EMPTYCHAR) {
                fitScore = 0;
                break;
              }
            }
            if (y > 0) {
              if (this.grid[x + i][y - 1].targetChar !== EMPTYCHAR) {
                fitScore = 0;
                break;
              }
            }
          }
        }
      }
    } else {
      for (let i = 0; i < word.length; i++) {
        if (i === 0 && y > 0) {
          if (this.grid[x][y - 1].targetChar !== EMPTYCHAR) {
            fitScore = 0;
            break;
          }
        } else if (i === word.length - 1 && y + i < GRID_WIDTH - 1) {
          if (this.grid[x][y + i + 1].targetChar !== EMPTYCHAR) {
            fitScore = 0;
            break;
          }
        }
        if (y + i < GRID_WIDTH) {
          if (this.grid[x][y + i].targetChar === word[i]) {
            fitScore += 1;
          } else if (this.grid[x][y + i].targetChar !== EMPTYCHAR) {
            fitScore = 0;
            break;
          } else {
            if (x < GRID_HEIGHT) {
              if (this.grid[x + 1][y + i].targetChar !== EMPTYCHAR) {
                fitScore = 0;
                break;
              }
            }
            if (x > 0) {
              if (this.grid[x - 1][y + i].targetChar !== EMPTYCHAR) {
                fitScore = 0;
                break;
              }
            }
          }
        }
      }
    }

    return fitScore;
  }

  placeWord(word: string, clue: string, x: number, y: number, vertical: boolean): boolean {
    let wordPlaced = false;

    if (vertical) {
      if (word.length + x < GRID_HEIGHT) {
        for (let i = 0; i < word.length; i++) {
          this.grid[x + i][y].targetChar = word[i];
        }
        wordPlaced = true;
        // this.finalList.push({vertical:true,row:y,col:x,answer:word,clue:clue});
      }
    } else {
      if (word.length + y < GRID_WIDTH) {
        for (let i = 0; i < word.length; i++) {
          this.grid[x][y + i].targetChar = word[i];
        }
        wordPlaced = true;
      }
    }

    if (wordPlaced) {
      const currentIndex = this.activeWordList.length;
      this.activeWordList[currentIndex] = { word, clue, x, y, vertical };

      if (this.activeWordList[currentIndex].vertical) {
        this.downCount++;
        this.activeWordList[currentIndex].number = this.downCount;
      } else {
        this.acrossCount++;
        this.activeWordList[currentIndex].number = this.acrossCount;
      }
    }

    return wordPlaced;
  }

  isActiveWord(word: string): boolean {
    if (this.activeWordList.length > 0) {
      for (const activeWord of this.activeWordList) {
        if (word === activeWord.word) {
          return true;
        }
      }
    }
    return false;
  }

  displayGrid() {
    let rowStr = "";
    for (let x = 0; x < this.cols; x++) {
      for (let y = 0; y < this.rows; y++) {
        rowStr += `<td>${this.grid[x][y].targetChar}</td>`;
      }
      document.getElementById('tempTable')!.innerHTML += `<tr>${rowStr}</tr>`;
      rowStr = "";
    }
    //console.log('across', this.acrossCount);
    //console.log('down', this.downCount);
  }

  generateBoard(seed = 0) {
    let bestScoreIndex = 0;
    let topScore = 0;
    let fitScore = 0;

    this.placeWord(wordArray[seed].word,  wordArray[seed].clue, 0, 0, false);
    

    for (let iy = 0; iy < FIT_ATTEMPTS; iy++) {
      for (let ix = 1; ix < wordArray.length; ix++) {
        if (!this.isActiveWord(wordArray[ix].word)) {
          topScore = 0;
          bestScoreIndex = 0;

          this.suggestCoords(wordArray[ix].word);
          this.coordList = this.shuffleArray(this.coordList);
          //console.log("coordList:");
          //console.log(this.coordList);
          if (this.coordList[0]) {
            for (let c = 0; c < this.coordList.length; c++) {
              fitScore = this.checkFitScore(wordArray[ix].word, this.coordList[c].x, this.coordList[c].y, this.coordList[c].vertical);
              if (fitScore > topScore) {
                topScore = fitScore;
                bestScoreIndex = c;
              }
            }
          }

          if (topScore > 1) {
            //console.log("Tops SCore greater than 1");
            this.placeWord(wordArray[ix].word, wordArray[ix].clue, this.coordList[bestScoreIndex].x, this.coordList[bestScoreIndex].y, this.coordList[bestScoreIndex].vertical);
          }
        }
      }
    }
    if (this.activeWordList.length < wordArray.length / 2) {
      seed++;
      //console.log("AGAIN generateBoard");
      this.generateBoard(seed);
    }
  }

  shuffleArray(array: any[]): any[] {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  }
}

function seedBoard() {
  const gameboard = new Board(GRID_WIDTH, GRID_HEIGHT);
  gameboard.generateBoard();
  //console.log(gameboard.coordList);
  //console.log(gameboard.grid);
  let st="";
  for(let i =0 ;i<GRID_HEIGHT ;i++) {
    st ="";
    for(let j=0 ; j<GRID_WIDTH ; j++){
      st= st+ gameboard.grid[i][j].targetChar;
    }
    //console.log(st);
  }
//   gameboard.displayGrid();
}
// export default seedBoard;
function populateGrid(i:number,j:number,word:string,vertical:Boolean,erase:Boolean){
  // if(erase){
  //   if(vertical){
  //     for(let k=0;k<word.length;k++){
  //       if(GRID[i+k][j]!=''){
  //         GRID[i+k][j]='';
  //       }
  //     }
  //   }
  //   else{
  //     for(let k=0;k<word.length;k++){
  //       if(GRID[i][j+k]!=''){
  //         GRID[i][j+k]='';
  //       }
  //     }
  //   }
  //   return;
  // }
  if(vertical){
    for(let k=0;k<word.length;k++){
      GRID[i+k][j]=word[k];
    }
  }
  else{
    for(let k=0;k<word.length;k++){
      GRID[i][j+k]=word[k];
    }
  }
}
function checkPossibility(i:number,j:number,word:string,vertical:Boolean){
  if(vertical){
    if(i+word.length>GRID_HEIGHT){
      return false;
    }
    for(let k=0;k<word.length;k++){
      if(GRID[i+k][j]!=word[k]){
        if(GRID[i+k][j]!=EMPTYCHAR){
          return false;
        }
      }
    }
    populateGrid(i,j,word,vertical,false);
    return true;

  }
  else{
    if(j+word.length - 1 > GRID_WIDTH - 1){
      return false;
    }
    for(let k=0;k<word.length;k++){
      if(GRID[i][j+k]!=word[k]){
        if(GRID[i][j+k]!=EMPTYCHAR){
          return false;
        }
      }
    }
    populateGrid(i,j,word,vertical,false);
    return true;

  }
}
function recurse(index=0,vertical=false):Boolean{
  if(index>=wordArray.length){
    return true;
  }
  //console.log("Recurse index",index);
  //console.log(finalList);
  // //console.log("RECURSE",index,vertical);
  let word= wordArray[index];
  for(let i=0;i<GRID_HEIGHT;i++){
    for(let j=0;j<GRID_WIDTH;j++){
      // if(GRID[i][j]==''){
        if(checkPossibility(i,j,word.word,vertical)){
          // //console.log("CEHCK POSSIBILITY 1");
          if(recurse(index+1,!vertical)){
            finalList.push({answer:word.word,clue:word.clue,row:i,col:j,vertical:vertical})
            return true;
          }
          else if(recurse(index+1,vertical)){
            finalList.push({answer:word.word,clue:word.clue,row:i,col:j,vertical:vertical})
            return true;
          }
        }
        if(checkPossibility(i,j,word.word,!vertical)){
          // //console.log("CHECK POSSIBILITY 2");
          if(recurse(index+1,vertical)){
            finalList.push({answer:word.word,clue:word.clue,row:i,col:j,vertical:!vertical})
            return true;
          }
          else if(recurse(index+1, !vertical)){
            finalList.push({answer:word.word,clue:word.clue,row:i,col:j,vertical:!vertical})
            return true;
          }
        }
      // }
      // else if(GRID[i][j]===)
    }
  }
return false;
}
function myLogic(data:Word[]){
  wordArray = data;
  //console.log("WOrdarray in logic",wordArray);
  finalList=[];
  recurse();
  // //console.log(finalList);
  // let st="";
  // for(let i=0;i<GRID.length;i++){
  //   st ="";
  //   for(let j=0;j<GRID[0].length;j++){
  //     st= st+ GRID[i][j];
  //   }
  //   //console.log(st);
  // }
  //console.log("Before returnging",finalList);
  // exportGrid = grid;
  return [finalList,GRID];
}
export default myLogic;

// const GRID_HEIGHT = 10 ;
// const GRID_WIDTH=10;
// function board(cols:number, rows:number) { //instantiator object for making gameboards
//     // this.cols = cols;
//     // this.rows = rows;
//     var activeWordList:string[] = []; //keeps array of words actually placed in board
//     var acrossCount = 0;
//     var downCount = 0;
    
//     var grid = new Array(cols); //create 2 dimensional array for letter grid
//     for (var i = 0; i < rows; i++) {
//         grid[i] = new Array(rows);
//     }
    
//     for (var x = 0; x < cols; x++) {
//         for (var y = 0; y < rows; y++) {
//             grid[x][y] = {};
//             grid[x][y].targetChar = EMPTYCHAR; //target character, hidden
//             grid[x][y].indexDisplay = ''; //used to display index number of word start
//             grid[x][y].value = '-'; //actual current letter shown on board
//         }
//     }
    
//     function suggestCoords(word:string) { //search for potential cross placement locations
//         var c = '';
//         coordList:[]{x:number,y:Number,score:number,vertical:Boolean} = [];
//         coordCount = 0;
//         for (i = 0; i < word.length; i++) { //cycle through each character of the word
//             for (x = 0; x < GRID_HEIGHT; x++) {
//                 for (y = 0; y < GRID_WIDTH; y++) {
//                     c = word[i];
//                     if (grid[x][y].targetChar == c) { //check for letter match in cell
//                         if (x - i + 1> 0 && x - i + word.length-1 < GRID_HEIGHT) { //would fit vertically?
//                             coordList[coordCount] = {};
//                             coordList[coordCount].x = x - i;
//                             coordList[coordCount].y = y;
//                             coordList[coordCount].score = 0;
//                             coordList[coordCount].vertical = true;
//                             coordCount++;
//                         }
    
//                         if (y - i + 1 > 0 && y - i + word.length-1 < GRID_WIDTH) { //would fit horizontally?
//                             coordList[coordCount] = {};
//                             coordList[coordCount].x = x;
//                             coordList[coordCount].y = y - i;
//                             coordList[coordCount].score = 0;
//                             coordList[coordCount].vertical = false;
//                             coordCount++;
//                         }
//                     }
//                 }
//             }
//         }
//     }
    
//     function checkFitScore(word, x, y, vertical) {
//         var fitScore = 1; //default is 1, 2+ has crosses, 0 is invalid due to collision
    
//         if (vertical) { //vertical checking
//             for (i = 0; i < word.length; i++) {
//                 if (i == 0 && x > 0) { //check for empty space preceeding first character of word if not on edge
//                     if (grid[x - 1][y].targetChar != EMPTYCHAR) { //adjacent letter collision
//                         fitScore = 0;
//                         break;
//                     }
//                 } else if (i == word.length && x < GRID_HEIGHT) { //check for empty space after last character of word if not on edge
//                      if (grid[x+i+1][y].targetChar != EMPTYCHAR) { //adjacent letter collision
//                         fitScore = 0;
//                         break;
//                     }
//                 }
//                 if (x + i < GRID_HEIGHT) {
//                     if (grid[x + i][y].targetChar == word[i]) { //letter match - aka cross point
//                         fitScore += 1;
//                     } else if (grid[x + i][y].targetChar != EMPTYCHAR) { //letter doesn't match and it isn't empty so there is a collision
//                         fitScore = 0;
//                         break;
//                     } else { //verify that there aren't letters on either side of placement if it isn't a crosspoint
//                         if (y < GRID_WIDTH - 1) { //check right side if it isn't on the edge
//                             if (grid[x + i][y + 1].targetChar != EMPTYCHAR) { //adjacent letter collision
//                                 fitScore = 0;
//                                 break;
//                             }
//                         }
//                         if (y > 0) { //check left side if it isn't on the edge
//                             if (grid[x + i][y - 1].targetChar != EMPTYCHAR) { //adjacent letter collision
//                                 fitScore = 0;
//                                 break;
//                             }
//                         }
//                     }
//                 }
    
//             }
    
//         } else { //horizontal checking
//             for (i = 0; i < word.length; i++) {
//                 if (i == 0 && y > 0) { //check for empty space preceeding first character of word if not on edge
//                     if (grid[x][y-1].targetChar != EMPTYCHAR) { //adjacent letter collision
//                         fitScore = 0;
//                         break;
//                     }
//                 } else if (i == word.length - 1 && y + i < GRID_WIDTH -1) { //check for empty space after last character of word if not on edge
//                     if (grid[x][y + i + 1].targetChar != EMPTYCHAR) { //adjacent letter collision
//                         fitScore = 0;
//                         break;
//                     }
//                 }
//                 if (y + i < GRID_WIDTH) {
//                     if (grid[x][y + i].targetChar == word[i]) { //letter match - aka cross point
//                         fitScore += 1;
//                     } else if (grid[x][y + i].targetChar != EMPTYCHAR) { //letter doesn't match and it isn't empty so there is a collision
//                         fitScore = 0;
//                         break;
//                     } else { //verify that there aren't letters on either side of placement if it isn't a crosspoint
//                         if (x < GRID_HEIGHT) { //check top side if it isn't on the edge
//                             if (grid[x + 1][y + i].targetChar != EMPTYCHAR) { //adjacent letter collision
//                                 fitScore = 0;
//                                 break;
//                             }
//                         }
//                         if (x > 0) { //check bottom side if it isn't on the edge
//                             if (grid[x - 1][y + i].targetChar != EMPTYCHAR) { //adjacent letter collision
//                                 fitScore = 0;
//                                 break;
//                             }
//                         }
//                     }
//                 }
    
//             }
//         }
    
//         return fitScore;
//     }
    
//     function placeWord(word, clue, x, y, vertical) { //places a new active word on the board
    
//         var wordPlaced = false;
    
//         if (vertical) {
//             if (word.length + x < GRID_HEIGHT) {
//                 for (i = 0; i < word.length; i++) {
//                     grid[x + i][y].targetChar = word[i];
//                 }
//                 wordPlaced = true;
//             }
//         } else {
//             if (word.length + y < GRID_WIDTH) {
//                 for (i = 0; i < word.length; i++) {
//                     grid[x][y + i].targetChar = word[i];
//                 }
//                 wordPlaced = true;
//             }
//         }
    
//         if (wordPlaced) {
//             var currentIndex = activeWordList.length;
//             activeWordList[currentIndex] = {};
//             activeWordList[currentIndex].word = word;
//             activeWordList[currentIndex].clue = clue;
//             activeWordList[currentIndex].x = x;
//             activeWordList[currentIndex].y = y;
//             activeWordList[currentIndex].vertical = vertical;
    
//             if (activeWordList[currentIndex].vertical) {
//                 downCount++;
//                 activeWordList[currentIndex].number = downCount;
//             } else {
//                 acrossCount++;
//                 activeWordList[currentIndex].number = acrossCount;
//             }
//         }
    
//     }
    
//     function isActiveWord(word) {
//         if (activeWordList.length > 0) {
//             for (var w = 0; w < activeWordList.length; w++) {
//                 if (word == activeWordList[w].word) {
//                     ////console.log(word + ' in activeWordList');
//                     return true;
//                 }
//             }
//         }
//         return false;
//     }
    
//     this.displayGrid = function displayGrid() {
    
//         var rowStr = "";
//         for (var x = 0; x < cols; x++) {
    
//             for (var y = 0; y < rows; y++) {
//                 rowStr += "<td>" + grid[x][y].targetChar + "</td>";
//             }
//             $('#tempTable').append("<tr>" + rowStr + "</tr>");
//             rowStr = "";
    
//         }
//         //console.log('across ' + acrossCount);
//         //console.log('down ' + downCount);
//     }
    
//     //for each word in the source array we test where it can fit on the board and then test those locations for validity against other already placed words
//     this.generateBoard = function generateBoard(seed = 0) {
    
//         var bestScoreIndex = 0;
//         var top = 0;
//         var fitScore = 0;
//         var startTime;
    
//         //manually place the longest word horizontally at 0,0, try others if the generated board is too weak
//         placeWord(wordArray[seed].word, wordArray[seed].displayWord, wordArray[seed].clue, 0, 0, false);
    
//         //attempt to fill the rest of the board 
//         for (var iy = 0; iy < FIT_ATTEMPTS; iy++) { //usually 2 times is enough for max fill potential
//             for (var ix = 1; ix < wordArray.length; ix++) {
//                 if (!isActiveWord(wordArray[ix].word)) { //only add if not already in the active word list
//                     topScore = 0;
//                     bestScoreIndex = 0;
    
//                     suggestCoords(wordArray[ix].word); //fills coordList and coordCount
//                     coordList = shuffleArray(coordList); //adds some randomization
    
//                     if (coordList[0]) {
//                         for (c = 0; c < coordList.length; c++) { //get the best fit score from the list of possible valid coordinates
//                             fitScore = checkFitScore(wordArray[ix].word, coordList[c].x, coordList[c].y, coordList[c].vertical);
//                             if (fitScore > topScore) {
//                                 topScore = fitScore;
//                                 bestScoreIndex = c;
//                             }
//                         }
//                     }
    
//                     if (topScore > 1) { //only place a word if it has a fitscore of 2 or higher
    
//                         placeWord(wordArray[ix].word, wordArray[ix].clue, coordList[bestScoreIndex].x, coordList[bestScoreIndex].y, coordList[bestScoreIndex].vertical);
//                     }
//                 }
    
//             }
//         }
//         if(activeWordList.length < wordArray.length/2) { //regenerate board if if less than half the words were placed
//             seed++;
//             generateBoard(seed);
//         }
//     }
//     }
//     function seedBoard() {
//         gameboard = new board(GRID_WIDTH, GRID_HEIGHT);
//         gameboard.generateBoard();
//         gameboard.displayGrid();
//     }