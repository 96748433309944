// src/components/AllLobbiesPopover.tsx
import React, { useState, useEffect } from 'react';
import { Popover, Transition } from '@headlessui/react';
import Badge from 'shared/Badge/Badge';
import JoinLobbyPopover from './JoinLobbyPopover';
import TeamSelectPopover from './TeamSelectPopover';

interface Lobby {
    _id: string;
    courtName: string;
}

interface AllLobbiesProps {
    lobbies: Lobby[];
    onClose: () => void;
}

const AllLobbiesPopover: React.FC<AllLobbiesProps> = ({ lobbies, onClose }) => {
    const [isMakeLobbyOpen, setisMakeLobbyOpen] = useState(false);
    const [isJoinLobbyOpen, setisJoinLobbyOpen] = useState(false);
    const [openTeamSelect, setOpenTeamSelect] = useState(false);
    const [enteredLobby, setEnteredLobby] = useState('');
    const [lobbyName, setLobbyName] = useState('');
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredLobbies, setFilteredLobbies] = useState<Lobby[]>(lobbies);

    useEffect(() => {
        setFilteredLobbies(
            lobbies.filter((lobby) =>
                lobby.courtName.toLowerCase().includes(searchTerm.toLowerCase())
            )
        );
    }, [searchTerm, lobbies]);

    const handleJoinLobbyClose = () => {
        setisMakeLobbyOpen(false);
        setisJoinLobbyOpen(false);
        setLobbyName('');
    };

    const handleJoinLobbyOpen = () => {
        setisJoinLobbyOpen(true);
    };

    const handleJoinLobby = (name: string) => {

        if (sessionStorage.getItem('email')) {
            setLobbyName(name);
            handleJoinLobbyOpen();
        } else {
            window.location.href = "/login"
        }
    };

    const handleTeamSelectClose = () => {
        setOpenTeamSelect(false);
    };

    return (
        <Popover>
            <Transition
                show={true}
                enter="transition duration-100 ease-out"
                enterFrom="transform scale-95 opacity-0"
                enterTo="transform scale-100 opacity-100"
                leave="transition duration-75 ease-out"
                leaveFrom="transform scale-100 opacity-100"
                leaveTo="transform scale-95 opacity-0"
            >
                <Popover.Panel static className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
                    {isJoinLobbyOpen && (
                        <JoinLobbyPopover
                            name={lobbyName}
                            onClose={handleJoinLobbyClose}
                            setEnteredLobby={setEnteredLobby}
                            setOpenTeamSelect={setOpenTeamSelect}
                        />
                    )}

                    {openTeamSelect && (
                        <TeamSelectPopover onClose={handleTeamSelectClose} hostedCourtName={enteredLobby} />
                    )}

                    <div className="lg:w-1/3 p-6 bg-white rounded-lg shadow-lg">
                        <div className="flex justify-between items-center mb-4">
                            <h2 className="text-2xl font-semibold text-gray-800">Active Lobbies</h2>
                            <Badge name="Live" />
                        </div>

                        <input
                            type="text"
                            placeholder="Search lobbies..."
                            className="w-full p-2 mb-4 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                        />

                        <div className="mb-6 max-h-80 overflow-y-auto">
                            {filteredLobbies.length > 0 ? (
                                <ul>
                                    {filteredLobbies.map((lobby) => (
                                        <li
                                            key={lobby._id}
                                            className="flex justify-between items-center py-3 px-4 bg-gray-50 hover:bg-gray-100 rounded mb-2 transition duration-300 ease-in-out"
                                        >
                                            <div>
                                                <h2 className="text-lg font-medium text-gray-700">{lobby.courtName}</h2>
                                                <p className="text-sm text-gray-500">Password Protected</p>
                                            </div>
                                            <button
                                                onClick={() => handleJoinLobby(lobby.courtName)}
                                                className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition duration-300"
                                            >
                                                Join
                                            </button>
                                        </li>
                                    ))}
                                </ul>
                            ) : (
                                <div className="text-center text-gray-500 py-10">No lobbies found.</div>
                            )}
                        </div>

                        <div className="mt-4 flex justify-end">
                            <button
                                className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-700"
                                onClick={onClose}
                            >
                                Close
                            </button>
                        </div>
                    </div>
                </Popover.Panel>
            </Transition>
        </Popover>
    );
};

export default AllLobbiesPopover;
