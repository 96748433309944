import React, { FC } from "react";

import SectionGridFilterCard from "containers/ListingStayPage/SectionGridFilterCard";

import SectionVideos from "containers/PageHome/SectionVideos";

const MootCourt: FC<{}> = () => {
    return(
        <div className="bg-creamyYellow mx-auto">
             <SectionGridFilterCard className="pb-24 lg:pb-28" />
        </div>
        
    )
}

export default function MootCourtPage() {
    return (
        <MootCourt />
    );
  }