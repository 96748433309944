import React from "react";
import { motion } from "framer-motion";
import {
  Shield,
  Clock,
  DollarSign,
  Brain,
  Settings,
  CalendarCheck,
} from "lucide-react";

const HostWithUsSection = () => {
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2,
      },
    },
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5,
      },
    },
  };

  const benefits = [
    {
      icon: <Shield className="w-8 h-8 text-purple-600" />,
      title: "Full Admin Control",
      description:
        "Get complete administrative access to manage your competition with ease",
    },
    {
      icon: <CalendarCheck className="w-8 h-8 text-purple-600" />,
      title: "Automated Scheduling",
      description:
        "We handle all court scheduling, saving you time and reducing complexity",
    },
    {
      icon: <Settings className="w-8 h-8 text-purple-600" />,
      title: "Easy Management",
      description:
        "Intuitive interface for managing participants, rounds, and submissions",
    },
    {
      icon: <Brain className="w-8 h-8 text-purple-600" />,
      title: "AI-Powered Judging",
      description:
        "Advanced AI judges provide consistent, unbiased evaluation and detailed reports",
    },
    {
      icon: <Clock className="w-8 h-8 text-purple-600" />,
      title: "Save Time",
      description:
        "Automated processes reduce administrative overhead significantly",
    },
    {
      icon: <DollarSign className="w-8 h-8 text-purple-600" />,
      title: "Cost Effective",
      description:
        "Eliminate expenses for external judges and reduce operational costs",
    },
  ];

  return (
    <div className="max-w-4xl mx-auto mb-12">
      <motion.div
        initial="hidden"
        animate="visible"
        variants={containerVariants}
        className="text-center mb-12"
      >
        <motion.h2
          className="text-3xl font-bold mb-4 bg-gradient-to-r from-purple-600 to-blue-600 bg-clip-text text-transparent"
          variants={itemVariants}
        >
          Why Host Your Moot Court With Us?
        </motion.h2>
        <motion.p
          className="text-lg text-gray-600 max-w-2xl mx-auto"
          variants={itemVariants}
        >
          Transform your moot court competition with our cutting-edge platform
          that combines AI technology with seamless management tools.
        </motion.p>
      </motion.div>

      <motion.div
        variants={containerVariants}
        initial="hidden"
        animate="visible"
        className="grid md:grid-cols-3 gap-6"
      >
        {benefits.map((benefit, index) => (
          <motion.div
            key={index}
            variants={itemVariants}
            className="bg-white p-6 rounded-xl shadow-lg hover:shadow-xl transition-shadow duration-300"
          >
            <div className="flex flex-col items-center text-center space-y-4">
              <div className="p-3 bg-purple-50 rounded-full">
                {benefit.icon}
              </div>
              <h3 className="text-xl font-semibold text-gray-800">
                {benefit.title}
              </h3>
              <p className="text-gray-600">{benefit.description}</p>
            </div>
          </motion.div>
        ))}
      </motion.div>
    </div>
  );
};

export default HostWithUsSection;
