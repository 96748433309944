// src/utils/schema.ts
interface SchemaData {
  "@context": string;
  "@type": string;
  [key: string]: any;
}

export const generateOrganizationSchema = (): SchemaData => ({
  "@context": "https://schema.org",
  "@type": "Organization",
  name: "AI Moot Court",
  url: "https://aimootcourt.com",
  logo: "https://aimootcourt.com/logo.png",
  description:
    "AI-powered moot court platform for legal education and training",
});

export const generateHomePageSchema = (): SchemaData => ({
  "@context": "https://schema.org",
  "@type": "WebSite",
  name: "AI Moot Court",
  url: "https://aimootcourt.com",
  potentialAction: {
    "@type": "SearchAction",
    target: "https://aimootcourt.com/search?q={search_term_string}",
    "query-input": "required name=search_term_string",
  },
});

export const generatePracticePage = (): SchemaData => ({
  "@context": "https://schema.org",
  "@type": "WebSite",
  name: "AI Moot Court",
  url: "https://aimootcourt.com",
  potentialAction: {
    "@type": "SearchAction",
    target: "https://aimootcourt.com/mootcourt?q={search_term_string}",
    "query-input": "required name=search_term_string",
  },
});

export const generateMootCourtSchema = (): SchemaData => ({
  "@context": "https://schema.org",
  "@type": "EducationalEvent",
  name: "AI Moot Court Competition",
  description:
    "Virtual moot court competition powered by artificial intelligence",
  eventStatus: "https://schema.org/EventScheduled",
  eventAttendanceMode: "https://schema.org/OnlineEventAttendanceMode",
  location: {
    "@type": "VirtualLocation",
    url: "https://aimootcourt.com/mootCourt",
  },
  offers: {
    "@type": "Offer",
    availability: "https://schema.org/InStock",
    price: "0",
    priceCurrency: "USD",
  },
});

export const generateBlogSchema = (post: any): SchemaData => ({
  "@context": "https://schema.org",
  "@type": "BlogPosting",
  headline: post.title,
  image: post.image,
  author: {
    "@type": "Organization",
    name: "AI Moot Court",
  },
  publisher: {
    "@type": "Organization",
    name: "AI Moot Court",
    logo: {
      "@type": "ImageObject",
      url: "https://aimootcourt.com/logo.png",
    },
  },
  datePublished: post.publishDate,
  dateModified: post.modifiedDate,
  description: post.description,
});
