import React, { FC } from "react";

import NewNavigation from "containers/PageHome/NewNavigation";

export interface HeaderProps {
  navType?: "MainNav1" | "MainNav2";
  className?: string;
}

const Header: FC<HeaderProps> = ({ navType = "MainNav1", className = "" }) => {

  return (
    <div
      className={`sticky top-0 w-full left-0 right-0 z-40 nc-header-bg bg-creamyYellow border border-none ${className}`}
    >
      {/* <MainNav1 /> */}
      <NewNavigation />
    </div>
  );
};

export default Header;
