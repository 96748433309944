import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { apiUri } from '../../constants';
import Alert from 'shared/Alert/Alert';
import PreMadeCasePopover from './SelectPreMadeCasePopover';
import { off } from 'process';

interface CreateLobbyPageProps {
  className?: string;
  onClose: () => void;
  setOpenTeamSelect: React.Dispatch<React.SetStateAction<boolean>>;
  setEnteredLobby: React.Dispatch<React.SetStateAction<string>>;
};

const CreateLobbyPage: React.FC<CreateLobbyPageProps> = ({ onClose, setOpenTeamSelect, setEnteredLobby, className = "" }) => {
  const [lobbyName, setLobbyName] = useState('');
  const [password, setPassword] = useState('');
  const [isLobbyNameUnique, setIsLobbyNameUnique] = useState(true);
  const [isCustomCase, setIsCustomCase] = useState(false);
  const [thingsToKnow, setThingsToKnow] = useState('');
  const [caseDetails, setCaseDetails] = useState('');
  const [caseFile, setCaseFile] = useState<File | null>(null);
  const [error, setError] = useState('');
  const [isPremadeOpen, setisPremadeOpen] = useState(false)
  const [isNothingSelected, setIsNothingSelected] = useState(false)
  const [isError, setIsError] = useState(false)

  const checkLobbyNameUnique = async () => {
    try {
      // const response = await axios.post(`${apiUri}/checkMootCourtName`, { hostedCourtName: lobbyName });
      // setIsLobbyNameUnique(response.data.isUnique);
      setIsLobbyNameUnique(true);
    } catch (err) {
      console.error('Error checking lobby name uniqueness:', err);
    }
  };

  const handlePopoverClose = () => {
    setisPremadeOpen(false)
    if (sessionStorage.getItem("MootcourtSelectLobby") == "" || sessionStorage.getItem("MootcourtSelectLobby") == null) {
      setIsNothingSelected(true)
    }
  }

  const handleSelectOurCase = () => {
    setIsCustomCase(false)
    setIsNothingSelected(false)
    setisPremadeOpen(true)
  }
  const handleCreateLobby = async () => {

      if (!sessionStorage.getItem("email")) {
        window.location.href = "/login"
        return
      }
    
    if (!lobbyName) {
      setError('Lobby Name is required');
      setIsError(true)
      return;
    }

    

    if (!isLobbyNameUnique) {
      setError('Lobby Name is already taken');
      setIsError(true)
      return;
    }
    

    if (isCustomCase === false) {
      const r = sessionStorage.getItem("MootcourtSelectLobby") 
  
      if (r === "" || r === null) {
    
        setError('No case was selected from preMade case.')
        setIsError(true)
      }
      
    }
// alert("jo")
    let temp = "false"
    if (isCustomCase) {
      temp = "true"
    }

    let formData = new FormData();
    formData.append('hostedCourtName', lobbyName);
    formData.append('password', password);
    formData.append('customeCase', temp)

    if (isCustomCase && caseFile) {
      //console.log("ISCustomcase true, caseFile is true");
      formData.append('thingsToKnow', thingsToKnow);
      formData.append('caseDetails', caseDetails);
      formData.append('caseFile', caseFile);
    }

    try {
      //console.log("THis is the form data create lobby page");
      //console.log(formData);
      // for (let [key, value] of formData.entries()) {
      //   console.log(`${key}: ${value}`);
      // }
      // console.log(formData)
      // console.log("hooooooooooooooo")
      const response = await axios.post(`${apiUri}/hostMootCourt`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      // console.log(response.data)

      if (response.data.success) {
        setOpenTeamSelect(true);
        onClose();

      }
      else {
        // //console.log("Error on creating lobby");
        // //console.log(response.data.message);
        setError(response.data.data);
        console.error(response.data.data);
      }

    } catch (err) {
      setError('Error creating lobby');
      console.error(err);
    }
  };

  useEffect(() => {
    if (isError) {
      const timer = setTimeout(() => setIsError(false), 2000);
      return () => clearTimeout(timer);
    }
  }, [isError]);

  const handleJoinLobby = async () => {
    if (!lobbyName) {
      setError('Lobby Name is required');
      return;
    }

    if (!isLobbyNameUnique) {
      setError('Lobby Name is already taken');
      return;
    }
    // //console.log("BEFORE SENDING TO JOIN ")
    // //console.log(lobbyName, password);

    try {
      const response = await axios.post(`${apiUri}/joinMootCourt`, { hostedCourtName: lobbyName, password: password });
      if (response.status === 200) {
        setOpenTeamSelect(true);
        onClose();
      }
      else {
        //console.log("Error on joining lobby");
        //console.log(response.data.message);
      }
    }
    catch (err) {
      setError('Error joining lobby');
      console.error(err);
    }


  };

  useEffect(() => {
    if (lobbyName) {
      checkLobbyNameUnique();
    }
  }, [lobbyName]);

  return (
    <div className={`container mx-auto my-12 p-6 bg-creamyYellow dark:bg-gray-800 rounded-lg shadow-md ${className}`}>
      {isPremadeOpen && <PreMadeCasePopover onClose={handlePopoverClose} />}
      {isNothingSelected && <Alert children="No case was selected from the pre made. Kindly try again." type="error" />}
      <h2 className="text-3xl font-bold mb-6 text-neutral-900 dark:text-neutral-100">
        {isCustomCase ? 'Create Custom Case Lobby' : 'Create Lobby'}
      </h2>

      <div className="mb-4">
        <label className="block text-xl font-medium text-neutral-900 dark:text-neutral-100">
          Lobby Name
        </label>
        <span className='text-sm'>*Must be UNIQUE</span>
        <input
          type="text"
          className={`mt-1 p-2 w-full border rounded-md focus:ring ${isLobbyNameUnique ? 'border-gray-300' : 'border-red-500'}`}
          value={lobbyName}
          onChange={(e) => {
            setLobbyName(e.target.value)
            setEnteredLobby(e.target.value)
          }}
          required
        />
        {!isLobbyNameUnique && (
          <p className="text-red-500 text-sm mt-1">Lobby Name is already taken</p>
        )}
      </div>
      <div className="mb-4">
        <label className="block text-xl font-medium text-neutral-900 dark:text-neutral-100">
          Case Type
        </label>
        <div className="flex space-x-4 mt-2">
          <button
            className={`px-4 py-2 rounded-md font-semibold ${!isCustomCase
              ? 'bg-blue-500 text-white'
              : 'bg-gray-300 text-gray-700 opacity-50'
              }`}
            onClick={handleSelectOurCase}
          >
            Select Our Case
          </button>
          {/* <button
            className={`px-4 py-2 rounded-md font-semibold ${isCustomCase
              ? 'bg-blue-500 text-white'
              : 'bg-gray-300 text-gray-700 opacity-50'
              }`}
            onClick={() => setIsCustomCase(true)}
          >
            Enter Custom Case
          </button> */}
        </div>

        {isCustomCase ? (
          <div className="mt-4">
            <label className="block text-lg font-medium text-neutral-900 dark:text-neutral-100">
              Things to Know
            </label>
            <textarea
              className="mt-1 p-2 w-full border rounded-md focus:ring border-gray-300"
              rows={4}
              placeholder="Enter important sections, case laws, etc."
              onChange={(e) => setThingsToKnow(e.target.value)}
              required
            ></textarea>

            <label className="block mt-4 text-lg font-medium text-neutral-900 dark:text-neutral-100">
              Case Description 
            </label>
            <textarea
              className="mt-1 p-2 w-full border rounded-md focus:ring border-gray-300"
              rows={6}
              placeholder="Enter the details of the case"
              onChange={(e) => setCaseDetails(e.target.value)}
              required
            ></textarea>

            <div className="mt-4">
              <label className="block text-lg font-medium text-neutral-900 dark:text-neutral-100">
                Upload Full Case PDF
              </label>
              <input
                type="file"
                accept="application/pdf"
                className="mt-1 p-2 w-full border rounded-md focus:ring border-gray-300"
                onChange={(e) => setCaseFile(e.target.files == null ? null : e.target.files[0])}
                required
              />
            </div>
          </div>
        ) : (
          <div className="mt-4">
            <p className="text-sm text-gray-700">
              You can select from predefined cases by clicking the above button.
            </p>
          </div>
        )}
      </div>
      <div className="mb-4">
        <label className="block text-xl font-medium text-neutral-900 dark:text-neutral-100">
          Password
        </label>
        <input
          type="password"
          className="mt-1 p-2 w-full border rounded-md focus:ring border-gray-300"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
      </div>

      {error && (
        <Alert children={error} type='error' />
      )}

      <div className="flex justify-end">
        <button
          onClick={() => {
            handleCreateLobby();
          }}
          className={`${isNothingSelected ? "disabled:cursor-not-allowed" : ""} bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-700 mr-2`}

        >
          Create Lobby
        </button>
        <button
          onClick={onClose}
          className="bg-gray-300 text-gray-700 px-4 py-2 rounded-md hover:bg-gray-400"
        >
          Cancel
        </button>
      </div>
    </div>
  );
};

// function GetLobbyPage() {
//   return (
//     <CreateLobbyPage heading="Create Court" onClose={() => { }} setEnteredLobby={() => { }} setOpenTeamSelect={() => { }} makeLobby={false} />
//   )

// }
export default CreateLobbyPage;
