import React, { Children, useEffect } from "react";
import Alert from "shared/Alert/Alert";

const RazorpayButtonSem: React.FC = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://checkout.razorpay.com/v1/payment-button.js";
    script.setAttribute("data-payment_button_id", "pl_PEgqRoGzSjeE90");
    script.async = true;

    const form = document.createElement("form");
    form.appendChild(script);
    document.getElementById("razorpay-button-container")?.appendChild(form);

    return () => {
      if (script) {
        script.remove();
      }
    };
  }, []);

  return <div id="razorpay-button-container"></div>;
};

export default RazorpayButtonSem;
