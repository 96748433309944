import React, { useState } from "react";
import { motion } from "framer-motion";
import {
  Mail,
  Phone,
  MapPin,
  Send,
  MessageCircle,
  Clock,
  ExternalLink,
} from "lucide-react";

// Define type for color keys to restrict to valid color strings
type ColorType = "purple" | "blue" | "amber";

interface ContactMethod {
  icon: React.ReactNode;
  title: string;
  description: string;
  value: string;
  link?: string;
  color: ColorType;
  action?: string;
}

// Define styles with strict keys of type ColorType
const colorStyles: Record<
  ColorType,
  { bg: string; text: string; border: string; hoverBg: string; shadow: string }
> = {
  purple: {
    bg: "bg-purple-50",
    text: "text-purple-600",
    border: "border-purple-200",
    hoverBg: "hover:bg-purple-100",
    shadow: "shadow-purple-200/50",
  },
  blue: {
    bg: "bg-blue-50",
    text: "text-blue-600",
    border: "border-blue-200",
    hoverBg: "hover:bg-blue-100",
    shadow: "shadow-blue-200/50",
  },
  amber: {
    bg: "bg-amber-50",
    text: "text-amber-600",
    border: "border-amber-200",
    hoverBg: "hover:bg-amber-100",
    shadow: "shadow-amber-200/50",
  },
};

const contactMethods: ContactMethod[] = [
  {
    icon: <Mail className="w-6 h-6" />,
    title: "Email Us",
    description: "Get in touch via email",
    value: "adyityasingh@gmail.com",
    link: "mailto:adyityasingh@gmail.com",
    color: "purple",
    action: "Send Email",
  },
  {
    icon: <Phone className="w-6 h-6" />,
    title: "Call Us",
    description: "We're here to help",
    value: "+91 7017158278 / 8383990480",
    link: "tel:+917017158278",
    color: "blue",
    action: "Call Now",
  },
  {
    icon: <MapPin className="w-6 h-6" />,
    title: "Visit Us",
    description: "Our office location",
    value: "SPD Plaza, Koramangala, Bengaluru, Karnataka 560034",
    link: "https://maps.google.com",
    color: "amber",
    action: "Get Directions",
  },
];

const ContactCard: React.FC<{ method: ContactMethod; index: number }> = ({
  method,
  index,
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const colors = colorStyles[method.color];

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      whileInView={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5, delay: index * 0.1 }}
      viewport={{ once: true }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      className="relative group"
    >
      <div
        className={`p-6 rounded-2xl min-h-[300px] border ${colors.bg} ${
          colors.border
        } ${colors.shadow} transition-transform duration-300 ${
          isHovered ? "shadow-xl -translate-y-1" : "shadow-md"
        }`}
      >
        <div
          className={`p-3 rounded-xl w-fit mb-4 ${colors.bg} ${colors.text} ${
            isHovered ? "scale-110" : ""
          } transition-transform duration-300`}
        >
          {method.icon}
        </div>

        <h3 className="text-xl font-semibold text-black mb-2">
          {method.title}
        </h3>
        <p className="text-black mb-4">{method.description}</p>
        <p className="text-gray-800 font-medium break-words">{method.value}</p>

        {method.link && (
          <a
            href={method.link}
            target="_blank"
            rel="noopener noreferrer"
            aria-label={method.action}
            className={`mt-6 inline-flex items-center px-4 py-2 rounded-full border ${colors.text} ${colors.hoverBg} transition-all duration-300 text-sm font-medium`}
          >
            {method.action}
            <ExternalLink className="w-4 h-4 ml-2" />
          </a>
        )}
      </div>
    </motion.div>
  );
};

const ContactSection: React.FC = () => {
  return (
    <section className="py-5">
      <div className="container mx-auto px-4">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
          viewport={{ once: true }}
          className="text-center mb-16"
        >
          <div className="inline-flex items-center justify-center p-2 bg-purple-50 rounded-full mb-4">
            <MessageCircle className="w-5 h-5 text-purple-600 mr-2" />
            <span className="text-sm font-medium text-purple-600">
              24/7 Support
            </span>
          </div>

          <h2 className="text-4xl font-bold mb-4 bg-gradient-to-r from-purple-600 to-blue-600 bg-clip-text text-transparent">
            Get in Touch
          </h2>
          <p className="text-lg text-black max-w-2xl mx-auto">
            Have questions? We're here to help. Contact us through any of the
            following methods.
          </p>
        </motion.div>

        <div className="grid md:grid-cols-3 gap-8 max-w-6xl mx-auto">
          {contactMethods.map((method, index) => (
            <ContactCard key={index} method={method} index={index} />
          ))}
        </div>

        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6, delay: 0.3 }}
          viewport={{ once: true }}
          className="mt-16 grid grid-cols-1 md:grid-cols-2 gap-8 max-w-4xl mx-auto"
        >
          <div className="flex items-center space-x-4 p-6 rounded-xl bg-gray-100 text-lg">
            <Clock className="w-8 h-8 text-black" />
            <div>
              <h4 className="font-semibold text-black">Business Hours</h4>
              <p className="text-black">
                Monday - Friday: 9:00 AM - 6:00 PM IST
              </p>
            </div>
          </div>

          <div className="flex items-center space-x-4 p-6 rounded-xl bg-purple-100 text-lg">
            <Send className="w-8 h-8 text-purple-600" />
            <div>
              <h4 className="font-semibold text-black">Quick Response</h4>
              <p className="text-black">We typically respond within 2 hours</p>
            </div>
          </div>
        </motion.div>
      </div>
    </section>
  );
};

export default ContactSection;
