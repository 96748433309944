import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import axios from 'axios';
import { apiUri, modelUri } from '../../constants';
import ButtonPrimary from 'shared/Button/ButtonPrimary';
import { P2PAnalyticsProps } from 'routers/types';
import { useLocation } from 'react-router-dom';
import LoadingIcon from 'components/Loading/LoadingIcon';


const P2PAnalytics: React.FC<P2PAnalyticsProps> = ({
  user1_similarity,
  user1_vocab_overlap,
  user1_sen_struct,
  user1_theme_sim,
  user1_overall_coh,
  user1_score_range,
  user2_similarity,
  user2_vocab_overlap,
  user2_sen_struct,
  user2_theme_sim,
  user2_overall_coh,
  user2_score_range,
  decision,
  idealConvo
}) => {
  const getScoreColor = (score: number) => {
    if (score >= 80) return 'text-green-600';
    if (score >= 50) return 'text-yellow-600';
    return 'text-red-600';
  };

  const handleExit = () => {
    sessionStorage.removeItem("fullUserConversation")
    sessionStorage.removeItem("fullFriendConversation")
    sessionStorage.removeItem("messages")
    window.location.href = '/feedback'
  }

  const containerVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0 },
  };

  const itemVariants = {
    hidden: { opacity: 0, x: -50 },
    visible: { opacity: 1, x: 0 },
  };

  return (
    <div className='text-center container justify-center'>
      <motion.div
        className="max-w-6xl mx-auto p-6 bg-creamyYellow shadow-lg rounded-lg border border-black border-3"
        initial="hidden"
        animate="visible"
        variants={containerVariants}
        transition={{ duration: 0.5, ease: 'easeOut' }}
      >
        <div className="mb-4">
          <h3 className="text-xl font-semibold text-gray-800">User 1 Comparison</h3>
          <motion.div
            className="mt-2 text-gray-600"
            variants={itemVariants}
            transition={{ delay: 0.2 }}
          >
            <p>{user1_similarity}</p>
          </motion.div>
        </div>
        <div className="mb-4">
          <h3 className="text-xl font-semibold text-gray-800">User 2 Comparison</h3>
          <motion.div
            className="mt-2 text-gray-600"
            variants={itemVariants}
            transition={{ delay: 0.3 }}
          >
            <p>{user2_similarity}</p>
          </motion.div>
        </div>
        <div className="mb-4">
          <h3 className="text-xl font-semibold text-gray-800">Ideal Conversation</h3>
          <motion.div
            className="mt-2 text-gray-600"
            variants={itemVariants}
            transition={{ delay: 0.2 }}
          >
            <p>{idealConvo}</p>
          </motion.div>
        </div>
        <div className="mb-4">
          <h3 className="text-xl font-semibold text-gray-800">User 1 Score Breakdown</h3>
          <motion.ul
            className="mt-2 list-disc list-inside text-gray-600"
            variants={itemVariants}
            initial="hidden"
            animate="visible"
            transition={{ delay: 0.4 }}
          >
            <li>Vocabulary Overlap: {user1_vocab_overlap}</li>
            <li>Sentence Structure: {user1_sen_struct}</li>
            <li>Theme Similarity: {user1_theme_sim}</li>
            <li>Overall Coherence: {user1_overall_coh}</li>
            <li>Score Range: {user1_score_range}</li>
          </motion.ul>
        </div>
        <div className="mb-4">
          <h3 className="text-xl font-semibold text-gray-800">User 2 Score Breakdown</h3>
          <motion.ul
            className="mt-2 list-disc list-inside text-gray-600"
            variants={itemVariants}
            initial="hidden"
            animate="visible"
            transition={{ delay: 0.5 }}
          >
            <li>Vocabulary Overlap: {user2_vocab_overlap}</li>
            <li>Sentence Structure: {user2_sen_struct}</li>
            <li>Theme Similarity: {user2_theme_sim}</li>
            <li>Overall Coherence: {user2_overall_coh}</li>
            <li>Score Range: {user2_score_range}</li>
          </motion.ul>
        </div>
        <div className="mb-4">
          <h3 className="text-xl font-semibold text-gray-800">Decision</h3>
          <motion.div
            className="mt-2 text-gray-600"
            variants={itemVariants}
            transition={{ delay: 0.6 }}
          >
            <p>{decision}</p>
          </motion.div>
        </div>
      </motion.div>
      <div className='p-6'>
        <ButtonPrimary className="text-center p-6 justify-center bg-red-400 hover:bg-red-600" onClick={handleExit}> Exit </ButtonPrimary>
      </div>
    </div>
  );
};


const P2PAnalyticsWrapper = () => {
  const location = useLocation();
  const state = location.state as P2PAnalyticsProps;

  if (!state) {
    return <div>No data available</div>;
  }

  return <P2PAnalytics {...state} />;
};

export default P2PAnalyticsWrapper;

// export default P2PAnalytics;
