// src/components/AllUpcomingCourtsPopover.tsx
import React, { useState, useEffect } from 'react';
import { Popover, Transition } from '@headlessui/react';

interface MootCourt {
    _id:string,
     caseTitle: string,
    collegeName: string,
    time: string,
    dateOfStart: string,
    dateOfEnd: string,
    teamSize: string,
}

interface AllUpcomingCourtsProps {
    UpcomingCourts: MootCourt[];
    onClose: () => void;
}

const AllUpcomingCourtsPopover: React.FC<AllUpcomingCourtsProps> = ({ UpcomingCourts, onClose }) => {
    const [isMakeLobbyOpen, setisMakeLobbyOpen] = useState(false);
    const [isJoinLobbyOpen, setisJoinLobbyOpen] = useState(false);
    const [openTeamSelect, setOpenTeamSelect] = useState(false);
    const [enteredLobby, setEnteredLobby] = useState('');
    const [lobbyName, setLobbyName] = useState('');
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredUpcomingCourts, setFilteredUpcomingCourts] = useState<MootCourt[]>(UpcomingCourts);

    useEffect(() => {
        setFilteredUpcomingCourts(
            UpcomingCourts.filter((lobby) =>
                lobby.caseTitle.toLowerCase().includes(searchTerm.toLowerCase())
            )
        );
    }, [searchTerm, UpcomingCourts]);

    const handleUserRegister = () => {
            
        if (!sessionStorage.getItem("email")) {
            window.location.href = "/login"
        }
        else {
            window.location.href = "/userRegisterMoot"
        }
    }
    

    const handleJoinLobbyOpen = () => {
        setisJoinLobbyOpen(true);
    };

    return (
        <Popover>
            <Transition
                show={true}
                enter="transition duration-100 ease-out"
                enterFrom="transform scale-95 opacity-0"
                enterTo="transform scale-100 opacity-100"
                leave="transition duration-75 ease-out"
                leaveFrom="transform scale-100 opacity-100"
                leaveTo="transform scale-95 opacity-0"
            >
                <Popover.Panel static className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
                   
                    <div className="lg:w-1/3 p-6 bg-white rounded-lg shadow-lg">
                        <div className="flex justify-between items-center mb-4">
                            <h2 className="text-2xl font-semibold text-gray-800">Upcoming Courts</h2>
                        
                        </div>

                        <input
                            type="text"
                            placeholder="Search UpcomingCourts..."
                            className="w-full p-2 mb-4 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                        />

                        <div>
                            <ul className="divide-y divide-gray-200">
                                {filteredUpcomingCourts.length > 0 ? (
                                    filteredUpcomingCourts.slice(0, 5).map((court, index) => (
                                        <li key={court._id} className="py-4 flex justify-between items-center">
                                            <div>
                                                <span className="text-lg font-medium text-gray-700">
                                                    {index + 1}. {court.caseTitle}
                                                </span>
                                                <p className="text-sm text-gray-500">
                                                    Host: {court.collegeName} | Date: {court.dateOfStart} | Duration: {court.time}
                                                </p>
                                            </div>
                                            <button
                                                onClick={handleUserRegister}
                                                className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition duration-300"
                                            >
                                                Register
                                            </button>
                                        </li>
                                    ))
                                ) : (
                                    <div className="text-center text-gray-500 py-10">
                                        No upcoming moot courts available.
                                    </div>
                                )}
                            </ul>
                        
                        </div>

                        <div className="mt-4 flex justify-end">
                            <button
                                className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-700"
                                onClick={onClose}
                            >
                                Close
                            </button>
                        </div>
                    </div>
                </Popover.Panel>
            </Transition>
        </Popover>
    );
};

export default AllUpcomingCourtsPopover;
