import { useState, useRef, useEffect } from 'react';
import io, { Socket } from 'socket.io-client';
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { apiUri } from '../../constants';
import * as mediasoupClient from 'mediasoup-client';
import crypto from 'crypto';
import { IceCandidate, IceParameters } from 'mediasoup-client/lib/types';



const VideoConference: React.FC<{}> = () => {

    // let socket: Socket;
    // if (socket.connected) {
    //     console.log("Socket connected", socket.id);
    // }
    // else {
    //     console.log("Socket not connected");
    // }
    // socket.on('connection-success', (data) => {
    //     startCamera();
    // });
    const [socket, setSocket] = useState<Socket>();
    const [device, setDevice] = useState<mediasoupClient.Device | null>(null);
    const [roomId, setRoomId] = useState('');
    const [tempRoomId, setTempRoomId] = useState('');
    const videoRef = useRef<HTMLVideoElement>(null);
    const remoteVideoRef = useRef<HTMLVideoElement>(null);
    const [rtpCapabilities, setRtpCapabilities] = useState<any>(null); // RTP Capabilities for the device

    const [createProducerTransportCalled, setCreateProducerTransportCalled] = useState(false);

    const [connectProducerTransportCalled, setConnectProducerTransportCalled] = useState(false);

    const [createConsumerTransportCalled, setCreateConsumerTransportCalled] = useState(false);

    const [connectConsumerTransportCalled, setConnectConsumerTransportCalled] = useState(false);


    const [producerTransport, setProducerTransport] = useState<mediasoupClient.types.Transport | null>(
        null
    ); // Transport for sending media
    const [consumerTransport, setConsumerTransport] = useState<mediasoupClient.types.Transport<mediasoupClient.types.AppData>>();

    // let serverProducerId: string;
    const [serverProducerId, setServerProducerId] = useState('');
    let localConsumer: mediasoupClient.types.Consumer<mediasoupClient.types.AppData> | undefined;

    const [params, setParams] = useState({
        encoding: [
            { rid: "r0", maxBitrate: 100000, scalabilityMode: "S1T3" }, // Lowest quality layer
            { rid: "r1", maxBitrate: 300000, scalabilityMode: "S1T3" }, // Middle quality layer
            { rid: "r2", maxBitrate: 900000, scalabilityMode: "S1T3" }, // Highest quality layer
        ],
        codecOptions: { videoGoogleStartBitrate: 1000 }, // Initial bitrate
    });

    useEffect(() => {
        console.log("This is the serverPRoducer ID: ", serverProducerId);
    }, [serverProducerId])
    useEffect(() => {
        if (createConsumerTransportCalled) {
            connectRecvTransport();
        }
    }, [createConsumerTransportCalled]);

    useEffect(() => {
        if (connectProducerTransportCalled) {
            createRecvTransport();
        }
    }, [connectProducerTransportCalled])

    useEffect(() => {
        if (createProducerTransportCalled) {
            connectSendTransport();
        }
    }, [createProducerTransportCalled])

    useEffect(() => {
        console.log("THIS IS TEH NEW PRODUCER TRANSPORT", producerTransport);
    }, [producerTransport]);

    useEffect(() => {
        console.log("THIS IS THE NEW COSUMER TRANSPORT", consumerTransport);
    }, [consumerTransport]);

    useEffect(() => {
        let tempSocket = io(`${apiUri}`);

        let timer = setInterval(() => {
            console.log("Set Interval running...");
            if (tempSocket.connected) {
                console.log("Socket connected", tempSocket.id);
                setSocket(tempSocket);
                startCamera();
                clearInterval(timer);
            }
            else {
                console.log("Socket not connected");
            }
        }, 2000)

        return () => {
            if (socket)
                socket.disconnect();
        }
    }, []);
    useEffect(() => {
        if (socket == null || socket == undefined) {
            console.log("SOcket is null");
        }
        else {
            console.log("This is the socket", socket.id);
        }
    }, [socket])


    const startCamera = async () => {
        try {
            console.log("Start camera function called");
            // Check if getUserMedia is available
            if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
                throw new Error("Camera API not supported by this browser.");
            }
            const stream = await navigator.mediaDevices.getUserMedia({ video: true });
            if (videoRef.current) {
                const track = stream.getVideoTracks()[0];
                videoRef.current.srcObject = stream;
                setParams((current) => ({ ...current, track }));
            }
        } catch (error) {
            console.error("Error accessing camera:", error);
        }
    };

    const handleCreateRoom = () => {
        console.log("Handlecreate Room called");
        const randomRoomId = Math.random().toString(36).slice(2, 10);
        // Generate a random alphanumeric string of length 8

        setRoomId(randomRoomId);
        setTempRoomId(randomRoomId);
        // socket.emit('createRoom', roomId, (response: any) => {
        //     if (response.status === 'ok') {
        //         console.log(`Room created with ID: ${response.roomId}`);
        //     }
        //     else {
        //         console.log(`Error: ${response.message}`);
        //     }
        // });
    }

    const handleJoinRoom = () => {
        if (socket == undefined) {
            console.log("Socket undefined");
            return;
        }
        setRoomId(tempRoomId);
        // socket.emit('joinRoom', roomId, (response: any) => {
        //     if (response.status === 'ok') {
        //         console.log(`Joined room with ID: ${response.roomId}`);
        //     }
        //     else {
        //         console.log(`Error: ${response.message}`);
        //     }
        // });
    }

    const startStreamForJoin = () => {
        setConnectProducerTransportCalled(true);
    }

    useEffect(() => {
        console.log('Room id changed');
        if (roomId === undefined || roomId === null || roomId === '') {
            return;
        }
        if (socket == undefined) {
            console.log("Socket is undefined");
            return;
        }
        console.log('starting joinRoom emit');
        socket.emit('joinRoom', roomId, ({ status, roomId }: { status: string, roomId: string }) => {
            console.log("JoinRoom socket called ", roomId);
            if (status === 'ok') {
                console.log(`Joined room ${roomId}`);
                // Proceed with media setup (transport creation, producing, etc.)
                socket.emit('getRtpCapabilities', roomId, async (rtpCapabilities: mediasoupClient.types.RtpCapabilities) => {
                    console.log("FRONTEND: getRtpCapabilities callback called with parameters: ", rtpCapabilities);
                    setRtpCapabilities(rtpCapabilities);
                    let tempDevice = new mediasoupClient.Device();
                    await tempDevice.load({ routerRtpCapabilities: rtpCapabilities });
                    setDevice(tempDevice);
                    console.log("DEvice set");
                    // create transports and produce streams
                });
            }
        });

        return () => {
            socket.disconnect();
        };
    }, [roomId]);

    const startStreaming = async () => {
        if (!device) return;

        // Create Producer Transport
        if (socket == undefined) {
            console.log("Socket is undefined");
            return;
        }
        socket.emit('createProducerTransport', roomId, async (transportParams: {
            id: string,
            iceParameters: IceParameters,
            iceCandidates: mediasoupClient.types.IceCandidate[],
            dtlsParameters: mediasoupClient.types.DtlsParameters
        }) => {
            console.log("FRONTEND: Callback of createProducerTransport called with parameters: ", transportParams);
            // console.log(transportParams);
            const tempProducerTransport = device.createSendTransport(transportParams);
            setProducerTransport(tempProducerTransport);
            console.log("FRONTEND: producerTransport created", tempProducerTransport);
            tempProducerTransport.on('connect', ({ dtlsParameters }, callback, errback) => {
                try {
                    console.log("FRONTEND: ProducerTransport connect event detected")
                    console.log("----------> producer transport has connected");
                    socket.emit('connectProducerTransport', { dtlsParameters }, roomId, tempProducerTransport.id);
                    callback();
                }
                catch (err) {
                    // if (err) {
                    console.log(err);
                    errback(Error('Unknow error'));
                    // }
                    // else
                    // errback(err);
                }
            });

            tempProducerTransport.on('produce',
                async ({ kind, rtpParameters }, callback, errback) => {
                    console.log("Handling produce....")
                    try {
                        socket.emit('produceTransport', { roomId, transportId: tempProducerTransport.id, kind, rtpParameters }, ({ id }: any) => {
                            // Callback to provide the server-generated producer ID back to the transport
                            callback({ id });
                            setServerProducerId(id);
                            // serverProducerId = id;
                            console.log("THIS is the server-generated producerId without useState", id);
                            console.log("THIS is the server-generated producerId", serverProducerId);
                        });
                    }
                    catch (err) {
                        console.error(err);
                        errback(new Error('Failed to produce'));
                    }
                });


            setCreateProducerTransportCalled(true);

            // Get User Media
            // const stream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
            // const videoTrack = stream.getVideoTracks()[0];
            // const audioTrack = stream.getAudioTracks()[0];

            // await producerTransport?.produce({ track: videoTrack });
            // await producerTransport?.produce({ track: audioTrack });

            // if (videoRef.current) {
            //     videoRef.current.srcObject = stream;
            // }
        });





        // setTimeout(() => {
        //     createRecvTransport();
        //     setTimeout(() => {
        //         connectRecvTransport();

        //     }, 2000);
        // }, 3000);

    };

    const connectSendTransport = async () => {
        //connectSendTransport 
        let localProducer = await producerTransport?.produce(params);
        console.log("LOCAL PRODUCER is generated successfully");
        localProducer?.on('trackended', () => {
            console.log('track ended');
        });

        localProducer?.on('transportclose', () => {
            console.log('transport closed');
        });

        setConnectProducerTransportCalled(true);
    }

    const createRecvTransport = async () => {
        socket?.emit('createConsumerTransport', roomId, ({ paramsTemp }: { paramsTemp: any }) => {
            console.log(paramsTemp);
            if (paramsTemp.error) {
                console.log(paramsTemp.error);
                return;
            }

            let tempConsumerTransport = device?.createRecvTransport(paramsTemp);
            console.log("Consumer Transport created with Id:  ", tempConsumerTransport?.id);
            setConsumerTransport(tempConsumerTransport);

            tempConsumerTransport?.on('connect', async ({ dtlsParameters }: any, callback: any, errback: any) => {
                console.log("Callback of createConsumerTransport called");

                try {
                    console.log("BEFORE Sending connectConsumerTransport event");
                    await socket.emit('connectConsumerTransport', { dtlsParameters }, roomId, paramsTemp.id);
                    console.log("----------> consumer transport has connected");
                    callback();

                }
                catch (err) {
                    console.log(err);
                    errback(new Error('Unknow error'));
                }
            });
            setCreateConsumerTransportCalled(true);
        })
    };
    const connectRecvTransport = async () => {

        console.log("COnnectRecvTransport called");
        console.log(serverProducerId, consumerTransport?.id);
        await socket?.emit("consumeMedia", { rtpCapabilities: device?.rtpCapabilities }, roomId, serverProducerId, consumerTransport?.id, async ({ params }: any) => {
            if (params.error) {
                console.log(params.error);
                return;
            }

            // Consuming media using the receive transport
            let consumer = await consumerTransport?.consume({
                id: params.id,
                producerId: params.producerId,
                kind: params.kind,
                rtpParameters: params.rtpParameters
            })
            // consumer?.id

            // setLocalConsumer(consumer);
            localConsumer = consumer;

            // Accessing the media track from the consumer
            if (consumer) {
                const { track } = consumer;

                console.log("************** track", track);
                // console.log('Started Consuming Media');
                // Attaching the media track to the remote video element for playback
                console.log("THis is the track", track);
                if (remoteVideoRef.current) {
                    remoteVideoRef.current.srcObject = new MediaStream([track]);
                    console.log("REmote vide is not null");
                }
            }
            else {
                console.error("NO Consumer.....");
            }





            // Notifying the server to resume media consumption
            socket.emit("resumePausedConsumer", { roomId, params });
            console.log("----------> consumer transport has resumed");
        })
    };
    return (
        <div className="flex justify-center items-center h-screen bg-gray-900">
            <div className="flex flex-col items-center w-full p-10 z-10">
                <h1 className="text-3xl text-white font-bold">Video Conference</h1>
                <p className="text-sm text-gray-400">Join a video call with your team or colleagues.</p>
                <div>
                    <video ref={videoRef} autoPlay playsInline ></video>
                    <video ref={remoteVideoRef} id="remotevideo" autoPlay playsInline />
                </div>
                <ButtonPrimary onClick={startStreaming}>
                    Start Streaming
                </ButtonPrimary>
                <ButtonPrimary onClick={startStreamForJoin}
                > Consume Stream </ButtonPrimary>
                {/* Video Conference Button */}
                <ButtonPrimary
                    className="my-5"
                    onClick={handleCreateRoom}
                >
                    Create Conference
                </ButtonPrimary>
                <input
                    className='my-4 w-[15rem]'
                    placeholder='Enter Room ID'
                    type="text"
                    value={tempRoomId}
                    onChange={(e) => setTempRoomId(e.target.value)}
                />
                <ButtonPrimary
                    className=""
                    onClick={handleJoinRoom}
                >Join Video Conference</ButtonPrimary>
            </div>
        </div>
    );

}
export default VideoConference;