import React, { Children, useEffect } from "react";
import Alert from "shared/Alert/Alert";
import axios from "axios";
import { apiUri } from "../../constants";

const DiscountedRazorPayButton: React.FC = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://checkout.razorpay.com/v1/payment-button.js";
    script.setAttribute("data-payment_button_id", "pl_P0j7hR8RlzYqPu");
    script.async = true;

    const form = document.createElement("form");
    form.appendChild(script);
    document.getElementById("razorpay-button-container")?.appendChild(form);

    return () => {
      if (script) {
        script.remove();
      }
    };
  }, []);

  useEffect(() => {
    const addProvider = async () => {
      try {
        const response = await axios.post(`${apiUri}/addCouponProvider`, {
          couponCode: sessionStorage.getItem("appliedCoupon"),
        });

        console.log(response.data.data);
      } catch (error) {
        console.error("Error adding provider:", error);
      }
    };
    addProvider();
  }, []);

  return <div id="razorpay-button-container"></div>;
};

export default DiscountedRazorPayButton;
