import React, { useEffect, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import {
  X,
  Users,
  AlertCircle,
  Check,
  ChevronRight,
  User,
  Trophy,
} from "lucide-react";
import axios from "axios";
import { apiUri } from "../../constants";

interface TeamRegisterPopoverProps {
  onClose: () => void;
  teamSize: number;
}

interface FormErrors {
  teamName: string;
  members: string[];
  captain: string;
}

const TeamRegisterPopover: React.FC<TeamRegisterPopoverProps> = ({
  onClose,
  teamSize,
}) => {
  const [teamName, setTeamName] = useState("");
  const [captain, setCaptain] = useState("");
  const [members, setMembers] = useState<string[]>(
    new Array(teamSize).fill("")
  );
  const [errors, setErrors] = useState<FormErrors>({
    teamName: "",
    members: new Array(teamSize).fill(""),
    captain: "",
  });
  const [isMemberReg, setIsMemberReg] = useState<boolean[]>(
    new Array(teamSize).fill(false)
  );
  const [allGood, setAllGood] = useState(false);
  const [isErr, setIsErr] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
const checkTeamNameUniqueness = async (name: string) => {
  try {
    const response = await axios.post(`${apiUri}/isTeamNameUniq`, {
      mootCourtId: sessionStorage.getItem("FetchMootCourtId"),
      teamName: name,
    });
    if (!response.data.success) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        teamName: "Team name is already taken",
      }));
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        teamName: "",
      }));
    }
  } catch (error) {
    //console.log(error)
  }
};

const handleTeamNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  const value = e.target.value;
  setTeamName(value);
  checkTeamNameUniqueness(value);
};

const handleMemberChange = async (index: number, value: string) => {
  const newMembers = [...members];
  newMembers[index] = value;
  setMembers(newMembers);

  if (!emailRegex.test(value)) {
    const newErrors = { ...errors };
    newErrors.members[index] = "Invalid email address";
    setErrors(newErrors);
    return;
  } else if (newMembers.filter((email) => email === value).length > 1) {
    const newErrors = { ...errors };
    newErrors.members[index] = "Duplicate email address within the team";
    setErrors(newErrors);
    return;
  } else {
    const newErrors = { ...errors };
    newErrors.members[index] = "";
    setErrors(newErrors);
  }

  try {
    const isUserInPlatform = await axios.post(`${apiUri}/userExist`, {
      email: value,
    });

    if (isUserInPlatform.data.success) {
      const fetchRegResponse = await axios.post(`${apiUri}/fetchIsUserReg`, {
        mootCourtId: sessionStorage.getItem("FetchMootCourtId"),
        member: value,
      });

      const newIsMemberReg = [...isMemberReg];
      newIsMemberReg[index] = fetchRegResponse.data.success;
      setIsMemberReg(newIsMemberReg);

      if (fetchRegResponse.data.success) {
        const newErrors = { ...errors };
        newErrors.members[index] =
          "User is already registered for this moot court";
        setErrors(newErrors);
      }
    } else {
      const newIsMemberReg = [...isMemberReg];
      newIsMemberReg[index] = false;
      setIsMemberReg(newIsMemberReg);

      const newErrors = { ...errors };
      newErrors.members[index] =
        "User is not registered on the platform. Kindly Sign Up.";
      setErrors(newErrors);
    }
  } catch (error) {
    console.error("Error checking user registration:", error);
    const newErrors = { ...errors };
    // newErrors.members[index] = "Error checking registration status";
    setErrors(newErrors);
  }
};

const handleSubmit = async (e: React.FormEvent) => {
  e.preventDefault();

  if (!members.includes(captain)) {
    setErrors((prevErrors) => ({
      ...prevErrors,
      captain: "The captain must be one of the team members",
    }));
    return;
  }

  try {
    const result = await axios.post(`${apiUri}/addUserToMoot`, {
      teamName,
      captain,
      members,
      mootCourtId: sessionStorage.getItem("FetchMootCourtId"),
    });

    if (result.data.success) {
      try {
        const resp = await axios.post(`${apiUri}/addToIdle,`, {
          idealTeams: [teamName],
        });

        if (resp.data.success) {
          setAllGood(true);

          setTimeout(() => {
            onClose();
          }, 8000);
        }
      } catch (error) {
        setIsErr(true);
      }
    }
  } catch (error) {
    setIsErr(true);
  }
};

useEffect(() => {
  if (isErr) {
    const timer = setTimeout(() => setIsErr(false), 3000);
    return () => clearTimeout(timer);
  }
}, [isErr]);


  // Previous validation functions remain the same

  const formVariants = {
    hidden: { opacity: 0, x: -50 },
    visible: { opacity: 1, x: 0, transition: { duration: 0.3 } },
    exit: { opacity: 0, x: 50, transition: { duration: 0.3 } },
  };

  const overlayVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1, transition: { duration: 0.3 } },
  };

  const StatusMessage: React.FC<{
    type: "success" | "error" | "warning";
    message: string;
  }> = ({ type, message }) => {
    const colors = {
      success: "bg-green-50 text-green-700 border-green-200",
      error: "bg-red-50 text-red-700 border-red-200",
      warning: "bg-yellow-50 text-yellow-700 border-yellow-200",
    };

    return (
      <motion.div
        initial={{ opacity: 0, y: -10 }}
        animate={{ opacity: 1, y: 0 }}
        className={`${colors[type]} px-4 py-2 rounded-lg border flex items-center mt-1`}
      >
        {type === "success" && <Check className="w-4 h-4 mr-2" />}
        {type === "error" && <AlertCircle className="w-4 h-4 mr-2" />}
        {type === "warning" && <AlertCircle className="w-4 h-4 mr-2" />}
        <span className="text-sm">{message}</span>
      </motion.div>
    );
    };
  
  const RequiredField: React.FC = () => (
    <span className="text-red-500 text-lg leading-none" title="Required field">
      *
    </span>
  );

  const renderStepIndicator = () => (
    <div className="flex items-center justify-center mb-8">
      {[1, 2, 3].map((step) => (
        <div key={step} className="flex items-center">
          <motion.div
            className={`w-8 h-8 rounded-full flex items-center justify-center ${
              step === currentStep
                ? "bg-blue-600 text-white"
                : step < currentStep
                ? "bg-green-500 text-white"
                : "bg-gray-200 text-gray-600"
            }`}
            whileHover={{ scale: 1.1 }}
          >
            {step < currentStep ? <Check className="w-4 h-4" /> : step}
          </motion.div>
          {step < 3 && (
            <div
              className={`w-12 h-1 ${
                step < currentStep ? "bg-green-500" : "bg-gray-200"
              }`}
            />
          )}
        </div>
      ))}
    </div>
  );

  
  const renderStep1 = () => (
    <motion.div
      variants={formVariants}
      initial="hidden"
      animate="visible"
      exit="exit"
      className="h-[400px] overflow-y-auto px-2 custom-scrollbar"
    >
      <h3 className="text-xl font-semibold mb-6 sticky top-0 bg-white z-10 pb-4 border-b">
        Team Information
      </h3>
      <div className="space-y-6">
        <div>
          <label className="block text-gray-700 text-sm font-medium mb-2 flex items-center">
            Team Name <RequiredField />
            <motion.span
              className="ml-2 text-xs text-gray-500"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
            >
              (3-30 characters)
            </motion.span>
          </label>
          <input
            type="text"
            value={teamName}
            onChange={(e) => handleTeamNameChange(e)}
            className={`w-full px-4 py-2 rounded-lg border ${
              errors.teamName ? "border-red-300" : "border-gray-300"
            } focus:outline-none focus:ring-2 focus:ring-blue-500`}
            placeholder="Enter unique team name"
            minLength={3}
            maxLength={30}
            required
          />
          {errors.teamName && (
            <StatusMessage type="error" message={errors.teamName} />
          )}
        </div>

        <div>
          <label className="block text-gray-700 text-sm font-medium mb-2 flex items-center">
            Team Captain Email <RequiredField />
          </label>
          <input
            type="email"
            value={captain}
            onChange={(e) => setCaptain(e.target.value)}
            className="w-full px-4 py-2 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
            placeholder="Enter captain's email"
            required
          />
          {errors.captain && (
            <StatusMessage type="error" message={errors.captain} />
          )}
        </div>
      </div>
    </motion.div>
  );

  const renderStep2 = () => (
    <motion.div
      variants={formVariants}
      initial="hidden"
      animate="visible"
      exit="exit"
      className="h-[400px] overflow-y-auto px-2 custom-scrollbar"
    >
      <h3 className="text-xl font-semibold mb-6 sticky top-0 bg-white z-10 pb-4 border-b">
        Team Members
      </h3>
      <div className="space-y-6">
        {members.map((member, index) => (
          <motion.div
            key={index}
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: index * 0.1 }}
          >
            <label className="block text-gray-700 text-sm font-medium mb-2 flex items-center">
              Team Member {index + 1} <RequiredField />
              {captain === member && (
                <span className="ml-2 text-xs bg-blue-100 text-blue-700 px-2 py-1 rounded-full">
                  Team Captain
                </span>
              )}
            </label>
            <input
              type="email"
              value={member}
              onChange={(e) => handleMemberChange(index, e.target.value)}
              className={`w-full px-4 py-2 rounded-lg border ${
                errors.members[index] ? "border-red-300" : "border-gray-300"
              } focus:outline-none focus:ring-2 focus:ring-blue-500`}
              placeholder="Enter member's email"
              required
            />
            {errors.members[index] && (
              <StatusMessage type="error" message={errors.members[index]} />
            )}
            {!errors.members[index] && !isMemberReg[index] && member && (
              <StatusMessage type="success" message="User is eligible" />
            )}
          </motion.div>
        ))}
      </div>
    </motion.div>
  );

  const renderStep3 = () => (
    <motion.div
      variants={formVariants}
      initial="hidden"
      animate="visible"
      exit="exit"
      className="h-[400px] overflow-y-auto px-2 custom-scrollbar"
    >
      <h3 className="text-xl font-semibold mb-6 sticky top-0 bg-white z-10 pb-4 border-b">
        Review & Confirm
      </h3>

      <div className="space-y-6">
        <motion.div
          className="bg-gray-50 p-6 rounded-lg space-y-4"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
        >
          <div>
            <h4 className="text-sm font-medium text-gray-500">Team Name</h4>
            <p className="text-lg font-medium">{teamName}</p>
          </div>

          <div>
            <h4 className="text-sm font-medium text-gray-500">Team Captain</h4>
            <p className="text-lg font-medium">{captain}</p>
          </div>

          <div>
            <h4 className="text-sm font-medium text-gray-500">Team Members</h4>
            <ul className="space-y-2 mt-2">
              {members.map((member, index) => (
                <motion.li
                  key={index}
                  className="flex items-center space-x-2 p-2 bg-white rounded-lg"
                  initial={{ opacity: 0, x: -20 }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{ delay: index * 0.1 }}
                >
                  <User className="w-4 h-4 text-gray-400" />
                  <span>{member}</span>
                  {captain === member && (
                    <span className="text-xs bg-blue-100 text-blue-700 px-2 py-1 rounded-full">
                      Captain
                    </span>
                  )}
                </motion.li>
              ))}
            </ul>
          </div>
        </motion.div>

        <motion.div
          className="bg-blue-50 p-6 rounded-lg"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.3 }}
        >
          <h4 className="font-medium flex items-center text-blue-700 mb-4">
            <Trophy className="w-5 h-5 mr-2" />
            Important Reminders
          </h4>
          <ul className="list-disc list-inside text-sm text-blue-600 space-y-2">
            <li>Join 15 minutes before your scheduled time</li>
            <li>Ensure all team members are in formal attire</li>
            <li>Keep your registration details handy</li>
            <li>All team members must be registered on the platform</li>
            <li>
              Changes to team composition are not allowed after registration
            </li>
          </ul>
        </motion.div>
      </div>
    </motion.div>
  );
  return (
    <AnimatePresence>
      <motion.div
        variants={overlayVariants}
        initial="hidden"
        animate="visible"
        className="fixed inset-0 bg-black bg-opacity-50 z-50 max-h-200px overflow-scroll flex items-center justify-center px-4"
      >
        <motion.div
          initial={{ scale: 0.9, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
          className="bg-white rounded-xl shadow-xl w-full max-w-2xl p-6 relative"
        >
          <button
            onClick={onClose}
            className="absolute right-4 top-4 text-gray-400 hover:text-gray-600"
          >
            <X className="w-6 h-6" />
          </button>

          {(isErr || allGood) && (
            <motion.div
              initial={{ opacity: 0, y: -10 }}
              animate={{ opacity: 1, y: 0 }}
              className="mb-4"
            >
              {isErr && (
                <StatusMessage
                  type="error"
                  message="Unable to register. Please try again later."
                />
              )}
              {allGood && (
                <StatusMessage
                  type="success"
                  message="Registration successful! Redirecting..."
                />
              )}
            </motion.div>
          )}

          {renderStepIndicator()}

          <form onSubmit={handleSubmit} className="space-y-8 max-h-400px">
            <AnimatePresence mode="wait">
              {currentStep === 1 && renderStep1()}
              {currentStep === 2 && renderStep2()}
              {currentStep === 3 && renderStep3()}
            </AnimatePresence>

            <div className="flex justify-between mt-8">
              {currentStep > 1 && (
                <motion.button
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                  type="button"
                  onClick={() => setCurrentStep(currentStep - 1)}
                  className="px-6 py-2 rounded-lg border border-gray-300 text-gray-700 hover:bg-gray-50"
                >
                  Back
                </motion.button>
              )}

              <motion.button
                whileHover={{ scale: 1.02 }}
                whileTap={{ scale: 0.98 }}
                type={currentStep === 3 ? "submit" : "button"}
                onClick={() => {
                  if (currentStep < 3) {
                    setCurrentStep(currentStep + 1);
                  }
                }}
                className="ml-auto px-6 py-2 rounded-lg bg-blue-600 text-white hover:bg-blue-700 flex items-center"
              >
                {currentStep === 3 ? "Confirm Registration" : "Next"}
                <ChevronRight className="w-4 h-4 ml-2" />
              </motion.button>
            </div>
          </form>
        </motion.div>
      </motion.div>
    </AnimatePresence>
  );
};

export default TeamRegisterPopover;
