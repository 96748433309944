// src/utils/faqSchema.ts
 const generateFAQSchema = () => ({
  "@context": "https://schema.org",
  "@type": "FAQPage",
  mainEntity: [
    {
      "@type": "Question",
      name: "What is AI Moot Court?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "AI Moot Court is an innovative platform that combines artificial intelligence with legal education to provide virtual moot court experiences for law students and professionals.",
      },
    },
    {
      "@type": "Question",
      name: "How does AI Moot Court work?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "AI Moot Court uses advanced AI technology to simulate court proceedings. Participants can present arguments, receive real-time feedback, and improve their advocacy skills in a virtual environment.",
      },
    },
    {
      "@type": "Question",
      name: "Who can participate in AI Moot Court?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "Law students, legal professionals, and institutions can participate in AI Moot Court. We offer both individual practice sessions and organized competitions.",
      },
    },
    {
      "@type": "Question",
      name: "What are the benefits of using AI Moot Court?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "Benefits include 24/7 practice availability, instant feedback, performance analytics, flexible scheduling, and cost-effective legal training without geographical limitations.",
      },
    },
    {
      "@type": "Question",
      name: "How do I register for a moot court competition?",
      acceptedAnswer: {
        "@type": "Answer",
        text: "You can register through our platform by creating an account, selecting your preferred competition, and completing the registration form. Both individual and team registrations are available.",
      },
    },
  ],
});

export default generateFAQSchema