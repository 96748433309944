import React, { useEffect, useRef, useState } from "react";
import { gsap } from "gsap";
import {
  PartyPopper,
  Mail,
  CheckCircle,
  X,
  Heart,
  Bell,
  Star,
} from "lucide-react";

interface NewsletterConfirmationProps {
  isOpen: boolean;
    email?: string;
    onClose: () => void;
}

const NewsletterConfirmation: React.FC<NewsletterConfirmationProps> = ({
  isOpen,
    email = "user@example.com",
  onClose
}) => {
  const popoverRef = useRef<HTMLDivElement>(null);
  const [confetti, setConfetti] = useState<
    Array<{ x: number; y: number; color: string }>
  >([]);

  useEffect(() => {
    if (isOpen) {
      const colors = ["#FFD700", "#FF69B4", "#00CED1", "#FF4500", "#32CD32"];
      const newConfetti = Array.from({ length: 50 }, (_, i) => ({
        x: Math.random() * window.innerWidth,
        y: -20,
        color: colors[Math.floor(Math.random() * colors.length)],
      }));
      setConfetti(newConfetti);
    }
  }, [isOpen]);

  useEffect(() => {
    if (isOpen && popoverRef.current) {
      gsap.fromTo(
        popoverRef.current,
        {
          scale: 0.5,
          opacity: 0,
          y: 50,
        },
        {
          scale: 1,
          opacity: 1,
          y: 0,
          duration: 0.5,
          ease: "back.out(1.7)",
        }
      );

      confetti.forEach((particle, i) => {
        gsap.to(`#confetti-${i}`, {
          y: window.innerHeight + 100,
          x: particle.x + (Math.random() - 0.5) * 200,
          rotation: Math.random() * 720 - 360,
          duration: 2 + Math.random() * 3,
          ease: "power1.out",
          delay: Math.random() * 0.5,
        });
      });
    }
  }, [isOpen, confetti]);

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-black/50">
      {confetti.map((particle, i) => (
        <div
          key={i}
          id={`confetti-${i}`}
          className="absolute w-3 h-3 rounded-sm"
          style={{
            left: particle.x,
            top: particle.y,
            backgroundColor: particle.color,
            transform: "rotate(0deg)",
          }}
        />
      ))}

      <div
        ref={popoverRef}
        className="bg-white rounded-2xl  shadow-2xl w-11/12 max-w-lg overflow-hidden"
      >
        <div className="bg-gradient-to-r from-blue-500 to-purple-500 p-6 text-white relative">
                  <button
                      className="absolute right-4 top-4 p-1 hover:bg-white/20 rounded-full transition-colors"
                  onClick={onClose}>
            <X className="h-6 w-6" />
          </button>
          <div className="flex items-center justify-center space-x-3">
            <Mail className="h-8 w-8 animate-bounce" />
            <h2 className="text-2xl font-bold">Welcome Aboard!</h2>
            <Heart className="h-8 w-8 animate-bounce" />
          </div>
        </div>

        <div className="p-6 flex flex-col items-center">
          <div className="mb-6 relative">
            <Star className="h-20 w-20 text-yellow-400 animate-pulse" />
            {/* <Bell className="h-8 w-8 text-purple-500 absolute -right-4 top-0 animate-bounce" /> */}
          </div>

          <div className="text-center space-y-4">
            <h3 className="text-xl font-semibold text-gray-800">
              You're In! 🎉
            </h3>
            <p className="text-gray-600">
              Thank you for joining our newsletter!
            </p>
            <p className="text-lg font-medium text-purple-600">{email}</p>
            <div className="flex items-center justify-center space-x-2 text-green-600">
              <CheckCircle className="h-5 w-5" />
              <span>Successfully subscribed</span>
            </div>
          </div>

          <div className="grid grid-cols-2 gap-4 mt-8 w-full max-w-sm">
            <div className="bg-blue-50 p-4 rounded-lg text-center">
              <p className="text-2xl font-bold text-blue-600">Weekly</p>
              <p className="text-sm text-blue-700">Updates</p>
            </div>
            <div className="bg-purple-50 p-4 rounded-lg text-center">
              <p className="text-2xl font-bold text-purple-600">Up to date</p>
              <p className="text-sm text-purple-700">In Legaltech</p>
            </div>
          </div>

          <div className="mt-6 bg-gray-50 p-4 rounded-lg w-full max-w-sm">
            <h4 className="font-semibold text-gray-700 mb-2">
              What to expect:
            </h4>
            <ul className="text-sm text-gray-600 space-y-2">
              <li className="flex items-center space-x-2">
                <CheckCircle className="h-4 w-4 text-green-500" />
                <span>Exclusive weekly content</span>
              </li>
              <li className="flex items-center space-x-2">
                <CheckCircle className="h-4 w-4 text-green-500" />
                <span>Special subscriber-only offers</span>
              </li>
              <li className="flex items-center space-x-2">
                <CheckCircle className="h-4 w-4 text-green-500" />
                <span>Early access to new features</span>
              </li>
            </ul>
          </div>

          <div className="mt-8 space-y-4">
            <button
              className="px-6 py-2 bg-gradient-to-r from-blue-500 to-purple-500 text-white rounded-lg hover:from-blue-600 hover:to-purple-600 transition-all transform hover:scale-105 active:scale-95 w-full"
              onClick={onClose}
            >
              Start Exploring
            </button>
            <p className="text-sm text-gray-500 text-center">
              Check your inbox for a welcome gift! 🎁
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewsletterConfirmation;
