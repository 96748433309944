import React, { useState, useEffect } from 'react';

const RegisterSuccess: React.FC = () => {
  const [seconds, setSeconds] = useState(5);

  useEffect(() => {
    
    const timer = setInterval(() => {
      setSeconds(prev => {
        if (prev <= 1) {
          clearInterval(timer);
            window.location.href = "/allMootCourts";
          return 0;
        }
        return prev - 1;
      });
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  return (
    <div className="bg-gray-100 h-screen flex items-center justify-center">
      <div className="bg-white p-6 md:mx-auto rounded-lg shadow-lg w-full max-w-md">
        <svg viewBox="0 0 24 24" className="text-green-600 w-16 h-16 mx-auto my-6">
          <path
            fill="currentColor"
            d="M12,0A12,12,0,1,0,24,12,12.014,12.014,0,0,0,12,0Zm6.927,8.2-6.845,9.289a1.011,1.011,0,0,1-1.43.188L5.764,13.769a1,1,0,1,1,1.25-1.562l4.076,3.261,6.227-8.451A1,1,0,1,1,18.927,8.2Z"
          />
        </svg>
        <div className="text-center">
          <h3 className="md:text-2xl text-base text-gray-900 font-semibold text-center">Registration Successful!</h3>
          <p className="text-gray-600 my-2">Thank you for completing your moot court registration.</p>
          <p className="text-gray-600">We are excited to have you with us.</p>
          <p className="text-gray-600 my-4">Redirecting you in {seconds} seconds...</p>
          <div className="py-10 text-center">
            <button
              className="w-full px-12 mt-3 bg-indigo-600 hover:bg-indigo-500 text-white font-semibold py-3 rounded-lg transition duration-300"
            >
              See Court
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegisterSuccess;
