import React, { useEffect, useState } from 'react';
import LoadingIcon from 'components/Loading/LoadingIcon';

interface PdfViewerProps {
    pdfBlob: Blob;
}

const PdfViewer: React.FC<PdfViewerProps> = ({ pdfBlob }) => {
    const [pdfUrl, setPdfUrl] = useState<string | null>(null);

    useEffect(() => {
        if (pdfBlob) {
            const url = URL.createObjectURL(pdfBlob);
            setPdfUrl(url);

            return () => {
                URL.revokeObjectURL(url); // Clean up the URL object
            };
        }
    }, [pdfBlob]);

    const handleDownload = () => {
        if (pdfUrl) {
            const link = document.createElement('a');
            link.href = pdfUrl;
            link.download = 'case.pdf'; // specify the file name
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    };

    return (
        <div className="w-full h-screen p-4 flex flex-col">
            {pdfUrl ? (
                <>
                    <iframe src={pdfUrl} title="PDF Viewer" className="w-full h-full mb-4" />
                    <button
                        className="mt-4 bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-700 self-end"
                        onClick={handleDownload}
                    >
                        Download PDF
                    </button>
                </>
            ) : (
                <LoadingIcon text="Loading PDF..." />
            )}
        </div>
    );
};

export default PdfViewer;
