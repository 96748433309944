import React, { FC, useRef, useEffect, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import SimpleBar from "simplebar-react";
import "simplebar-react/dist/simplebar.min.css";
import { Message } from "containers/PageCourt/types";
import ConversationLog from "containers/PageCourt/conversationLog";
import { X, MessageCircle, History, BookOpen } from "lucide-react";

interface SidebarProps {
  setExpand: (value: boolean) => void;
  messages: Message[];
  conversationLogRef: React.RefObject<HTMLDivElement>;
  isExpand: boolean;
  setIsExpand: React.Dispatch<React.SetStateAction<boolean>>;
}

const ConversationPopover: FC<SidebarProps> = ({
  isExpand,
  setIsExpand,
  setExpand,
  messages,
  conversationLogRef,
}) => {
  const popoverRef = useRef<HTMLDivElement>(null);
  const [isMobile, setIsMobile] = useState(false);

  // Check for mobile screen size
  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth < 768);
    };

    checkMobile();
    window.addEventListener("resize", checkMobile);
    return () => window.removeEventListener("resize", checkMobile);
  }, []);

  // Handle click outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        popoverRef.current &&
        !popoverRef.current.contains(event.target as Node)
      ) {
        setIsExpand(false);
        setExpand(false);
      }
    };

    if (isExpand) {
      document.addEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isExpand, setExpand, setIsExpand]);

  // Animation variants
  const popoverVariants = {
    mobile: {
      initial: { opacity: 0, y: 100 },
      animate: { opacity: 1, y: 0 },
      exit: { opacity: 0, y: 100 },
    },
    desktop: {
      initial: { opacity: 0, scale: 0.95, x: 20 },
      animate: { opacity: 1, scale: 1, x: 0 },
      exit: { opacity: 0, scale: 0.95, x: 20 },
    },
  };

  return (
    <AnimatePresence>
      {isExpand && (
        <>
          {/* Backdrop */}
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="fixed inset-0 bg-black/20 backdrop-blur-sm z-40"
            onClick={() => {
              setIsExpand(false);
              setExpand(false);
            }}
          />

          {/* Popover */}
          <motion.div
            ref={popoverRef}
            variants={
              isMobile ? popoverVariants.mobile : popoverVariants.desktop
            }
            initial="initial"
            animate="animate"
            exit="exit"
            transition={{ type: "spring", duration: 0.5 }}
            className={`
              fixed bg-white border border-gray-200 z-50 shadow-2xl
              ${
                isMobile
                  ? "inset-x-0 bottom-0 rounded-t-2xl max-h-[90vh]"
                  : "top-4 right-4 w-[min(400px,calc(100vw-2rem))] rounded-2xl max-h-[calc(100vh-2rem)]"
              }
            `}
          >
            {/* Header */}
            <div className="sticky top-0 z-10 bg-white border-b border-gray-100 rounded-t-2xl">
              <div className="p-4">
                <div className="flex items-center justify-between">
                  <div className="flex items-center gap-3">
                    <div className="relative group">
                      <div className="w-10 h-10 rounded-full overflow-hidden bg-blue-100 flex items-center justify-center">
                        <img
                          src="/conversation.gif"
                          alt="Conversation"
                          className="w-full h-full object-cover"
                        />
                      </div>
                    </div>
                    <div>
                      <h3 className="font-semibold text-gray-900">
                        Conversation History
                      </h3>
                      <p className="text-sm text-gray-500">
                        {messages.length} messages
                      </p>
                    </div>
                  </div>
                  <button
                    onClick={() => {
                      setIsExpand(false);
                      setExpand(false);
                    }}
                    className="p-2 hover:bg-gray-100 rounded-full transition-colors"
                  >
                    <X className="w-5 h-5 text-gray-500" />
                  </button>
                </div>
              </div>

              {/* Mobile Drag Handle */}
              {isMobile && (
                <div className="w-12 h-1.5 bg-gray-300 rounded-full mx-auto mb-2" />
              )}
            </div>

            <SimpleBar
              style={{
                maxHeight: isMobile
                  ? "calc(90vh - 5rem)"
                  : "calc(100vh - 10rem)",
                height: isMobile ? "auto" : "100%",
              }}
            >
              {/* Quick Tips */}
              <div className="p-4">
                <div className="bg-gradient-to-br from-blue-50 to-indigo-50 rounded-xl p-4">
                  <div className="flex items-center gap-2 text-blue-900 mb-3">
                    <BookOpen className="w-4 h-4" />
                    <h3 className="font-medium">Quick Tips</h3>
                  </div>
                  <ul className="space-y-2 text-sm text-blue-800">
                    <li className="flex items-center gap-2">
                      <span>💡</span>
                      <span>Review previous arguments</span>
                    </li>
                    <li className="flex items-center gap-2">
                      <span>📝</span>
                      <span>Take notes of key points</span>
                    </li>
                    <li className="flex items-center gap-2">
                      <span>🎯</span>
                      <span>Track your progress</span>
                    </li>
                  </ul>
                </div>
              </div>

              {/* Messages */}
              <div className="px-4 pb-4">
                {messages.length > 0 ? (
                  <div className="space-y-4">
                    <div className="flex items-center gap-2 text-gray-900 px-2">
                      <MessageCircle className="w-4 h-4" />
                      <h3 className="font-medium">Messages</h3>
                    </div>
                    <div className="bg-gray-50 rounded-xl p-4">
                      <ConversationLog
                        messages={messages}
                        conversationRef={conversationLogRef}
                      />
                    </div>
                  </div>
                ) : (
                  <div className="text-center py-8 bg-gray-50 rounded-xl">
                    <History className="w-12 h-12 text-gray-400 mx-auto mb-4" />
                    <p className="text-gray-600">
                      Start your conversation to see the history here!
                    </p>
                  </div>
                )}
              </div>
            </SimpleBar>
          </motion.div>
        </>
      )}
    </AnimatePresence>
  );
};

export default ConversationPopover;
