// src/components/PreMadeCasePopover.tsx
import React, { useState, useEffect } from 'react';
import { Popover, Transition } from '@headlessui/react';

const PreMadeCasePopover: React.FC<{ onClose: () => void }> = ({ onClose }) => {
    return (

        <Popover>
        <Transition
           show={true}
           enter="transition duration-100 ease-out"
           enterFrom="transform scale-95 opacity-0"
           enterTo="transform scale-100 opacity-100"
           leave="transition duration-75 ease-out"
           leaveFrom="transform scale-100 opacity-100"
           leaveTo="transform scale-95 opacity-0"
         >
           <Popover.Panel static className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
           <iframe
              src="/selectOurCase"
              title="Select our case"
              className="w-full h-full rounded-md"
            ></iframe>
            <div className="mt-4 flex justify-end">
                        <button
                            className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-700"
                            onClick={onClose}
                        >
                            Close
                        </button>
                    </div>
           </Popover.Panel>
           
       </Transition>
       </Popover>
    
      )
};

export default PreMadeCasePopover;
