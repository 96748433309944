import React, { useEffect, useState } from "react";
import { BookMarked, CheckCircle2Icon } from "lucide-react";
import axios from "axios";
import { apiUri } from "../../constants";

const BookmarkedCompletedIcon = () => {
  const [isBookmarked, setIsBookmarked] = useState(false);
  const [isCompleted, setIsCompleted] = useState(false);

  const caseId = sessionStorage.getItem("caseId");
  const email = sessionStorage.getItem("email");

  useEffect(() => {
    if (caseId && email) {
      axios
        .post(`${apiUri}/isCaseBookmarked`, { caseId: caseId, email: email })
        .then((response) => {
          setIsBookmarked(response.data.success);
        })
        .catch((error) =>
          console.error("Error checking bookmark status:", error)
        );

      axios
        .post(`${apiUri}/isCaseCompleted`, { caseId: caseId, email: email })
        .then((response) => {
          setIsCompleted(response.data.success);
        })
        .catch((error) =>
          console.error("Error checking completion status:", error)
        );
    }
  }, [caseId, email]);

  const handleBookmarkClick = () => {
    if (isBookmarked) {
      axios
        .post(`${apiUri}/removeCaseFromBookmark`, {
          caseId: caseId,
          email: email,
        })
        .then(() => {
          setIsBookmarked(false);
        })
        .catch((error) => console.error("Error removing bookmark:", error));
    } else {
      axios
        .post(`${apiUri}/addToBookmark`, { caseId: caseId, email: email })
        .then(() => {
          setIsBookmarked(true);
        })
        .catch((error) => console.error("Error adding bookmark:", error));
    }
  };

  return (
    <div className="flow-root">
      <div className="flex text-neutral-700 dark:text-neutral-300 text-sm gap-3">
        <div
          className={`group flex bg-white border-purple-200 border-2 items-center gap-2 px-4 py-2.5 rounded-lg 
        ${
          isBookmarked
            ? "bg-blue-50 dark:bg-blue-900/20 text-blue-600 dark:text-blue-400"
            : "hover:bg-neutral-50 dark:hover:bg-neutral-800"
        } 
        cursor-pointer transition-all duration-200 ease-in-out
        hover:shadow-sm`}
          title={isBookmarked ? "Remove Bookmark" : "Bookmark this item"}
          onClick={handleBookmarkClick}
          role="button"
          tabIndex={0}
        >
          <BookMarked
            className={`w-5 h-5  transition-colors duration-200
          ${
            isBookmarked
              ? "text-blue-600 dark:text-blue-400"
              : "text-neutral-400 dark:text-neutral-500 group-hover:text-blue-500 dark:group-hover:text-blue-400"
          }`}
          />
          <span className="hidden sm:block text-sm font-medium">
            {isBookmarked ? "Bookmarked" : "Bookmark"}
          </span>
        </div>

        <div
          className={`group flex items-center bg-white border-purple-200 border-2 gap-2 px-4 py-2.5 rounded-lg 
        ${
          isCompleted
            ? "bg-green-50 dark:bg-green-900/20 text-green-600 dark:text-green-400"
            : "hover:bg-neutral-50 dark:hover:bg-neutral-800"
        }
        transition-all duration-200 ease-in-out`}
          title={isCompleted ? "Completed" : "Not Completed"}
          role="status"
        >
          <CheckCircle2Icon
            className={`w-5 h-5 transition-colors duration-200
          ${
            isCompleted
              ? "text-green-600 dark:text-green-400"
              : "text-neutral-400 dark:text-neutral-500"
          }`}
          />
          <span className="hidden sm:block text-sm font-medium">
            {isCompleted ? "Completed" : "Not Completed"}
          </span>
        </div>
      </div>
    </div>
  );
};

export default BookmarkedCompletedIcon;
