import React from "react";
import { motion } from "framer-motion";
import { Scale } from "lucide-react";

interface LoadingIconProps {
  text?: string;
}

const LoadingIcon: React.FC<LoadingIconProps> = ({ text = "Loading..." }) => {
  return (
    <div className="flex flex-col items-center justify-center min-h-[400px]">
      <div className="relative">
        {/* Outer Circle with Gavel Animation */}
        <motion.div
          animate={{ rotate: 360 }}
          transition={{
            duration: 2,
            repeat: Infinity,
            ease: "linear",
          }}
          className="relative w-24 h-24"
        >
          {/* Animated dots */}
          {[...Array(8)].map((_, i) => (
            <motion.div
              key={i}
              className="absolute w-3 h-3 rounded-full"
              style={{
                top: "50%",
                left: "50%",
                transform: `rotate(${i * 45}deg) translate(36px, 0)`,
              }}
              animate={{
                scale: [0.6, 1, 0.6],
                opacity: [0.3, 1, 0.3],
              }}
              transition={{
                duration: 1.5,
                repeat: Infinity,
                delay: i * 0.15,
              }}
            >
              <div className="w-full h-full bg-purple-600 rounded-full" />
            </motion.div>
          ))}
        </motion.div>

        {/* Center Scale of Justice */}
        <motion.div
          className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white rounded-full p-4"
          animate={{
            scale: [1, 1.1, 1],
          }}
          transition={{
            duration: 2,
            repeat: Infinity,
            ease: "easeInOut",
          }}
        >
          <Scale className="w-8 h-8 text-purple-600" />
        </motion.div>

        {/* Pulsing Ring */}
        <motion.div
          className="absolute top-1/2 left-1/2 w-20 h-20 transform -translate-x-1/2 -translate-y-1/2 border-2 border-purple-600/30 rounded-full"
          animate={{
            scale: [1, 1.2, 1],
            opacity: [0.3, 0.1, 0.3],
          }}
          transition={{
            duration: 2,
            repeat: Infinity,
            ease: "easeInOut",
          }}
        />
      </div>

      {/* Loading Text */}
      {text && (
        <motion.div
          className="mt-8 text-center"
          initial={{ opacity: 0, y: 10 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          <div className="text-lg font-semibold bg-gradient-to-r from-purple-600 to-blue-600 bg-clip-text text-transparent">
            {text}
          </div>
          <motion.div
            className="mt-2 flex justify-center gap-1"
            animate={{
              opacity: [0.5, 1, 0.5],
            }}
            transition={{
              duration: 1.5,
              repeat: Infinity,
              ease: "easeInOut",
            }}
          >
            <div className="text-sm text-gray-500">Balancing justice</div>
            <motion.div
              animate={{
                translateY: [-1, 1, -1],
              }}
              transition={{
                duration: 1.5,
                repeat: Infinity,
                ease: "easeInOut",
              }}
              className="text-purple-600"
            >
              ⚖️
            </motion.div>
          </motion.div>
        </motion.div>
      )}
    </div>
  );
};

// Alternative Version with Paragraphs Animation
const LoadingIconAlt: React.FC<LoadingIconProps> = ({
  text = "Loading...",
}) => {
  return (
    <div className="flex flex-col items-center justify-center min-h-[400px]">
      <div className="relative">
        {/* Animated Legal Document Lines */}
        <div className="relative w-32 h-40 bg-white rounded-lg shadow-lg overflow-hidden">
          {[...Array(8)].map((_, i) => (
            <motion.div
              key={i}
              className="h-2 rounded-full my-2 mx-4"
              style={{
                backgroundColor: i === 0 ? "#7C3AED" : "#E2E8F0",
                width: i === 0 ? "70%" : `${Math.random() * 40 + 50}%`,
              }}
              animate={{
                opacity: [0.5, 1, 0.5],
                x: [-4, 0, -4],
              }}
              transition={{
                duration: 2,
                repeat: Infinity,
                delay: i * 0.2,
              }}
            />
          ))}

          {/* Scanning Line Effect */}
          <motion.div
            className="absolute top-0 left-0 w-full h-1 bg-gradient-to-r from-transparent via-purple-500 to-transparent"
            animate={{
              y: [0, 160, 0],
            }}
            transition={{
              duration: 2,
              repeat: Infinity,
              ease: "linear",
            }}
          />
        </div>

        {/* Scale of Justice Overlay */}
        <motion.div
          className="absolute -top-4 -right-4 bg-purple-600 rounded-full p-3 shadow-lg"
          animate={{
            rotate: [0, 360],
          }}
          transition={{
            duration: 4,
            repeat: Infinity,
            ease: "linear",
          }}
        >
          <Scale className="w-6 h-6 text-white" />
        </motion.div>
      </div>

      {/* Loading Text */}
      {text && (
        <motion.div
          className="mt-8 text-center"
          animate={{
            opacity: [0.5, 1, 0.5],
          }}
          transition={{
            duration: 2,
            repeat: Infinity,
            ease: "easeInOut",
          }}
        >
          <div className="text-lg font-semibold text-purple-600">{text}</div>
          <div className="text-sm text-gray-500 mt-1">
            Processing legal documents...
          </div>
        </motion.div>
      )}
    </div>
  );
};

export default LoadingIcon;
