// src/components/TeamSelectPopover.tsx
import React, { useState, useEffect } from 'react';
import { Popover, Transition } from '@headlessui/react';
import axios from 'axios';
import { apiUri } from '../../constants';

interface TeamSelectPopoverProps {

    onClose: () => void;
    hostedCourtName: string;
    // teams: Array<{ name: string, id: number }>;
    // selectedTeamId: number | null;
    // selectedTeamName: string | null;
};

const TeamSelectPopover: React.FC<TeamSelectPopoverProps> = ({ onClose, hostedCourtName }) => {

    const handleGetEmail = () => {
        return sessionStorage.getItem("email") as string;
    }

    const handleJoinProsecution = async () => {
        const email = handleGetEmail();
        try {

            const response = await axios.post(`${apiUri}/joinCourtInTeam`, {
                hostedCourtName: hostedCourtName,
                teamName: 'Prosecutor',
                userId: email,
            });
            if (response.data.success) {
                //console.log('Successfully joined the Prosecution team.')
                sessionStorage.setItem("hostedCourtName", hostedCourtName);
                sessionStorage.setItem("teamName", "Prosecutor");
                onClose()
                window.location.href = '/collegeMootcourt'
            } else {
                console.error('Error joining the Prosecution team:', response.data.error);
            }
        }
        catch (err) {
            console.error('Error joining the court as Prosecutor:', err);
        }

    };

    const handleJoinDefence = async () => {
        const email = handleGetEmail();
        try {

            const response = await axios.post(`${apiUri}/joinCourtInTeam`, {
                hostedCourtName: hostedCourtName,
                teamName: 'Defendant',
                userId: email,
            });
            if (response.data.success) {
                //console.log('Successfully joined the Defendant team.')
                sessionStorage.setItem("hostedCourtName", hostedCourtName);
                sessionStorage.setItem("teamName", "Defendant");
                onClose()
                window.location.href = '/collegeMootcourt';
            } else {
                console.error('Error joining the Defendant team:', response.data.error);
            }
        }
        catch (err) {
            console.error('Error joining the court as Defendant:', err);
        }

    }

    const handleBackToLobby = () => {
        window.location.href = "/lobby"
    }

    return (
        <Popover>
            <Transition
                show={true}
                enter="transition duration-100 ease-out"
                enterFrom="transform scale-95 opacity-0"
                enterTo="transform scale-100 opacity-100"
                leave="transition duration-75 ease-out"
                leaveFrom="transform scale-100 opacity-100"
                leaveTo="transform scale-95 opacity-0"
            >
                <Popover.Panel static className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
                    <div className="bg-creamyYellow dark:bg-gray-800 rounded-lg p-4 shadow-lg max-w-md w-full">
                        <h2 className="text-xl font-bold mb-2 text-neutral-900 dark:text-neutral-100">Pick a side</h2>

                        <div className="text-center">

                            <button
                                type="submit"
                                onClick={handleJoinDefence}
                                className="bg-teal-500 mt-4 text-white px-4 py-2 w-full rounded-md hover:bg-teal-700 mr-2"
                            >
                                Join Defence Team
                            </button>

                            <button
                                type="submit"
                                onClick={handleJoinProsecution}
                                className="bg-cyan-500 mt-4 text-white px-4 py-2 w-full rounded-md hover:bg-cyan-700 mr-2"
                            >
                                Join Prosecution Team
                            </button>
                        </div>
                        <div className='text-center justify-center'>
                            <button
                                type="submit"
                                onClick={handleBackToLobby}
                                className="bg-red-500 mt-4 text-white px-4 py-2 w-1/2 rounded-md hover:bg-red-700 mr-2"
                            >
                                Back to lobby
                            </button>
                        </div>
                    </div>

                </Popover.Panel>
            </Transition>
        </Popover>
    );
};

export default TeamSelectPopover;
