import React, { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { Gavel } from "lucide-react";
import AudienceSection from "./AudienceSection";
import PixelCharacter from "./PixelatedCharacter";

// Assuming AudienceSection and PixelCharacter are imported
const PixelatedCourtroom = ({
  messages,
  isProcessing,
}: {
  messages: Array<{
    role: "judge" | "user" | "ai";
    text: string;
  }>;
  isProcessing: boolean;
}) => {
  const [showGavel, setShowGavel] = useState(false);
  const currentMessage = messages[messages.length - 1];

  // alert(currentMessage.text)
  // alert(currentMessage.role)

  // Show gavel animation when judge speaks
  useEffect(() => {
    if (currentMessage?.role === "judge") {
      setShowGavel(true);
      const timer = setTimeout(() => setShowGavel(false), 1000);
      return () => clearTimeout(timer);
    }
  }, [messages]);

  return (
    <div className="w-full max-w-7xl mx-auto">
      <div className="relative aspect-[16/9] w-full bg-gradient-to-b from-amber-50 to-amber-100 rounded-2xl border-4 border-amber-900 overflow-hidden shadow-2xl">
        {/* Courtroom Layout */}
        <div className="grid grid-rows-[45%_55%] h-full">
          {/* Upper Section with Judge */}
          <div className="relative bg-gradient-to-b from-amber-100 to-amber-200">
            {/* Decorative Columns */}
            <div className="absolute inset-0 flex justify-around">
              {[...Array(5)].map((_, i) => (
                <div key={i} className="w-8 h-full bg-amber-300/30">
                  <div className="w-full h-4 mt-4 bg-amber-400/30" />
                  <div className="w-full h-4 mt-auto bg-amber-400/30" />
                </div>
              ))}
            </div>

            {/* State Seal */}
            <div className="absolute top-4 left-1/2 -translate-x-1/2 w-24 h-24 rounded-full border-4 border-amber-700 bg-amber-200">
              <div className="absolute inset-2 rounded-full border-2 border-amber-600" />
              <div className="absolute inset-4 rounded-full bg-amber-500/20" />
            </div>

            {/* Judge's Platform */}
            <div className="absolute bottom-0 left-1/2 -translate-x-1/2 w-full max-w-2xl">
              <div className="relative h-32 bg-gradient-to-b from-amber-800 to-amber-900 border-4 border-amber-950 rounded-t-xl">
                {/* Judge Character */}
                <div className="absolute -top-12 left-1/2 -translate-x-1/2">
                  <PixelCharacter
                    type="judge"
                    isThinking={
                      currentMessage?.role === "judge" && isProcessing
                    }
                    message={
                      currentMessage?.role === "judge"
                        ? currentMessage.text
                        : ""
                    }
                  />
                </div>
              </div>
            </div>
          </div>

          {/* Lower Section with Lawyers and Audience */}
          <div className="grid grid-rows-[60%_40%]">
            {/* Lawyers Area */}
            <div className="relative">
              {/* Center Aisle */}
              <div className="absolute inset-0">
                <div className="h-full w-32 mx-auto bg-red-800/20 border-x border-red-900/30" />
              </div>

              {/* Lawyers */}
              <div className="grid grid-cols-2 h-full px-8">
                {/* Defense Side */}
                <div className="relative flex items-center justify-center">
                  <PixelCharacter
                    type="user"
                    isThinking={currentMessage?.role === "user" && isProcessing}
                    message={
                      currentMessage?.role === "user" ? currentMessage.text : ""
                    }
                  />
                </div>

                {/* Prosecutor Side */}
                <div className="relative flex items-center justify-center">
                  <PixelCharacter
                    type="prosecutor"
                    isThinking={
                      currentMessage?.role === "ai" && isProcessing
                    }
                    message={
                      currentMessage?.role === "ai"
                        ? currentMessage.text
                        : ""
                    }
                  />
                </div>
              </div>
            </div>

            {/* Audience Area */}
            <div className="relative">
              <AudienceSection />
            </div>
          </div>
        </div>

        {/* Gavel Animation */}
        <AnimatePresence>
          {showGavel && (
            <motion.div
              initial={{ opacity: 0, scale: 0, rotate: -45 }}
              animate={{ opacity: 1, scale: 1, rotate: 45 }}
              exit={{ opacity: 0, scale: 0, rotate: -45 }}
              className="absolute top-1/3 left-1/2 -translate-x-1/2 -translate-y-1/2"
            >
              <Gavel className="w-16 h-16 text-amber-900" />
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </div>
  );
};

export default PixelatedCourtroom;
