import { motion } from "framer-motion";
import React from "react";

const AudienceHead = ({ delay = 0, row = 0 }) => {
  const skinTones = [
    "bg-amber-200", // very light
    "bg-amber-300", // light
    "bg-amber-400", // medium light
    "bg-amber-500", // medium
    "bg-amber-600", // medium dark
    "bg-amber-700", // dark
  ];

  const hairColors = [
    "bg-neutral-950", // black
    "bg-neutral-800", // dark brown
    "bg-neutral-700", // brown
    "bg-amber-800", // auburn
    "bg-yellow-700", // dark blonde
    "bg-yellow-600", // blonde
    "bg-slate-400", // gray
    "bg-white", // white
  ];

  const skinTone = skinTones[Math.floor(Math.random() * skinTones.length)];
  const hairColor = hairColors[Math.floor(Math.random() * hairColors.length)];

  // Randomize head tilt slightly
  const rotation = Math.random() * 6 - 3;

  // Add slight variation to animation timing
  const duration = 10 + Math.random() * 1.5;

  return (
    <motion.div
      className="relative"
      animate={{
        y: [-1, 1, -1],
      }}
      transition={{
        duration: 2 + Math.random(), // Random duration between 2-3s
        repeat: Infinity,
        delay: delay,
      }}
    >
      {/* Neck */}
      <div className={`w-3 h-2 ${skinTone} mx-auto rounded-b-sm`} />

      {/* Head */}
      <div className={`w-8 h-8 ${skinTone} rounded-full relative shadow-md`}>
        {/* Hair */}
        <div
          className={`absolute -top-1 -left-1 -right-1 h-5 ${hairColor} rounded-t-full`}
          style={{
            clipPath: "ellipse(50% 50% at 50% 50%)",
          }}
        />

        {/* Face Features */}
        <div className="absolute top-3 left-2 w-1 h-[2px] bg-neutral-900 rounded-full shadow-sm" />
        <div className="absolute top-3 right-2 w-1 h-[2px] bg-neutral-900 rounded-full shadow-sm" />

        {/* Expressions - randomly choose between smile and neutral */}
        {Math.random() > 0.3 ? (
          <div className="absolute bottom-2 left-1/2 -translate-x-1/2 w-2 h-[2px] bg-neutral-900 rounded-full transform rotate-[10deg]" />
        ) : (
          <div className="absolute bottom-2 left-1/2 -translate-x-1/2 w-3 h-[2px] bg-neutral-900 rounded-full" />
        )}
      </div>

      {/* Body hint */}
      <div
        className={`w-6 h-3 ${hairColor} mx-auto rounded-t-lg mt-1 opacity-80`}
      />
    </motion.div>
  );
};

const AudienceRow = ({ count = 8, rowIndex = 0 }) => {
  // Add slight vertical offset for depth
  const yOffset = rowIndex * 4;

  return (
    <div
      className="flex justify-around items-start w-full py-2 px-4"
      style={{ transform: `translateY(${yOffset}px)` }}
    >
      {Array.from({ length: count }).map((_, index) => (
        <AudienceHead
          key={index}
          delay={index * 0.1 + rowIndex * 0.2}
          row={rowIndex}
        />
      ))}
    </div>
  );
};

const AudienceSection = () => {
  return (
    <div className="grid grid-rows-4 gap-1 bg-gradient-to-b from-amber-900/20 to-amber-950/30 p-8 rounded-lg shadow-inner">
      {Array.from({ length: 4 }).map((_, index) => (
        <AudienceRow
          key={index}
          count={8 - Math.floor(index / 2)}
          rowIndex={index}
        />
      ))}
    </div>
  );
};

export default AudienceSection;
