import React, { useEffect, useRef } from "react";
import { motion, useAnimation } from "framer-motion";
import {
  Shield,
  Clock,
  DollarSign,
  Brain,
  Settings,
  CalendarCheck,
} from "lucide-react";

const SideBenefits = () => {
  const benefits = [
    {
      icon: <Shield className="w-6 h-6 text-purple-600" />,
      title: "Full Admin Control",
      description: "Complete access to manage your competition",
    },
    {
      icon: <CalendarCheck className="w-6 h-6 text-purple-600" />,
      title: "Automated Scheduling",
      description: "We handle all court scheduling",
    },
    {
      icon: <Settings className="w-6 h-6 text-purple-600" />,
      title: "Easy Management",
      description: "Intuitive interface for all operations",
    },
    {
      icon: <Brain className="w-6 h-6 text-purple-600" />,
      title: "AI-Powered Judging",
      description: "Consistent, unbiased evaluation",
    },
    {
      icon: <Clock className="w-6 h-6 text-purple-600" />,
      title: "Save Time",
      description: "Reduce administrative overhead",
    },
    {
      icon: <DollarSign className="w-6 h-6 text-purple-600" />,
      title: "Cost Effective",
      description: "Eliminate external judge expenses",
    },
  ];


  return (
    <div className="w-full max-w-md pl-8 pt-8">
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        className="mb-8"
      >
        <h2 className="text-2xl font-bold bg-gradient-to-r from-purple-600 to-blue-600 bg-clip-text text-transparent mb-4">
          Why Host With Us?
        </h2>
        <p className="text-gray-600">
          Transform your moot court competition with our cutting-edge platform
        </p>
      </motion.div>

      <div className="space-y-4">
        {benefits.map((benefit, index) => (
          <motion.div
            key={index}
            custom={index}
          
            className="bg-white p-4 rounded-lg shadow-md hover:shadow-lg transition-all duration-300"
          >
            <div className="flex items-start space-x-4">
              <div className="p-2 bg-purple-50 rounded-lg">{benefit.icon}</div>
              <div>
                <h3 className="font-semibold text-gray-800">{benefit.title}</h3>
                <p className="text-sm text-gray-600">{benefit.description}</p>
              </div>
            </div>
          </motion.div>
        ))}
      </div>
    </div>
  );
};

export default SideBenefits;
