import React, { FC, useEffect, useState } from "react";
import { CaseDataType } from "data/types";
import Pagination from "shared/Pagination/Pagination";
import CaseCard from "components/CaseCard/CaseCard";
import axios from "axios";
import { apiUri } from "../../constants";
import LoadingIcon from "components/Loading/LoadingIcon";
import HowItWorksPopover from "containers/CaseDetails/HowItWorksPopover";
import SurpriseMe from "./SurpriseMe";
import { Bookmark, Crown, FileText, Filter, Search, Star } from "lucide-react";
import { AnimatePresence } from "framer-motion";
import { generatePracticePage } from "utils/schema";
import { Schema } from "components/Schema/Schema";

export interface SectionGridFilterCardProps {
  className?: string;
  data?: CaseDataType[];
}

const SectionGridFilterCard: FC<SectionGridFilterCardProps> = ({
  className = "",
}) => {
  const [cases, setCases] = useState<CaseDataType[]>([]);
  const [filteredCases, setFilteredCases] = useState<CaseDataType[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [bookmarkedIds, setBookmarkedIds] = useState<string[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [caseType, setCaseType] = useState<string>("All");
  const [showBookmarked, setShowBookmarked] = useState<boolean>(false);

  const casesPerPage = 15;
  const [currentPage, setCurrentPage] = useState<number>(1);
  const subscribed = sessionStorage.getItem("subscribed");
  const [freeCases, setFreeCases] = useState(0);
  const [displayWorkingPopover, setDisplayWorkingPopover] = useState<boolean>(
    !sessionStorage.getItem("dontshowHowitWorks")
  );

  const fetchRandomCase = () => {
    if (cases.length === 0) return;
    const randomIndex = Math.floor(Math.random() * cases.length);
    const randomCase = cases[randomIndex];
    setSearchTerm(randomCase.title);
  };

  // Fetch bookmarked cases
  const fetchBookmarkedCases = async () => {
    try {
      const email = sessionStorage.getItem("email");
      if (email) {
        const response = await axios.post(`${apiUri}/listBookmarkedCases`, {
          email: email,
        });
        setBookmarkedIds(response.data.data || []);
        console.log(bookmarkedIds);
      }
    } catch (error) {
      console.error("Error fetching bookmarked cases:", error);
    }
  };

  // Fetch free trial cases
  useEffect(() => {
    const freeTrial = async () => {
      const email = sessionStorage.getItem("email");
      if (email) {
        try {
          const response = await axios.post(`${apiUri}/getFreeTrial`, {
            email,
          });
          if (response.data.data.freeCases > 0) {
            setFreeCases(response.data.data.freeCases);
            sessionStorage.setItem(
              "freeCases",
              response.data.data.freeCases.toString()
            );
          } else {
            setFreeCases(0);
            sessionStorage.setItem("freeCases", "0");
          }
        } catch (error) {
          console.error("Error fetching trial remaining details:", error);
        }
      }
    };
    freeTrial();
  }, []);

  // Fetch all cases
  useEffect(() => {
    const fetchCases = async () => {
      try {
        const response = await axios.get(`${apiUri}/fetchAllCases`);
        const shuffledCases = shuffleCases(response.data.data);
        setCases(shuffledCases);
        setFilteredCases(shuffledCases);
      } catch (error) {
        setError("Error fetching case data");
      } finally {
        setLoading(false);
      }
    };

    fetchCases();
  }, []);

  const closePopover = () => {
    setDisplayWorkingPopover(false);
  };

  const shuffleCases = (array: CaseDataType[]): CaseDataType[] => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  };

  // Filter cases based on search, type, and bookmarks
  useEffect(() => {
    const filterCases = () => {
      let tempCases = cases;

      // Filter by bookmarks if selected
      if (showBookmarked) {
        tempCases = tempCases.filter((caseItem) =>
          bookmarkedIds.includes(caseItem.id)
        );
      }

      // Apply category filter
      if (caseType !== "All") {
        tempCases = tempCases.filter(
          (caseItem) => caseItem.type.toLowerCase() === caseType.toLowerCase()
        );
      }

      // Apply search filter
      if (searchTerm) {
        tempCases = tempCases.filter((caseItem) =>
          caseItem.title.toLowerCase().includes(searchTerm.toLowerCase())
        );
      }

      setFilteredCases(tempCases);
    };

    filterCases();
    setCurrentPage(1);
  }, [searchTerm, caseType, cases, showBookmarked, bookmarkedIds]);

  const indexOfLastCase = currentPage * casesPerPage;
  const indexOfFirstCase = indexOfLastCase - casesPerPage;
  const currentCases = filteredCases.slice(indexOfFirstCase, indexOfLastCase);

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  if (loading) {
    return <LoadingIcon />;
  }

  if (error) {
    return <p>{error}</p>;
  }

  const handleBookmarkClick = async () => {
    setShowBookmarked(!showBookmarked);
    if (!showBookmarked) {
      await fetchBookmarkedCases();
    }
  };

  return (
    <div
      className={`nc-SectionGridFilterCard ${className}`}
      data-nc-id="SectionGridFilterCard"
    >
      <Schema data={generatePracticePage} />
      <AnimatePresence>
        {displayWorkingPopover && <HowItWorksPopover onClose={closePopover} />}
      </AnimatePresence>

      <div className="bg-white px-4 py-6">
        {/* Header Section */}
        <div className="flex flex-col md:flex-row md:items-center gap-6 mb-8">
          <div className="flex flex-col">
            <div className="flex items-center gap-3">
              <div className="p-2 bg-purple-100 rounded-xl">
                <FileText className="w-6 h-6 text-purple-600" />
              </div>
              <h2 className="text-3xl font-bold text-gray-900">
                Practice Cases
              </h2>
            </div>
            <span className="text-sm text-gray-500 mt-1 ml-11">
              New cases added every week
            </span>
          </div>

          {/* Subscription Status */}
          {subscribed !== null && (
            <div className="md:ml-auto">
              {subscribed === "true" ? (
                <div className="inline-flex items-center gap-2 px-4 py-2 bg-gradient-to-r from-purple-600 to-blue-600 text-white rounded-full shadow-lg">
                  <Crown className="w-5 h-5" />
                  <span className="font-medium">Premium Access</span>
                </div>
              ) : (
                <div className="inline-flex items-center gap-2 px-4 py-2 bg-amber-100 text-amber-800 rounded-full">
                  <Star className="w-5 h-5" />
                  <span className="font-medium">
                    {freeCases} Free Cases Remaining
                  </span>
                </div>
              )}
            </div>
          )}
        </div>

        {/* Search and Filter Bar */}
        <div className="bg-white rounded-2xl shadow-lg p-2">
          <div className="flex flex-col sm:flex-row gap-2">
            {/* Category Dropdown */}
            <div className="relative min-w-[160px]">
              <select
                className="w-full px-4 py-2.5 text-gray-700 bg-gray-50 rounded-xl appearance-none cursor-pointer focus:outline-none focus:ring-2 focus:ring-purple-500"
                value={caseType}
                onChange={(e) => setCaseType(e.target.value)}
              >
                <option value="All">All Categories</option>
                <option value="Criminal">Criminal Law</option>
                <option value="Civil">Civil Law</option>
                <option value="Commercial">Commercial Law</option>
              </select>
              <Filter className="w-4 h-4 text-gray-400 absolute right-4 top-1/2 transform -translate-y-1/2 pointer-events-none" />
            </div>

            {/* Bookmark Toggle */}
            <button
              onClick={handleBookmarkClick}
              className={`
          flex items-center justify-center gap-2 px-4 py-2.5 rounded-xl font-medium transition-all
          ${
            showBookmarked
              ? "bg-purple-600 text-white hover:bg-purple-700"
              : "bg-gray-50 text-gray-700 hover:bg-gray-100"
          }
        `}
            >
              <Bookmark
                className={`w-4 h-4 ${showBookmarked ? "fill-current" : ""}`}
              />
              <span>Bookmarked</span>
            </button>

            {/* Search Input */}
            <div className="flex-grow relative">
              <input
                type="search"
                placeholder="Search cases by title or description..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="w-full px-4 py-2.5 pl-12 pr-12 bg-gray-50 text-gray-700 rounded-xl focus:outline-none focus:ring-2 focus:ring-purple-500"
              />
              <Search className="w-5 h-5 text-gray-400 absolute left-4 top-1/2 transform -translate-y-1/2" />
              <button className="absolute right-3 top-1/2 transform -translate-y-1/2 p-1.5 bg-purple-600 text-white rounded-lg hover:bg-purple-700 transition-colors">
                <Search className="w-4 h-4" />
                <span className="sr-only">Search</span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="container mx-auto px-4">
        {showBookmarked && filteredCases.length === 0 ? (
          <div className="text-center py-12">
            <Bookmark className="w-16 h-16 mx-auto text-gray-400 mb-4" />
            <h3 className="text-xl font-semibold text-gray-700 mb-2">
              No Bookmarked Cases
            </h3>
            <p className="text-gray-500">
              You haven't bookmarked any cases yet.
            </p>
          </div>
        ) : (
          <div className="grid grid-cols-1 gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
            {!showBookmarked && <SurpriseMe onSurprise={fetchRandomCase} />}
            {currentCases.map((caseItem) => (
              <CaseCard key={caseItem.id} data={caseItem} />
            ))}
          </div>
        )}
      </div>

      <div className="flex mt-16 justify-center items-center">
        <Pagination
          currentPage={currentPage}
          totalPages={Math.ceil(filteredCases.length / casesPerPage)}
          onPageChange={handlePageChange}
        />
      </div>
    </div>
  );
};

export default SectionGridFilterCard;
